/* eslint-disable camelcase */
import {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import styled from "styled-components/macro"
import { FieldValues, useForm, UseFormRegister } from "react-hook-form"
import { DateTime } from "luxon"
import { useIntl } from "react-intl"

import * as Ct from "ldlj"
import {
  addProductReset,
  createFinalInvoiceThunk,
  createInvoicePreviewThunk,
  getProductsThunk,
  ProductFormatted,
  resetPreviewUrl,
  selectClientList,
  hasDuplicateInvoiceThunk,
  resetDuplicateStatusAction,
  getInvoiceForOvertakingThunk,
  ProductForInvoice,
  overtakingResetClientAndProducts,
  urlFailure,
  modifyProductReset,
  modifyProductThunk,
  getInvoicingAdditionalLogosThunk,
  CreateOrModifyClientReset,
  InvoiceEmailConfig,
  createInvoiceDraftThunk,
  draftInvoiceReset,
  getUnitsThunk,
} from "../../../../store/ducks/invoicing.duck"
import { getVatRatesThunk } from "../../../../store/ducks/vatRates.ducks"
import { useRNBSelector } from "../../../../store/rootReducer"
import { CompanyLegalForm, getIdFromParams } from "../../../../utils/company"
import { CreateOrModifyClientModal } from "../../../../components/CreateOrModifyClientModal"
import { ReactComponent as WhiteCross } from "../../../../assets/cross-white.svg"
import { ReactComponent as MinusSVG } from "../../../../assets/minus.svg"
import { ReactComponent as PlusSVG } from "../../../../assets/plus.svg"
import { ReactComponent as Warning } from "../../../../assets/warning-orange.svg"

import { getCompanyNumberingsThunk } from "../../../../store/ducks/numberings.ducks"
import { colors, sizes } from "../../../../styles/design.config"
import {
  getLegalNotice,
  getPriceIncludedTax,
} from "../../../../utils/invoicing"
import { usePrompt } from "../../../../utils/usePrompt.hook"
import { updateBankSettingsThunk } from "../../../../store/ducks/companies.ducks"
import { ConfirmPreviewModal } from "./settings/ConfirmPreviewModal"
import { capitalizeFirstLetter } from "../../../../utils/string"
import { InputDate } from "../../../../components/Commons/InputDate"
import { Alert } from "../../../../components/Commons/Alert"
import { Select } from "../../../../components/Commons/Select"
import { AmountTotals } from "../../../../components/Commons/AmountTotals"
import { CreateOrModifyNumberingModal } from "./settings/CreateOrModifyNumberingModal"
import { InputNumber } from "../../../../components/Commons/InputNumber"
import { getDifferenceInDays } from "../../../../utils/fiscalYears"
import { CreateOrModifyProductModal } from "../../../../components/CreateOrModifyProductModal"
import { MultiSelect } from "../../../../components/Commons/MultiSelect"
import { includes } from "../../../../utils/array"
import { Input } from "../../../../components/Commons/Input"
import { getDueDate } from "../../../../utils/date"

export const InvoiceIssuance = () => {
  const params = useParams()
  const selectedCompanyId = getIdFromParams(params)("company_id")

  const company = useRNBSelector(
    (state) =>
      state.companies.companies[selectedCompanyId ? selectedCompanyId : 0] || []
  )
  const [paymentPeriod, setPaymentPeriod] = useState(
    company?.paymentPeriod || 30
  )

  const [numbering, setNumbering] = useState({
    value: "",
    label: "",
  })
  const [client, setClient] = useState({
    value: "",
    label: "",
  })

  const today = DateTime.now().toJSDate().toISOString().substring(0, 10)
  const issuanceDate = today
  const [billDate, setBillDate] = useState(today)
  const [dueDate, setDueDate] = useState(
    DateTime.now().plus({ days: paymentPeriod }).toString().substring(0, 10)
  )

  const {
    shareCapital,
    legalForm,
    postalCode,
    address,
    city,
    siren,
    cityRegistrationRCS,
    useCityRegistrationRCS,
  }: {
    shareCapital: number | null
    legalForm: CompanyLegalForm | ""
    postalCode: number
    address: string
    city: string
    siren: number
    cityRegistrationRCS: string
    useCityRegistrationRCS: boolean
  } = useRNBSelector((state) => {
    const companyInformations =
      selectedCompanyId &&
      state.companies.companies[selectedCompanyId]?.informations

    if (companyInformations) {
      return companyInformations
    }

    return {
      postalCode: 0,
      address: "",
      city: "",
      siren: 0,
      legalForm: "",
      cityRegistrationRCS: "",
      shareCapital: 0,
      useCityRegistrationRCS: false,
    }
  })

  return (
    <Wrapper>
      <InvoiceClientAndDate
        shareCapital={shareCapital || 0}
        legalForm={legalForm}
        postalCode={postalCode}
        address={address}
        city={city}
        siren={siren}
        cityRegistrationRCS={cityRegistrationRCS}
        useCityRegistrationRCS={useCityRegistrationRCS}
        clientAndDateState={{
          numbering,
          setNumbering,
          client,
          setClient,
          issuanceDate,
          billDate,
          setBillDate,
          dueDate,
          setDueDate,
          paymentPeriod,
          setPaymentPeriod,
        }}
      />
    </Wrapper>
  )
}

interface ClientAndDateState {
  numbering: Ct.Option<string>
  client: Ct.Option<string>
  issuanceDate: string
  billDate: string
  dueDate: string
  paymentPeriod: number
}

interface ClientAndDateStateAndSetters extends ClientAndDateState {
  setNumbering: Dispatch<SetStateAction<Ct.Option<string>>>
  setClient: Dispatch<SetStateAction<Ct.Option<string>>>
  setBillDate: Dispatch<SetStateAction<string>>
  setDueDate: Dispatch<SetStateAction<string>>
  setPaymentPeriod: Dispatch<SetStateAction<number>>
}
interface InvoiceClientAndDateProps {
  clientAndDateState: ClientAndDateStateAndSetters
  shareCapital: number
  legalForm: CompanyLegalForm | ""
  postalCode: number
  address: string
  city: string
  siren: number
  cityRegistrationRCS: string
  useCityRegistrationRCS: boolean
}

export const basicTypes = ["creditNote", "quotation", "invoicing"] as const
export const editTypes = ["quotationEdit"] as const
export const overtakingTypes = [
  "quotationToInvoice",
  "invoiceToCreditNote",
] as const
export const draftTypes = [
  "draftToInvoice",
  "draftToCreditNote",
  "draftToQuotation",
] as const

export type DocumentTypeParam =
  | (typeof basicTypes)[number]
  | (typeof overtakingTypes)[number]
  | (typeof draftTypes)[number]
  | (typeof editTypes)[number]

const documentTypeMap: Record<DocumentTypeParam, (typeof basicTypes)[number]> =
  {
    creditNote: "creditNote",
    quotation: "quotation",
    invoicing: "invoicing",
    quotationToInvoice: "invoicing",
    invoiceToCreditNote: "creditNote",
    draftToInvoice: "invoicing",
    draftToCreditNote: "creditNote",
    draftToQuotation: "quotation",
    quotationEdit: "quotation",
  }

const InvoiceClientAndDate = ({
  clientAndDateState: {
    numbering,
    setNumbering,
    client,
    setClient,
    issuanceDate,
    billDate,
    setBillDate,
    dueDate,
    setDueDate,
    paymentPeriod,
    setPaymentPeriod,
  },
  shareCapital,
  legalForm,
  cityRegistrationRCS,
  useCityRegistrationRCS,
  postalCode,
  address,
  city,
  siren,
}: InvoiceClientAndDateProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const params = useParams()
  const selectedCompanyId = getIdFromParams(params)("company_id")
  const navigate = useNavigate()

  const [lastItemAdded, setLastItemAdded] = useState<number | null>()
  const allRefs = useRef<(HTMLDivElement | null)[]>([])
  const [currRef, setCurrRef] = useState<HTMLDivElement | null>()
  const [displayModal, setDisplayModal] = useState(false)
  const [displayModalNumbering, setDisplayModalCreateNumbering] =
    useState(false)

  const {
    overtakingProducts,
    overtakingInvoiceDocument,
    currentDraftId,
    allTableDataLoaded,
    batchDocumentCreatedForInvoiceId,
    company,
    vatRates,
    vatRatesOptions,
    pdfPreviewUrl,
    logosList,
    addProductStatus,
    hasDuplicateStatus,
    hasDuplicate,
    invoicePreviewStatus,
    clientCreatedForInvoiceId,
    createDraftInvoiceStatus,
  } = useRNBSelector((state) => ({
    overtakingProducts: state.invoicing?.invoiceInfos?.products,
    overtakingInvoiceDocument: state.invoicing?.invoiceInfos?.invoicingDocument,
    currentDraftId: state.invoicing?.invoiceInfos?.currentDraftId || null,
    allTableDataLoaded:
      state.invoicing.clientStatus === "SUCCESS" &&
      state.invoicing.productStatus === "SUCCESS" &&
      state.numberings.fetchNumberingStatus === "success",
    batchDocumentCreatedForInvoiceId:
      state.invoicing.batchDocumentCreatedForInvoiceId,
    company: selectedCompanyId
      ? state.companies.companies[selectedCompanyId]
      : null,
    vatRates: state.vatRates.vatRates,
    vatRatesOptions: state.vatRates.vatRatesOptions,
    pdfPreviewUrl: state.invoicing.invoicePreviewUrl,
    logosList: state.invoicing.additionalLogos,
    addProductStatus: state.invoicing.addProductStatus,
    hasDuplicateStatus: state.invoicing.hasDuplicateStatus,
    hasDuplicate: state.invoicing.hasDuplicate,
    invoicePreviewStatus: state.invoicing.invoicePreviewStatus,
    clientCreatedForInvoiceId: state.invoicing.clientCreatedForInvoiceId,
    createDraftInvoiceStatus: state.invoicing.createDraftInvoiceStatus,
  }))

  const companyName = company?.name || ""

  const productList = useRNBSelector((state) => state.invoicing.products)

  const [productsForInvoice, setProductsForInvoice] = useState<
    ProductForInvoice[]
  >([])

  const location = useLocation()
  const overtakingIdFromLocation = location.state as number

  const [modifiedProducts, setModifiedProducts] = useState<
    Array<{
      product: ProductFormatted
      newPrice: number
      initialPrice: number
      newVatRateId: number
      initialVatRateId: number
    }>
  >([])

  const documentTypeParam: DocumentTypeParam = params["document_type"]
    ? (params["document_type"] as DocumentTypeParam)
    : "invoicing"

  const documentType = documentTypeMap[documentTypeParam]

  const overtakingDocumentId = overtakingIdFromLocation
    ? documentTypeParam.includes("To")
      ? overtakingIdFromLocation
      : 0
    : 0
  const currentDocumentToEditId =
    overtakingIdFromLocation && documentTypeParam === "quotationEdit"
      ? overtakingIdFromLocation
      : 0

  const isCreditNoteType = documentType === "creditNote"
  const creditNoteMultiplier = documentType === "creditNote" ? -1 : 1

  const columnsTitles: string[] = [
    "product",
    "quantity",
    "price",
    "vatRate",
    "productDiscount",
    "taxIncludedPrice",
    "action",
  ].filter((title) => {
    if (documentType !== "creditNote") {
      return true
    }
    return title !== "productDiscount"
  })

  useEffect(() => {
    if (
      params["document_type"] &&
      !(
        includes(basicTypes, params["document_type"]) ||
        (includes(
          [...overtakingTypes, ...draftTypes, ...editTypes],
          params["document_type"]
        ) &&
          overtakingIdFromLocation !== null)
      )
    ) {
      dispatch(urlFailure())
      navigate(`/office/company/${selectedCompanyId}/invoicing/navigation`)
    }
  }, [params, overtakingIdFromLocation, selectedCompanyId])

  const productsOptions = productList
    .map((p) => {
      if (documentType === "creditNote" || p.creditNoteOnly === false) {
        return {
          value: String(p.id),
          label: p.productName,
        }
      } else {
        return {
          value: "",
          label: "",
        }
      }
    })
    .filter((x) => x.value !== "")

  useEffect(() => {
    if (batchDocumentCreatedForInvoiceId) {
      navigate(
        `/office/company/${selectedCompanyId}/invoicing/documents/created`,
        {
          state: documentType,
        }
      )
    }
  }, [batchDocumentCreatedForInvoiceId, documentType])

  useEffect(() => {
    if (createDraftInvoiceStatus === "SUCCESS") {
      dispatch(draftInvoiceReset())
      navigate(
        `/office/company/${selectedCompanyId}/invoicing/documents/drafts`,
        {
          state: documentType,
        }
      )
    }
  }, [createDraftInvoiceStatus])

  useEffect(() => {
    if (pdfPreviewUrl) {
      setShowPreviewModal(true)
    }
  }, [pdfPreviewUrl])

  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [productsOption, setProductOption] = useState({
    value: "",
    label: "",
  })

  type optionType = { value: string; label: string }

  const [logosOptions, setLogosOptions] = useState<Ct.Option<string>[]>([])
  const [logosSelection, setLogosSelection] =
    useState<ReadonlyArray<optionType> | null>([])
  const [logosCount, setLogosCount] = useState<number>(0)

  useEffect(() => {
    if (selectedCompanyId) {
      dispatch(getCompanyNumberingsThunk(selectedCompanyId))
      dispatch(getProductsThunk(selectedCompanyId))
      dispatch(getUnitsThunk(selectedCompanyId))
      dispatch(getVatRatesThunk())
      dispatch(getInvoicingAdditionalLogosThunk(selectedCompanyId))
    }
  }, [dispatch, selectedCompanyId])

  useEffect(() => {
    if (logosList.length > 0) {
      setLogosOptions(
        logosList.map((logo) => {
          return {
            value: logo.id.toString(),
            label: logo.original_file_name,
          }
        })
      )

      const filteredLogosList = logosList.filter((logo) => logo.is_default)

      setLogosSelection(
        filteredLogosList.map((logo) => {
          return {
            value: logo.id.toString(),
            label: logo.original_file_name,
          }
        })
      )

      setLogosCount(filteredLogosList.length)
    }
  }, [dispatch, logosList])

  const productsWithPrice = productsForInvoice.map((p) => {
    const product = productList.find((item) => item.id === p.productId)
    const vatRateId =
      productList?.find((item) => item.id === p?.productId)?.vatRateId || 0
    const vatRate =
      vatRates.find((e) => e.id === vatRateId)?.rate || vatRates[0].rate
    return {
      productId: p.productId,
      productDiscount: p.productDiscount,
      quantity: p.quantity,
      price:
        (product?.unitPrice ? Number(product.unitPrice) : 0) *
        creditNoteMultiplier,
      vatRate,
    }
  })

  const getRateFromProduct = (product: ProductForInvoice) => {
    const vatRateId =
      productList?.find((p) => p.id === product?.productId)?.vatRateId || 0
    const vatRate =
      vatRates.find((e) => e.id === vatRateId)?.rate || vatRates[0].rate
    return Number(vatRate)
  }

  const [freeText, setFreeText] = useState("")
  const [specialMentions, setSpecialMentions] = useState(
    company?.specialMentions || ""
  )

  const [specialDiscount, setSpecialDiscount] = useState(
    company?.specialDiscount || 0
  )

  const [totalDiscount, setTotalDiscount] = useState(0)

  const [firstPaymentPeriod, setFirstPaymentPeriod] = useState(
    company?.paymentPeriod
  )

  const [isChanged, setIsChanged] = useState(false)

  const [iban, setIban] = useState(company?.iban || "")
  const [bic, setBic] = useState(company?.bic || "")

  const [bankAccountOwner, setBankAccountOwner] = useState(
    company?.bankAccountOwner || ""
  )

  useEffect(() => {
    //reset legal mentions on company change
    setSpecialMentions(company?.specialMentions || "")
    setSpecialDiscount(company?.specialDiscount || 0)
    setBic(company?.bic || "")
    setIban(company?.iban || "")
    setBankAccountOwner(company?.bankAccountOwner || "")
    setFirstPaymentPeriod(company?.paymentPeriod)
  }, [company?.id])

  const disablePreviewButton =
    invoicePreviewStatus === "LOADING" ||
    !numbering.value ||
    !client.value ||
    !issuanceDate ||
    !billDate ||
    (documentType !== "creditNote" && productsWithPrice.find((p) => p.price < 0)
      ? true
      : false) ||
    productsForInvoice.length === 0 ||
    productsForInvoice.some(
      (elem) => elem.quantity === 0 || isNaN(elem.quantity)
    ) ||
    (documentType === "creditNote" &&
      Number(
        productsWithPrice
          .reduce((total, product) => {
            return total + Number(product.price) * product.quantity
          }, 0)
          .toFixed(3)
      ) >= 0.0) ||
    (documentType !== "creditNote" &&
      !(
        Number(
          productsWithPrice
            .reduce((total, product) => {
              return (
                total +
                ((Number(product.price) * (100 - product.productDiscount)) /
                  100) *
                  product.quantity
              )
            }, 0)
            .toFixed(3)
        ) >= 0.01
      ))

  usePrompt(
    intl.formatMessage({ id: "form.unsaved.message" }),
    createDraftInvoiceStatus !== "SUCCESS" &&
      !batchDocumentCreatedForInvoiceId &&
      (numbering?.value.length > 0 ||
        client.value.length > 0 ||
        productsForInvoice.length > 0)
  )

  const getInvoicePayload = (
    invoice_email_config: InvoiceEmailConfig | null = null
  ) => {
    return {
      company_id: selectedCompanyId?.toString() || "",
      invoice_date: billDate,
      due_date: dueDate,
      items: productsForInvoice.map((p) => {
        const product = productList.find(
          (item) => item.id === p.productId
        ) as ProductFormatted

        const vatRateId =
          productList?.find((item) => item.id === p?.productId)?.vatRateId || 0
        const productVatRate =
          vatRates.find((vt) => Number(vt.id) === vatRateId)?.rate || "0"
        const vatRate = Number(productVatRate) / 100

        return {
          id: p.productId,
          name: product.productName,
          description: p.newDescription,
          unit_price: product.unitPrice.toString(),
          tva_rate: vatRate.toFixed(4), // need 3 for 5.5%, but better be safe
          ht_total: (Number(product.unitPrice) * p.quantity).toFixed(2),
          tva_total: (Number(product.unitPrice) * p.quantity * vatRate).toFixed(
            2
          ),
          ttc: (Number(product.unitPrice) * p.quantity * (1 + vatRate)).toFixed(
            2
          ),
          quantity: p.quantity.toString(),
          unit: product.unit,
          product_discount: p.productDiscount.toString(),
        }
      }),
      legal_notice: getLegalNotice({
        companyName,
        legalForm,
        shareCapital,
        siren,
        useCityRegistrationRCS,
        cityRegistrationRCS,
        intl,
      }),
      legal_entity: Number(client.value) || null,
      numbering_id: Number(numbering.value) || null,
      address: address,
      postal_code: postalCode?.toString(),
      city: city,
      special_mentions: specialMentions,
      special_discount: specialDiscount,
      free_text: freeText,
      total_discount: totalDiscount,
      payment_period: paymentPeriod,
      document_type: documentType === "invoicing" ? "invoice" : documentType,
      old_document_id:
        overtakingDocumentId === 0 ? undefined : overtakingDocumentId,
      current_document_to_edit_id: currentDocumentToEditId,
      additional_logos_id: logosSelection?.map((logo) => Number(logo.value)),
      invoice_email_config: invoice_email_config,
      documentTypeParam: documentTypeParam,
    }
  }

  const createInvoicePreview = () => {
    if (selectedCompanyId) {
      dispatch(hasDuplicateInvoiceThunk(getInvoicePayload(), selectedCompanyId))
    }
  }

  const createDraft = () => {
    dispatch(createInvoiceDraftThunk(getInvoicePayload(), currentDraftId))
  }

  const getProductDescription = (productId: number): Ct.Option<string> => {
    const productDescription =
      productList.find((p) => p.id === productId)?.productDescription || ""

    return { label: productDescription, value: String(productDescription) }
  }

  const resetAction = () => {
    dispatch(resetDuplicateStatusAction())
  }

  useEffect(() => {
    if (
      hasDuplicate &&
      hasDuplicateStatus === "SUCCESS" &&
      documentTypeParam !== "quotationEdit"
    ) {
      setShowErrorMessage(true)
    } else if (hasDuplicateStatus === "SUCCESS") {
      dispatch(createInvoicePreviewThunk(getInvoicePayload()))
    }
  }, [hasDuplicateStatus])

  const AlertMessage = () => {
    return (
      <AlertCard>
        <Alert alertType="info">
          <Ct.Text
            text={intl.formatMessage({
              id: `${documentType}.issuance.duplicate`,
            })}
          />
          <Ct.Spacer height={2} />
          <ButtonWrapper>
            <Ct.Button
              label={"OUI"}
              onClick={() => {
                dispatch(createInvoicePreviewThunk(getInvoicePayload()))
                setShowErrorMessage(false)
              }}
            />
            <Ct.Button
              label={"NON"}
              onClick={() => {
                setShowErrorMessage(false)
                resetAction()
              }}
            />
          </ButtonWrapper>
        </Alert>
      </AlertCard>
    )
  }

  const getProductName = (productId: number): Ct.Option<string> => {
    const productName =
      productList.find((p) => p.id === productId)?.productName || ""

    return { label: productName, value: String(productId) }
  }

  const changeProduct = (
    oldProduct: number | undefined,
    newProduct: Ct.Option<string>
  ) => {
    const newProductId = Number(newProduct.value)
    const newItem = productsForInvoice.find((p) => p.productId === newProductId)

    setProductsForInvoice([
      ...productsForInvoice.filter(
        (p) => p.productId !== newItem?.productId && p.productId !== oldProduct
      ),
      {
        productId: newProductId,
        quantity: (newItem?.quantity || 0) + 1,
        newDescription: getProductDescription(newProductId).value,
        productDiscount: 0,
      },
    ])

    setLastItemAdded(Number(newProduct.value))

    setTimeout(function () {
      setLastItemAdded(null)
    }, 2000)

    if (oldProduct) {
      setCurrRef(allRefs.current.find((r) => r?.id === newProduct.value))
    }

    setTimeout(function () {
      currRef?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      })
    }, 100)
  }

  useEffect(() => {
    if (selectedCompanyId && addProductStatus === "SUCCESS") {
      dispatch(getProductsThunk(selectedCompanyId))
    }
  }, [dispatch, selectedCompanyId, addProductStatus])

  useEffect(() => {
    if (addProductStatus === "SUCCESS") {
      const productListSort = productList.sort((a, b) => a?.id - b?.id)
      const product = getProductName(productListSort[productList.length - 1].id)
      changeProduct(undefined, product)
      dispatch(addProductReset())
    }
  }, [dispatch, productList])

  const [isModalDisplayed, setIsModalDisplayed] = useState(false)

  const today = DateTime.now().toJSDate().toISOString().substring(0, 10)

  const { register } = useForm({})

  //we need a function declared outside of the component, because setState is called inside useEffect
  // it would cause an infinite rerender if function changes everytime
  const clientList = useRNBSelector(selectClientList)

  const clientOptions = clientList.map((c) => ({
    value: c.legalEntityId,
    label: capitalizeFirstLetter(c.name),
  }))

  useEffect(() => {
    if (clientCreatedForInvoiceId) {
      const clientOptionMatchingCreated = clientOptions.find(
        (c) => Number(c.value) === clientCreatedForInvoiceId
      )
      if (clientOptionMatchingCreated) {
        setClient(clientOptionMatchingCreated)
        dispatch(CreateOrModifyClientReset())
      }
    }
    // we refetch clientList after client creation to fetch the new client
  }, [clientCreatedForInvoiceId, clientList])

  const numberingList = useRNBSelector(
    (state) => state.numberings.numberings[selectedCompanyId || 0] || []
  )

  const numberingOptions = numberingList
    .map((n) => {
      const isInvoice = documentType === "invoicing" && n.type === "invoice"
      const showNumberings = isInvoice || n.type === documentType

      if (!showNumberings) {
        return {
          value: "",
          label: "",
        }
      }
      return {
        value: String(n.id),
        label: n.numbering,
      }
    })
    .filter((x) => x.value !== "")

  const invoicesAreConfigured =
    numberingOptions.length > 0 && clientOptions.length > 0
  const parametersCompleted = shareCapital !== null && legalForm !== null

  const selectedClient = useRNBSelector((state) =>
    state.invoicing.legalEntities.find(
      (le) => String(le.legal_entity_id) === client.value
    )
  )

  const selectedCompany = useRNBSelector(
    (state) =>
      state.companies.companies[selectedCompanyId ? selectedCompanyId : 0] || []
  )

  const showClientInfos = Boolean(selectedClient)

  const showBankInfos =
    Boolean(selectedCompany.iban) &&
    Boolean(selectedCompany.bic) &&
    Boolean(selectedCompany.bankAccountOwner) &&
    documentType === "invoicing"

  const companyAdress = (
    company ? [address, String(postalCode), city] : []
  ).join(" ")

  const companyEmail = company?.informations?.email
  const companyPhone = company?.informations?.phone

  const clientAdress = selectedClient
    ? `${selectedClient.adress} ${selectedClient.postal_code} ${selectedClient.city}`
    : ""

  const headerText =
    documentTypeParam === "quotationToInvoice"
      ? intl.formatMessage(
          { id: "quotationToInvoice.issuance.header.create-issuance" },
          { invoiceNumbering: String(overtakingDocumentId) }
        )
      : documentTypeParam === "invoiceToCreditNote"
      ? intl.formatMessage(
          { id: "invoiceToCreditNote.issuance.header.create-issuance" },
          { invoiceNumbering: String(overtakingDocumentId) }
        )
      : documentTypeParam === "quotationEdit"
      ? intl.formatMessage(
          { id: "quotationEdit.issuance.header.edit" },
          { invoiceNumbering: String(overtakingDocumentId) }
        )
      : intl.formatMessage({ id: "noType.issuance.header.create-issuance" })

  useEffect(() => {
    if (overtakingIdFromLocation) {
      dispatch(getInvoiceForOvertakingThunk(Number(overtakingIdFromLocation)))
    } else {
      dispatch(overtakingResetClientAndProducts())
    }
  }, [documentTypeParam])

  useEffect(() => {
    if (overtakingProducts && overtakingProducts.length > 0) {
      const newProductForInvoice = Object.values(overtakingProducts).filter(
        (p) =>
          productList.find(
            (productFromList) => productFromList.id === p.productId
          )
      )
      setProductsForInvoice(newProductForInvoice)
    } else {
      setProductsForInvoice([])
      setClient({ value: "", label: "" })
    }
  }, [overtakingProducts])

  useEffect(() => {
    if (overtakingInvoiceDocument?.legal_entity_id) {
      const name = clientList.find(
        (c) =>
          c.legalEntityId === String(overtakingInvoiceDocument.legal_entity_id)
      )?.name
      const client = {
        value: String(overtakingInvoiceDocument.legal_entity_id),
        label: name ? capitalizeFirstLetter(name) : "",
      }
      setClient(client)
    }

    if (includes(overtakingTypes, documentTypeParam)) {
      // numberings are different each document type so do not prefill numberings
      // for overtaking between two types
      return
    }

    if (overtakingInvoiceDocument?.invoicing_numbering_id) {
      const name = numberingOptions.find(
        (n) =>
          n.value === String(overtakingInvoiceDocument.invoicing_numbering_id)
      )?.label

      const numbering = {
        value: String(overtakingInvoiceDocument.invoicing_numbering_id),
        label: name ? capitalizeFirstLetter(name) : "",
      }
      setNumbering(numbering)
    }

    if (includes(basicTypes, documentTypeParam)) {
      // do not prefill dates for basic type, only when date was saved in draft
      return
    }

    if (overtakingInvoiceDocument?.free_text) {
      setFreeText(overtakingInvoiceDocument.free_text)
    }

    if (overtakingInvoiceDocument?.invoice_date) {
      setBillDate(overtakingInvoiceDocument.invoice_date)
    }
    if (overtakingInvoiceDocument?.due_date) {
      setDueDate(overtakingInvoiceDocument.due_date)
    }
  }, [overtakingInvoiceDocument?.id])

  useEffect(() => {
    const selectedClient = clientList.find(
      (c) => c.legalEntityId === client.value
    )
    if (!selectedClient) return

    setTotalDiscount(selectedClient.totalDiscount)

    if (overtakingInvoiceDocument?.total_discount) {
      setTotalDiscount(overtakingInvoiceDocument?.total_discount)
    }
  }, [client.value])

  const getValueForVatRateId = (product: ProductForInvoice) => {
    const vatRateId =
      productList?.find((p) => p.id === product.productId)?.vatRateId || 0
    const vatRate = vatRatesOptions.find(
      (vto) => Number(vto.value) === vatRateId
    )
    return vatRate ? vatRate : null
  }

  return showErrorMessage ? (
    <AlertMessage />
  ) : (
    <>
      <CreateOrModifyProductModal
        createOrModify={"create"}
        isDisplayed={displayModal}
        onClose={() => {
          setDisplayModal(false)
        }}
        documentType={
          documentType.includes("creditNote")
            ? "creditNote"
            : documentType.includes("quotation")
            ? "quotation"
            : "invoicing"
        }
      />
      <CreateOrModifyNumberingModal
        createOrModify={"create"}
        isDisplayed={displayModalNumbering}
        onClose={() => {
          setDisplayModalCreateNumbering(!displayModalNumbering)
        }}
      />
      <CreateOrModifyClientModal
        isDisplayed={isModalDisplayed}
        createOrModify={"create"}
        onClose={() => {
          setIsModalDisplayed(!isModalDisplayed)
        }}
        clientToCreate={null}
      />
      {!includes(basicTypes, documentTypeParam) && (
        <Tableheader>
          <Ct.Text
            text={headerText}
            textStyle={{
              textTransform: "uppercase",
              fontFamily: "Poppins",
              fontWeight: 600,
              fontSize: 2.75,
              color: "white",
            }}
          />
        </Tableheader>
      )}
      <TopCard
        width={"100%"}
        roundedCorner={includes(basicTypes, documentTypeParam)}
      >
        <RowCenterWrapper>
          <LeftDisplay>
            <Ct.Spacer height={1} />
            <NumberingWrapper>
              <Ct.Text
                text={intl.formatMessage({
                  id: `${documentType}.issuance.client.number-issuance`,
                })}
                textStyle={{
                  textTransform: "uppercase",
                  fontFamily: "Poppins",
                  fontWeight: 600,
                  fontSize: 2.0,
                  color: "cornflower",
                }}
              />
              <Ct.Spacer width={1} />
              <Select
                intl={intl}
                disabled={
                  !invoicesAreConfigured ||
                  !parametersCompleted ||
                  documentTypeParam === "quotationEdit"
                }
                value={numbering}
                options={numberingOptions}
                domain={"invoicing.text.client.select"}
                optionType={"numbering"}
                onChangeCallback={(value: Ct.Option<string>) => {
                  setNumbering(value)
                }}
                customWidth={"31.85rem"}
                maxwidth={"31.85rem"}
                overflow={"hidden"}
              />
              <Ct.Spacer width={1} />
              <Ct.Button
                label={<WhiteCross />}
                width={4}
                height={4}
                onClick={() => {
                  setDisplayModalCreateNumbering(!displayModalNumbering)
                }}
              />
            </NumberingWrapper>
            <Ct.Spacer height={1} />
            <CompanyInfos>
              <Ct.Text
                text={intl.formatMessage({ id: "invoicing.text.seller" })}
                textStyle={{
                  fontWeight: 600,
                  textTransform: "uppercase",
                  fontSize: 1.75,
                }}
              />
              <Ct.Spacer height={1} />
              <Ct.Text
                text={companyName}
                textStyle={{
                  fontWeight: 500,
                  textTransform: "uppercase",
                  fontSize: 1.55,
                }}
              />
              {companyEmail && (
                <RowStyle>
                  <Ct.Text
                    text={intl.formatMessage({ id: "invoicing.text.email" })}
                    textStyle={{
                      fontWeight: 500,
                      fontSize: 1.55,
                    }}
                  />
                  <Ct.Spacer width={1} />
                  <Ct.Text
                    text={companyEmail}
                    textStyle={{
                      fontWeight: 400,
                      fontSize: 1.55,
                    }}
                  />
                </RowStyle>
              )}
              {companyPhone && (
                <RowStyle>
                  <Ct.Text
                    text={intl.formatMessage({ id: "invoicing.text.phone" })}
                    textStyle={{
                      fontWeight: 500,
                      fontSize: 1.55,
                    }}
                  />
                  <Ct.Spacer width={1} />
                  <Ct.Text
                    text={companyPhone}
                    textStyle={{
                      fontWeight: 400,
                      fontSize: 1.55,
                    }}
                  />
                </RowStyle>
              )}
              <RowStyle>
                <Ct.Text
                  text={intl.formatMessage({ id: "invoicing.text.adress" })}
                  textStyle={{
                    fontWeight: 500,
                    fontSize: 1.55,
                  }}
                />
                <Ct.Spacer width={1} />
                <Ct.Text
                  text={companyAdress}
                  textStyle={{
                    fontWeight: 400,
                    fontSize: 1.55,
                  }}
                />
              </RowStyle>
              <RowStyle>
                <Ct.Text
                  text={intl.formatMessage({
                    id: "invoicing.text.siren-number",
                  })}
                  textStyle={{
                    fontWeight: 500,
                    fontSize: 1.55,
                  }}
                />
                <Ct.Spacer width={1} />
                <Ct.Text
                  text={String(siren)}
                  textStyle={{
                    fontWeight: 400,
                    fontSize: 1.55,
                  }}
                />
              </RowStyle>
            </CompanyInfos>
            <Ct.Spacer height={1} />
            <RowCenterWrapper>
              <BillAndDueDate>
                <InputDate
                  label={intl.formatMessage({
                    id: `${documentType}.issuance.client.input.bill-date`,
                  })}
                  name="billDate"
                  value={billDate}
                  onChangeValue={(billDate: string) => {
                    setIsChanged(
                      Number(getDifferenceInDays(dueDate, billDate)) !==
                        Number(firstPaymentPeriod)
                    )
                    setBillDate(billDate)
                    const newDueDate = getDueDate({ billDate, paymentPeriod })
                    setDueDate(newDueDate)
                  }}
                  disabled={
                    !invoicesAreConfigured ||
                    !parametersCompleted ||
                    documentTypeParam === "quotationEdit"
                  }
                  register={register as unknown as UseFormRegister<FieldValues>}
                  maximum={today}
                  minimum={DateTime.fromFormat(today, "yyyy-MM-dd")
                    .minus({ year: 1 })
                    .toString()
                    .substring(0, 10)}
                />
                <Ct.Spacer width={3} />
                <RowCenterWrapper>
                  {!isCreditNoteType && (
                    <InputDate
                      label={
                        documentType === "quotation"
                          ? intl.formatMessage({
                              id: "quotation.text.client.input.due-date",
                            })
                          : intl.formatMessage({
                              id: "invoicing.text.client.input.due-date",
                            })
                      }
                      name="dueDate"
                      value={dueDate}
                      onChangeValue={(dueDate: string) => {
                        setPaymentPeriod(getDifferenceInDays(dueDate, billDate))
                        setIsChanged(
                          Number(getDifferenceInDays(dueDate, billDate)) !==
                            Number(firstPaymentPeriod)
                        )
                        setDueDate(dueDate)
                      }}
                      disabled={!invoicesAreConfigured || !parametersCompleted}
                      register={
                        register as unknown as UseFormRegister<FieldValues>
                      }
                      minimum={today}
                      maximum={
                        documentType === "quotation"
                          ? DateTime.fromFormat(today, "yyyy-MM-dd")
                              .plus({ day: 90 })
                              .toString()
                              .substring(0, 10)
                          : ""
                      }
                    />
                  )}
                </RowCenterWrapper>
              </BillAndDueDate>
            </RowCenterWrapper>
          </LeftDisplay>
          <RightDisplay>
            <EmitDate>
              <Ct.Text
                text={intl.formatMessage({
                  id: "invoicing.text.client.input.issuance-date",
                })}
                textStyle={{
                  fontWeight: 600,
                  fontSize: 1.75,
                  textAlign: "center",
                }}
              />
              <Ct.Spacer width={1} />
              <Ct.Text
                text={intl.formatMessage({
                  id: "invoicing.text.double-points",
                })}
                textStyle={{
                  fontWeight: 600,
                  fontSize: 1.75,
                  textAlign: "center",
                }}
              />
              <Ct.Spacer width={1} />
              <Ct.Text
                text={DateTime.fromFormat(today, "yyyy-MM-dd").toFormat(
                  "dd/MM/yyyy"
                )}
                textStyle={{
                  fontWeight: 600,
                  fontSize: 1.75,
                  textAlign: "center",
                }}
              />
            </EmitDate>
            <ClientWrapper>
              <RowCenterWrapper>
                <Ct.Text
                  text={intl.formatMessage({
                    id: "invoicing.text.client",
                  })}
                  textStyle={{
                    fontWeight: 600,
                    textTransform: "uppercase",
                    fontSize: 1.75,
                    textAlign: "center",
                  }}
                />
              </RowCenterWrapper>
              <Ct.Spacer width={1} />
              <ClientChoice>
                <Select
                  intl={intl}
                  disabled={
                    !invoicesAreConfigured ||
                    !parametersCompleted ||
                    documentTypeParam === "quotationToInvoice" ||
                    documentTypeParam === "invoiceToCreditNote" ||
                    documentTypeParam === "quotationEdit"
                  }
                  value={client}
                  options={clientOptions}
                  domain={"invoicing.text.client"}
                  optionType={"options"}
                  onChangeCallback={(value: Ct.Option<string>) => {
                    setClient(value)
                  }}
                  customWidth={"38rem"}
                  maxwidth={"38rem"}
                  overflow={"hidden"}
                />
                <Ct.Spacer width={1} />

                <Ct.Button
                  disabled={
                    !invoicesAreConfigured ||
                    !parametersCompleted ||
                    documentTypeParam === "quotationToInvoice" ||
                    documentTypeParam === "invoiceToCreditNote" ||
                    documentTypeParam === "quotationEdit"
                  }
                  label={<WhiteCross />}
                  width={4}
                  height={4}
                  onClick={() => {
                    setIsModalDisplayed(!isModalDisplayed)
                  }}
                />
              </ClientChoice>
            </ClientWrapper>

            {showClientInfos ? (
              <ClientInfos>
                <Ct.Text
                  text={selectedClient?.name || ""}
                  textStyle={{
                    fontWeight: 600,
                    textTransform: "uppercase",
                    fontSize: 1.55,
                  }}
                />
                <RowStyle>
                  <Ct.Text
                    text={intl.formatMessage({
                      id: "invoicing.text.siren-number",
                    })}
                    textStyle={{
                      fontWeight: 600,
                      fontSize: 1.55,
                    }}
                  />
                  <Ct.Spacer width={1} />
                  <Ct.Text
                    text={String(selectedClient?.identification_number || "")}
                    textStyle={{
                      fontWeight: 400,
                      fontSize: 1.55,
                    }}
                  />
                </RowStyle>
                <RowStyle>
                  <Ct.Text
                    text={intl.formatMessage({
                      id: "invoicing.text.adress",
                    })}
                    textStyle={{
                      fontWeight: 600,
                      fontSize: 1.55,
                    }}
                  />
                  <Ct.Spacer width={1} />
                  <Ct.Text
                    text={selectedClient ? clientAdress : ""}
                    textStyle={{
                      fontWeight: 400,
                      fontSize: 1.55,
                    }}
                  />
                </RowStyle>
              </ClientInfos>
            ) : (
              <Ct.Spacer height={6} />
            )}
          </RightDisplay>
        </RowCenterWrapper>
        <Ct.Spacer height={5} />
        <StyledHeader>
          {columnsTitles.map((title: string, index) => (
            <CustomTitles
              key={title}
              width={index === 0 ? "25rem" : undefined}
              justifyContent={"center"}
            >
              <Ct.Text
                text={intl.formatMessage({
                  id: `invoicing.text.table.${title}`,
                })}
                textStyle={{
                  fontWeight: 600,
                  fontSize: 1.75,
                  textTransform: "uppercase",
                  fontFamily: "Poppins",
                  textAlign: "center",
                  color: "white",
                }}
              />
            </CustomTitles>
          ))}
        </StyledHeader>
        <StyleTable>
          {(shareCapital === null || !legalForm) && allTableDataLoaded ? (
            <NoParamsModal>
              <Ct.Spacer height={2} />
              <Alert alertType="info">
                <Ct.Text
                  text={intl.formatMessage({
                    id: "invoicing.issuance.no-company-settings.1",
                  })}
                  textStyle={{
                    lineHeight: 3,
                  }}
                />
                <Ct.Spacer height={1} />
                <Ct.Text
                  text={intl.formatMessage({
                    id: "invoicing.issuance.no-company-settings.2",
                  })}
                  textStyle={{
                    lineHeight: 3,
                  }}
                />
                <Ct.Spacer height={4} />
                <Ct.ColumnCenterCenter>
                  <Ct.Button
                    label={intl.formatMessage({
                      id: "invoicing.issuance.no-company-settings.button",
                    })}
                    width={sizes.button.standard}
                    onClick={() => {
                      navigate(
                        `/office/company/${selectedCompanyId}/settings/informations`
                      )
                    }}
                  />
                </Ct.ColumnCenterCenter>
              </Alert>
            </NoParamsModal>
          ) : (productList.length === 0 ||
              clientList.length === 0 ||
              numberingOptions.length === 0) &&
            allTableDataLoaded ? (
            <NoParamsModal>
              <Alert alertType="info">
                <Ct.Text
                  text={intl.formatMessage({
                    id: "invoicing.issuance.no-invoice-settings.1",
                  })}
                  textStyle={{
                    lineHeight: 3,
                  }}
                />
                <Ct.Spacer height={1} />
                <Ct.Text
                  text={intl.formatMessage({
                    id: "invoicing.issuance.no-invoice-settings.2",
                  })}
                  textStyle={{
                    lineHeight: 3,
                  }}
                />
                <Ct.Spacer height={1} />
                <Ct.Text
                  text={intl.formatMessage({
                    id: "invoicing.issuance.no-invoice-settings.3",
                  })}
                  textStyle={{
                    lineHeight: 3,
                  }}
                />
                <Ct.Spacer height={4} />
                <Ct.ColumnCenterCenter>
                  <Ct.Button
                    label={intl.formatMessage({
                      id: "invoicing.issuance.no-invoice-settings.button",
                    })}
                    width={sizes.button.standard}
                    onClick={() => {
                      navigate(
                        `/office/company/${selectedCompanyId}/invoicing/settings/customers`
                      )
                    }}
                  />
                </Ct.ColumnCenterCenter>
              </Alert>
            </NoParamsModal>
          ) : (
            <>
              {productsForInvoice.map(
                (product: ProductForInvoice, index: number) => (
                  <Fragment key={product.productId}>
                    <Ct.WrapperRows>
                      <WrapperRowsStyled
                        isNewlyAdded={lastItemAdded === product.productId}
                        id={`${product.productId}`}
                        ref={(ref) => {
                          if (ref && !allRefs.current.includes(ref)) {
                            allRefs.current.push(ref)
                            setCurrRef(ref)
                          }
                        }}
                      >
                        <CustomCell
                          justifyContent={"flex-start"}
                          width={"25rem"}
                        >
                          <Select
                            intl={intl}
                            value={getProductName(product.productId)}
                            options={productsOptions}
                            domain={"invoicing.issuance.product-name"}
                            optionType={"label"}
                            onChangeCallback={(
                              selectedProduct: Ct.Option<string>
                            ) => {
                              changeProduct(
                                Number(product.productId),
                                selectedProduct
                              )
                            }}
                            alignSelf={"center"}
                            customWidth={"30rem"}
                            maxwidth={"30rem"}
                            height={"6rem"}
                            overflow={"hidden"}
                          />
                          <Ct.Spacer width={1} />
                        </CustomCell>
                        <CustomCell justifyContent={"center"}>
                          <BorderedDiv color={"rock"}>
                            <StyledMinusSVG
                              onClick={() => {
                                const productsCopy = [...productsForInvoice]
                                if (productsCopy[index].quantity > 0.01) {
                                  productsCopy[index] = {
                                    newDescription:
                                      productsCopy[index].newDescription,
                                    productId: productsCopy[index].productId,
                                    quantity: Number(
                                      (
                                        productsCopy[index].quantity - 0.01
                                      ).toFixed(2)
                                    ),
                                    productDiscount:
                                      productsCopy[index].productDiscount,
                                  }
                                }
                                setProductsForInvoice(productsCopy)
                              }}
                            />
                            <StyledInput
                              autoFocus
                              min="0.01"
                              step="0.01"
                              type={"number"}
                              value={String(productsForInvoice[index].quantity)}
                              onChange={(e) => {
                                const productsCopy = [...productsForInvoice]
                                const newQuantity = isNaN(
                                  parseFloat(e.target.value)
                                )
                                  ? 0
                                  : parseFloat(e.target.value)

                                productsCopy[index] = {
                                  newDescription:
                                    productsCopy[index].newDescription,
                                  productId: productsCopy[index].productId,
                                  quantity: Math.abs(newQuantity),
                                  productDiscount:
                                    productsCopy[index].productDiscount || 0,
                                }

                                setProductsForInvoice(productsCopy)
                              }}
                            />
                            <StyledPlusSVG
                              onClick={() => {
                                const productsCopy = [...productsForInvoice]
                                productsCopy[index] = {
                                  newDescription:
                                    productsCopy[index].newDescription,
                                  productId: productsCopy[index].productId,
                                  quantity: Number(
                                    (
                                      productsCopy[index].quantity + 0.01
                                    ).toFixed(2)
                                  ),
                                  productDiscount:
                                    productsCopy[index].productDiscount,
                                }
                                setProductsForInvoice(productsCopy)
                              }}
                            />
                          </BorderedDiv>
                        </CustomCell>

                        <CustomCell justifyContent={"center"}>
                          <BorderedDiv
                            color={
                              modifiedProducts.find(
                                (p) => p.product.id === product.productId
                              )
                                ? Number(
                                    modifiedProducts.find(
                                      (p) => p.product.id === product.productId
                                    )?.newPrice
                                  ) !==
                                  Number(
                                    modifiedProducts.find(
                                      (p) => p.product.id === product.productId
                                    )?.initialPrice
                                  )
                                  ? "purple"
                                  : "rock"
                                : "rock"
                            }
                          >
                            {isCreditNoteType ? <RowStyle>-</RowStyle> : ""}
                            <StyledInput
                              type={"number"}
                              step={0.01}
                              min={0.01}
                              width={"16"}
                              value={
                                productList?.find(
                                  (p) => p.id === product?.productId
                                )?.unitPrice
                              }
                              // eslint-disable-next-line
                              onChange={(e: any) => {
                                let newModifiedProducts = [...modifiedProducts]
                                let oneModifiedProduct =
                                  newModifiedProducts.find(
                                    (p) => p.product.id === product.productId
                                  )

                                let oneModifiedProductToAdd = productList.find(
                                  (p) => p.id === product.productId
                                )

                                if (oneModifiedProduct) {
                                  if (
                                    Number(oneModifiedProduct.initialPrice) ===
                                    Number(e.target.value)
                                  ) {
                                    if (
                                      oneModifiedProduct.newVatRateId !==
                                      oneModifiedProduct.initialVatRateId
                                    ) {
                                      oneModifiedProduct.newPrice =
                                        oneModifiedProduct.initialPrice
                                    } else {
                                      newModifiedProducts =
                                        newModifiedProducts.filter(
                                          (p) =>
                                            p.product.id !== product.productId
                                        )
                                    }
                                  } else {
                                    oneModifiedProduct.newPrice = Math.abs(
                                      e.target.value
                                    )
                                  }
                                } else {
                                  if (oneModifiedProductToAdd) {
                                    newModifiedProducts.push({
                                      product: oneModifiedProductToAdd,
                                      newPrice: Math.abs(e.target.value),
                                      initialPrice: Number(
                                        oneModifiedProductToAdd.unitPrice
                                      ),
                                      newVatRateId: Number(
                                        oneModifiedProductToAdd.vatRateId
                                      ),
                                      initialVatRateId: Number(
                                        oneModifiedProductToAdd.vatRateId
                                      ),
                                    })
                                  }
                                }

                                setModifiedProducts(newModifiedProducts)
                                let currentProductToChange = productList?.find(
                                  (p) => p.id === product.productId
                                )
                                if (currentProductToChange) {
                                  currentProductToChange.unitPrice = Math.abs(
                                    e.target.value
                                  ).toString()
                                }
                              }}
                            />
                            €
                          </BorderedDiv>
                        </CustomCell>
                        <CustomCell justifyContent={"center"}>
                          <div>
                            <Select
                              overrideBorderColor={
                                modifiedProducts.find(
                                  (p) => p.product.id === product.productId
                                )?.initialVatRateId !==
                                modifiedProducts.find(
                                  (p) => p.product.id === product.productId
                                )?.newVatRateId
                                  ? "purple"
                                  : undefined
                              }
                              intl={intl}
                              reducePadding={true}
                              options={vatRatesOptions}
                              value={getValueForVatRateId(product)}
                              label={"vat"}
                              domain={"invoicing.settings.products"}
                              moveTextFromLeft={"6rem"}
                              customWidth={"15rem"}
                              // eslint-disable-next-line
                              onChangeCallback={(e: any) => {
                                let newModifiedProducts = [...modifiedProducts]
                                let oneModifiedProduct =
                                  newModifiedProducts.find(
                                    (p) => p.product.id === product.productId
                                  )

                                let oneModifiedProductToAdd = productList.find(
                                  (p) => p.id === product.productId
                                )

                                if (oneModifiedProduct) {
                                  if (
                                    Number(
                                      oneModifiedProduct.initialVatRateId
                                    ) === Number(e.value)
                                  ) {
                                    if (
                                      oneModifiedProduct.newPrice !==
                                      oneModifiedProduct.initialPrice
                                    ) {
                                      oneModifiedProduct.newVatRateId =
                                        oneModifiedProduct.initialVatRateId
                                    } else {
                                      newModifiedProducts =
                                        newModifiedProducts.filter(
                                          (p) =>
                                            p.product.id !== product.productId
                                        )
                                    }
                                  } else {
                                    oneModifiedProduct.newVatRateId = Number(
                                      e.value
                                    )
                                  }
                                } else {
                                  if (oneModifiedProductToAdd) {
                                    if (
                                      Number(
                                        oneModifiedProductToAdd.vatRateId
                                      ) !== Number(e.value)
                                    ) {
                                      newModifiedProducts.push({
                                        product: oneModifiedProductToAdd,
                                        newPrice: Number(
                                          oneModifiedProductToAdd.unitPrice
                                        ),
                                        initialPrice: Number(
                                          oneModifiedProductToAdd.unitPrice
                                        ),
                                        newVatRateId: Number(e.value),
                                        initialVatRateId: Number(
                                          oneModifiedProductToAdd.vatRateId
                                        ),
                                      })
                                    }
                                  }
                                }

                                setModifiedProducts(newModifiedProducts)
                                let currentProductToChange = productList?.find(
                                  (p) => p.id === product.productId
                                )
                                if (currentProductToChange) {
                                  currentProductToChange.vatRateId = Number(
                                    e.value
                                  )
                                  currentProductToChange.rate = Number(
                                    e.label.slice(0, -1)
                                  )
                                }
                              }}
                            />
                          </div>
                        </CustomCell>
                        {documentType !== "creditNote" && (
                          <CustomCell justifyContent={"center"}>
                            <InputNumber
                              maxWidth={10}
                              label={intl.formatMessage({
                                id: "invoicing.issuance.product-discount",
                              })}
                              value={product.productDiscount}
                              onChange={(e) => {
                                let newProductDiscount = 0
                                if (
                                  !e.target.value ||
                                  parseFloat(e.target.value) < 0
                                ) {
                                  newProductDiscount = 0
                                } else if (parseFloat(e.target.value) > 100) {
                                  newProductDiscount = 100
                                } else {
                                  newProductDiscount = Math.round(
                                    e.target.valueAsNumber
                                  )
                                }

                                const productsCopy = [...productsForInvoice]

                                productsCopy[index] = {
                                  newDescription:
                                    productsCopy[index].newDescription,
                                  productId: productsCopy[index].productId,
                                  quantity: productsCopy[index].quantity,
                                  productDiscount: newProductDiscount,
                                }

                                setProductsForInvoice(productsCopy)
                              }}
                              max={100}
                              min={0}
                              step={1}
                              suffix={"%"}
                            />
                          </CustomCell>
                        )}

                        <CustomCell justifyContent={"center"}>
                          {isCreditNoteType ? <RowStyle>-</RowStyle> : ""}
                          {getPriceIncludedTax({
                            price: Number(
                              productList?.find(
                                (p) => p.id === product?.productId
                              )?.unitPrice
                            ),
                            rate: getRateFromProduct(product),
                            quantity: product.quantity,
                            productDiscount: product.productDiscount,
                          })
                            .toString()
                            .replace(".", ",")}
                          €
                        </CustomCell>
                        <CustomCell justifyContent={"center"}>
                          <Ct.FileTrash
                            onClick={() => {
                              setProductsForInvoice(
                                productsForInvoice.filter(
                                  (p) => p.productId !== product.productId
                                )
                              )
                            }}
                          />
                        </CustomCell>
                      </WrapperRowsStyled>
                      <WrapperRowsStyled
                        isNewlyAdded={lastItemAdded === product.productId}
                      >
                        <CustomCell
                          justifyContent={"flex-start"}
                          width={"25rem"}
                        >
                          <Input
                            AutoGrowing={true}
                            height="8rem"
                            label={intl.formatMessage({
                              id: "invoicing.text.description",
                            })}
                            value={product.newDescription}
                            onTextAreaChange={(e) => {
                              const productsCopy = [...productsForInvoice]
                              productsCopy[index] = {
                                newDescription: e.target.value,
                                productId: productsCopy[index].productId,
                                quantity: productsCopy[index].quantity,
                                productDiscount:
                                  productsCopy[index].productDiscount,
                              }

                              setProductsForInvoice(productsCopy)
                            }}
                            disabled={false}
                          />
                        </CustomCell>
                      </WrapperRowsStyled>
                    </Ct.WrapperRows>
                  </Fragment>
                )
              )}
              <StyledWrapperRows>
                <Select
                  intl={intl}
                  value={productsOption}
                  options={productsOptions}
                  domain={"invoicing.text.table"}
                  optionType={"choose-product"}
                  onChangeCallback={(product: Ct.Option<string>) => {
                    changeProduct(undefined, product)
                    setProductOption(product)
                  }}
                  customWidth={"30rem"}
                  maxwidth={"30rem"}
                  overflow={"hidden"}
                />
                <Ct.Spacer width={1} />
                <Ct.Button
                  label={<WhiteCross />}
                  onClick={() => {
                    dispatch(addProductReset())
                    dispatch(modifyProductReset())
                    setDisplayModal(true)
                  }}
                  width={4}
                  height={4}
                />
              </StyledWrapperRows>
            </>
          )}
        </StyleTable>
        <Ct.Spacer height={3} />
        {totalDiscount > 0 &&
          productsForInvoice.some((p) => p.productDiscount > 0) && (
            <RowCenteredWrapper>
              <Warning />
              <Ct.Spacer width={0.5} />
              <Ct.Text
                text={intl.formatMessage({
                  id: "invoicing.issuance.discount-alert",
                })}
                textStyle={{
                  fontWeight: 700,
                  fontSize: 1.75,
                  color: "orange",
                }}
              />
            </RowCenteredWrapper>
          )}
        <RowCenterWrapper>
          <br />
          <ColumnEnd>
            {["invoicing", "quotation"].includes(documentType) && (
              <>
                <InputNumber
                  maxWidth={30}
                  label={intl.formatMessage({
                    id: "invoicing.issuance.total-discount",
                  })}
                  value={totalDiscount}
                  onChange={(e) => {
                    if (!e.target.value || parseFloat(e.target.value) < 0) {
                      setTotalDiscount(0)
                    } else if (parseFloat(e.target.value) > 99) {
                      setTotalDiscount(99)
                    } else {
                      setTotalDiscount(Math.round(e.target.valueAsNumber))
                    }
                  }}
                  max={99}
                  min={0}
                  step={1}
                  suffix={"%"}
                />
                <Ct.Spacer />
              </>
            )}
            <AmountTotals
              intl={intl}
              productsWithPrice={productsWithPrice}
              totalDiscount={totalDiscount}
            />
            {showBankInfos && (
              <PaiementInfos>
                <Ct.Text
                  text={intl.formatMessage({
                    id: "invoicing.text.payment-infos",
                  })}
                  textStyle={{
                    fontWeight: 600,
                    fontSize: 1.55,
                  }}
                />
                <Ct.Spacer width={1} />
                <ColumnStyle>
                  <RowStyle>
                    <Ct.Text
                      text={intl.formatMessage({
                        id: "invoicing.text.account-owner",
                      })}
                      textStyle={{
                        color: "cornflower",
                        fontWeight: 600,
                        fontSize: 1.55,
                      }}
                    />
                    <Ct.Spacer width={1} />
                    <Ct.Text
                      text={selectedCompany?.bankAccountOwner || ""}
                      textStyle={{
                        fontWeight: 400,
                        fontSize: 1.55,
                      }}
                    />
                  </RowStyle>
                  <RowStyle>
                    <Ct.Text
                      text={intl.formatMessage({ id: "invoicing.text.iban" })}
                      textStyle={{
                        color: "cornflower",
                        fontWeight: 600,
                        fontSize: 1.55,
                      }}
                    />
                    <Ct.Spacer width={1} />
                    <Ct.Text
                      text={selectedCompany?.iban || ""}
                      textStyle={{
                        fontWeight: 400,
                        fontSize: 1.55,
                      }}
                    />
                  </RowStyle>
                  <RowStyle>
                    <Ct.Text
                      text={intl.formatMessage({ id: "invoicing.text.bic" })}
                      textStyle={{
                        color: "cornflower",
                        fontWeight: 600,
                        fontSize: 1.55,
                      }}
                    />
                    <Ct.Spacer width={1} />
                    <Ct.Text
                      text={selectedCompany?.bic || ""}
                      textStyle={{
                        fontWeight: 400,
                        fontSize: 1.55,
                      }}
                    />
                  </RowStyle>
                </ColumnStyle>
              </PaiementInfos>
            )}
          </ColumnEnd>
        </RowCenterWrapper>
        <RowCenterWrapper>
          <Ct.Text
            text={intl.formatMessage({ id: "invoicing.text.legit-mention" })}
            textStyle={{
              textTransform: "uppercase",
              fontWeight: 600,
              fontSize: 1.85,
            }}
          />
        </RowCenterWrapper>
        <Ct.Spacer height={1} />
        <RowCenterWrapper>
          <Ct.Text
            text={getLegalNotice({
              companyName,
              legalForm,
              shareCapital,
              siren,
              useCityRegistrationRCS,
              cityRegistrationRCS,
              intl,
            })}
            textStyle={{
              fontWeight: 400,
              fontSize: 1.55,
            }}
          />
        </RowCenterWrapper>
        <Ct.Spacer height={2} />
        <RowStartWrapper>
          {documentType === "invoicing" && (
            <>
              <InputNumber
                maxWidth={30}
                label={intl.formatMessage({
                  id: "invoicing.issuance.special-discount",
                })}
                value={specialDiscount}
                onChange={(e) => {
                  if (!e.target.value || parseFloat(e.target.value) < 0) {
                    setSpecialDiscount(0)
                  } else if (parseFloat(e.target.value) > 100) {
                    setSpecialDiscount(100)
                  } else {
                    setSpecialDiscount(Math.round(e.target.valueAsNumber))
                  }
                }}
                max={100}
                min={0}
                step={1}
                suffix={"%"}
              />
              <Ct.Spacer width={2} />
            </>
          )}

          {documentType === "invoicing" && (
            <>
              <InputNumber
                isChanged={isChanged}
                name="paymentPeriod"
                register={register as unknown as UseFormRegister<FieldValues>}
                label={intl.formatMessage({
                  id: "invoicing.bill-settings.payment-period",
                })}
                value={paymentPeriod}
                onChange={(e) => {
                  setIsChanged(
                    Number(firstPaymentPeriod) !==
                      Number(Math.round(e.target.valueAsNumber))
                  )
                  if (!e.target.value || parseFloat(e.target.value) < 0) {
                    setPaymentPeriod(0)
                  } else {
                    setPaymentPeriod(Math.round(e.target.valueAsNumber))
                    setDueDate(
                      DateTime.now()
                        .plus({ days: Math.round(e.target.valueAsNumber) })
                        .toString()
                        .substring(0, 10)
                    )
                  }
                }}
                min={0}
                step={1}
                maxWidth={30}
                suffix="jours"
                suffixPosition={8}
              />
              <Ct.Spacer width={2} />
            </>
          )}
          <StyledMultiSelectContainer>
            <MultiSelect
              intl={intl}
              field={{}}
              options={logosOptions}
              value={logosSelection}
              label={""}
              domain={`invoicing.additional-logos.add-additional-logos`}
              optionType={"drop-down-label"}
              onChange={(e) => {
                if (e.length > 3) {
                  return
                }
                setLogosCount(e.length)
                setLogosSelection(e as optionType[])
              }}
              canToggleAllOptions={false}
              disabled={!logosOptions.length}
              noOptionsCustom={intl.formatMessage({
                id: "invoicing.additional-logos.add-additional-logos.no-options",
              })}
              placeHolder={
                !logosOptions.length
                  ? "Vous ne possédez aucun logo additionnel"
                  : logosCount === 0 || logosCount === 1
                  ? `${logosCount}/3 logo additionnel selectionné`
                  : `${logosCount}/3 logos additionnels selectionnés`
              }
              customMenuWidth={"fit"}
              renderValues={false}
            />
          </StyledMultiSelectContainer>
        </RowStartWrapper>
        <Ct.Spacer height={2} />
        <RowCenterWrapper>
          <Input
            AutoGrowing={true}
            height="8rem"
            label={intl.formatMessage({ id: "invoicing.text.mention" })}
            value={
              isCreditNoteType
                ? intl.formatMessage({
                    id: "invoiceToCreditNote.issuance.text.special-mention",
                  })
                : specialMentions
            }
            onTextAreaChange={(e) => {
              setSpecialMentions(e.target.value)
            }}
            disabled={
              numberingList.length === 0 ||
              clientList.length === 0 ||
              !shareCapital ||
              !legalForm ||
              isCreditNoteType
            }
          />
        </RowCenterWrapper>
        <Ct.Spacer />
        <RowCenterWrapper>
          <Input
            AutoGrowing={true}
            height="8rem"
            label={intl.formatMessage({ id: "invoicing.text.free-text" })}
            value={freeText}
            onTextAreaChange={(e) => {
              setFreeText(e.target.value)
            }}
            disabled={false}
          />
        </RowCenterWrapper>
        {documentType !== "invoicing" ? (
          <Ct.Spacer height={5} />
        ) : (
          <Ct.Spacer height={3} />
        )}
        {(isChanged || modifiedProducts.length > 0) && (
          <>
            <Ct.RowCenter>
              <StyledPurpleText
                text={intl.formatMessage({
                  id: `invoice.issuance.modifications`,
                })}
                textStyle={{
                  fontWeight: 400,
                  fontSize: 1.55,
                }}
              />
            </Ct.RowCenter>
            <Ct.Spacer height={3} />
          </>
        )}
        <Ct.RowCenter>
          {documentTypeParam !== "quotationEdit" && (
            <>
              <Ct.Button
                label={intl.formatMessage({
                  id: `draft.issuance.cta`,
                })}
                textTransform={"uppercase"}
                width={sizes.button.standard}
                onClick={() => {
                  createDraft()
                }}
              />
              <Ct.Spacer />
            </>
          )}
          <Ct.Button
            label={intl.formatMessage({
              id: `${documentType}.issuance.visualize.cta`,
            })}
            textTransform={"uppercase"}
            width={sizes.button.standard}
            disabled={disablePreviewButton}
            onClick={() => {
              createInvoicePreview()
            }}
          />
        </Ct.RowCenter>
        <Ct.Spacer height={window.innerHeight < 960 ? 2 : 4} />
        <ConfirmPreviewModal
          productsWithPrice={productsWithPrice}
          isDisplayed={showPreviewModal}
          pdfPreviewUrl={pdfPreviewUrl}
          totalDiscount={totalDiscount}
          onClose={() => {
            setShowPreviewModal(false)
            dispatch(resetPreviewUrl())
            resetAction()
          }}
          onConfirmPreview={(
            invoiceEmailConfig: InvoiceEmailConfig | null = null
          ) => {
            if (!selectedCompanyId) {
              return
            }
            dispatch(
              updateBankSettingsThunk(
                {
                  iban,
                  bic,
                  bankAccountOwner,
                  specialMentions,
                  specialDiscount: Number(company?.specialDiscount || "0"),
                  paymentPeriod,
                },
                selectedCompanyId
              )
            )
            modifiedProducts.forEach((p) => {
              dispatch(
                modifyProductThunk(
                  {
                    oldPrice: p.initialPrice.toString(),
                    newPrice: p.newPrice.toString(),
                    oldProductName: p.product.productName,
                    newProductName: p.product.productName,
                    oldUnitId: p.product.unitId.toString(),
                    newUnitId: p.product.unitId.toString(),
                    oldVatRateId: p.initialVatRateId.toString(),
                    newVatRateId: p.newVatRateId.toString(),
                    oldCreditNoteOnly: p.product.creditNoteOnly,
                    newCreditNoteOnly: p.product.creditNoteOnly,
                    oldDescription: p.product.productDescription,
                    newDescription: p.product.productDescription,
                  },
                  p.product.id,
                  selectedCompany.id
                )
              )
            })
            dispatch(
              createFinalInvoiceThunk(getInvoicePayload(invoiceEmailConfig))
            )
            setShowPreviewModal(false)
            dispatch(resetPreviewUrl())
            resetAction()
          }}
          invoiceRecap={{
            sellerName: company?.name || "",
            sellerAdress: companyAdress,
            sellerSiren: String(siren),
            buyerName: client?.label || "",
            buyerAdress: clientAdress,
            buyerSiren: String(selectedClient?.identification_number || ""),
            invoiceDate: DateTime.fromFormat(
              issuanceDate,
              "yyyy-MM-dd"
            ).toFormat("dd/MM/yyyy"),
            invoiceType: documentType,
            isEditingQuotation: documentTypeParam === "quotationEdit",
          }}
          invoiceMailInfo={{
            companyName: companyName,
            legalEntityId: client.value,
            dueDate,
          }}
        />
        {documentType !== "invoicing" && <Ct.Spacer height={3} />}
      </TopCard>
    </>
  )
}

const Wrapper = styled((props) => <Ct.Column {...props} />)`
  padding-bottom: 4rem;
  margin: 0 2rem;
`
const RowCenterWrapper = styled((props) => <Ct.RowCenter {...props} />)`
  width: 100%;
  justify-content: space-between;
`

const RowCenteredWrapper = styled((props) => <Ct.RowCenter {...props} />)`
  width: 100%;
  justify-content: center;
`
const RowStartWrapper = styled((props) => <Ct.RowCenter {...props} />)`
  width: 100%;
  justify-content: flex-start;
`
const StyledWrapperRows = styled((props) => <Ct.WrapperRows {...props} />)`
  padding: 2rem 3rem;
  display: flew;
  flex-direction: column;
`
interface WrapperRowsStyledProps {
  isNewlyAdded: boolean
}
const WrapperRowsStyled = styled.div<WrapperRowsStyledProps>`
  display: flex;
  align-items: flex-start;
  padding-left: 3rem;
  scroll-margin: 500px;
  animation: ${({ isNewlyAdded }) =>
    isNewlyAdded ? "addedStyle 2s 1" : "none"};

  @keyframes addedStyle {
    0% {
      background-color: ${colors.mist};
    }
    100% {
      background-color: ${colors.white};
    }
  }
`
const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`
const NoParamsModal = styled(Center)`
  margin-top: 2rem;
  margin-bottom: 2rem;
`

interface RoundedCorner {
  roundedCorner: boolean
}
const TopCard = styled(Ct.Card)<RoundedCorner>`
  border-radius: ${({ roundedCorner }) =>
    roundedCorner ? "2.5rem" : "0 0 2.5rem 2.5rem"};

  @media (min-height: 961px) {
    padding: 2rem 3rem 0;
    min-height: calc(100vh - 28rem);
  }
  @media (max-height: 960px) {
    padding: 2rem 2rem 0;
    min-height: calc(100vh - 36rem);
  }
`
const AlertCard = styled(Ct.Card)`
  background-color: unset;
  box-shadow: unset;
  min-width: 35vw;
  @media (min-height: 961px) {
    min-height: calc(100vh - 28rem);
  }
  @media (max-height: 960px) {
    min-height: calc(100vh - 26rem);
  }
`
const StyleTable = styled(Ct.TableWrapper)`
  height: auto;
  overflow: auto;
  margin-bottom: 2rem;
  border-radius: 0 0 2.5rem 2.5rem;
`
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`
const NumberingWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-bottom: 4rem;
`
const EmitDate = styled.div`
  display: flex;
  justify-content: end;
  padding-bottom: 4rem;
`
interface CellProps {
  justifyContent: string
  width?: string
}
const CustomTitles = styled.span<CellProps>`
  display: flex;
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : "center"};
  width: ${({ width }) => (width ? width : "initial")};
  flex: ${({ width }) => (width ? "initial" : 1)};
`
const CustomCell = styled.span<CellProps>`
  display: flex;
  height: 11rem;
  @media (max-height: 960px) {
    height: 9rem;
  }
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : "center"};
  align-items: center;
  margin: 0;
  position: relative;
  width: ${({ width }) => (width ? width : "initial")};
  flex: ${({ width }) => (width ? "initial" : 1)};
`
const ClientWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`
const ClientChoice = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 1rem;
`
const CompanyInfos = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 5rem;
`
const LeftDisplay = styled.div`
  padding-bottom: 2rem;
`
const RightDisplay = styled.div`
  padding-bottom: 2rem;
`
const ClientInfos = styled.div`
  display: flex;
  flex-direction: column;
`
const BillAndDueDate = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`
const ColumnStyle = styled.div`
  display: flex;
  flex-direction: column;
`

const ColumnEnd = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`
const RowStyle = styled.div`
  display: flex;
  flex-direction: row;
`
const PaiementInfos = styled.div`
  padding-top: 1rem;
  display: flex;
  flex-direction: row;
`
const Tableheader = styled(Ct.TableHeader)`
  background-color: ${colors.navy};
`
const StyledMinusSVG = styled(MinusSVG)`
  &:hover {
    cursor: pointer;
    & path {
      fill: ${colors.cornflower};
    }
  }
`
const StyledPlusSVG = styled(PlusSVG)`
  width: 24px;
  height: 24px;
  /* stylelint-disable no-descending-specificity */
  & path {
    fill: ${colors.rock};
  }

  :hover {
    cursor: pointer;
    & path {
      fill: ${colors.cornflower};
    }
  }
`
interface StyledInputProps {
  width?: string
}

const StyledInput = styled.input<StyledInputProps>`
  border: 0;
  height: 5rem;
  width: ${({ width }) => (width ? `${width}rem` : `8rem`)};
  width: 8rem;
  text-align: center;
  box-shadow: none;
  outline: none;
  background: transparent;
  appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
`
interface BorderedDivProps {
  color: keyof typeof colors
}

const BorderedDiv = styled.div<BorderedDivProps>`
  border: ${({ color }) =>
    color === "purple"
      ? `2px ${colors[color]} solid`
      : `1px ${colors[color]} solid`};
  border-radius: 7px;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0 1rem;
`
const StyledPurpleText = styled(Ct.Text)`
  color: ${colors.purple};
`

const StyledMultiSelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 40rem;
`

const StyledHeader = styled(Ct.TableHeader)`
  background-color: ${colors.navy};
  display: flex;
  padding-left: 3rem;
`
