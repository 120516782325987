/* eslint-disable camelcase */
import { useDispatch } from "react-redux"
import { useIntl } from "react-intl"
import styled from "styled-components/macro"
import { sizes } from "../styles/design.config"
import { FieldValues, useForm, UseFormRegister } from "react-hook-form"
import { useEffect, useState } from "react"
import { SIRENCheckerPanel } from "./SIRENCheckerPanel"
import * as Ct from "ldlj"
import { attemptCompanyRegistrationThunk } from "../store/ducks/companies.ducks"
import { useRNBSelector } from "../store/rootReducer"
import { Navigate, useNavigate, useParams } from "react-router-dom"
import { convertEnumToArray } from "../utils/array"
import { ComptaSoftwareOptionsEnum } from "../model/settings"
import { InputDate } from "./Commons/InputDate"
import { Select } from "./Commons/Select"
import { getFiduciaryThunk } from "../store/ducks/fiduciary.ducks"
import { AccountingSoftware } from "../utils/company"

export const CompanyRegister = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const [sirenRegistered, setSirenRegistered] = useState(false)
  const [beginExercise, setBeginExercise] = useState("")
  const [endExercise, setEndExercise] = useState("")
  const [hiddenfromTeam, setHiddenFromTeam] = useState(false)

  const softwareOptionArray = convertEnumToArray(ComptaSoftwareOptionsEnum) as [
    AccountingSoftware,
    string
  ][]
  const accounting_softwareOptions: Ct.OptionList<AccountingSoftware> =
    Ct.createOptionList<AccountingSoftware>(softwareOptionArray)

  const handleSirenRegistration = () => {
    setSirenRegistered(!sirenRegistered)
    setBeginExercise("")
    setEndExercise("")
    companyName && setValue("companyName", "")
    accounting_softwareReference && setValue("accounting_softwareReference", "")
  }

  const fiduciaryState = useRNBSelector((state) => state.fiduciary)
  const companyState = useRNBSelector((state) => state.companies)
  const userState = useRNBSelector((state) => state.user)

  const { register, handleSubmit, watch, setValue } = useForm({})
  const initialCompanyName = companyState.name
  const initFiduciaryComptaSoftware = fiduciaryState.accounting_software
  const [accounting_software, setComptaSoftware] = useState<Ct.Option<string>>({
    value: initFiduciaryComptaSoftware || "",
    label: initFiduciaryComptaSoftware || "",
  })

  const userInformations = useRNBSelector((state) => state.user)
  const fiduciaryInformations = useRNBSelector((state) => state.fiduciary)
  const navigate = useNavigate()
  const accountTypeFromURL = useParams()["account_type"] || ""

  /* eslint-disable camelcase */
  useEffect(() => {
    if (userInformations) {
      window.userpilot.identify(userInformations.id, {
        name: userInformations.firstName + " " + userInformations.lastName,
        email: userInformations.email,
        first_name: userInformations.firstName,
        last_name: userInformations.lastName,
        created_at: userInformations.created_at,
        role: userInformations.typology,
        company: {
          id: fiduciaryInformations.id, // Required, used to identify the company
          name: fiduciaryInformations.name,
          created_at: fiduciaryInformations.created_at,
          company_country: "FR",
        },
      })
    }
  }, [userInformations, fiduciaryInformations, userInformations.typology])

  useEffect(() => {
    dispatch(getFiduciaryThunk())
  }, [])

  useEffect(() => {
    if (
      accountTypeFromURL !== "accrual_accounting" &&
      accountTypeFromURL !== "cash_accounting"
    )
      navigate("/not_found")
  }, [accountTypeFromURL])

  useEffect(() => {
    setComptaSoftware({
      value: fiduciaryState.accounting_software || "",
      label: fiduciaryState.accounting_software || "",
    })
  }, [fiduciaryState.accounting_software])

  useEffect(() => {
    if (sirenRegistered) {
      setValue("companyName", initialCompanyName)
    }
  }, [sirenRegistered, initialCompanyName, setValue])

  const companyName = watch("companyName")
  const accounting_softwareReference = watch("accounting_softwareReference")
  const handleFormSubmit = (companyFormData: CompanyFormData) => {
    dispatch(
      attemptCompanyRegistrationThunk({
        ...companyFormData,
        accounting_software: accounting_software,
        accounting_softwareReference: accounting_softwareReference,
        hiddenFromTeam: hiddenfromTeam,
        accountType: accountTypeFromURL,
        initialCompanyName: initialCompanyName,
      })
    )
  }

  const dateAuthorized = (startDate: string, signe: string): string => {
    let date: Date = new Date(startDate)
    if (signe === "plus") date.setDate(date.getDate() + 1)
    else if (signe === "minus") date.setDate(date.getDate() - 1)
    else return ""
    let dateString: string = date.toISOString().substring(0, 10)
    return dateString
  }

  if (
    userState.typology === "customer" ||
    userState.typology === "customer_accountant"
  ) {
    return <Navigate to={"/unauthorized"} />
  }

  if (companyState.registerCompanyStatus === "SUCCESS") {
    return (
      <Navigate
        to={`/office/company/${companyState.newCompanyId}/initialize`}
      />
    )
  }

  return (
    <div>
      <Ct.Title text={intl.formatMessage({ id: "company-register.title" })} />
      <Ct.Subtitle
        text={intl.formatMessage({ id: "company-register.subtitle" })}
      />

      <Ct.Spacer height={5} />

      <Pannel>
        <SIRENCheckerPanel
          handleSirenRegistration={handleSirenRegistration}
          sirenRegistered={sirenRegistered}
          sirenType="company"
        />

        <Ct.Spacer width={4} />

        <Pannel>
          <Ct.Card>
            <Ct.StyledForm onSubmit={handleSubmit(handleFormSubmit)}>
              <Ct.Input
                name="companyName"
                register={register as unknown as UseFormRegister<FieldValues>}
                label={intl.formatMessage({ id: "company-register.name" })}
                type="text"
                value={companyName}
                disabled={!sirenRegistered}
              />

              <Ct.Spacer height={4} />

              <Ct.StyledDuoInput>
                <InputDate
                  label={intl.formatMessage({
                    id: "company-register.begin-exercise",
                  })}
                  name="beginExercise"
                  disabled={!sirenRegistered}
                  value={beginExercise}
                  onChangeValue={(beginExerciseDate: string) => {
                    setBeginExercise(beginExerciseDate)
                  }}
                  register={register as unknown as UseFormRegister<FieldValues>}
                  maximum={endExercise && dateAuthorized(endExercise, "minus")}
                />
                <InputDate
                  label={intl.formatMessage({
                    id: "company-register.end-exercise",
                  })}
                  name="endExercise"
                  disabled={!sirenRegistered || beginExercise === ""}
                  value={endExercise}
                  onChangeValue={setEndExercise}
                  register={register as unknown as UseFormRegister<FieldValues>}
                  minimum={
                    beginExercise && dateAuthorized(beginExercise, "plus")
                  }
                />
              </Ct.StyledDuoInput>

              <Ct.Spacer height={4} />

              <Select
                intl={intl}
                value={accounting_software}
                options={accounting_softwareOptions}
                onChangeCallback={(e: Ct.Option<string>) => {
                  setComptaSoftware(e)
                }}
                label={"accounting_software"}
                domain={"company-register"}
                optionType={"compta-software"}
                disabled={!sirenRegistered}
              />

              <Ct.Spacer height={4} />

              {accounting_software.value === "isacompta" && (
                <Ct.Input
                  name="accounting_softwareReference"
                  register={register as unknown as UseFormRegister<FieldValues>}
                  label={intl.formatMessage({
                    id: "company-register.accounting-software-reference",
                  })}
                  type="text"
                  value={accounting_softwareReference}
                  disabled={!sirenRegistered}
                />
              )}

              {accounting_software.value === "isacompta" && (
                <Ct.Spacer height={4} />
              )}

              {userState.typology === "administrator" ? (
                <>
                  <HideCompanySwitch>
                    <Ct.Switch
                      value={hiddenfromTeam}
                      onToggle={function (): void {
                        if (
                          !sirenRegistered ||
                          userState.typology !== "administrator"
                        ) {
                          return
                        }
                        setHiddenFromTeam(!hiddenfromTeam)
                      }}
                    />
                    <Ct.Spacer width={2} />
                    <Ct.Text
                      text={intl.formatMessage({
                        id: "company-register.hidden-from-team",
                      })}
                      textStyle={{
                        color:
                          !sirenRegistered ||
                          userState.typology !== "administrator"
                            ? "disabledGrey"
                            : undefined,
                      }}
                    />
                  </HideCompanySwitch>
                  <Ct.Spacer height={4} />
                </>
              ) : (
                <></>
              )}

              <Ct.Button
                type="submit"
                width={42.5}
                label={intl.formatMessage({ id: "company-register.cta" })}
                disabled={
                  !sirenRegistered ||
                  !beginExercise ||
                  !endExercise ||
                  !companyName ||
                  (accounting_software.value === "isacompta" &&
                    !accounting_softwareReference)
                }
                loadingStatus={
                  companyState.registerCompanyStatus === "LOADING"
                    ? "loading"
                    : "idle"
                }
              />
            </Ct.StyledForm>
          </Ct.Card>
        </Pannel>
      </Pannel>
    </div>
  )
}

export interface CompanyFormData {
  companyName: string
  initialCompanyName: string
  beginExercise: string
  endExercise: string
  accounting_software: { value: string; label: string }
  accounting_softwareReference?: string
  hiddenFromTeam: boolean
  accountType: string
}

const Pannel = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  @media (${sizes.screen.breakpoint}) {
    flex-direction: column;
  }
`

const HideCompanySwitch = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`
