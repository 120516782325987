import * as Ct from "ldlj"
import styled from "styled-components/macro"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useIntl } from "react-intl"

import { ReactComponent as CloseCross } from "../../assets/close-cross.svg"
import { ReactComponent as SquareCheck } from "../../assets/square-check.svg"
import { ReactComponent as SmallArrowRight } from "../../assets/small-arrow-right.svg"
import { colors } from "../../styles/design.config"

import {
  checkRemovePrefixMerchantCodes,
  removePrefixMerchantCode,
  sortMerchantCodesByCode,
  normalizeMerchantCode,
} from "../../utils/merchantCodes"
import { getIdFromParams } from "../../utils/company"

import { useRNBSelector } from "../../store/rootReducer"
import {
  MerchantCode,
  RemovePrefixMerchantCodesReset,
  GetCompanyMerchantCodesDataThunk,
  removePrefixMerchantCodesThunk,
} from "../../store/ducks/merchantCodes.ducks"

import { LockableInput } from "../Commons/LockableInput"
import { Text } from "../Commons/Text"

/* eslint-disable camelcase */

interface RemovePrefixMerchantCodesModalProps {
  onClose: () => void
  isDisplayed: boolean
}

export const RemovePrefixMerchantCodesModal = ({
  isDisplayed,
  onClose,
}: RemovePrefixMerchantCodesModalProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const selectedCompanyId = getIdFromParams(useParams())("company_id")
  const modalWidth = 120

  const merchantCodes = useRNBSelector(
    (state) => state.merchantCodes.merchantCodes
  )
  const removePrefixMerchantCodesStatus = useRNBSelector(
    (state) => state.merchantCodes.removePrefixMerchantCodesStatus
  )

  const [prefix, setPrefix] = useState<string>("")
  const [maxPrefixLength, setMaxPrefixLength] = useState(0)
  const [validPrefix, setValidPrefix] = useState(false)
  const [showMerchantCodes, setShowMerchantCodes] = useState(false)
  const [reducibleMerchantCodes, setReducibleMerchantCodes] = useState<
    MerchantCode[]
  >([])

  const handleFormSubmit = () => {
    if (showMerchantCodes) {
      if (selectedCompanyId)
        dispatch(removePrefixMerchantCodesThunk(selectedCompanyId, prefix))
    } else {
      const processedMc = checkRemovePrefixMerchantCodes(merchantCodes, prefix)
      setReducibleMerchantCodes(processedMc.reducibleMerchantCodes)
      setShowMerchantCodes(true)
    }
  }

  const disableSaveButton = () => {
    if (showMerchantCodes) return reducibleMerchantCodes.length === 0
    else return !validPrefix
  }

  const goBackToLengthSelect = () => {
    setShowMerchantCodes(false)
    setReducibleMerchantCodes([])
  }

  const resetForm = () => {
    dispatch(RemovePrefixMerchantCodesReset())
    setPrefix("")
    setValidPrefix(false)
    goBackToLengthSelect()
  }

  const closeModal = () => {
    resetForm()
    onClose()
  }

  useEffect(() => {
    if (selectedCompanyId) {
      dispatch(GetCompanyMerchantCodesDataThunk(selectedCompanyId))
    }
  }, [])

  useEffect(() => {
    if (prefix !== "") {
      setValidPrefix(prefix.length < maxPrefixLength)
    } else setValidPrefix(prefix !== "")
  }, [prefix, maxPrefixLength])

  useEffect(() => {
    if (removePrefixMerchantCodesStatus === "success") closeModal()
  }, [removePrefixMerchantCodesStatus])

  useEffect(() => {
    const maxLength = merchantCodes.reduce((max, mc) => {
      return Math.max(max, mc.code.length)
    }, 0)
    setMaxPrefixLength(maxLength)
  }, [merchantCodes])

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={closeModal}
      left="50%"
      right="50%"
      top={`calc(30vh - 30rem)`}
    >
      <StyledCard width={`${modalWidth}rem`}>
        <StyledHeader width={modalWidth}>
          <div />

          <StyledTitle
            text={intl.formatMessage({
              id: `accounting-plan.merchant-codes.remove-prefix.modal.title`,
            })}
          />

          <ModalClose onClick={() => closeModal()}>
            <CloseCross />
          </ModalClose>
        </StyledHeader>

        <Ct.Spacer height={3} />
        <Ct.Separator size="full" />
        <Ct.Spacer height={3} />

        <LengthSelectWrapper width={modalWidth}>
          <Text
            text={intl.formatMessage({
              id: "accounting-plan.merchant-codes.remove-prefix.modal.desc",
            })}
            textStyle={{
              textAlign: "center",
              fontSize: 2,
            }}
          />

          <Ct.Spacer height={3} />

          <InputWrapper>
            <LockableInput
              type="text"
              label="Prefix à supprimer"
              value={prefix}
              placeholder="Prefix à supprimer"
              maxWidth="100%"
              locked={showMerchantCodes}
              readOnly={showMerchantCodes}
              onChange={(e) => {
                setPrefix(normalizeMerchantCode(e.target.value))
              }}
            />
            <Ct.Spacer height={1} />

            {prefix !== "" && !validPrefix && (
              <Text
                text={intl.formatMessage(
                  {
                    id: "accounting-plan.merchant-codes.remove-prefix.modal.error.length",
                  },
                  { maxPrefixLength: maxPrefixLength }
                )}
                textStyle={{ color: "amaranth" }}
              />
            )}
          </InputWrapper>
        </LengthSelectWrapper>

        {showMerchantCodes && (
          <ListsAccountsWrapper width={modalWidth}>
            <Ct.Spacer height={2} />
            <Text
              text={intl.formatMessage({
                id: "accounting-plan.merchant-codes.remove-prefix.modal.reducible.title",
              })}
              textStyle={{
                fontSize: 2,
              }}
            />
            <Ct.Spacer height={2} />
            <GridContainer columns={3}>
              {sortMerchantCodesByCode(reducibleMerchantCodes, true).map(
                (rmc) => (
                  <Item key={`reducibleMerchantCodes-${rmc.id}`}>
                    <StyledSquareCheck />
                    <Ct.Spacer width={1} />
                    <StyledItemText
                      text={rmc.code}
                      textStyle={{ fontWeight: 500 }}
                    />
                    <Ct.Spacer width={1} />
                    <SmallArrowRight />
                    <Ct.Spacer width={2} />
                    <StyledItemText2
                      text={removePrefixMerchantCode(rmc.code, prefix)}
                      textStyle={{ fontWeight: 500, textAlign: "center" }}
                    />
                  </Item>
                )
              )}
            </GridContainer>

            {disableSaveButton() && (
              <Text
                text={intl.formatMessage({
                  id: "accounting-plan.merchant-codes.remove-prefix.modal.reducible.none",
                })}
                textStyle={{
                  fontSize: 2,
                  color: "amaranth",
                }}
              />
            )}

            <Ct.Spacer height={3} />
          </ListsAccountsWrapper>
        )}

        <Ct.Spacer height={3} />

        <ButtonsPanel>
          <Ct.Button
            label={intl.formatMessage({
              id: showMerchantCodes
                ? "accounting-plan.merchant-codes.remove-prefix.modal.back-to-prefix"
                : "accounting-plan.accounts.modal.button.back",
            })}
            colorType="Tertiary"
            width={42}
            onClick={() => {
              if (showMerchantCodes) goBackToLengthSelect()
              else closeModal()
            }}
            colorScheme={{
              border: "mist",
              color: "cornflower",
              background: "mist",
            }}
          />
          <Ct.Button
            label={intl.formatMessage({
              id: showMerchantCodes
                ? "accounting-plan.merchant-codes.remove-prefix.modal.confirm"
                : "accounting-plan.merchant-codes.remove-prefix.modal.continue",
            })}
            colorType="Primary"
            width={42}
            onClick={() => {
              handleFormSubmit()
            }}
            disabled={disableSaveButton()}
            colorScheme={{
              border: "mist",
              color: "white",
              background: "mist",
            }}
            loadingStatus={removePrefixMerchantCodesStatus}
          />
        </ButtonsPanel>
      </StyledCard>
    </Ct.Modal>
  )
}

const StyledCard = styled((props) => <Ct.Card {...props} />)`
  padding: 3.5rem 0;
  border-radius: 2rem;
`
const StyledHeader = styled.div<{ width: number }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${({ width }) => `${width - 10}rem`};
`
const StyledTitle = styled((props) => <Ct.Title {...props} />)`
  font-size: 24px;
`
const ModalClose = styled.div`
  cursor: pointer;
`
const LengthSelectWrapper = styled.div<{ width: number }>`
  width: ${({ width }) => `${width - 30}rem`};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const InputWrapper = styled.div`
  width: 70%;
`

const ListsAccountsWrapper = styled.div<{ width: number }>`
  width: ${({ width }) => `${width - 20}rem`};
`

const GridContainer = styled.div<{ columns: number }>`
  width: 100%;
  max-height: 24rem;
  overflow: auto;
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
  gap: 1rem;

  /* FIREFOX */
  scrollbar-color: ${colors.rock} transparent;
  scrollbar-width: thin !important;

  /* CHROME */
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${colors.rock};
    border-radius: 3px;
    width: 4px;
  }
`
const Item = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  text-align: center;
  padding-right: 4rem;
`
const StyledSquareCheck = styled(SquareCheck)`
  height: 3rem;
`

const StyledItemText = styled(Text)`
  flex-grow: 1.2;
`
const StyledItemText2 = styled(Text)`
  flex-grow: 1;
`

const ButtonsPanel = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100rem;
`
