import CegidLogo from "../../assets/fiduciary-logos/logo-cegid.png"
import AcdLogo from "../../assets/fiduciary-logos/logo-ACD.png"
import EbpLogo from "../../assets/fiduciary-logos/logo-ebp.jpeg"
import FulllLogo from "../../assets/fiduciary-logos/Logo-Fulll.png"
import InqomLogo from "../../assets/fiduciary-logos/logo-inqom.svg"
import IsacomptaLogo from "../../assets/fiduciary-logos/logo-isacompta.svg"
import LoopLogo from "../../assets/fiduciary-logos/Logo-Loop.jpeg"
import MyunisoftLogo from "../../assets/fiduciary-logos/logo-myunisoft.jpeg"
import NetexcomLogo from "../../assets/fiduciary-logos/logo-netexcom.png"
import QuadratusLogo from "../../assets/fiduciary-logos/logo-Quadratus.png"
import QuickbooksLogo from "../../assets/fiduciary-logos/logo-quickbooks.png"
import RevorLogo from "../../assets/fiduciary-logos/logo-revor.jpeg"
import SageLogo from "../../assets/fiduciary-logos/logo-sage.png"
import TeogestLogo from "../../assets/fiduciary-logos/logo-teogest.jpeg"
import TiimeLogo from "../../assets/fiduciary-logos/logo-time.png"
import ExtrabatLogo from "../../assets/fiduciary-logos/logo-extrabat.svg"
import styled from "styled-components/macro"
import { Text } from "./Text"
// IF NEW LOGO IS ADDED, IT NEEDS TO BE ADDED IN THE LISTS ExistingLogos & softwareAndLogo

export const ExistingLogos = [
  "fulll",
  "loop",
  "cegid",
  "quadratus",
  "isacompta",
  "tiime",
  "quickbooks",
  "sage",
  "acd",
  "ebp",
  "my_unisoft",
  "revor",
  "cegid_11",
  "teogest",
  "netexcom",
  "inqom",
  "extrabat",
]

export type LogoType = (typeof ExistingLogos)[number]

interface LogoProps {
  software: string
  width: string
  height: string
}
export const Logos = ({ software, width, height }: LogoProps) => {
  const softwareAndLogo: Array<{ software: LogoType; logo: string }> = [
    { software: "cegid", logo: CegidLogo },
    { software: "cegid_11", logo: CegidLogo },
    { software: "acd", logo: AcdLogo },
    { software: "ebp", logo: EbpLogo },
    { software: "fulll", logo: FulllLogo },
    { software: "inqom", logo: InqomLogo },
    { software: "isacompta", logo: IsacomptaLogo },
    { software: "loop", logo: LoopLogo },
    { software: "my_unisoft", logo: MyunisoftLogo },
    { software: "netexcom", logo: NetexcomLogo },
    { software: "quadratus", logo: QuadratusLogo },
    { software: "quickbooks", logo: QuickbooksLogo },
    { software: "revor", logo: RevorLogo },
    { software: "sage", logo: SageLogo },
    { software: "teogest", logo: TeogestLogo },
    { software: "tiime", logo: TiimeLogo },
    { software: "extrabat", logo: ExtrabatLogo },
  ]

  const foundLogo = softwareAndLogo.find(
    (element) => element.software === software
  )

  return foundLogo ? (
    <StyledLogo src={foundLogo.logo} width={width} height={height} />
  ) : (
    <Text
      text={software}
      textStyle={{
        color: "navy",
        textTransform: "uppercase",
        fontSize: 12,
        fontWeight: 700,
        fontFamily: "Poppins",
      }}
    />
  )
}

interface StyledLogoProps {
  width: string
  height: string
}

const StyledLogo = styled.img<StyledLogoProps>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  object-fit: contain;
  flex-shrink: 0;
`
