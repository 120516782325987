import { NavigateFunction, Params } from "react-router-dom"
import { Company } from "../store/ducks/companies.ducks"
import { AuxiliaryOrPrincipal } from "../store/ducks/companySettings.ducks"
/* eslint-disable camelcase */

export const findCompaniesToAdd = ({
  mainUserCompanies,
  accessibleCompanies,
}: {
  mainUserCompanies: Company[]
  accessibleCompanies: { id: number; name: string }[]
}): Company[] => {
  const companiesToAdd = mainUserCompanies.filter(
    (mainUserCompany) =>
      !accessibleCompanies.find(
        (accessibleCompany) => accessibleCompany.id === mainUserCompany.id
      )
  )
  return companiesToAdd
}

export const getIdFromParams =
  (params: Readonly<Params<string>>) => (paramName: string) => {
    if (!params[paramName]) {
      // param not there or ''
      return null
    }

    const id = Number(params[paramName])
    if (isNaN(id)) {
      // param is not a number, eg. "toto" instead of number id
      return null
    }

    return id
  }
export type BuyOrSell = "buy" | "sell" | null
export const getBuyOrSellFromParams = (
  params: Readonly<Params<string>>
): BuyOrSell => {
  switch (params.buy_or_sell) {
    case "buy":
      return "buy"
    case "sell":
      return "sell"
    default:
      return null
  }
}

export type buyOrSell = "buy" | "sell"

export const selectCompany = (
  companyId: number,
  navigate: NavigateFunction,
  route: string,
  companies: Array<Company>
) => {
  const companyToSelect = companies.find((c) => c.id === companyId)
  if (companyToSelect) {
    if (
      (!companyToSelect.standard_ledgers ||
        companyToSelect.standard_ledgers.length === 0) &&
      !companyToSelect.no_fec
    ) {
      navigate(`/office/company/${companyId}/initialize`)
    } else if (
      route.includes("office/company") &&
      !route.includes("initialize")
    ) {
      const newRoute = route.replace(/company\/\d+/i, `company/${companyId}`)
      navigate(newRoute)
    } else {
      navigate(`/office/company/${companyId}`)
    }
  } else {
    navigate("/404")
  }
}

export type AccountingSoftware =
  | "fulll"
  | "loop"
  | "cegid"
  | "coala"
  | "quadratus"
  | "unknown"
  | "isacompta"
  | "tiime"
  | "ciel"
  | "quickbooks"
  | "sage"
  | "acl"
  | "acd"
  | "ebp"
  | "my_unisoft"
  | "revor"
  | "cegid_11"
  | "teogest"
  | "netexcom"
  | "inqom"
  | "pennylane"
  | "extrabat"

export const AccountingSoftwareArray: AccountingSoftware[] = [
  "fulll",
  "loop",
  "cegid",
  "coala",
  "quadratus",
  "unknown",
  "isacompta",
  "tiime",
  "ciel",
  "quickbooks",
  "sage",
  "acl",
  "acd",
  "ebp",
  "my_unisoft",
  "revor",
  "cegid_11",
  "teogest",
  "netexcom",
  "inqom",
  "pennylane",
  "extrabat",
]

export const displayPrefix = (
  auxiliary_or_principal: AuxiliaryOrPrincipal,
  accounting_software_prefix: { buy: string; sell: string } | null,
  companyAuxiliary: undefined | boolean,
  buyOrSell: "buy" | "sell"
) => {
  if (accounting_software_prefix) {
    return buyOrSell === "buy"
      ? accounting_software_prefix.buy
      : accounting_software_prefix.sell
  }

  if (companyAuxiliary || !!companyAuxiliary) return ""

  if (
    auxiliary_or_principal?.length === 1 &&
    auxiliary_or_principal[0] === "auxiliary"
  )
    return ""

  return buyOrSell === "buy" ? "401" : "411"
}

export const getHistoryValues = (metadata: { [index: string]: string }) => {
  let toDisplay = []

  for (let i = 0; i < Object.keys(metadata).length; i++) {
    const metadataKey = Object.keys(metadata)[i]
    const metadataValue = Object.values(metadata)[i]

    switch (metadataKey) {
      case "previous_accounting_software":
        toDisplay.push("Ancien logiciel: " + metadataValue.toUpperCase())
        break
      case "previous_buy_auxiliary_prefix":
        toDisplay.push("Ancien préfixe d'achats: " + metadataValue)
        break
      case "previous_sell_auxiliary_prefix":
        toDisplay.push("Ancien Préfixe de ventes: " + metadataValue)
        break
      case "previous_accounting_software_reference":
        toDisplay.push("Ancienne référence du dossier: " + metadataValue)
        break
      default:
        return ""
    }
  }
  return toDisplay.join("; ")
}

export type CompanyLegalForm = (typeof legalFormOptionList)[number]["value"]

export type LegalFormOption = (typeof legalFormOptionList)[number]
export const legalFormOptionList = [
  { value: "SARL", label: "SARL" },
  { value: "SAS", label: "SAS" },
  { value: "SASU", label: "SASU" },
  { value: "SA", label: "SA" },
  { value: "SNC", label: "SNC" },
  { value: "EURL", label: "EURL" },
  { value: "SCA", label: "SCA" },
  { value: "SCS", label: "SCS" },
  { value: "EI", label: "EI" },
  { value: "SCI", label: "SCI" },
  { value: "SCEA", label: "SCEA" },
  { value: "SERL", label: "SERL" },
  { value: "EIRL", label: "EIRL" },
  { value: "SELARL", label: "SELARL" },
  { value: "SELAFA", label: "SELAFA" },
  { value: "SELAS", label: "SELAS" },
  { value: "SELNC", label: "SELNC" },
  { value: "SELCS", label: "SELCS" },
  { value: "GIE", label: "GIE" },
  { value: "GEIE", label: "GEIE" },
  { value: "GAEC", label: "GAEC" },
  { value: "EARL", label: "EARL" },
  { value: "GFA", label: "GFA" },
  { value: "SCOP", label: "SCOP" },
  { value: "SCM", label: "SCM" },
  { value: "SCP", label: "SCP" },
] as const

export const shareCapitalNotMandatory = [
  "SNC",
  "SCS",
  "EI",
  "EIRL",
  "SELNC",
  "SELCS",
  "GIE",
  "GEIE",
] as const
