import { useEffect, VFC, MouseEvent, useState, Fragment } from "react"
import { useDispatch } from "react-redux"
import styled from "styled-components/macro"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useIntl } from "react-intl"
import { useDeepCompareMemo } from "use-deep-compare"

import {
  Company,
  SetIsFavoriteThunk,
  getPendingBatchesAndWritingsByCompany,
  registerCompanyResetStatus,
} from "../../../store/ducks/companies.ducks"
import { useRNBSelector } from "../../../store/rootReducer"
import { boxShadow, colors } from "../../../styles/design.config"
import { ReactComponent as Wheel } from "../../../assets/wheel.svg"
import { ReactComponent as Plus } from "../../../assets/plus.svg"
import { ReactComponent as Bulb } from "../../../assets/bulb.svg"
import { ReactComponent as Info } from "../../../assets/info-line.svg"
import { ReactComponent as HeartFilled } from "../../../assets/heart-filled.svg"
import { ReactComponent as HeartEmpty } from "../../../assets/heart-empty.svg"
import * as Ct from "ldlj"
import { croppedString } from "../../../utils/string"
import { ReactComponent as Search } from "../../../assets/search.svg"
import {
  filterCompanies,
  sortCompaniesAlphabetically,
  sortCompaniesByClientsCount,
  sortCompaniesByCollaboratorsCount,
  sortCompaniesByFavoriteAndName,
  sortCompaniesByWritingsToDownloadOrGenerate,
} from "../../../utils/fiduciary"
import config from "../../../config"
import { SortButton } from "../../../components/Commons/SortButton"
import { selectCompanyAction } from "../../../store/ducks/companySettings.ducks"
import { getPennylaneTokenFromCodeThunk } from "../../../store/ducks/accountingSoftware.ducks"

const Companies: VFC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const fiduciary = useRNBSelector((state) => state.fiduciary)
  const fiduciaryPricingType = useRNBSelector(
    (state) => state.fiduciary.active_pricing?.pricing_type
  )

  const [query] = useSearchParams()
  const code = query.get("code")

  const [displayedCompanies, setDisplayedCompanies] = useState<Company[]>([
    {} as Company,
  ])

  const [search, setSearch] = useState("")
  const [asc, setAsc] = useState(true)
  const [initialLoad, setInitialLoad] = useState(true)

  const companies = useRNBSelector((state) =>
    Object.values(state.companies.companies)
  )

  const { pennylaneCodeRegisterStatus } = useRNBSelector((state) => ({
    pennylaneCodeRegisterStatus:
      state.accountingSoftware.pennylaneCodeRegisterStatus,
  }))

  useEffect(() => {
    const selectedCompanyId = Number(localStorage.getItem("pennylaneCompanyId"))

    if (pennylaneCodeRegisterStatus === "SUCCESS") {
      navigate(`/office/company/${selectedCompanyId}/settings/change_software`)
    }
  }, [pennylaneCodeRegisterStatus])

  useEffect(() => {
    const selectedCompanyId = Number(localStorage.getItem("pennylaneCompanyId"))

    if (pennylaneCodeRegisterStatus === "IDLE" && code && selectedCompanyId) {
      dispatch(
        getPennylaneTokenFromCodeThunk({
          code,
          companyId: selectedCompanyId,
          redirectUri:
            "https://app.chaintrust.io/rnb/office/fiduciary/companies",
        })
      )
    }
  }, [pennylaneCodeRegisterStatus, code])

  const standardLedgers = useRNBSelector((state) => state.user.standardLedgers)
  const currentUserTypology = useRNBSelector((state) => state.user.typology)
  const companiesLogos = useRNBSelector(
    (state) => state.companies.companiesLogos
  )

  const standardLedgersCompanyIds = useDeepCompareMemo(
    () => standardLedgers.map((standardLedger) => standardLedger["companyId"]),
    [standardLedgers]
  )

  const companiesForCustomer = useDeepCompareMemo(
    () =>
      companies.filter(
        (c) => standardLedgersCompanyIds.includes(c.id) || c.no_fec
      ),
    [companies, standardLedgersCompanyIds]
  )
  useEffect(() => {
    if (companies.length > 0 && initialLoad) {
      dispatch(getPendingBatchesAndWritingsByCompany())
      setInitialLoad(false)
    }
  }, [companies, dispatch])

  const currentFiduciaryCreationLocked = useRNBSelector(
    (state) => state.fiduciary.companies_creation_locked_at
  )

  type SortOptionsValues =
    | "companyName"
    | "favorite"
    | "clientCount"
    | "collaboratorCount"
    | "readyToDownloadAndGenerate"

  const sortOptions: Ct.OptionList<SortOptionsValues> = [
    { value: "companyName", label: "Nom de société", sortType: "string" },
    { value: "clientCount", label: "Nombre de clients", sortType: "number" },
    {
      value: "collaboratorCount",
      label: "Nombre de collaborateurs",
      sortType: "number",
    },
    {
      value: "readyToDownloadAndGenerate",
      label: "Écritures prêtes",
      sortType: "number",
    },
    {
      value: "favorite",
      label: "Favoris",
      sortType: "string",
    },
  ]

  const sorter = (asc: boolean) => (option: SortOptionsValues) => {
    if (option === "companyName") {
      setDisplayedCompanies(
        sortCompaniesAlphabetically(displayedCompanies, asc)
      )
    }
    if (option === "clientCount") {
      setDisplayedCompanies(
        sortCompaniesByClientsCount(displayedCompanies, asc)
      )
    }
    if (option === "collaboratorCount") {
      setDisplayedCompanies(
        sortCompaniesByCollaboratorsCount(displayedCompanies, asc)
      )
    }
    if (option === "readyToDownloadAndGenerate") {
      setDisplayedCompanies(
        sortCompaniesByWritingsToDownloadOrGenerate(displayedCompanies, asc)
      )
    }
    if (option === "favorite") {
      setDisplayedCompanies(
        sortCompaniesByFavoriteAndName(displayedCompanies, asc)
      )
    }
    setAsc(!asc)
  }

  const clickCard = (id: number) => {
    dispatch(selectCompanyAction())
    if (
      standardLedgers.find((element) => element.companyId === id) ||
      companies.find((c) => c.id === id)?.no_fec
    ) {
      navigate(`/office/company/${id}`)
    } else {
      navigate(`/office/company/${id}/initialize`)
    }
  }

  const clickSettings = (event: MouseEvent, id: number) => {
    if (currentUserTypology === "customer") {
      navigate(`/office/company/${id}/settings/informations`)
    } else {
      navigate(`/office/company/${id}/settings`)
    }
    event.stopPropagation()
  }

  const clickFavorite = (
    event: MouseEvent,
    id: number,
    isFavorite: boolean
  ) => {
    dispatch(SetIsFavoriteThunk(id, isFavorite))
    event.stopPropagation()
  }

  const clickAddCompany = () => {
    dispatch(registerCompanyResetStatus())
    if (
      fiduciary.status === "SUCCESS" &&
      fiduciaryPricingType === "per_document"
    ) {
      navigate("/office/fiduciary/register_company/accrual_accounting")
    } else if (fiduciary.cash_or_accrual === "cash") {
      navigate(`/office/fiduciary/register_company/cash_accounting`)
    } else if (fiduciary.cash_or_accrual === "accrual") {
      navigate(`/office/fiduciary/register_company/accrual_accounting`)
    } else {
      navigate("/office/fiduciary/company_accounting_type")
    }
  }

  useEffect(() => {
    dispatch(getPendingBatchesAndWritingsByCompany())
  }, [dispatch])

  useEffect(() => {
    const filteredCompanies = filterCompanies(companies, search)
    setDisplayedCompanies(filteredCompanies)
  }, [setDisplayedCompanies, search])

  useEffect(() => {
    if (!companies && !companiesForCustomer) return

    const companiesToDisplay =
      currentUserTypology === "customer" ? companiesForCustomer : companies

    const companiesWithoutDeactivated = companiesToDisplay.filter(
      (value) => value.deactivatedCompany !== "SUCCESS"
    )

    const sortedCompanies = sortCompaniesByFavoriteAndName(
      companiesWithoutDeactivated,
      true
    )

    setDisplayedCompanies(sortedCompanies)
  }, [
    setDisplayedCompanies,
    currentUserTypology,
    standardLedgersCompanyIds,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(companies),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(companiesForCustomer),
  ])

  const userInformations = useRNBSelector((state) => state.user)
  const fiduciaryInformations = useRNBSelector((state) => state.fiduciary)

  /* eslint-disable camelcase */
  useEffect(() => {
    if (userInformations) {
      window.userpilot.identify(userInformations.id, {
        name: userInformations.firstName + " " + userInformations.lastName,
        email: userInformations.email,
        first_name: userInformations.firstName,
        last_name: userInformations.lastName,
        created_at: userInformations.created_at,
        role: userInformations.typology,
        company: {
          id: fiduciaryInformations.id, // Required, used to identify the company
          name: fiduciaryInformations.name,
          created_at: fiduciaryInformations.created_at,
          company_country: "FR",
        },
      })
    }
  }, [userInformations, fiduciaryInformations, userInformations.typology])

  return !displayedCompanies ? (
    <></>
  ) : (
    <Wrapper>
      <StyledSection>
        <Filters>
          <Ct.Input
            label="Rechercher"
            value={search}
            suffix={<Search />}
            maxWidth={30}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
            shadowed={true}
            noBorder={true}
          />
          <Ct.Row>
            {currentUserTypology &&
              currentUserTypology !== "customer" &&
              currentUserTypology !== "customer_accountant" &&
              (currentFiduciaryCreationLocked &&
              currentUserTypology === "collaborator" ? (
                <>
                  <Ct.RowCenter>
                    <BulbPurple />
                  </Ct.RowCenter>
                  <StyledPurpleText
                    text={intl.formatMessage({
                      id: "fiduciary.companies.stop-create",
                    })}
                    textStyle={{
                      fontWeight: 500,
                      fontSize: 2,
                    }}
                  />
                </>
              ) : (
                <Ct.Button
                  label={intl.formatMessage({
                    id: "fiduciary.companies.addCompany",
                  })}
                  onClick={clickAddCompany}
                  width={24}
                  prefix={<Plus />}
                />
              ))}

            <Ct.Spacer width={3} />

            <SortButton
              sortMethod={sorter(asc)}
              options={sortOptions}
              defaultOption={sortOptions[0]}
              intl={intl}
              asc={!asc}
            />
          </Ct.Row>
        </Filters>
        <Ct.Spacer height={5} />
        <Content>
          {displayedCompanies.map((company, i) => (
            <Fragment key={i}>
              <CompanyCard key={i}>
                <CardContent key={i} onClick={(event) => clickCard(company.id)}>
                  <FlexStartFull>
                    {companiesLogos[company.id] && (
                      <>
                        <StyledCompanyLogo
                          src={`${config.invoicingLogosBucketUri}${
                            companiesLogos[company.id]?.file_name
                          }`}
                          alt="logo"
                        />
                        <Ct.Spacer width={2} />
                      </>
                    )}
                    <WrapperHeader>
                      <Header>
                        <CompanyName>
                          {croppedString(company.name, 60)}
                        </CompanyName>

                        <Ct.Text
                          text={company.accounting_software_reference || ""}
                          textStyle={{
                            color: "slateGrey",
                            fontSize: 1.5,
                            fontWeight: 500,
                          }}
                        />
                      </Header>

                      <CompanySettingsContainer data-cy="company-settings">
                        {currentUserTypology === "customer" ? (
                          <span
                            onClick={(event) =>
                              clickSettings(event, company.id)
                            }
                          >
                            <StyledInfo />
                          </span>
                        ) : (
                          <Ct.Row>
                            <span
                              onClick={(event) => {
                                clickFavorite(
                                  event,
                                  company.id,
                                  !company?.is_favorite
                                )
                              }}
                            >
                              {company.is_favorite ? (
                                <HeartFilled />
                              ) : (
                                <HeartEmpty />
                              )}
                            </span>

                            <Ct.Spacer height={0} width={1} />

                            <span
                              onClick={(event) =>
                                clickSettings(event, company.id)
                              }
                            >
                              <Wheel />
                            </span>
                          </Ct.Row>
                        )}
                      </CompanySettingsContainer>
                    </WrapperHeader>
                  </FlexStartFull>

                  <Ct.Spacer height={1} />

                  <div>
                    {company.depositInReview ? (
                      <>
                        <Ct.Spacer height={1} />
                        <Ct.RowCenter>
                          <OrangeIndicator />
                          <Ct.Spacer width={1} />
                          <Ct.Text
                            text={intl.formatMessage({
                              id: "fiduciary.companies.deposit_in_review",
                            })}
                            textStyle={{
                              fontWeight: 500,
                              fontSize: 1.75,
                            }}
                          />
                        </Ct.RowCenter>
                      </>
                    ) : (
                      <div />
                    )}

                    {company.readyToGenerate &&
                    currentUserTypology !== "customer" ? (
                      <>
                        <Ct.Spacer height={1} />
                        <Ct.RowCenter>
                          <BlueIndicator />
                          <Ct.Spacer width={1} />
                          <Ct.Text
                            text={`Archive(s) prête(s) à être générée(s)`}
                            textStyle={{
                              fontWeight: 500,
                              fontSize: 1.75,
                            }}
                          />
                        </Ct.RowCenter>
                      </>
                    ) : (
                      <div />
                    )}

                    {company.readyToDownload &&
                    company.readyToDownload > 0 &&
                    currentUserTypology !== "customer" ? (
                      <>
                        <Ct.Spacer height={1} />
                        <Ct.RowCenter>
                          <GreenIndicator />
                          <Ct.Spacer width={1} />
                          <Ct.Text
                            text={
                              company.readyToDownload > 1
                                ? `${company.readyToDownload} archives prêtes à être téléchargées`
                                : `${company.readyToDownload} archive prête à être téléchargée`
                            }
                            textStyle={{
                              fontWeight: 500,
                              fontSize: 1.75,
                            }}
                          />
                        </Ct.RowCenter>
                      </>
                    ) : (
                      <div />
                    )}

                    {company.standard_ledgers &&
                    company.standard_ledgers.length === 0 &&
                    !company.no_fec ? (
                      <>
                        <Ct.Spacer height={1} />
                        <Ct.RowCenter>
                          <RedIndicator />
                          <Ct.Spacer width={1} />
                          <Ct.Text
                            text={intl.formatMessage({
                              id: "fiduciary.companies.no-fec",
                            })}
                            textStyle={{
                              fontWeight: 500,
                              fontSize: 1.75,
                            }}
                          />
                        </Ct.RowCenter>
                      </>
                    ) : (
                      <div />
                    )}
                  </div>
                  <Ct.Spacer height={2} />
                  <WrapperBottomText>
                    {userInformations.typology !== "customer" && (
                      <Ct.Text
                        text={intl.formatMessage({
                          id: `fiduciary.companies.accounting_type.${company.accounting_type}`,
                        })}
                        textStyle={{
                          color: "slateGrey",
                          fontSize: 1.5,
                          fontWeight: 500,
                        }}
                      />
                    )}

                    <Ct.Text
                      text={intl.formatMessage(
                        {
                          id: "fiduciary.companies.collaborators",
                        },
                        {
                          countCollaborators: company.collaborator_count || 0,
                          pluralCollaborators:
                            company.collaborator_count &&
                            company.collaborator_count > 1
                              ? "s"
                              : "",
                          clientCount: company.customer_count || 0,
                          pluralClient:
                            company.customer_count && company.customer_count > 1
                              ? "s"
                              : "",
                        }
                      )}
                      textStyle={{
                        color: "slateGrey",
                        fontSize: 1.5,
                        fontWeight: 500,
                      }}
                    />
                  </WrapperBottomText>
                </CardContent>
              </CompanyCard>
            </Fragment>
          ))}
        </Content>
      </StyledSection>
    </Wrapper>
  )
}

export default Companies

const Wrapper = styled.div`
  padding-bottom: 4rem;
  display: flex;
  height: 100%;
  box-sizing: border-box;
`

const StyledSection = styled.section`
  border-radius: 0 2.5rem 2.5rem;
  background-color: ${colors.white};
  box-shadow: ${boxShadow};
  padding: 2rem 2rem 4rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`

const Filters = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem;
`

const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 46rem);
  justify-content: center;
  grid-column-gap: 4rem;
  grid-row-gap: 4rem;
  grid-auto-rows: 26rem;
  padding-bottom: 5rem;
  overflow: auto;

  /* FIREFOX */
  scrollbar-color: ${colors.rock} transparent;
  scrollbar-width: thin !important;

  /* CHROME */
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${colors.rock};
    border-radius: 3px;
    width: 4px;
  }
`

const CompanyCard = styled.div`
  display: flex;
  padding: 20px 16px 24px;
  cursor: pointer;
  height: 22rem;
  background: #ffffff;
  box-shadow: 1px 4px 11px 0 rgba(76, 119, 219, 0.24);
  border-radius: 10px;
  border: 1px solid #edeeef;

  :hover {
    background-color: ${colors.mist};
  }
`

const CardContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

interface WithDataCy {
  "data-cy": string
}

const CompanySettingsContainer = styled.div<WithDataCy>`
  cursor: pointer;
  position: relative;
  height: 3rem;
`

const StyledInfo = styled(Info)`
  & path {
    fill: ${colors.navy};
  }
`
const CompanyName = styled.h3`
  font-size: 2rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: ${colors.navy};
  text-transform: uppercase;
  line-height: 3rem;
  word-break: break-word;
`

const StyledCompanyLogo = styled.img`
  border-radius: 1.25rem;
  height: 6.5rem;
  width: 6.5rem;
  border: 1px solid ${colors.cornflower};
  object-fit: contain;
  cursor: pointer;
  flex-shrink: 0;
`

const Indicator = styled.div`
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
`

const BlueIndicator = styled(Indicator)`
  background-color: ${colors.cornflower};
`
const OrangeIndicator = styled(Indicator)`
  background-color: ${colors.orange};
`
const GreenIndicator = styled(Indicator)`
  background-color: ${colors.shamrock};
`
const RedIndicator = styled(Indicator)`
  background-color: ${colors.radicalRed};
`
const FlexStartFull = styled((props) => <Ct.FlexStart {...props} />)`
  height: 6.5rem;
`

const StyledPurpleText = styled(Ct.Text)`
  display: flex;
  align-items: center;
  color: ${colors.navy};
`

const BulbPurple = styled(Bulb)`
  & path {
    fill: ${colors.brightSun};
  }
`
const WrapperBottomText = styled.div`
  line-height: 2rem;
  display: flex;
  flex-direction: column;
`
const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const WrapperHeader = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
`
