import styled from "styled-components/macro"
import { boxShadow, colors, sizes } from "../../../../styles/design.config"
import * as Ct from "ldlj"
import { useIntl } from "react-intl"
import { Alert } from "../../../../components/Commons/Alert"
import { Text } from "../../../../components/Commons/Text"
import { Table, TableBuilder } from "../../../../components/Commons/Table"
import {
  arraysHaveChanged,
  checkIfRowAlreadyExists,
  CreateKeyWord,
  getChangedRows,
  hasKeyWordDuplicates,
} from "../../../../utils/accountingKeyWords"
import { Select } from "../../../../components/Commons/Select"
import { useEffect, useState } from "react"
import { Input } from "../../../../components/Commons/Input"
import { ReactComponent as TrashIcon } from "../../../../assets/TrashIcon.svg"
import { ReactComponent as Eye } from "../../../../assets/eyeFilled.svg"
import { ReactComponent as Warning } from "../../../../assets/warning.svg"
import { useDispatch } from "react-redux"
import { getIdFromParams } from "../../../../utils/company"
import { useParams } from "react-router-dom"
import {
  CreateUpdateDeleteKeyWordsCompanyThunk,
  GetCompanyAccountingKeyWordsThunk,
} from "../../../../store/ducks/companySettings.ducks"
import { useRNBSelector } from "../../../../store/rootReducer"
import { capitalizeFirstLetter } from "../../../../utils/string"
import { Button } from "../../../../components/Commons/Button"
import { AccountingKeyWordEventsModal } from "../../../../components/accountingPlan/AccountingKeyWordEventsModal"
import { usePrompt } from "../../../../utils/usePrompt.hook"
import { TableWrapper } from "../../../../components/Commons/TableStylesForSelect"
/* eslint-disable camelcase */

export const AccountingKeyWords = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const selectedCompanyId = getIdFromParams(useParams())("company_id") || 0
  const { existingKeyWords, events } = useRNBSelector((state) => ({
    existingKeyWords: state.companySettings.accounting_key_words.key_words,
    events: state.companySettings.accounting_key_words.events,
  }))

  const [hoverId, setHoverId] = useState<number | null>(null)
  const [keyWordsToDisplay, setKeyWordsToDisplay] = useState<CreateKeyWord[]>([
    {
      buy_or_sell: {
        value: "buy",
        label: capitalizeFirstLetter(
          intl.formatMessage({
            id: `buy-or-sell.buy`,
          })
        ),
      },
      key_word: "",
      account_root: null,
      create_or_update: "create",
      id: null,
    },
  ])
  const [keyWordsToDestroy, setKeyWordsToDestroy] = useState<CreateKeyWord[]>(
    []
  )
  const [displayEvents, setDisplayEvents] = useState<boolean>(false)
  const options: Ct.Option<string>[] = ["buy", "sell"].map((bs) => ({
    value: bs.toString(),
    label: capitalizeFirstLetter(
      intl.formatMessage({
        id: `buy-or-sell.${bs}`,
      })
    ),
  }))

  useEffect(() => {
    if (existingKeyWords.length > 0) {
      setKeyWordsToDisplay(
        existingKeyWords.map((kw) => ({
          buy_or_sell:
            options.find((o) => o.value === kw.buy_or_sell) || options[0],
          key_word: kw.key_word,
          account_root: kw.account_root,
          create_or_update: "update",
          id: kw.id,
        }))
      )
    }
  }, [existingKeyWords])

  useEffect(() => {
    const lastItem: CreateKeyWord =
      keyWordsToDisplay[keyWordsToDisplay.length - 1]
    if (lastItem.account_root) {
      setKeyWordsToDisplay([
        ...keyWordsToDisplay,
        {
          buy_or_sell: {
            value: "buy",
            label: capitalizeFirstLetter(
              intl.formatMessage({
                id: `buy-or-sell.buy`,
              })
            ),
          },
          key_word: "",
          account_root: null,
          create_or_update: "create",
          id: null,
        },
      ])
    }
  }, [keyWordsToDisplay])

  useEffect(() => {
    dispatch(GetCompanyAccountingKeyWordsThunk(selectedCompanyId))
  }, [dispatch])

  const isSaveDisabled =
    arraysHaveChanged(
      keyWordsToDisplay.slice(0, -1),
      existingKeyWords.map((kw) => ({
        buy_or_sell: {
          value: kw.buy_or_sell,
          label: capitalizeFirstLetter(
            intl.formatMessage({
              id: `buy-or-sell.${kw.buy_or_sell}`,
            })
          ),
        },
        key_word: kw.key_word,
        account_root: kw.account_root,
        create_or_update: "update",
        id: kw.id,
      }))
    ) &&
    !hasKeyWordDuplicates(keyWordsToDisplay) &&
    keyWordsToDisplay.every((kw) => kw.account_root !== 0)

  usePrompt(
    intl.formatMessage({ id: "accounting-plan.key-words.prompt" }),
    isSaveDisabled
  )

  const columns: TableBuilder<CreateKeyWord>[] = [
    {
      headerText: "accounting-plan.key-words.table-header.buy-or-sell",
      width: "32%",
      flexGrow: "none",
      content: (row: CreateKeyWord) => {
        const index = keyWordsToDisplay.indexOf(row)
        return (
          <Select
            intl={intl}
            options={options}
            value={row.buy_or_sell}
            onChangeCallback={(e: Ct.Option<string>) => {
              const index = keyWordsToDisplay.indexOf(row)
              const updatedKeyWord = [...keyWordsToDisplay]
              updatedKeyWord[index] = {
                ...row,
                buy_or_sell:
                  options.find((u) => u.value === e.value) || options[0],
              }
              setKeyWordsToDisplay(updatedKeyWord)
            }}
            label={""}
            domain={""}
            optionType={""}
            borderRadius={"0"}
            overrideBorder={`1px solid ${colors.grey} `}
            backgroundColor={
              hoverId === index ? "rgba(252, 90, 90, 0.2)" : colors.white
            }
          />
        )
      },
    },
    {
      headerText: "accounting-plan.key-words.table-header.key-word",
      width: "32%",
      flexGrow: "none",
      content: (row: CreateKeyWord) => {
        const index = keyWordsToDisplay.indexOf(row)
        return (
          <Input
            label=""
            value={row.key_word}
            onChange={(e) => {
              const updatedKeyWord = [...keyWordsToDisplay]
              updatedKeyWord[index] = {
                ...row,
                key_word: e.target.value,
              }
              setKeyWordsToDisplay(updatedKeyWord)
            }}
            borderRadius={0}
            borderColor={
              checkIfRowAlreadyExists(row, index, keyWordsToDisplay) === index
                ? colors.amaranth
                : row.key_word === "" && index !== keyWordsToDisplay.length - 1
                ? colors.amaranth
                : colors.grey
            }
            backgroundColor={
              hoverId === index ? "rgba(252, 90, 90, 0.2)" : colors.white
            }
          />
        )
      },
    },
    {
      headerText: "accounting-plan.key-words.table-header.account-root",
      width: "32%",
      flexGrow: "none",
      content: (row: CreateKeyWord) => {
        const index = keyWordsToDisplay.indexOf(row)
        return (
          <WrapperInput>
            <Input
              label=""
              value={String(row.account_root)}
              onChange={(e) => {
                const updatedKeyWord = [...keyWordsToDisplay]
                updatedKeyWord[index] = {
                  ...row,
                  account_root: Number(e.target.value),
                }
                setKeyWordsToDisplay(updatedKeyWord)
              }}
              borderRadius={0}
              borderColor={
                row.account_root !== null && Number(row.account_root) === 0
                  ? colors.amaranth
                  : colors.grey
              }
              backgroundColor={
                hoverId === index ? "rgba(252, 90, 90, 0.2)" : colors.white
              }
              type={"number"}
              min={"1"}
            />
          </WrapperInput>
        )
      },
    },
    {
      headerText: "",
      width: "4%",
      flexGrow: "1 0",
      heightItem: "5.6rem",
      content: (row: CreateKeyWord) => {
        const index = keyWordsToDisplay.indexOf(row)
        return (
          <TrashBlock
            onMouseEnter={() => {
              if (index === keyWordsToDisplay.length - 1) return
              setHoverId(index)
            }}
            onMouseLeave={() => {
              if (index === keyWordsToDisplay.length - 1) return
              setHoverId(null)
            }}
            onClick={() => {
              if (index === keyWordsToDisplay.length - 1) return
              if (index > -1) {
                setKeyWordsToDestroy([...keyWordsToDestroy, row])

                const updatedKeyWord = [...keyWordsToDisplay]
                updatedKeyWord.splice(index, 1)
                setKeyWordsToDisplay(updatedKeyWord)
                setHoverId(null)
              }
            }}
            backgroundColor={
              hoverId === index ? "rgba(252, 90, 90, 0.2)" : colors.white
            }
          >
            <StyledTrashIcon
              disabled={index === keyWordsToDisplay.length - 1}
            />
          </TrashBlock>
        )
      },
    },
  ]

  return (
    <Wrapper>
      <StyledSection>
        {displayEvents && (
          <AccountingKeyWordEventsModal
            isDisplayed={displayEvents}
            onClose={() => {
              setDisplayEvents(false)
            }}
            events={events}
          />
        )}

        <Header>
          <Right
            onClick={() => {
              setDisplayEvents(true)
            }}
          >
            <Eye />
            <Ct.Spacer width={1} />

            <Text
              text={intl.formatMessage({
                id: "accounting-plan.key-words.events",
              })}
              textStyle={{
                color: "cornflower",
                cursor: "pointer",
              }}
            />
          </Right>
        </Header>

        <StyledContent>
          <Ct.Spacer height={2} />

          <StyledText
            text={intl.formatMessage({
              id: "accounting-plan.key-words.title",
            })}
            textStyle={{
              fontFamily: "Poppins",
              color: "navy",
              fontWeight: 600,
              fontSize: "2.25",
              textTransform: "uppercase",
            }}
          />

          <Ct.Spacer height={2} />

          <Alert alertType={"bulb"}>
            <Text
              text={intl.formatMessage({
                id: "accounting-plan.key-words.alert",
              })}
            />
            <Text
              text={intl.formatMessage({
                id: "accounting-plan.key-words.alert2",
              })}
            />
          </Alert>

          <Ct.Spacer height={4} />

          <TableWrapper height="initial">
            <Table
              intl={intl}
              columns={columns}
              rows={keyWordsToDisplay}
              alignItems={"center"}
              width={"100%"}
              height={"initial"}
              padding={"0"}
              paddingRows={"0"}
              fontWeightTitle={600}
              alertMessage={"accounting-plan.key-words.table.empty"}
              sortableColumnsLength={0}
              customScrollBar={true}
              centerFirstItem={true}
            />
          </TableWrapper>

          <Ct.Spacer height={4} />

          <CenteredDiv>
            {hasKeyWordDuplicates(keyWordsToDisplay) ? (
              <>
                <Warning />
                <Ct.Spacer width={1} />
                <Text
                  text={intl.formatMessage({
                    id: "accounting-plan.key-words.table-header.events.duplicates",
                  })}
                  textStyle={{
                    color: "amaranth",
                    fontWeight: 600,
                  }}
                />
              </>
            ) : (
              <Text
                text={intl.formatMessage(
                  {
                    id:
                      keyWordsToDisplay.length - 1 - existingKeyWords.length <=
                      1
                        ? `accounting-plan.key-words.table-header.count.one`
                        : "accounting-plan.key-words.table-header.count.multiple",
                  },
                  {
                    count:
                      keyWordsToDisplay.length - 1 - existingKeyWords.length < 0
                        ? 0
                        : keyWordsToDisplay.length -
                          1 -
                          existingKeyWords.length,
                  }
                )}
              />
            )}
          </CenteredDiv>
          <Ct.Spacer height={4} />

          <CenteredDiv>
            <Button
              label={intl.formatMessage({
                id: "accounting-plan.key-words.table-header.save",
              })}
              width={sizes.button.standard}
              disabled={!isSaveDisabled}
              onClick={() => {
                const rows = getChangedRows(
                  keyWordsToDisplay.slice(0, -1),
                  existingKeyWords.map((kw) => ({
                    buy_or_sell: {
                      value: kw.buy_or_sell,
                      label: capitalizeFirstLetter(
                        intl.formatMessage({
                          id: `buy-or-sell.${kw.buy_or_sell}`,
                        })
                      ),
                    },
                    key_word: kw.key_word,
                    account_root: kw.account_root,
                    create_or_update: "update",
                    id: kw.id,
                  }))
                )

                dispatch(
                  CreateUpdateDeleteKeyWordsCompanyThunk({
                    companyId: selectedCompanyId,
                    key_words: rows,
                    key_words_to_destroy: keyWordsToDestroy,
                  })
                )
                setKeyWordsToDestroy([])
              }}
            />
          </CenteredDiv>
        </StyledContent>
      </StyledSection>
    </Wrapper>
  )
}

/* stylelint-disable no-descending-specificity */
const Wrapper = styled.div`
  padding-bottom: 4rem;
  display: flex;
  height: 100%;
  box-sizing: border-box;
`
const StyledSection = styled.section`
  border-radius: 0 2.5rem 2.5rem;
  background-color: ${colors.white};
  box-shadow: ${boxShadow};
  padding: 2rem 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  overflow: auto;
  /* FIREFOX */
  scrollbar-color: ${colors.rock} transparent;
  scrollbar-width: thin !important;
  /* CHROME */
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${colors.rock};
    border-radius: 3px;
    width: 4px;
  }
`
const Header = styled.header`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 3rem 1rem 1rem 1rem;
  width: 100%;
`
const Right = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  padding-right: 3rem;
`
const StyledContent = styled.div`
  width: 80%;
`
const StyledText = styled((props) => <Text {...props} />)`
  display: flex;
  justify-content: flex-start !important;
  width: 100%;
`
const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`
const TrashBlock = styled.div<{ backgroundColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 0.5rem;
  border: 1px solid ${colors.grey};
  width: 100%;
  height: 100%;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : colors.white};
  transition: 0.5s background-color ease-in-out;
`
const WrapperInput = styled.div<{ backgroundColor?: string }>`
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : colors.white};
  transition: 0.5s background-color ease-in-out;
  display: flex;
  width: 100%;
`
const StyledTrashIcon = styled(TrashIcon)<{ disabled: boolean }>`
  fill: ${(props) => (props.disabled ? colors.moon : colors.rock)};
  padding-left: 0.6rem;
  padding-top: 0.2rem;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  transition: 0.5s fill ease-in-out;
  :hover {
    fill: ${(props) => (props.disabled ? colors.moon : colors.amaranth)};
  }
`
