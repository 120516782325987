import axios, { AxiosError } from "axios"

import { Dispatch, RNBThunkAction } from "../store.config"
import { AnyAction } from "redux"
import { isAxiosError } from "../../utils/asyncTools"
import { GetCompanyThunk } from "./companies.ducks"
import { InstructionType } from "./merchants.ducks"
import { addOrReplaceBy } from "../../utils/array"
import { GetBillOfExchangeAccountsThunk } from "./accounts.ducks"

export enum MerchantCodesActionsEnum {
  UPDATE_MERCHANT_NAME = "MERCHANTCODES/UpdateMerchantName",
  ADD_MERCHANT_CODE = "MERCHANTCODES/AddMerchantCode",

  GET_COMPANY_MERCHANT_CODES_DATA_ATTEMPT = "MERCHANTCODES/GetCompanyMerchantCodesDataAttempt",
  GET_COMPANY_MERCHANT_CODES_DATA_SUCCESS = "MERCHANTCODES/GetCompanyMerchantCodesDataSuccess",
  GET_COMPANY_MERCHANT_CODES_DATA_FAILURE = "MERCHANTCODES/GetCompanyMerchantCodesDataFailure",

  UPDATE_MERCHANT_CODE_CENTRALIZE_ATTEMPT = "MERCHANTCODES/UpdateMerchantCodeCentralizeAttempt",
  UPDATE_MERCHANT_CODE_CENTRALIZE_SUCCESS = "MERCHANTCODES/UpdateMerchantCodeCentralizeSuccess",
  UPDATE_MERCHANT_CODE_CENTRALIZE_FAILURE = "MERCHANTCODES/UpdateMerchantCodeCentralizeFailure",
  UPDATE_MERCHANT_CODE_CENTRALIZE_RESET = "MERCHANTCODES/UpdateMerchantCodeCentralizeReset",

  RENAME_MERCHANT_CODE_ATTEMPT = "MERCHANTCODES/RenameMerchantCodeAttempt",
  RENAME_MERCHANT_CODE_SUCCESS = "MERCHANTCODES/RenameMerchantCodeSuccess",
  RENAME_MERCHANT_CODE_FAILURE = "MERCHANTCODES/RenameMerchantCodeFailure",
  RENAME_MERCHANT_CODE_RESET = "MERCHANTCODES/RenameMerchantCodeReset",

  DECENTRALIZE_AND_UPDATE_MERCHANT_CODE_ATTEMPT = "MERCHANTCODES/DecentralizeAndUpdateMerchantCodeAttempt",
  DECENTRALIZE_AND_UPDATE_MERCHANT_CODE_SUCCESS = "MERCHANTCODES/DecentralizeAndUpdateMerchantCodeSuccess",
  DECENTRALIZE_AND_UPDATE_MERCHANT_CODE_FAILURE = "MERCHANTCODES/DecentralizeAndUpdateMerchantCodeFailure",

  CREATE_OR_UPDATE_MERCHANT = "MERCHANTCODES/CreateOrUpdateMerchant",
  CREATE_MERCHANT_CODE_FROM_INPUT_MASK = "MERCHANTCODES/CreateOrUpdateMerchantCode",

  GET_ALL_MERCHANTS_OF_COMPANY_SUCCESS = "MERCHANTCODES/GetAllMerchantsOfCompanySuccess",
  GET_ALL_MERCHANTS_OF_COMPANY_FAILURE = "MERCHANTCODES/GetAllMerchantsOfCompanyFailure",

  UPDATE_MANY_MERCHANTS_FOR_CODE_ATTEMPT = "MERCHANTCODES/UpdateManyMerchantsForCodeAttempt",
  UPDATE_MANY_MERCHANTS_FOR_CODE_SUCCESS = "MERCHANTCODES/UpdateManyMerchantsForCodeSuccess",
  UPDATE_MANY_MERCHANTS_FOR_CODE_FAILURE = "MERCHANTCODES/UpdateManyMerchantsForCodeFailure",
  UPDATE_MANY_MERCHANTS_FOR_CODE_RESET = "MERCHANTCODES/UpdateManyMerchantsForCodeReset",

  CREATE_MERCHANT_CODE_ATTEMPT = "MERCHANTCODES/CreateMerchantCodeAttempt",
  CREATE_MERCHANT_CODE_SUCCESS = "MERCHANTCODES/CreateMerchantCodeSuccess",
  CREATE_MERCHANT_CODE_FAILURE = "MERCHANTCODES/CreateMerchantCodeFailure",
  CREATE_MERCHANT_CODE_RESET = "MERCHANTCODES/CreateMerchantCodeReset",

  CREATE_MULTIPLE_MERCHANT_CODES_ATTEMPT = "MERCHANTCODES/CreateMultipleMerchantCodesAttempt",
  CREATE_MULTIPLE_MERCHANT_CODES_SUCCESS = "MERCHANTCODES/CreateMultipleMerchantCodesSuccess",
  CREATE_MULTIPLE_MERCHANT_CODES_FAILURE = "MERCHANTCODES/CreateMultipleMerchantCodesFailure",
  CREATE_MULTIPLE_MERCHANT_CODES_RESET = "MERCHANTCODES/CreateMultipleMerchantCodesReset",

  ADD_MERCHANT_CODES_TOO_MANY_LINES = "ACCOUNTS/addMerchantCodesTooManyLines",

  GET_ALL_MERCHANT_CODES_ATTEMPT = "MERCHANTCODES/GetAllMerchantCodesAttempt",
  GET_ALL_MERCHANT_CODES_SUCCESS = "MERCHANTCODES/GetAllMerchantCodesSuccess",
  GET_ALL_MERCHANT_CODES_FAILURE = "MERCHANTCODES/GetAllMerchantCodesFailure",

  CREATE_RULE_BILL_OF_EXCHANGE_ATTEMPT = "MERCHANTCODES/CreateRuleBillOfExchangeAttempt",
  CREATE_RULE_BILL_OF_EXCHANGE_SUCCESS = "MERCHANTCODES/CreateRuleBillOfExchangeSuccess",
  CREATE_RULE_BILL_OF_EXCHANGE_FAILURE = "MERCHANTCODES/CreateRuleBillOfExchangeFailure",

  DESTROY_RULE_BILL_OF_EXCHANGE_ATTEMPT = "MERCHANTCODES/DestroyRuleBillOfExchangeAttempt",
  DESTROY_RULE_BILL_OF_EXCHANGE_SUCCESS = "MERCHANTCODES/DestroyRuleBillOfExchangeSuccess",
  DESTROY_RULE_BILL_OF_EXCHANGE_FAILURE = "MERCHANTCODES/DestroyRuleBillOfExchangeFailure",

  EDIT_RULE_BILL_OF_EXCHANGE_ATTEMPT = "MERCHANTCODES/EditRuleBillOfExchangeAttempt",
  EDIT_RULE_BILL_OF_EXCHANGE_SUCCESS = "MERCHANTCODES/EditRuleBillOfExchangeSuccess",
  EDIT_RULE_BILL_OF_EXCHANGE_FAILURE = "MERCHANTCODES/EditRuleBillOfExchangeFailure",

  REDUCE_MERCHANTCODES_ATTEMPT = "MERCHANTCODES/ReduceMerchantCodesAttempt",
  REDUCE_MERCHANTCODES_SUCCESS = "MERCHANTCODES/ReduceMerchantCodesSuccess",
  REDUCE_MERCHANTCODES_FAILURE = "MERCHANTCODES/ReduceMerchantCodesFailure",
  REDUCE_MERCHANTCODES_RESET = "MERCHANTCODES/ReduceMerchantCodesReset",

  REMOVE_PREFIX_MERCHANTCODES_ATTEMPT = "MERCHANTCODES/RemovePrefixMerchantCodesAttempt",
  REMOVE_PREFIX_MERCHANTCODES_SUCCESS = "MERCHANTCODES/RemovePrefixMerchantCodesSuccess",
  REMOVE_PREFIX_MERCHANTCODES_FAILURE = "MERCHANTCODES/RemovePrefixMerchantCodesFailure",
  REMOVE_PREFIX_MERCHANTCODES_RESET = "MERCHANTCODES/RemovePrefixMerchantCodesReset",
}

export type ToastErrorProps = {
  titleKey: string
  bodyKey: string
  bodyValues: Record<string, string>
}

export const updateMerchantName = ({
  merchantId,
  newMerchantName,
}: {
  merchantId: number
  newMerchantName: string
}) =>
  ({
    type: MerchantCodesActionsEnum.UPDATE_MERCHANT_NAME,
    payload: {
      merchantId,
      newMerchantName,
    },
  } as const)
export const addMerchantCode = (payload: MerchantCode) =>
  ({
    type: MerchantCodesActionsEnum.ADD_MERCHANT_CODE,
    payload,
  } as const)

export const GetCompanyMerchantCodesDataAttempt = () =>
  ({
    type: MerchantCodesActionsEnum.GET_COMPANY_MERCHANT_CODES_DATA_ATTEMPT,
  } as const)
export const GetCompanyMerchantCodesDataSuccess = (
  merchantCodes: MerchantCode[]
) =>
  ({
    type: MerchantCodesActionsEnum.GET_COMPANY_MERCHANT_CODES_DATA_SUCCESS,
    merchantCodes,
  } as const)
export const GetCompanyMerchantCodesDataFailure = () =>
  ({
    type: MerchantCodesActionsEnum.GET_COMPANY_MERCHANT_CODES_DATA_FAILURE,
  } as const)

export const UpdateMerchantCodeCentralizeAttempt = () =>
  ({
    type: MerchantCodesActionsEnum.UPDATE_MERCHANT_CODE_CENTRALIZE_ATTEMPT,
  } as const)
export const UpdateMerchantCodeCentralizeSuccess = (
  merchantCodeId: number,
  centralize: boolean
) =>
  ({
    type: MerchantCodesActionsEnum.UPDATE_MERCHANT_CODE_CENTRALIZE_SUCCESS,
    payload: { merchantCodeId, centralize },
  } as const)
export const UpdateMerchantCodeCentralizeFailure = (error: ToastErrorProps) =>
  ({
    type: MerchantCodesActionsEnum.UPDATE_MERCHANT_CODE_CENTRALIZE_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: error.titleKey,
      bodyKey: error.bodyKey,
      bodyValues: error.bodyValues,
    },
  } as const)
export const UpdateMerchantCodeCentralizeReset = () =>
  ({
    type: MerchantCodesActionsEnum.UPDATE_MERCHANT_CODE_CENTRALIZE_RESET,
  } as const)
export const RenameMerchantCodeAttempt = () =>
  ({
    type: MerchantCodesActionsEnum.RENAME_MERCHANT_CODE_ATTEMPT,
  } as const)
export const RenameMerchantCodeSuccess = ({
  merchantCodeId,
  code,
  default_buy_code,
  default_sell_code,
}: {
  merchantCodeId: number
  code: string
  default_buy_code: boolean
  default_sell_code: boolean
}) =>
  ({
    type: MerchantCodesActionsEnum.RENAME_MERCHANT_CODE_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: "accounting-plan.rename-code.toast.success.title",
      bodyKey: "accounting-plan.rename-code.toast.success.body",
    },
    payload: {
      merchantCodeId,
      code,
      default_buy_code,
      default_sell_code,
    },
  } as const)
export const RenameMerchantCodeFailure = (error: ToastErrorProps) =>
  ({
    type: MerchantCodesActionsEnum.RENAME_MERCHANT_CODE_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: error.titleKey,
      bodyKey: error.bodyKey,
      bodyValues: error.bodyValues,
    },
  } as const)
export const RenameMerchantCodeReset = () =>
  ({
    type: MerchantCodesActionsEnum.RENAME_MERCHANT_CODE_RESET,
  } as const)

export const DecentralizeAndUpdateMerchantCodeAttempt = () =>
  ({
    type: MerchantCodesActionsEnum.DECENTRALIZE_AND_UPDATE_MERCHANT_CODE_ATTEMPT,
  } as const)
export const DecentralizeAndUpdateMerchantCodeSuccess = () =>
  ({
    type: MerchantCodesActionsEnum.DECENTRALIZE_AND_UPDATE_MERCHANT_CODE_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `accounting-plan.decantralize-code.success.title`,
      bodyKey: `accounting-plan.decantralize-code.success.body`,
    },
  } as const)
export const DecentralizeAndUpdateMerchantCodeFailure = () =>
  ({
    type: MerchantCodesActionsEnum.DECENTRALIZE_AND_UPDATE_MERCHANT_CODE_FAILURE,
  } as const)

export const GetAllMerchantsOfCompanySuccess = (
  merchants: MerchantsOfCompany[]
) =>
  ({
    type: MerchantCodesActionsEnum.GET_ALL_MERCHANTS_OF_COMPANY_SUCCESS,
    merchants,
  } as const)

export const CreateOrUpdateMerchant = (merchant: MerchantsOfCompany) =>
  ({
    type: MerchantCodesActionsEnum.CREATE_OR_UPDATE_MERCHANT,
    merchant,
  } as const)

export const CreateMerchantCodeFromInputMask = (merchantCode: MerchantCode) =>
  ({
    type: MerchantCodesActionsEnum.CREATE_MERCHANT_CODE_FROM_INPUT_MASK,
    merchantCode,
  } as const)

export const GetAllMerchantsOfCompanyFailure = () =>
  ({
    type: MerchantCodesActionsEnum.GET_ALL_MERCHANTS_OF_COMPANY_FAILURE,
  } as const)

export const UpdateManyMerchantsForCodeAttempt = () =>
  ({
    type: MerchantCodesActionsEnum.UPDATE_MANY_MERCHANTS_FOR_CODE_ATTEMPT,
  } as const)
export const UpdateManyMerchantsForCodeSuccess = (
  code: string,
  merchantCodeId: number,
  merchantIds: number[]
) =>
  ({
    type: MerchantCodesActionsEnum.UPDATE_MANY_MERCHANTS_FOR_CODE_SUCCESS,
    payload: {
      merchantCodeId,
      merchantIds,
    },
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `accounting-plan.add-merchant.success.title`,
      bodyKey: `accounting-plan.add-merchant.success.body`,
      bodyValues: { code: code },
    },
  } as const)
export const UpdateManyMerchantsForCodeFailure = () =>
  ({
    type: MerchantCodesActionsEnum.UPDATE_MANY_MERCHANTS_FOR_CODE_FAILURE,
  } as const)
export const UpdateManyMerchantsForCodeReset = () =>
  ({
    type: MerchantCodesActionsEnum.UPDATE_MANY_MERCHANTS_FOR_CODE_RESET,
  } as const)

export const CreateMerchantCodeAttempt = () =>
  ({
    type: MerchantCodesActionsEnum.CREATE_MERCHANT_CODE_ATTEMPT,
  } as const)
export const CreateMerchantCodeSuccess = (code: string) =>
  ({
    type: MerchantCodesActionsEnum.CREATE_MERCHANT_CODE_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `accounting-plan.create-code.success.title`,
      titleValues: { code: code },
      bodyKey: `accounting-plan.create-code.success.message`,
      bodyValues: { code: code },
    },
  } as const)
export const CreateMerchantCodeReset = () =>
  ({
    type: MerchantCodesActionsEnum.CREATE_MERCHANT_CODE_RESET,
  } as const)
export const CreateMerchantCodeFailure = (error: ToastErrorProps) =>
  ({
    type: MerchantCodesActionsEnum.CREATE_MERCHANT_CODE_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: error.titleKey,
      bodyKey: error.bodyKey,
      bodyValues: error.bodyValues,
    },
  } as const)

export const CreateMultipleMerchantCodesAttempt = () =>
  ({
    type: MerchantCodesActionsEnum.CREATE_MULTIPLE_MERCHANT_CODES_ATTEMPT,
  } as const)
export const CreateMultipleMerchantCodesReset = () =>
  ({
    type: MerchantCodesActionsEnum.CREATE_MULTIPLE_MERCHANT_CODES_RESET,
  } as const)
export const CreateMultipleMerchantCodesSuccess = () =>
  ({
    type: MerchantCodesActionsEnum.CREATE_MULTIPLE_MERCHANT_CODES_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `accounting-plan.create-codes.success.title`,
      bodyKey: `accounting-plan.create-codes.success.body`,
    },
  } as const)

export const CreateMultipleMerchantCodesFailure = (error: ToastErrorProps) =>
  ({
    type: MerchantCodesActionsEnum.CREATE_MULTIPLE_MERCHANT_CODES_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: error.titleKey,
      bodyKey: error.bodyKey,
      bodyValues: error.bodyValues,
    },
  } as const)

export const addMerchantCodesTooManyLines = () =>
  ({
    type: MerchantCodesActionsEnum.ADD_MERCHANT_CODES_TOO_MANY_LINES,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey:
        "accounting-plan.create-codes.modal.add-mc.too-many-lines.title",
      bodyKey: "accounting-plan.create-codes.modal.add-mc.too-many-lines.body",
    },
  } as const)

export const GetAllMerchantCodesAttempt = () =>
  ({
    type: MerchantCodesActionsEnum.GET_ALL_MERCHANT_CODES_ATTEMPT,
  } as const)
export const GetAllMerchantCodesSuccess = (codes: MerchantCode[]) =>
  ({
    type: MerchantCodesActionsEnum.GET_ALL_MERCHANT_CODES_SUCCESS,
    codes,
  } as const)
export const GetAllMerchantCodesFailure = () =>
  ({
    type: MerchantCodesActionsEnum.GET_ALL_MERCHANT_CODES_FAILURE,
  } as const)

export const CreateRuleBillOfExchangeAttempt = () =>
  ({
    type: MerchantCodesActionsEnum.CREATE_RULE_BILL_OF_EXCHANGE_ATTEMPT,
  } as const)
export const CreateRuleBillOfExchangeSuccess = () =>
  ({
    type: MerchantCodesActionsEnum.CREATE_RULE_BILL_OF_EXCHANGE_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `accounting-plan.lcr.create-or-edit-modal.tooltip.success-title`,
      bodyKey: `accounting-plan.lcr.create-or-edit-modal.tooltip.success-message`,
    },
  } as const)
export const CreateRuleBillOfExchangeFailure = () =>
  ({
    type: MerchantCodesActionsEnum.CREATE_RULE_BILL_OF_EXCHANGE_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `accounting-plan.lcr.create-or-edit-modal.tooltip.error-title`,
      bodyKey: `accounting-plan.lcr.create-or-edit-modal.tooltip.error-message`,
    },
  } as const)

export const DestroyRuleBillOfExchangeAttempt = () =>
  ({
    type: MerchantCodesActionsEnum.DESTROY_RULE_BILL_OF_EXCHANGE_ATTEMPT,
  } as const)
export const DestroyRuleBillOfExchangeSuccess = () =>
  ({
    type: MerchantCodesActionsEnum.DESTROY_RULE_BILL_OF_EXCHANGE_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `accounting-plan.lcr.delete.tooltip.success-title`,
      bodyKey: `accounting-plan.lcr.delete.tooltip.success-message`,
    },
  } as const)
export const DestroyRuleBillOfExchangeFailure = () =>
  ({
    type: MerchantCodesActionsEnum.DESTROY_RULE_BILL_OF_EXCHANGE_FAILURE,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `accounting-plan.lcr.delete.tooltip.error-title`,
      bodyKey: `accounting-plan.lcr.delete.tooltip.error-message`,
    },
  } as const)

export const EditRuleBillOfExchangeAttempt = () =>
  ({
    type: MerchantCodesActionsEnum.EDIT_RULE_BILL_OF_EXCHANGE_ATTEMPT,
  } as const)
export const EditRuleBillOfExchangeSuccess = () =>
  ({
    type: MerchantCodesActionsEnum.EDIT_RULE_BILL_OF_EXCHANGE_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `accounting-plan.lcr.edit.tooltip.success-title`,
      bodyKey: `accounting-plan.lcr.edit.tooltip.success-message`,
    },
  } as const)
export const EditRuleBillOfExchangeFailure = () =>
  ({
    type: MerchantCodesActionsEnum.EDIT_RULE_BILL_OF_EXCHANGE_FAILURE,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `accounting-plan.lcr.edit.tooltip.error-title`,
      bodyKey: `accounting-plan.lcr.edit.tooltip.error-message`,
    },
  } as const)

export const displayErrorMerchantCodes = (
  error: AxiosError | Error,
  defaultKey = "error"
) => {
  let key = defaultKey
  let bodyValues = {}
  if (
    isAxiosError(error) &&
    error.response !== undefined &&
    error.response.data
  ) {
    const errorMsg = JSON.stringify(error.response.data)
    if (errorMsg.includes("too long")) {
      const regex = /Code (\w+) is too long.*limit is (\d+)/
      const match = errorMsg.match(regex)
      key = "accounting-plan.create-code.error.too-long"
      if (match) bodyValues = { code: match[1], limit: match[2] }
    } else if (errorMsg.includes("forbidden characters")) {
      key = "accounting-plan.create-code.error.forbidden-characters"
      const regex = /Code (.+) contain forbidden/
      const match = errorMsg.match(regex)
      if (match) bodyValues = { code: match[1] }
    } else if (errorMsg.includes("est déjà utilisé")) {
      key = "accounting-plan.create-code.error.already-taken"
      const regex = /code (.+) est déjà utilisé/
      const match = errorMsg.match(regex)
      if (match) bodyValues = { code: match[1] }
    } else if (
      errorMsg.includes("cannot decentralize code because is default code")
    ) {
      key = "accounting-plan.create-code.uniq-code-decentralize.error"
    } else if (
      errorMsg.includes(
        "Un compte non centralisateur ne peut être mis en code par défaut en achats/ventes"
      )
    ) {
      key = "accounting-plan.create-code.uniq-code-not-centralize.error"
      const regex = /code (.+), Un compte non centralisateur/
      const match = errorMsg.match(regex)
      if (match) bodyValues = { code: match[1] }
    } else if (
      errorMsg.includes(
        "cannot centralize because company has disabled centralized codes"
      ) ||
      errorMsg.includes("Votre dossier est paramétré sans code de regroupement")
    ) {
      key =
        "accounting-plan.create-code.centralize.disabled-centralized-codes.error"
      const regex = /vous ne pouvez donc pas regrouper le code (\w+)./
      const match = errorMsg.match(regex)
      if (match) bodyValues = { code: match[1] }
    } else if (errorMsg.includes("centralize")) {
      key = "accounting-plan.merchant-code.error.decentralize-account"
    } else if (
      errorMsg.includes("another code has already been selected for default")
    ) {
      key = "accounting-plan.create-code.uniq-code.error"
    } else if (errorMsg.includes("Onboarding not finished for this company")) {
      key = "accounting-plan.create-code.onboarding-unifinished.error"
    }
  }

  return { titleKey: `${key}.title`, bodyKey: `${key}.body`, bodyValues }
}

export const ReduceMerchantCodesAttempt = () =>
  ({
    type: MerchantCodesActionsEnum.REDUCE_MERCHANTCODES_ATTEMPT,
  } as const)
export const ReduceMerchantCodesSuccess = () =>
  ({
    type: MerchantCodesActionsEnum.REDUCE_MERCHANTCODES_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `accounting-plan.merchant-codes.reduce.modal.success.title`,
      bodyKey: `accounting-plan.merchant-codes.reduce.modal.success.body`,
    },
  } as const)
export const ReduceMerchantCodesFailure = (
  bodyKey: string,
  bodyValues: Record<string, string>
) =>
  ({
    type: MerchantCodesActionsEnum.REDUCE_MERCHANTCODES_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `accounting-plan.merchant-codes.reduce.modal.error.title`,
      bodyKey: bodyKey,
      bodyValues: bodyValues,
    },
  } as const)
export const ReduceMerchantCodesReset = () =>
  ({
    type: MerchantCodesActionsEnum.REDUCE_MERCHANTCODES_RESET,
  } as const)

export const RemovePrefixMerchantCodesAttempt = () =>
  ({
    type: MerchantCodesActionsEnum.REMOVE_PREFIX_MERCHANTCODES_ATTEMPT,
  } as const)
export const RemovePrefixMerchantCodesSuccess = () =>
  ({
    type: MerchantCodesActionsEnum.REMOVE_PREFIX_MERCHANTCODES_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `accounting-plan.merchant-codes.remove-prefix.modal.success.title`,
      bodyKey: `accounting-plan.merchant-codes.remove-prefix.modal.success.body`,
    },
  } as const)
export const RemovePrefixMerchantCodesFailure = (
  bodyKey: string,
  bodyValues: Record<string, string>
) =>
  ({
    type: MerchantCodesActionsEnum.REMOVE_PREFIX_MERCHANTCODES_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `accounting-plan.merchant-codes.remove-prefix.modal.error.title`,
      bodyKey: bodyKey,
      bodyValues: bodyValues,
    },
  } as const)
export const RemovePrefixMerchantCodesReset = () =>
  ({
    type: MerchantCodesActionsEnum.REMOVE_PREFIX_MERCHANTCODES_RESET,
  } as const)

export type MerchantCodesActionsType = ReturnType<
  | typeof updateMerchantName
  | typeof addMerchantCode
  | typeof GetCompanyMerchantCodesDataAttempt
  | typeof GetCompanyMerchantCodesDataSuccess
  | typeof GetCompanyMerchantCodesDataFailure
  | typeof UpdateMerchantCodeCentralizeAttempt
  | typeof UpdateMerchantCodeCentralizeSuccess
  | typeof UpdateMerchantCodeCentralizeFailure
  | typeof UpdateMerchantCodeCentralizeReset
  | typeof RenameMerchantCodeAttempt
  | typeof RenameMerchantCodeSuccess
  | typeof RenameMerchantCodeFailure
  | typeof RenameMerchantCodeReset
  | typeof DecentralizeAndUpdateMerchantCodeAttempt
  | typeof DecentralizeAndUpdateMerchantCodeSuccess
  | typeof DecentralizeAndUpdateMerchantCodeFailure
  | typeof CreateOrUpdateMerchant
  | typeof CreateMerchantCodeFromInputMask
  | typeof GetAllMerchantsOfCompanySuccess
  | typeof GetAllMerchantsOfCompanyFailure
  | typeof UpdateManyMerchantsForCodeAttempt
  | typeof UpdateManyMerchantsForCodeSuccess
  | typeof UpdateManyMerchantsForCodeFailure
  | typeof UpdateManyMerchantsForCodeReset
  | typeof CreateMerchantCodeAttempt
  | typeof CreateMerchantCodeSuccess
  | typeof CreateMerchantCodeFailure
  | typeof CreateMerchantCodeReset
  | typeof CreateMultipleMerchantCodesAttempt
  | typeof CreateMultipleMerchantCodesReset
  | typeof CreateMultipleMerchantCodesSuccess
  | typeof CreateMultipleMerchantCodesFailure
  | typeof GetAllMerchantCodesAttempt
  | typeof GetAllMerchantCodesSuccess
  | typeof GetAllMerchantCodesFailure
  | typeof CreateRuleBillOfExchangeAttempt
  | typeof CreateRuleBillOfExchangeSuccess
  | typeof CreateRuleBillOfExchangeFailure
  | typeof DestroyRuleBillOfExchangeAttempt
  | typeof DestroyRuleBillOfExchangeSuccess
  | typeof DestroyRuleBillOfExchangeFailure
  | typeof EditRuleBillOfExchangeAttempt
  | typeof EditRuleBillOfExchangeSuccess
  | typeof EditRuleBillOfExchangeFailure
  | typeof ReduceMerchantCodesAttempt
  | typeof ReduceMerchantCodesSuccess
  | typeof ReduceMerchantCodesFailure
  | typeof ReduceMerchantCodesReset
  | typeof RemovePrefixMerchantCodesAttempt
  | typeof RemovePrefixMerchantCodesSuccess
  | typeof RemovePrefixMerchantCodesFailure
  | typeof RemovePrefixMerchantCodesReset
>

export const merchantCodesInitialState: MerchantCodesState = {
  merchantCodes: [],
  merchantsOfCompany: [],
  updateOneMerchantStatus: "idle",
  updateMerchantsForCodeStatus: "idle",
  updateMerchantCodeCentralize: "idle",
  createMerchantCodeStatus: "idle",
  createMultipleMerchantCodesStatus: "idle",
  reduceMerchantCodesStatus: "idle",
  removePrefixMerchantCodesStatus: "idle",
}

export interface MerchantCodesState {
  merchantCodes: MerchantCode[]
  merchantsOfCompany: MerchantsOfCompany[]
  updateOneMerchantStatus: "idle" | "loading" | "success" | "error"
  updateMerchantsForCodeStatus: "idle" | "loading" | "success" | "error"
  updateMerchantCodeCentralize: "idle" | "loading" | "success" | "error"
  createMerchantCodeStatus: "idle" | "loading" | "success" | "error"
  createMultipleMerchantCodesStatus: "idle" | "loading" | "success" | "error"
  reduceMerchantCodesStatus: "idle" | "loading" | "success" | "error"
  removePrefixMerchantCodesStatus: "idle" | "loading" | "success" | "error"
}

export interface MerchantCode {
  id: number
  code: string
  centralize: boolean
  auxiliary: boolean
  merchants: MerchantsOfCode[]
  code_instructions: Instructions[]
  default_buy_code: boolean
  default_sell_code: boolean
}

export type optionType = { value: string; label: string }

export interface MerchantCodeToCreate {
  code: string
  centralize: boolean
  auxiliary: boolean
  merchants: ReadonlyArray<optionType>
  merchant_ids: Number[]
  default_buy_code: boolean
  default_sell_code: boolean
}

export interface MerchantsOfCompany {
  id: number
  name: string
  code_id: number | null
  code: string | null
  is_new_merchant: boolean
}

export interface MerchantsWithCode extends MerchantsOfCompany {
  code_id: number
  code: string
}

export interface MerchantsOfCode {
  merchant_id: number
  merchant_name: string
  code_id: number
}

export interface Instructions {
  created_at: string
  instruction_type: InstructionType
  user: string
  metadata: {
    [index: string]: string
  }
}

export interface NewMerchantCode {
  code: string
  centralize: boolean
}

export const isMerchantCode = (
  merchantCode: NewMerchantCode | MerchantCode
): merchantCode is MerchantCode => {
  return (merchantCode as MerchantCode).id !== undefined
}

export function merchantCodesReducer(
  state = merchantCodesInitialState,
  action: MerchantCodesActionsType
): MerchantCodesState {
  switch (action.type) {
    case MerchantCodesActionsEnum.GET_COMPANY_MERCHANT_CODES_DATA_ATTEMPT:
      return { ...state, merchantCodes: [] }
    case MerchantCodesActionsEnum.GET_COMPANY_MERCHANT_CODES_DATA_SUCCESS:
      return { ...state, merchantCodes: action.merchantCodes }

    case MerchantCodesActionsEnum.ADD_MERCHANT_CODE:
      return {
        ...state,
        merchantCodes: [...state.merchantCodes, action.payload],
      }

    case MerchantCodesActionsEnum.UPDATE_MERCHANT_CODE_CENTRALIZE_SUCCESS:
      return {
        ...state,
        merchantCodes: [
          ...state.merchantCodes.map((mc) => {
            if (mc.id === action.payload.merchantCodeId) {
              return {
                ...mc,
                centralize: action.payload.centralize,
              }
            }
            return mc
          }),
        ],
        updateMerchantCodeCentralize: "success",
      }
    case MerchantCodesActionsEnum.UPDATE_MERCHANT_CODE_CENTRALIZE_ATTEMPT:
      return { ...state, updateMerchantCodeCentralize: "loading" }
    case MerchantCodesActionsEnum.UPDATE_MERCHANT_CODE_CENTRALIZE_FAILURE:
      return { ...state, updateMerchantCodeCentralize: "error" }
    case MerchantCodesActionsEnum.UPDATE_MERCHANT_CODE_CENTRALIZE_RESET:
      return { ...state, updateMerchantCodeCentralize: "idle" }
    case MerchantCodesActionsEnum.RENAME_MERCHANT_CODE_ATTEMPT:
      return { ...state, updateOneMerchantStatus: "loading" }
    case MerchantCodesActionsEnum.RENAME_MERCHANT_CODE_SUCCESS:
      const { code, default_buy_code, default_sell_code, merchantCodeId } =
        action.payload

      const updatedMerchantCodes = state.merchantCodes.map((merchantCode) =>
        merchantCode.id === merchantCodeId
          ? {
              ...merchantCode,
              code,
              default_buy_code,
              default_sell_code,
            }
          : merchantCode
      )

      return {
        ...state,
        merchantCodes: updatedMerchantCodes,
        updateOneMerchantStatus: "success",
      }
    case MerchantCodesActionsEnum.RENAME_MERCHANT_CODE_FAILURE:
      return { ...state, updateOneMerchantStatus: "error" }
    case MerchantCodesActionsEnum.RENAME_MERCHANT_CODE_RESET:
      return { ...state, updateOneMerchantStatus: "idle" }

    case MerchantCodesActionsEnum.GET_ALL_MERCHANTS_OF_COMPANY_SUCCESS:
      return { ...state, merchantsOfCompany: action.merchants }
    case MerchantCodesActionsEnum.CREATE_OR_UPDATE_MERCHANT: {
      return {
        ...state,
        merchantsOfCompany: addOrReplaceBy({
          array: state.merchantsOfCompany,
          addIfItemNotFound: (e) => e.id === action.merchant.id,
          item: action.merchant,
        }),
      }
    }
    case MerchantCodesActionsEnum.CREATE_MERCHANT_CODE_FROM_INPUT_MASK: {
      return {
        ...state,
        merchantCodes: [...state.merchantCodes, action.merchantCode],
      }
    }

    case MerchantCodesActionsEnum.CREATE_MERCHANT_CODE_ATTEMPT:
      return { ...state, createMerchantCodeStatus: "loading" }
    case MerchantCodesActionsEnum.CREATE_MERCHANT_CODE_SUCCESS:
      return { ...state, createMerchantCodeStatus: "success" }
    case MerchantCodesActionsEnum.CREATE_MERCHANT_CODE_FAILURE:
      return { ...state, createMerchantCodeStatus: "error" }
    case MerchantCodesActionsEnum.CREATE_MERCHANT_CODE_RESET:
      return { ...state, createMerchantCodeStatus: "idle" }

    case MerchantCodesActionsEnum.CREATE_MULTIPLE_MERCHANT_CODES_ATTEMPT:
      return { ...state, createMultipleMerchantCodesStatus: "loading" }
    case MerchantCodesActionsEnum.CREATE_MULTIPLE_MERCHANT_CODES_SUCCESS:
      return {
        ...state,
        createMultipleMerchantCodesStatus: "success",
      }
    case MerchantCodesActionsEnum.CREATE_MULTIPLE_MERCHANT_CODES_FAILURE:
      return { ...state, createMultipleMerchantCodesStatus: "error" }
    case MerchantCodesActionsEnum.CREATE_MULTIPLE_MERCHANT_CODES_RESET:
      return {
        ...state,
        createMultipleMerchantCodesStatus: "idle",
      }

    case MerchantCodesActionsEnum.UPDATE_MERCHANT_NAME: {
      const updatedMerchants = state.merchantsOfCompany.map((m) => {
        if (m.id !== action.payload.merchantId) {
          return m
        }
        return { ...m, name: action.payload.newMerchantName }
      })

      return { ...state, merchantsOfCompany: updatedMerchants }
    }
    case MerchantCodesActionsEnum.GET_ALL_MERCHANT_CODES_ATTEMPT: {
      return { ...state, merchantCodes: [] }
    }
    case MerchantCodesActionsEnum.GET_ALL_MERCHANT_CODES_SUCCESS: {
      return { ...state, merchantCodes: action.codes }
    }

    case MerchantCodesActionsEnum.UPDATE_MANY_MERCHANTS_FOR_CODE_ATTEMPT: {
      return { ...state, updateMerchantsForCodeStatus: "loading" }
    }
    case MerchantCodesActionsEnum.UPDATE_MANY_MERCHANTS_FOR_CODE_SUCCESS: {
      const { merchantCodeId, merchantIds } = action.payload
      const merchants = state.merchantsOfCompany
        .filter((moc) => merchantIds.includes(moc.id))
        .map((moc) => {
          return {
            merchant_id: moc.id,
            merchant_name: moc.name,
            code_id: merchantCodeId,
          }
        })
      const index = state.merchantCodes.findIndex(
        (e) => e.id === merchantCodeId
      )
      if (index >= 0) {
        state.merchantCodes = state.merchantCodes.map((mc) => {
          mc.merchants = mc.merchants.filter(
            (m) => !merchantIds.includes(m.merchant_id)
          )
          return mc
        })
        state.merchantCodes[index] = {
          ...state.merchantCodes[index],
          merchants: [...state.merchantCodes[index].merchants, ...merchants],
        }
      }
      return { ...state, updateMerchantsForCodeStatus: "success" }
    }
    case MerchantCodesActionsEnum.UPDATE_MANY_MERCHANTS_FOR_CODE_FAILURE: {
      return { ...state, updateMerchantsForCodeStatus: "error" }
    }
    case MerchantCodesActionsEnum.UPDATE_MANY_MERCHANTS_FOR_CODE_RESET: {
      return { ...state, updateMerchantsForCodeStatus: "idle" }
    }

    case MerchantCodesActionsEnum.REDUCE_MERCHANTCODES_ATTEMPT:
      return { ...state, reduceMerchantCodesStatus: "loading" }
    case MerchantCodesActionsEnum.REDUCE_MERCHANTCODES_SUCCESS:
      return { ...state, reduceMerchantCodesStatus: "success" }
    case MerchantCodesActionsEnum.REDUCE_MERCHANTCODES_FAILURE:
      return { ...state, reduceMerchantCodesStatus: "error" }
    case MerchantCodesActionsEnum.REDUCE_MERCHANTCODES_RESET:
      return { ...state, reduceMerchantCodesStatus: "idle" }

    case MerchantCodesActionsEnum.REMOVE_PREFIX_MERCHANTCODES_ATTEMPT:
      return { ...state, removePrefixMerchantCodesStatus: "loading" }
    case MerchantCodesActionsEnum.REMOVE_PREFIX_MERCHANTCODES_SUCCESS:
      return { ...state, removePrefixMerchantCodesStatus: "success" }
    case MerchantCodesActionsEnum.REMOVE_PREFIX_MERCHANTCODES_FAILURE:
      return { ...state, removePrefixMerchantCodesStatus: "error" }
    case MerchantCodesActionsEnum.REMOVE_PREFIX_MERCHANTCODES_RESET:
      return { ...state, removePrefixMerchantCodesStatus: "idle" }

    default:
      return { ...state }
  }
}

export const updateCentralizeStatus =
  (merchantCodeId: number, centralize: boolean, companyId: number) =>
  (dispatch: Dispatch<AnyAction | RNBThunkAction>) => {
    dispatch(UpdateMerchantCodeCentralizeAttempt())
    return axios
      .put("/merchant_codes/update_centralize_status", {
        id: merchantCodeId,
        centralize: centralize,
      })
      .then(() => {
        dispatch(
          UpdateMerchantCodeCentralizeSuccess(merchantCodeId, centralize)
        )
      })
      .catch((error: AxiosError) => {
        if (
          JSON.stringify(error.response?.data).includes(
            "cannot centralize because company has disabled centralized codes"
          )
        ) {
          dispatch(GetCompanyThunk(companyId))
        }
        dispatch(
          UpdateMerchantCodeCentralizeFailure(displayErrorMerchantCodes(error))
        )
      })
  }

export const createMerchantCode =
  (companyId: number) =>
  (merchantId: number) =>
  (payload: { centralize: boolean; code: string }) =>
    axios.post("/merchant_codes", {
      ...payload,
      company_id: companyId,
      merchant_id: merchantId,
    })

interface ResponseMerchantCodesData {
  merchant_codes: MerchantCode[]
}

export const renameMerchantCodeThunk =
  (
    merchantCodeId: number,
    newCode: string,
    companyId: number,
    default_buy_code: boolean,
    default_sell_code: boolean
  ) =>
  (dispatch: Dispatch<AnyAction | RNBThunkAction>) => {
    dispatch(RenameMerchantCodeAttempt())
    return axios
      .put("/merchant_codes/update_merchant_code", {
        id: merchantCodeId,
        new_code: newCode,
        default_buy_code: default_buy_code,
        default_sell_code: default_sell_code,
      })
      .then(() => {
        dispatch(
          RenameMerchantCodeSuccess({
            merchantCodeId,
            code: newCode,
            default_buy_code,
            default_sell_code,
          })
        )
      })
      .catch((e) => {
        if (
          e.response.data.error.includes(
            "another code has already been selected for default"
          )
        ) {
          dispatch(GetCompanyMerchantCodesDataThunk(companyId))
        }
        dispatch(RenameMerchantCodeFailure(displayErrorMerchantCodes(e)))
      })
  }

export const GetCompanyMerchantCodesDataThunk =
  (companyId: number) => (dispatch: Dispatch<AnyAction>) => {
    dispatch(GetCompanyMerchantCodesDataAttempt())

    return axios
      .get<ResponseMerchantCodesData>(
        "/companies/get_merchants_codes_data_for_company",
        { params: { company_id: companyId } }
      )
      .then(({ data }) => {
        dispatch(GetCompanyMerchantCodesDataSuccess(data.merchant_codes))
      })
      .catch(GetCompanyMerchantCodesDataFailure)
  }

export const DecentralizeAndUpdateMerchantCodeThunk =
  (
    id: number,
    codesAndMerchants: {
      merchant_id: number
      code_id: number | null
      code: string
    }[],
    companyId: number
  ) =>
  (dispatch: Dispatch<AnyAction | RNBThunkAction>) => {
    dispatch(DecentralizeAndUpdateMerchantCodeAttempt())
    return axios
      .put("/merchant_codes/update_many_merchant_codes_decentralize", {
        id: id,
        codes_and_merchants: codesAndMerchants,
      })
      .then(() => {
        dispatch(DecentralizeAndUpdateMerchantCodeSuccess())
        dispatch(GetCompanyMerchantCodesDataThunk(companyId))
      })
  }

export const GetAllMerchantsOfCompanyForCodes =
  (companyId: number) => (dispatch: Dispatch<AnyAction | RNBThunkAction>) => {
    return axios
      .get<MerchantsOfCompany[]>("merchants/get_all_merchants_for_company", {
        params: { company_id: companyId },
      })
      .then(({ data }) => {
        dispatch(GetAllMerchantsOfCompanySuccess(data))
      })
      .catch(() => {
        dispatch(GetAllMerchantsOfCompanyFailure())
      })
  }

export const UpdateManyMerchantsForCode =
  (code: string, merchantCodeId: number, merchants: number[]) =>
  (dispatch: Dispatch<AnyAction | RNBThunkAction>) => {
    dispatch(UpdateManyMerchantsForCodeAttempt())
    return axios
      .put("/merchant_codes/update_many_merchants_for_code", {
        id: merchantCodeId,
        merchants: merchants,
      })
      .then(() => {
        dispatch(
          UpdateManyMerchantsForCodeSuccess(code, merchantCodeId, merchants)
        )
      })
      .catch(() => {
        dispatch(UpdateManyMerchantsForCodeAttempt())
      })
  }

export const GetAllMerchantCodesThunk =
  (companyId: number) => (dispatch: Dispatch<AnyAction>) => {
    dispatch(GetAllMerchantCodesAttempt())
    return axios
      .get<MerchantCode[]>("/merchant_codes/get_all_merchant_codes", {
        params: { id: companyId },
      })
      .then((response) => {
        dispatch(GetAllMerchantCodesSuccess(response.data))
      })
      .catch(() => {
        dispatch(GetAllMerchantCodesFailure())
      })
  }

export const CreateRuleBillOfExchangeThunk =
  (
    companyId: number,
    rule: {
      priority_number: number
      text_in_description: string
      merchant_code_id: number
    }
  ) =>
  (dispatch: Dispatch<AnyAction | RNBThunkAction>) => {
    dispatch(CreateRuleBillOfExchangeAttempt())
    return axios
      .post(`/merchant_codes/create_rule_bill_of_exchange_merchant_code`, {
        company_id: companyId,
        new_rule: rule,
      })
      .then(() => {
        dispatch(CreateRuleBillOfExchangeSuccess())
        dispatch(GetBillOfExchangeAccountsThunk(companyId))
      })
      .catch(() => dispatch(CreateRuleBillOfExchangeFailure()))
  }

export const DestroyRuleBillOfExchangeThunk =
  (companyId: number, ruleId: number) =>
  (dispatch: Dispatch<AnyAction | RNBThunkAction>) => {
    dispatch(DestroyRuleBillOfExchangeAttempt())
    return axios
      .post(`/merchant_codes/destroy_rule_bill_of_exchange_merchant_code`, {
        company_id: companyId,
        rule_id: ruleId,
      })
      .then(() => {
        dispatch(DestroyRuleBillOfExchangeSuccess())
        dispatch(GetBillOfExchangeAccountsThunk(companyId))
      })
      .catch(() => dispatch(DestroyRuleBillOfExchangeFailure()))
  }

export const EditRuleBillOfExchangeThunk =
  (
    companyId: number,
    ruleId: number,
    editedRule: {
      priority_number: number
      merchant_code_id: number
      text_in_description: string
    }
  ) =>
  (dispatch: Dispatch<AnyAction | RNBThunkAction>) => {
    dispatch(EditRuleBillOfExchangeAttempt())
    return axios
      .post(`/merchant_codes/edit_rule_bill_of_exchange_merchant_code`, {
        company_id: companyId,
        rule_id: ruleId,
        edited_rule: editedRule,
      })
      .then(() => {
        dispatch(EditRuleBillOfExchangeSuccess())
        dispatch(GetBillOfExchangeAccountsThunk(companyId))
      })
      .catch(() => dispatch(EditRuleBillOfExchangeFailure()))
  }

export const createMultipleMerchantCodesThunk =
  (companyId: number, merchantCodesToCreate: MerchantCodeToCreate[]) =>
  (dispatch: Dispatch<AnyAction | RNBThunkAction>) => {
    dispatch(CreateMultipleMerchantCodesAttempt())
    return axios
      .put(`/merchant_codes/create_all_merchant_codes`, {
        company_id: companyId,
        merchant_codes_to_create: merchantCodesToCreate,
      })
      .then(() => {
        dispatch(CreateMultipleMerchantCodesSuccess())
        dispatch(GetCompanyMerchantCodesDataThunk(companyId))
      })
      .catch((error) => {
        dispatch(
          CreateMultipleMerchantCodesFailure(
            displayErrorMerchantCodes(
              error,
              "accounting-plan.accounts.modal.multiple.create.error"
            )
          )
        )
      })
  }

interface ReduceMerchantCodeResponse {
  updated: string[]
  cannot_update: string[]
}
export const reduceMerchantCodesThunk =
  (companyId: number, newLength: number) =>
  (dispatch: Dispatch<AnyAction | RNBThunkAction>) => {
    dispatch(ReduceMerchantCodesAttempt())
    return axios
      .put<ReduceMerchantCodeResponse>(
        `/companies/${companyId}/reduce_merchant_codes`,
        {
          new_length: newLength,
        }
      )
      .then(() => {
        dispatch(ReduceMerchantCodesSuccess())
        dispatch(GetCompanyMerchantCodesDataThunk(companyId))
      })
      .catch((e) => {
        let bodyKey = "accounting-plan.merchant-codes.reduce.modal.error.body"
        dispatch(ReduceMerchantCodesFailure(bodyKey, {}))
      })
  }

interface RemovePrefixMerchantCodeResponse {
  updated: string[]
  cannot_update: string[]
}
export const removePrefixMerchantCodesThunk =
  (companyId: number, prefix: string) =>
  (dispatch: Dispatch<AnyAction | RNBThunkAction>) => {
    dispatch(RemovePrefixMerchantCodesAttempt())
    return axios
      .put<RemovePrefixMerchantCodeResponse>(
        `/companies/${companyId}/remove_prefix_merchant_codes`,
        {
          prefix: prefix,
        }
      )
      .then(() => {
        dispatch(RemovePrefixMerchantCodesSuccess())
        dispatch(GetCompanyMerchantCodesDataThunk(companyId))
      })
      .catch((e) => {
        let bodyKey =
          "accounting-plan.merchant-codes.remove-prefix.modal.error.body"
        dispatch(RemovePrefixMerchantCodesFailure(bodyKey, {}))
      })
  }
