import { useEffect } from "react"

export const useKeyDown = (
  callback: () => void,
  keys: ("ArrowDown" | "ArrowUp" | "Enter" | "æ")[],
  altModifier = false
) => {
  const onKeyDown = (event: KeyboardEvent) => {
    if (altModifier && !event.altKey) {
      return
    }
    const wasAnyKeyPressed = keys.some((key) => event.key === key)
    if (wasAnyKeyPressed) {
      event.preventDefault()
      callback()
    }
  }
  useEffect(() => {
    document.addEventListener("keydown", onKeyDown)
    return () => {
      document.removeEventListener("keydown", onKeyDown)
    }
  }, [onKeyDown])
}
