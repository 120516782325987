import React, { SyntheticEvent, useState } from "react"
import styled from "styled-components/macro"
import * as Ct from "ldlj"

import { Suspense } from "react"
import { ReactComponent as CalendarCornflower } from "../../assets/calendarSearchCornflower.svg"
import {
  locale,
  InputDatePickerProps,
  StyledDatePickerGlobal,
  formatDateDatePicker,
  StyledDatePickerPopupGlobal,
} from "../../utils/date"
import { DateTime } from "luxon"

const DatePicker = React.lazy(() => import("rsuite/DatePicker"))
const CustomProvider = React.lazy(() => import("rsuite/CustomProvider"))

export const InputDatePicker = (props: InputDatePickerProps) => {
  const {
    value,
    onChange,
    onClean = () => {},
    onFocus = () => {},
    onBlur = () => {},
    onOpen = () => {},
    minimum,
    maximum,
    editable = true,
    cleanable = false,
    placement = "bottomStart",
    placeholder = "Sélectionner une date",
    messageTooltipDisabled = "",
  } = props

  const [tmpValue, setTmpValue] = useState<Date | null>(null)
  const [invalidDate, setInvalidDate] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const disabledDate = (date: Date): boolean => {
    let disableMin = false
    let disableMax = false
    date.setHours(0, 0, 0, 0)

    if (minimum) {
      const startDate = new Date(minimum)
      startDate.setHours(0, 0, 0, 0)
      disableMin = date < startDate
    }
    if (maximum) {
      const endDate = new Date(maximum)
      endDate.setHours(0, 0, 0, 0)
      disableMax = date > endDate
    }
    return disableMin || disableMax
  }

  const handleChange = (
    value: Date | null,
    event: SyntheticEvent<Element, Event>
  ) => {
    if (cleanable && value === null) onChange(value, event)
    else if (value && DateTime.fromJSDate(value).isValid) {
      if (invalidDate) setInvalidDate(false)
      const dateString = formatDateDatePicker(value)
      onChange(dateString, event)
    } else {
      setInvalidDate(true)
      setTmpValue(value)
    }
  }

  return (
    <StyledDiv>
      <StyledDatePickerGlobal />
      {isOpen && Boolean(messageTooltipDisabled) && (
        <StyledDatePickerPopupGlobal toastMessage={messageTooltipDisabled} />
      )}
      <Suspense fallback={<Ct.SpinningLoader />}>
        <CustomProvider locale={locale}>
          <DatePicker
            value={invalidDate ? tmpValue : value}
            editable={editable}
            placeholder={placeholder}
            onChange={handleChange}
            onClean={onClean}
            onFocus={onFocus}
            onBlur={onBlur}
            shouldDisableDate={disabledDate}
            format={"dd/MM/yyyy"}
            isoWeek={true}
            cleanable={cleanable}
            placement={placement}
            caretAs={StyledCalendar}
            oneTap={true}
            onOpen={() => {
              setIsOpen(true)
              onOpen()
            }}
            onClose={() => setIsOpen(false)}
          />
        </CustomProvider>
      </Suspense>

      <Ct.Spacer width={1} />
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.25rem;
  padding: 0 1rem 0 0;
`
const StyledCalendar = styled(CalendarCornflower)`
  width: 4rem;
  height: 3rem;
`
