import styled from "styled-components/macro"
import * as Ct from "ldlj"
import { useIntl } from "react-intl"
import { Switch } from "../../components/Commons/Switch"

interface SwitchCaptureEmailProps {
  value: boolean
  onToggle: () => void
}

export const SwitchCaptureEmail = ({
  value,
  onToggle,
}: SwitchCaptureEmailProps) => {
  const intl = useIntl()

  return (
    <WrapperSwitchCaptureEmail>
      <Switch
        value={value}
        onToggle={() => onToggle()}
        onColor={"amaranth"}
        size="small"
      />
      <Ct.Spacer width={1} />
      <Ct.Text
        text={intl.formatMessage({
          id: `invitation.email.used-as-capture-email`,
        })}
        textStyle={{ color: "amaranth", fontSize: 1.5 }}
      />
    </WrapperSwitchCaptureEmail>
  )
}

const WrapperSwitchCaptureEmail = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 1rem 1.75rem 0 1.75rem;
`
