import styled from "styled-components/macro"
import { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { useRNBSelector } from "../../store/rootReducer"
import { selectUserToEditAction } from "../../store/ducks/team.ducks"
import {
  attemptRegisteringClientThunk,
  userInvitationRetryAction,
  UserState,
} from "../../store/ducks/user.ducks"
import { Modal } from "../../components/Commons/Modal"
import { AuthorisationList } from "../../components/AuthorisationList"
import { ReactComponent as CloseCross } from "../../assets/close-cross.svg"
import * as Ct from "ldlj"
import { colors } from "../../styles/design.config"
import { SwitchCaptureEmail } from "./SwitchCaptureEmail"

/* eslint-disable camelcase */

type userTypologyList =
  | "manager"
  | "collaborator"
  | "customer"
  | "customer_accountant"
  | ""

export const UserInvitation = (props: Ct.ModalComponentsProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const user = useRNBSelector((state) => state.user)
  const fiduciaryId = useRNBSelector((state) => state.fiduciary.id)
  const team = useRNBSelector((state) => state.team.team)
  const invitationStatus = useRNBSelector(
    (state) => state.user.userInvitationStatus
  )

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [selectedRole, setSelectedRole] = useState<userTypologyList>("")
  const [showDeleteCaptureEmail, setShowDeleteCaptureEmail] = useState(false)
  const [deleteCaptureEmail, setDeleteCaptureEmail] = useState(false)

  const errorStatuses: Array<UserState["userInvitationStatus"]> = [
    "EMAIL_USED",
    "IN_ANOTHER_FIDUCIARY",
    "EMAIL_USED_AS_CAPTURE_EMAIL",
  ]
  const isError: boolean = errorStatuses.includes(invitationStatus)

  interface UnknownErrorMessageProps {
    message: string
  }
  const UnknownErrorMessage = ({ message }: UnknownErrorMessageProps) => (
    <StyledMessage
      text={intl.formatMessage({
        id: `invitation.${message}`,
      })}
      textStyle={{ color: "amaranth", fontSize: 1.5 }}
    />
  )

  const isValidEmail = (email: string) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    return emailPattern.test(email)
  }

  const sendInvitation = () => {
    dispatch(
      attemptRegisteringClientThunk({
        firstName,
        lastName,
        email,
        userTypology: selectedRole,
        fiduciaryId: fiduciaryId,
        deleteCaptureEmail: deleteCaptureEmail,
      })
    )
  }

  const reset = () => {
    setFirstName("")
    setLastName("")
    setEmail("")
    setSelectedRole("")
    setDeleteCaptureEmail(false)
    setShowDeleteCaptureEmail(false)
  }

  const close = () => {
    props.onClose()
  }

  useEffect(() => {
    const successStatus = [
      "SUCCESS",
      "REACTIVATED_SUCCESS",
      "CUSTOMER_SUCCESS",
      "COLLAB_SUCCESS",
    ]
    if (successStatus.includes(invitationStatus)) {
      if (["CUSTOMER_SUCCESS", "COLLAB_SUCCESS"].includes(invitationStatus)) {
        const lastElementOfTeam = Object.entries(team).find(
          (element) => element[1].email === email
        )
        if (lastElementOfTeam !== undefined) {
          dispatch(selectUserToEditAction(Number(lastElementOfTeam[0])))
        }
      }
      reset()
      toast.success(
        intl.formatMessage({ id: "invitation.toaster.user-created" })
      )
      close()
    } else if (invitationStatus === "EMAIL_USED_AS_CAPTURE_EMAIL") {
      setShowDeleteCaptureEmail(true)
    } else if (invitationStatus !== "RETRY_ACTION") {
      setDeleteCaptureEmail(false)
      setShowDeleteCaptureEmail(false)
    }
  }, [invitationStatus, intl, dispatch])

  useEffect(() => {
    if (isError) dispatch(userInvitationRetryAction())
    if (showDeleteCaptureEmail) {
      setDeleteCaptureEmail(false)
      setShowDeleteCaptureEmail(false)
    }
  }, [email])

  useEffect(() => {
    if (isError && deleteCaptureEmail) dispatch(userInvitationRetryAction())
  }, [deleteCaptureEmail])

  useEffect(() => {
    if (user.typology === "collaborator") setSelectedRole("customer")
  }, [user.typology])

  return (
    <StyledModal
      isDisplayed={props.isDisplayed}
      onClose={props.onClose}
      top={"100"}
      left={"100"}
    >
      <StyledCard radius={1.25}>
        <ModalCloseCross onClick={close}>
          <CloseCross />
        </ModalCloseCross>

        <Ct.Spacer />
        <Ct.Title
          text={intl.formatMessage({ id: "invitation.title" })}
          css={{ fontSize: "4.5rem" }}
        />
        <Ct.Spacer />

        <DividerBar />
        <Ct.Spacer height={2} />

        <Left>
          <Ct.Text
            text={intl.formatMessage({ id: "authorize-list.first-text" })}
            textStyle={{
              fontSize: 2,
              fontWeight: 600,
              textTransform: "uppercase",
            }}
          />
        </Left>

        <InputSection>
          <WrapperMessageError>
            <Ct.Input
              required={true}
              name="firstName"
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              label={intl.formatMessage({ id: "invitation.first-name" })}
              showError={false}
              disabled={isError}
              borderRadius={1.25}
            />
          </WrapperMessageError>

          <Ct.Spacer width={4} />

          <WrapperMessageError>
            <Ct.Input
              required={false}
              name="lastName"
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              label={intl.formatMessage({ id: "invitation.last-name" })}
              showError={false}
              disabled={isError}
              borderRadius={1.25}
            />
          </WrapperMessageError>

          <Ct.Spacer width={4} />

          <WrapperMessageError>
            <Ct.Input
              required={false}
              name="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              label={intl.formatMessage({ id: "invitation.email" })}
              showError={isError}
              borderRadius={1.25}
            />
            {email !== "" && !isValidEmail(email) && (
              <UnknownErrorMessage message="email.wrongFormat" />
            )}
            {isError && invitationStatus !== "EMAIL_USED_AS_CAPTURE_EMAIL" && (
              <UnknownErrorMessage message="email.already-exist" />
            )}
            {showDeleteCaptureEmail && (
              <SwitchCaptureEmail
                value={deleteCaptureEmail}
                onToggle={() => setDeleteCaptureEmail(!deleteCaptureEmail)}
              />
            )}
          </WrapperMessageError>
        </InputSection>

        <Ct.Spacer height={3} />

        <Left>
          <Ct.Text
            text={intl.formatMessage({ id: "authorize-list.second-text" })}
            textStyle={{
              fontSize: 2,
              fontWeight: 600,
              textTransform: "uppercase",
            }}
          />
        </Left>

        <ListSection>
          <AuthorisationList
            id="ManagerInvitation"
            disabled={
              isError ||
              firstName === "" ||
              lastName === "" ||
              !isValidEmail(email) ||
              user.typology === "collaborator"
            }
            isSelected={selectedRole === "manager"}
            content={intl.formatMessage({
              id: "authorize-list.title.manager",
            })}
            onClick={() => setSelectedRole("manager")}
          />
          <Ct.Spacer width={4} />

          <AuthorisationList
            id="CollaboratorInvitation"
            disabled={
              isError ||
              firstName === "" ||
              lastName === "" ||
              !isValidEmail(email) ||
              user.typology === "collaborator"
            }
            isSelected={selectedRole === "collaborator"}
            content={intl.formatMessage({
              id: "authorize-list.title.collaborator",
            })}
            onClick={() => setSelectedRole("collaborator")}
          />
          <Ct.Spacer width={4} />

          <AuthorisationList
            id="CustomerAccountantInvitation"
            disabled={
              isError ||
              firstName === "" ||
              lastName === "" ||
              !isValidEmail(email)
            }
            isSelected={selectedRole === "customer_accountant"}
            content={intl.formatMessage({
              id: "authorize-list.title.customer_accountant",
            })}
            onClick={() => setSelectedRole("customer_accountant")}
          />
          <Ct.Spacer width={4} />

          <AuthorisationList
            id="ClientInvitation"
            disabled={
              isError ||
              firstName === "" ||
              lastName === "" ||
              !isValidEmail(email)
            }
            isSelected={selectedRole === "customer"}
            content={intl.formatMessage({
              id: "authorize-list.title.customer",
            })}
            onClick={() => setSelectedRole("customer")}
          />
        </ListSection>

        <Ct.Spacer height={3} />

        {selectedRole && (
          <Ct.Button
            label={intl.formatMessage(
              { id: "authorize-list.invite-user" },
              {
                userTitle: intl.formatMessage({
                  id: `authorize-list.title.${selectedRole}`,
                }),
              }
            )}
            disabled={
              firstName === "" ||
              lastName === "" ||
              !isValidEmail(email) ||
              selectedRole.length === 0 ||
              isError
            }
            onClick={() => sendInvitation()}
            width={30}
          />
        )}
        <Ct.Spacer height={3} />
      </StyledCard>
    </StyledModal>
  )
}

export default UserInvitation

const StyledModal = styled((props) => <Modal {...props} />)``
const StyledCard = styled((props) => <Ct.Card {...props} />)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: fixed;

  left: 2rem;
  right: 2rem;
  top: 2rem;
  bottom: 2rem;

  width: calc(100% - 4rem);
  height: calc(100% - 4rem);
  box-sizing: border-box;
  padding: 1rem 0;

  overflow-x: hidden;
  overflow-y: auto;
`
const ModalCloseCross = styled.div`
  display: flex;
  position: fixed;
  align-self: flex-end;
  cursor: pointer;
  top: 7rem;
  right: 7rem;
`
const DividerBar = styled.div`
  width: 100%;
  border-top: 2px solid ${colors.moon};
`
const Left = styled.div`
  align-self: flex-start;
  padding: 2rem 0 3rem 5rem;
`

const InputSection = styled.section`
  display: flex;
  width: 95%;
  @media (max-width: 1260px) {
    flex-direction: column;
  }
`

const ListSection = styled.section`
  width: 95%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: static;
  padding: 0 5rem;
  @media (max-width: 1260px) {
    flex-direction: column;
  }
`

const StyledMessage = styled((props) => <Ct.Text {...props} />)`
  width: 100%;
  padding: 0.5rem 1.75rem 0 1.75rem;
  box-sizing: border-box;
`

const WrapperMessageError = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`
