/* eslint-disable camelcase */
import { useEffect, useRef, useState } from "react"
import {
  Controller,
  FieldValues,
  useForm,
  UseFormRegister,
} from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import Cropper from "react-cropper"
import "cropperjs/dist/cropper.css"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import * as Ct from "ldlj"
import { convertEnumToArray } from "../../../utils/array"
import { ComptaSoftwareOptionsEnum } from "../../../model/settings"
import { useIntl } from "react-intl"
import { base64ArrayBuffer } from "../../../utils/base64ArrayBuffer"
import {
  getApiConnected,
  getCompaniesApiConnectedReset,
  getCompaniesApiConnectedThunk,
  getFiduciaryThunk,
  GetInqomSignRequestInfosResponse,
  getInqomSignRequestThunk,
  inqomSignRequestCallback,
  relaunchApiConnectionThunk,
  updateFiduciaryInfosThunk,
  updateFiduciaryLogoThunk,
} from "../../../store/ducks/fiduciary.ducks"
import { useRNBSelector } from "../../../store/rootReducer"
import { cleanPhone, PHONE_VALIDATOR } from "../../../utils/phoneValidator"
import { usePrompt } from "../../../utils/usePrompt.hook"
import { DropFileUpload, UploadStatus } from "../../../components/DropFile"
import { Select } from "../../../components/Commons/Select"
import styled from "styled-components/macro"
import { boxShadow, colors, sizes } from "../../../styles/design.config"
import { ConnectFiduciaryApiModal } from "./ConnectFiduciaryApiModal"
import { Switch } from "../../../components/Commons/Switch"
import { Logos } from "../../../components/Commons/Logos"
import { ChangeSoftwareFiduciaryModal } from "../../../components/fiduciary/ChangeSoftwareFiduciaryModal"
import { ReactComponent as Wheel } from "../../../assets/wheel.svg"
import { AccountingSoftware } from "../../../utils/company"
import {
  ChangeCardContent,
  ChangeHeader,
  ChangeItem,
  ChangesList,
  TextNoChanges,
} from "../../../components/ChangesList"

interface FiduciarySettingsData {
  fiduciaryName: string
  address: string
  city: string
  postalCode: string
  phone: string
  accounting_software: string
}

const Settings = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const cropperRef = useRef<HTMLImageElement>(null)

  const [uploadStatus] = useState<UploadStatus>("start")
  const [uploadProgress] = useState(0)
  const [uploadedImageSrc, setUploadedImageSrc] = useState<string | null>(null)
  const [cropper, setCropper] = useState<Cropper>()
  const [formatError, setFormatError] = useState(false)

  const fiduciary = useRNBSelector((state) => state.fiduciary)
  const currentUserTypology = useRNBSelector((state) => state.user.typology)

  const initFiduciaryName = fiduciary.name
  const initFiduciaryAddress = fiduciary.address
  const initFiduciaryCity = fiduciary.city
  const initFiduciaryPostalCode = fiduciary.postalCode
  const initFiduciaryPhone = fiduciary.phone
  const initFiduciaryAccountingSoftware = fiduciary.accounting_software
  const initialApiConnected = fiduciary.api_connected
  const initialAcdApiDueDate = fiduciary.acd_api_due_date
  const initialAcdApiDocumentReference = fiduciary.acd_api_document_reference
  const [acdApiDueDate, setAcdApiDueDate] = useState(initialAcdApiDueDate)
  const [acdApiDocumentReference, setAcdApiDocumentReference] = useState(
    initialAcdApiDocumentReference
  )
  const [isApiConnected, setIsApiConnected] = useState(false)
  const [isModalDisplayed, setIsModalDisplayed] = useState(false)
  const [displayChangeSoftware, setDisplayChangeSoftware] = useState(false)

  const apiConnectedStatus = fiduciary.apiConnectedStatus
  // const isInPossibleApi = ["coala", "acd", "inqom", "fulll"].includes(
  const isInPossibleApi = ["coala", "acd", "inqom"].includes(
    initFiduciaryAccountingSoftware || ""
  )
  const softwareOptionArray = convertEnumToArray(ComptaSoftwareOptionsEnum) as [
    AccountingSoftware,
    string
  ][]

  const [companiesApiConnectedModal, setCompaniesApiConnectedModal] =
    useState(false)
  const companiesApiConnected = useRNBSelector(
    (state) => state.fiduciary.companiesApiConnected
  )

  const accounting_softwareOptions: Ct.OptionList<AccountingSoftware> =
    Ct.createOptionList<AccountingSoftware>(softwareOptionArray)

  useEffect(() => {
    setAcdApiDueDate(initialAcdApiDueDate)
  }, [initialAcdApiDueDate])

  useEffect(() => {
    setAcdApiDocumentReference(initialAcdApiDocumentReference)
  }, [initialAcdApiDocumentReference])

  useEffect(() => {
    if (initFiduciaryAccountingSoftware === "inqom") {
      setIsApiConnected(
        fiduciary.inqom_sign_request?.connected || initialApiConnected
      )
    }
    if (initFiduciaryAccountingSoftware === "acd") {
      setIsApiConnected(initialApiConnected)
    }
    if (initFiduciaryAccountingSoftware === "coala") {
      setIsApiConnected(initialApiConnected)
    }

    setIsModalDisplayed(false)
  }, [initialApiConnected, initFiduciaryAccountingSoftware])

  useEffect(() => {
    if (fiduciary.id !== 0) {
      dispatch(getApiConnected(fiduciary.id))
    }
  }, [fiduciary.id])

  const switchAcdSentDataHasChanged =
    initialAcdApiDueDate !== acdApiDueDate ||
    initialAcdApiDocumentReference !== acdApiDocumentReference

  const schema = yup.object({
    fiduciaryName: yup.string().required(),
    address: yup.string().required(),
    city: yup.string().required(),
    postalCode: yup.string().required().length(5),
    phone: yup.string().matches(PHONE_VALIDATOR).required(),
  })

  const {
    register,
    watch,
    setValue,
    formState: { errors, isDirty },
    reset,
    control,
  } = useForm<FiduciarySettingsData>({
    mode: "onChange",
    resolver: yupResolver(schema, { abortEarly: false }),
    criteriaMode: "all",
    reValidateMode: "onChange",
  })

  const fiduciaryName = watch("fiduciaryName")
  const address = watch("address")
  const city = watch("city")
  const postalCode = watch("postalCode")
  const phone = watch("phone")

  const [accounting_software] = useState<Ct.Option<string>>({
    value: initFiduciaryAccountingSoftware || "",
    label: initFiduciaryAccountingSoftware || "",
  })

  const getUrlParams = (search = ``) => {
    const hashes = search.split(`&`)
    return hashes.reduce(
      (acc, hash) => {
        const [key, value] = hash.split(`=`)
        return {
          ...acc,
          [key]: decodeURIComponent(value),
        }
      },
      // eslint-disable-next-line
      { fiduciary_id: fiduciary.id }
    )
  }

  useEffect(() => {
    if (fiduciary.id === 0) {
      dispatch(getFiduciaryThunk())
    }
    if (
      window.location.hash !== "" &&
      fiduciary.id !== 0 &&
      fiduciary.accounting_software === "inqom"
    ) {
      const urlParameters = getUrlParams(window.location.hash.substring(1))
      dispatch(inqomSignRequestCallback(urlParameters, fiduciary.id))
    }
  }, [window.location.hash, fiduciary.id, fiduciary.accounting_software])

  usePrompt(
    intl.formatMessage({ id: "form.unsaved.message" }),
    isDirty || switchAcdSentDataHasChanged
  )

  useEffect(() => {
    setValue("fiduciaryName", initFiduciaryName)
    setValue("address", initFiduciaryAddress)
    setValue("city", initFiduciaryCity)
    setValue("postalCode", initFiduciaryPostalCode)
    setValue("phone", initFiduciaryPhone)
    setValue("accounting_software", initFiduciaryAccountingSoftware || "")
  }, [
    initFiduciaryName,
    initFiduciaryAddress,
    initFiduciaryCity,
    initFiduciaryPostalCode,
    initFiduciaryPhone,
    initFiduciaryAccountingSoftware,
    setValue,
  ])

  const uploadFile = (file: File): void => {
    if (!validateLogoFormat(file)) {
      setFormatError(true)
      return
    }
    setFormatError(false)
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = function (e) {
      setUploadedImageSrc(reader.result as string)
    }
  }

  const validateLogoFormat = (file: File): Boolean => {
    const format = file.name.substring(file.name.lastIndexOf(".")).toLowerCase()
    return [".jpg", ".png", ".jpeg"].includes(format.toLowerCase())
  }

  const onCrop = () => {
    if (!cropper) {
      return
    }

    let file: File
    cropper.getCroppedCanvas().toBlob(async function (blob: Blob | null) {
      if (!blob) {
        return
      }

      file = new File([blob], "fiduciary_logo.jpg", { type: "image/jpeg" })

      crypto.subtle
        .digest("SHA-512", await file.arrayBuffer())
        .then(function (h) {
          let fingerprint = base64ArrayBuffer(h)
          dispatch(
            updateFiduciaryLogoThunk({
              file,
              originalFileName: file.name,
              originalFileSize: file.size,
              fingerprint,
            })
          )
        })
        .then(() => {
          setUploadedImageSrc(null)
        })
    }, "image/jpeg")
  }

  const submitForm = () => {
    dispatch(
      updateFiduciaryInfosThunk({
        name: fiduciaryName,
        address,
        city,
        postalCode,
        phone: cleanPhone(phone),
        acdApiDueDate: acdApiDueDate !== undefined ? acdApiDueDate : null,
        acdApiDocumentReference:
          acdApiDocumentReference !== undefined
            ? acdApiDocumentReference
            : null,
      })
    )
  }

  if (
    currentUserTypology === "customer" ||
    currentUserTypology === "customer_accountant" ||
    currentUserTypology === "collaborator"
  ) {
    navigate(`/unauthorized`)
  }

  useEffect(() => {
    if (fiduciary.fiduciaryInfoUpdateStatus === "SUCCESS") {
      reset({
        fiduciaryName: initFiduciaryName,
        address: initFiduciaryAddress,
        city: initFiduciaryCity,
        postalCode: initFiduciaryPostalCode,
        phone: initFiduciaryPhone,
        accounting_software: initFiduciaryAccountingSoftware || undefined,
      })
    }
  }, [
    fiduciary.fiduciaryInfoUpdateStatus,
    initFiduciaryName,
    initFiduciaryAddress,
    initFiduciaryCity,
    initFiduciaryPostalCode,
    initFiduciaryPhone,
    initFiduciaryAccountingSoftware,
    reset,
  ])

  useEffect(() => {
    if (fiduciary.accounting_software === "inqom" && fiduciary.id !== 0) {
      dispatch(getInqomSignRequestThunk(fiduciary.id))
    }
  }, [fiduciary.accounting_software, fiduciary.id])

  const getUpdateButtonDisplay = () =>
    fiduciary.accounting_software &&
    ["acd", "inqom"].includes(fiduciary.accounting_software)
      ? "fiduciary-settings.api-companies-connected"
      : "fiduciary-settings.api-companies-not-connected"

  return (
    <Wrapper>
      <ConnectFiduciaryApiModal
        isDisplayed={isModalDisplayed}
        onClose={() => {
          setIsModalDisplayed(false)
        }}
        fiduciarySoftware={initFiduciaryAccountingSoftware || ""}
      />
      <StyledSection>
        <StyledContent>
          <Ct.StyledForm>
            <CardContainer>
              <StyledCard>
                <StyledText
                  text={intl.formatMessage({
                    id: "fiduciary-register.settings-title",
                  })}
                  textStyle={{
                    fontFamily: "Poppins",
                    color: "navy",
                    fontWeight: 600,
                    fontSize: "2.25",
                  }}
                />
                <Ct.Spacer height={4} />
                <Ct.Input
                  name="fiduciaryName"
                  register={register as unknown as UseFormRegister<FieldValues>}
                  label={intl.formatMessage({ id: "fiduciary-register.name" })}
                  type="text"
                  value={fiduciaryName}
                />
                <Ct.Spacer height={4} />
                <Ct.Input
                  name="address"
                  register={register as unknown as UseFormRegister<FieldValues>}
                  label={intl.formatMessage({
                    id: "fiduciary-register.address",
                  })}
                  type="text"
                  value={address}
                />
                <Ct.Spacer height={4} />
                <Ct.StyledDuoInput>
                  <Ct.Input
                    name="city"
                    register={
                      register as unknown as UseFormRegister<FieldValues>
                    }
                    label={intl.formatMessage({
                      id: "fiduciary-register.city",
                    })}
                    type="text"
                    value={city}
                  />
                  <Ct.Spacer width={3} />
                  <Ct.Input
                    name="postalCode"
                    register={
                      register as unknown as UseFormRegister<FieldValues>
                    }
                    label={intl.formatMessage({
                      id: "fiduciary-register.postal-code",
                    })}
                    type="text"
                    value={postalCode}
                  />
                </Ct.StyledDuoInput>
                <Ct.Spacer height={4} />
                <Ct.StyledDuoInput>
                  <Ct.Input
                    name="phone"
                    register={
                      register as unknown as UseFormRegister<FieldValues>
                    }
                    label={intl.formatMessage({
                      id: "fiduciary-register.phone",
                    })}
                    type="text"
                    value={phone}
                  />
                  <Ct.Spacer width={3} />
                  <Controller
                    name="accounting_software"
                    control={control}
                    render={({ field }) => (
                      <Select
                        intl={intl}
                        disabled={true}
                        field={field}
                        value={accounting_software}
                        options={accounting_softwareOptions}
                        label={"accounting_software"}
                        domain={"fiduciary-register"}
                        optionType={"compta-software"}
                      />
                    )}
                  />
                </Ct.StyledDuoInput>
                <Ct.Spacer height={4} />
                {!uploadedImageSrc && (
                  <DropFileUpload
                    uploadFile={uploadFile}
                    dropText="Logo"
                    uploadStatus={uploadStatus}
                    uploadProgress={uploadProgress}
                  />
                )}
                <Ct.Spacer height={4} />
                {uploadedImageSrc && (
                  <>
                    <Cropper
                      src={uploadedImageSrc}
                      initialAspectRatio={1}
                      onInitialized={(instance) => {
                        setCropper(instance)
                      }}
                      ref={cropperRef}
                    />
                    <Ct.Spacer />
                    <Ct.Button
                      label={intl.formatMessage({
                        id: "fiduciary-register.update-logo.crop-button",
                      })}
                      onClick={() => {
                        onCrop()
                      }}
                      width={42.5}
                    />

                    <Ct.Spacer height={4} />
                  </>
                )}
              </StyledCard>

              <Line />

              <StyledCard>
                <StyledText
                  text={intl.formatMessage({
                    id: "fiduciary-register.accounting-software-settings-title",
                  })}
                  textStyle={{
                    fontFamily: "Poppins",
                    color: "navy",
                    fontWeight: 600,
                    fontSize: "2.25",
                  }}
                />
                <Ct.Spacer height={4} />
                <ApiConnectionCard>
                  <ApiCardContent>
                    {["administrator", "manager"].includes(
                      currentUserTypology
                    ) && (
                      <WrapperChangeSoftware>
                        <StyledWheel
                          onClick={() => {
                            setDisplayChangeSoftware(true)
                          }}
                        />
                      </WrapperChangeSoftware>
                    )}

                    {initFiduciaryAccountingSoftware && (
                      <Logos
                        software={initFiduciaryAccountingSoftware as string}
                        width={"40rem"}
                        height={"20rem"}
                      />
                    )}
                    <Ct.Spacer height={2} />
                    {isApiConnected ? (
                      <Ct.RowCenter>
                        <GreenIndicator />
                        <Ct.Spacer width={1} />
                        <Ct.Text
                          text={intl.formatMessage({
                            id: "fiduciary.settings.connected",
                          })}
                          textStyle={{
                            fontFamily: "Roboto",
                            fontWeight: 400,
                            fontStyle: "italic",
                            fontSize: 2,
                            color: "shamrock",
                          }}
                        />
                      </Ct.RowCenter>
                    ) : (
                      <Ct.RowCenter>
                        <Ct.Button
                          width={42.5}
                          label={intl.formatMessage({
                            id: "fiduciary.settings.connect",
                          })}
                          disabled={
                            !isInPossibleApi ||
                            apiConnectedStatus === "IDLE" ||
                            apiConnectedStatus === "CHECKING" ||
                            (fiduciary.accounting_software === "inqom" &&
                              !["administrator", "manager"].includes(
                                currentUserTypology
                              ))
                          }
                          onClick={() => {
                            if (fiduciary.accounting_software === "acd") {
                              setIsModalDisplayed(true)
                            } else if (
                              fiduciary.accounting_software === "inqom"
                            ) {
                              redirectToInqom(
                                fiduciary.inqom_sign_request?.params || null
                              )
                            }
                            // else if (
                            //   fiduciary.accounting_software === "fulll"
                            // ) {
                            //   redirectToFulll()
                            // }
                          }}
                        />
                      </Ct.RowCenter>
                    )}

                    <Ct.Spacer height={2} />
                  </ApiCardContent>
                </ApiConnectionCard>
                <Ct.Spacer height={4} />
                {initFiduciaryAccountingSoftware === "acd" &&
                  isApiConnected && (
                    <SwitchContainerBlock>
                      <StyledText
                        text={intl.formatMessage({
                          id: "fiduciary-settings.acd-sent-data-handle.body",
                        })}
                        textStyle={{
                          fontFamily: "Poppins",
                          color: "navy",
                          fontWeight: 700,
                          fontSize: "1.5",
                        }}
                      />
                      <Ct.Spacer height={4} />
                      <SwitchContainer>
                        <Switch
                          value={acdApiDueDate || false}
                          onToggle={function (): void {
                            setAcdApiDueDate(!acdApiDueDate)
                          }}
                          disabled={apiConnectedStatus !== "CHECKED"}
                        />
                        <Ct.Spacer width={2} />
                        <Ct.Text
                          text={intl.formatMessage({
                            id: "fiduciary-settings.send-due-date-ACD",
                          })}
                        />
                      </SwitchContainer>
                      <Ct.Spacer height={4} />
                      <SwitchContainer>
                        <Switch
                          value={acdApiDocumentReference || false}
                          onToggle={function (): void {
                            setAcdApiDocumentReference(!acdApiDocumentReference)
                          }}
                          disabled={apiConnectedStatus !== "CHECKED"}
                        />
                        <Ct.Spacer width={2} />
                        <Ct.Text
                          text={intl.formatMessage({
                            id: "fiduciary-settings.send-document-reference-ACD",
                          })}
                        />
                      </SwitchContainer>
                      <Ct.Spacer height={4} />
                    </SwitchContainerBlock>
                  )}

                {isApiConnected && (
                  <>
                    <ApiConnectionCard>
                      <ApiCardContent>
                        <>
                          <Ct.Text
                            text={intl.formatMessage({
                              id: getUpdateButtonDisplay(),
                            })}
                            textStyle={{
                              color: "navy",
                              lineHeight: 2,
                              fontWeight: 500,
                              fontSize: 2,
                            }}
                          />
                          <Ct.Spacer />
                          <DuoButtonsWrapper>
                            {(fiduciary.accounting_software === "acd" ||
                              fiduciary.accounting_software === "inqom") && (
                              <>
                                <Ct.Button
                                  width={25}
                                  label={intl.formatMessage({
                                    id: "fiduciary-settings.relaunch-api-connection.button",
                                  })}
                                  onClick={() =>
                                    dispatch(
                                      relaunchApiConnectionThunk(fiduciary.id)
                                    )
                                  }
                                />
                                <Ct.Spacer width={3} />
                              </>
                            )}
                            <Ct.Button
                              width={25}
                              label={intl.formatMessage({
                                id: "fiduciary-settings.api-companies-connected-list",
                              })}
                              onClick={() => {
                                dispatch(
                                  getCompaniesApiConnectedThunk(fiduciary.id)
                                )
                                setCompaniesApiConnectedModal(true)
                              }}
                            />
                          </DuoButtonsWrapper>
                        </>
                        <Ct.Spacer />
                      </ApiCardContent>
                    </ApiConnectionCard>
                  </>
                )}

                <Ct.Modal
                  isDisplayed={companiesApiConnectedModal}
                  onClose={() => setCompaniesApiConnectedModal(false)}
                  left="50%"
                  right="50%"
                  top="calc(30vh - 25rem)"
                >
                  <Ct.Card width={"140rem"}>
                    <Ct.CloseCross
                      onClick={() => {
                        dispatch(getCompaniesApiConnectedReset())
                        setCompaniesApiConnectedModal(false)
                      }}
                    />
                    <Ct.Title
                      text={intl.formatMessage({
                        id: "fiduciary-settings.api-companies-connected.modal.title",
                      })}
                      size={7}
                    />

                    <Ct.Spacer height={4} />

                    <ChangeCardContent>
                      <ChangeHeader>
                        <Ct.Text
                          text={intl.formatMessage({
                            id: `office-company-settings.companies-connected.modal.subtitle`,
                          })}
                          textStyle={{
                            textTransform: "uppercase",
                            fontWeight: 700,
                          }}
                        />
                      </ChangeHeader>

                      {companiesApiConnected &&
                      companiesApiConnected.length > 0 ? (
                        <ChangesList>
                          {companiesApiConnected.map((company) => (
                            <ChangeItem key={company.id}>
                              <Ct.Text
                                text={intl.formatMessage(
                                  {
                                    id: `fiduciary-settings.api-companies-connected.modal.line`,
                                  },
                                  {
                                    companyId: company.id,
                                    companyName: company.company_name,
                                    userCompanyName: company.user_company_name,
                                    siren: company.identification_number,
                                    accountingSoftware:
                                      company.accounting_software.toUpperCase(),
                                  }
                                )}
                              />
                            </ChangeItem>
                          ))}
                        </ChangesList>
                      ) : (
                        <>
                          <Ct.Spacer height={2} />
                          <TextNoChanges
                            text={intl.formatMessage({
                              id: `fiduciary-settings.api-companies-connected.no-company`,
                            })}
                          />
                          <Ct.Spacer height={2} />
                        </>
                      )}
                    </ChangeCardContent>

                    <Ct.Spacer height={4} />

                    <Ct.Button
                      label={intl.formatMessage({
                        id: "fiduciary-settings.api-companies-connected.ok-button",
                      })}
                      onClick={() => {
                        dispatch(getCompaniesApiConnectedReset())
                        setCompaniesApiConnectedModal(false)
                      }}
                      width={sizes.button.standard}
                    />
                  </Ct.Card>
                </Ct.Modal>
              </StyledCard>
            </CardContainer>
            <Ct.Spacer height={4} />

            <Ct.Button
              label={intl.formatMessage({
                id: "fiduciary-register.update-infos.save-button",
              })}
              width={42.5}
              onClick={submitForm}
              disabled={
                (Object.values(errors).length > 0 || !isDirty) &&
                !switchAcdSentDataHasChanged
              }
            />
          </Ct.StyledForm>
          {formatError && (
            <>
              <Ct.Spacer />
              <Ct.Text
                text={intl.formatMessage({
                  id: "fiduciary-register.update-logo-failure.wrong-format",
                })}
                textStyle={{ color: "amaranth", fontWeight: 700 }}
              />
            </>
          )}
        </StyledContent>
      </StyledSection>

      {displayChangeSoftware && (
        <ChangeSoftwareFiduciaryModal
          isDisplayed={displayChangeSoftware}
          onClose={() => {
            setDisplayChangeSoftware(false)
          }}
        />
      )}
    </Wrapper>
  )
}

const redirectToInqom = (
  params: GetInqomSignRequestInfosResponse["params"] | null
) => {
  if (!params) return

  const url = `${params.request_url}?response_type=${params.response_type}&scope=${params.scope}&client_id=${params?.client_id}&state=${params?.state}&redirect_uri=${params?.redirect_uri}`
  window.open(url)
}

// const redirectToFulll = () => {
//   const FULLL_CHAINTRUST_CLIENT_ID = "60b2fd12-7311-4b8e-a690-2f5a21f22f66"
//   const url = `https://auth-fulll.fulll.house/oauth2/authorize?client_id=${FULLL_CHAINTRUST_CLIENT_ID}`
//   // const url = `https://auth.fulll.io/oauth2/authorize?client_id=${FULLL_CHAINTRUST_CLIENT_ID}`
//   window.open(url)
// }

export default Settings

const Wrapper = styled.div`
  padding-bottom: 4rem;
  display: flex;
  box-sizing: border-box;
  height: 100%;
`

const StyledSection = styled.section`
  border-radius: 0 2.5rem 2.5rem;
  background-color: ${colors.white};
  box-shadow: ${boxShadow};
  padding: 8rem 2rem 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  overflow: auto;

  /* FIREFOX */
  scrollbar-color: ${colors.rock} transparent;
  scrollbar-width: thin !important;

  /* CHROME */
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${colors.rock};
    border-radius: 3px;
    width: 4px;
  }
`

const StyledContent = styled.div`
  width: 75rem;
`

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
`
const SwitchContainerBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
`
const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
`
const StyledCard = styled.div`
  width: 70rem;
  margin: 0 4rem 4rem 6.5rem;
`
const StyledText = styled((props) => <Ct.Text {...props} />)`
  display: flex;
  justify-content: flex-start !important;
  width: 100%;
`
const Line = styled.div`
  width: 1px;
  background: #ededed;
  border: 1px solid #ededed;
  margin: 0rem 5rem 0rem 5rem;
`

const ApiConnectionCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: ${boxShadow};
  border-radius: 10px;
  border: 1px solid #f6f6f6;
  padding: 2rem 0 2rem 0;
`
const ApiCardContent = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
`
const Indicator = styled.div`
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
`
const GreenIndicator = styled(Indicator)`
  background-color: ${colors.shamrock};
`
const WrapperChangeSoftware = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

const StyledWheel = styled((props) => <Wheel {...props} />)`
  padding: 1rem;
  cursor: pointer;
`
const DuoButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`
