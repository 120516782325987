import axios, { AxiosError } from "axios"
import * as R from "ramda"

import { Dispatch, RNBThunkAction } from "../store.config"
import { RootState } from "../rootReducer"
import {
  FullDocumentsActionsEnum,
  FullDocumentsActionsType,
  PendingFullDocumentDeactivated,
  PendingFullDocumentProcessed,
} from "./fullDocuments.ducks"
import { DeactivatedReasonType } from "../../utils/translated_deactivated_reasons"

const enum WritingsActionsEnum {
  GET_WRITINGS_ATTEMPT = "WRITINGS/getWritingsAttempt",
  GET_WRITINGS_SUCCESS = "WRITINGS/getWritingsSuccess",
  GET_WRITINGS_FAILURE = "WRITINGS/getWritingsFailure",

  CREATE_WRITINGS_ATTEMPT = "WRITINGS/createWritingsAttempt",
  CREATE_WRITINGS_SUCCESS = "WRITINGS/createWritingsSuccess",
  CREATE_WRITINGS_FAILURE = "WRITINGS/createWritingsFailure",
  CREATE_WRITINGS_RESET = "WRITINGS/createWritingsReset",

  DOWNLOAD_WRITINGS_ATTEMPT = "WRITINGS/downloadWritingsAttempt",
  DOWNLOAD_WRITINGS_SUCCESS = "WRITINGS/downloadWritingsSuccess",
  DOWNLOAD_WRITINGS_FAILURE = "WRITINGS/downloadWritingsFailure",

  SET_WRITINGS_PER_FISCALYEAR = "WRITINGS/setWritingsPerFiscalYear",

  HAS_SEEN_WRITINGS = "WRITINGS/hasSeenWritings",

  SAVE_DOCUMENT_URL = "saveDocumentUrl",

  SAVE_WRITING_ARCHIVE_DOWNLOAD_EVENT_ATTEMPT = "WRITINGS/saveWritingArchiveDownloadEventAttempt",
  SAVE_WRITING_ARCHIVE_DOWNLOAD_EVENT_SUCCESS = "WRITINGS/saveWritingArchiveDownloadEventSuccess",
  SAVE_WRITING_ARCHIVE_DOWNLOAD_EVENT_FAILURE = "WRITINGS/saveWritingArchiveDownloadEventFailure",

  RETRY_API_IMPORT_FOR_FULL_DOCUMENT_ATTEMPT = "WRITINGS/retryApiImportForFullDocumentAttempt",
  RETRY_API_IMPORT_FOR_FULL_DOCUMENT_SUCCESS = "WRITINGS/retryApiImportForFullDocumentSuccess",
  RETRY_API_IMPORT_FOR_FULL_DOCUMENT_FAILURE = "WRITINGS/retryApiImportForFullDocumentFailure",

  GET_WRITING_ARCHIVE_DOWNLOAD_HISTORY_ATTEMPT = "WRITINGS/getWritingArchiveDownloadHistoryAttempt",
  GET_WRITING_ARCHIVE_DOWNLOAD_HISTORY_SUCCESS = "WRITINGS/getWritingArchiveDownloadHistorySuccess",
  GET_WRITING_ARCHIVE_DOWNLOAD_HISTORY_FAILURE = "WRITINGS/getWritingArchiveDownloadHistoryFailure",

  GET_WRITING_ARCHIVE_DETAILS_ATTEMPT = "WRITINGS/getWritingArchiveDetailsAttempt",
  GET_WRITING_ARCHIVE_DETAILS_SUCCESS = "WRITINGS/getWritingArchiveDetailsSuccess",
  GET_WRITING_ARCHIVE_DETAILS_FAILURE = "WRITINGS/getWritingArchiveDetailsFailure",

  REGENERATE_ARCHIVE_ATTEMPT = "WRITINGS/RegenerateArchiveAttempt",
  REGENERATE_ARCHIVE_SUCCESS = "WRITINGS/RegenerateArchiveSuccess",
  REGENERATE_ARCHIVE_FAILURE = "WRITINGS/RegenerateArchiveFailure",

  VALIDATE_WRITING_ATTEMPT = "WRITINGS/ValidateWritingAttempt",
  VALIDATE_WRITING_SUCCESS = "WRITINGS/ValidateWritingSuccess",
  VALIDATE_WRITING_FAILURE = "WRITINGS/ValidateWritingFailure",
  VALIDATE_WRITING_RESET = "WRITINGS/ValidateWritingReset",

  DELETE_ARCHIVE_ATTEMPT = "WRITINGS/DeleteArchiveAttempt",
  DELETE_ARCHIVE_SUCCESS = "WRITINGS/DeleteArchiveSuccess",
  DELETE_ARCHIVE_FAILURE = "WRITINGS/DeleteArchiveFailure",
  DELETE_ARCHIVE_RESET = "WRITINGS/DeleteArchiveReset",
}

export const getWritingsAttemptAction = (fiscalYearId: number) =>
  ({ type: WritingsActionsEnum.GET_WRITINGS_ATTEMPT, fiscalYearId } as const)
export const getWritingsSuccessAction = (
  archives: Archives,
  fiscalYearId: number
) =>
  ({
    type: WritingsActionsEnum.GET_WRITINGS_SUCCESS,
    archives,
    fiscalYearId,
  } as const)
export const getWritingsFailureAction = (
  error: AxiosError,
  fiscalYearId: number
) =>
  ({
    type: WritingsActionsEnum.GET_WRITINGS_FAILURE,
    withToast: true,
    toasterType: "error",
    fiscalYearId,
    message: {
      titleKey: error.name,
      bodyKey: error.message,
    },
  } as const)
export const createWritingsAttemptAction = (fiscalYearId: number) =>
  ({ type: WritingsActionsEnum.CREATE_WRITINGS_ATTEMPT, fiscalYearId } as const)
export const createWritingsSuccessAction = (fiscalYearId: number) =>
  ({
    type: WritingsActionsEnum.CREATE_WRITINGS_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: "company-writings.create.success.title",
      bodyKey: "company-writings.create.success.body",
    },
    fiscalYearId: fiscalYearId,
  } as const)
export const createWritingsFailureAction = (
  error: AxiosError,
  fiscalYearId: number
) =>
  ({
    type: WritingsActionsEnum.CREATE_WRITINGS_FAILURE,
    withToast: true,
    toasterType: "error",
    fiscalYearId,
    message: {
      titleKey: error.name,
      bodyKey: error.message,
    },
  } as const)
export const createWritingsResetAction = (fiscalYearId: number) =>
  ({ type: WritingsActionsEnum.CREATE_WRITINGS_RESET, fiscalYearId } as const)
export const downloadWritingsAttemptAction = () =>
  ({ type: WritingsActionsEnum.DOWNLOAD_WRITINGS_ATTEMPT } as const)
export const downloadWritingsSuccessAction = () =>
  ({
    type: WritingsActionsEnum.DOWNLOAD_WRITINGS_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: "company-writings.download.success.title",
      bodyKey: "company-writings.download.success.body",
    },
  } as const)
export const downloadWritingsFailureAction = (error: AxiosError) =>
  ({
    type: WritingsActionsEnum.DOWNLOAD_WRITINGS_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: error.name,
      bodyKey: error.message,
    },
  } as const)
export const setWritingsPerFiscalYearAction = (payload: NotifPerFiscalYear) =>
  ({
    type: WritingsActionsEnum.SET_WRITINGS_PER_FISCALYEAR,
    payload,
  } as const)
export const hasSeenWritings = (prevOrNext: string) =>
  ({
    type: WritingsActionsEnum.HAS_SEEN_WRITINGS,
    withToast: true,
    toasterType: "info",
    message: {
      titleKey: `writings.notifs.toaster.title`,
      bodyKey: `writings.notifs.toaster.${prevOrNext}-years`,
    },
  } as const)

export const saveDocumentUrl = (
  url: string[],
  fullDocId: number,
  fiscalYearId: number
) =>
  ({
    type: WritingsActionsEnum.SAVE_DOCUMENT_URL,
    url,
    fullDocId,
    fiscalYearId,
  } as const)

export const getWritingArchiveDownloadHistoryAttempt = () =>
  ({
    type: WritingsActionsEnum.GET_WRITING_ARCHIVE_DOWNLOAD_HISTORY_ATTEMPT,
  } as const)

export const getWritingArchiveDownloadHistorySuccess = (
  download_history: Array<{
    email: string
    created_at: string
  }>,
  user_archive_generator: {
    email: string
    archive_generated_at: string
  },
  user_writing_validator: Array<{
    email: string
    writing_validated_at: string
    count_fd: number
  }>
) =>
  ({
    type: WritingsActionsEnum.GET_WRITING_ARCHIVE_DOWNLOAD_HISTORY_SUCCESS,
    download_history,
    user_archive_generator,
    user_writing_validator,
  } as const)

export const getWritingArchiveDownloadHistoryFailure = () =>
  ({
    type: WritingsActionsEnum.GET_WRITING_ARCHIVE_DOWNLOAD_HISTORY_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "writings.download.history.get-archive.error.title",
      bodyKey: "writings.download.history.get-archive.error.body",
    },
  } as const)

export const saveWritingArchiveDownloadEventAttempt = () =>
  ({
    type: WritingsActionsEnum.SAVE_WRITING_ARCHIVE_DOWNLOAD_EVENT_ATTEMPT,
  } as const)

export const saveWritingArchiveDownloadEventSuccess = () =>
  ({
    type: WritingsActionsEnum.SAVE_WRITING_ARCHIVE_DOWNLOAD_EVENT_SUCCESS,
  } as const)

export const saveWritingArchiveDownloadEventFailure = () =>
  ({
    type: WritingsActionsEnum.SAVE_WRITING_ARCHIVE_DOWNLOAD_EVENT_FAILURE,
  } as const)

export const retryApiImportForFullDocumentAttempt = () =>
  ({
    type: WritingsActionsEnum.RETRY_API_IMPORT_FOR_FULL_DOCUMENT_ATTEMPT,
  } as const)

export const retryApiImportForFullDocumentSuccess = ({
  fullDocumentId,
  accountingSoftware,
}: {
  fullDocumentId: number
  accountingSoftware: "pennylane" | "acd"
}) =>
  ({
    type: WritingsActionsEnum.RETRY_API_IMPORT_FOR_FULL_DOCUMENT_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: "api-status.retry.success.title",
      bodyKey: "api-status.retry.success.body",
    },
    payload: {
      fullDocumentId,
      accountingSoftware,
    },
  } as const)

export const retryApiImportForFullDocumentFailure = () =>
  ({
    type: WritingsActionsEnum.RETRY_API_IMPORT_FOR_FULL_DOCUMENT_FAILURE,
  } as const)

export const getWritingArchiveDetailsAttempt = () =>
  ({
    type: WritingsActionsEnum.GET_WRITING_ARCHIVE_DETAILS_ATTEMPT,
  } as const)
export const getWritingArchiveDetailsSuccess = (
  details: (ArchiveDetail | ArchiveDetailWithApiStatus)[]
) =>
  ({
    type: WritingsActionsEnum.GET_WRITING_ARCHIVE_DETAILS_SUCCESS,
    details,
  } as const)
export const getWritingArchiveDetailsFailure = () =>
  ({
    type: WritingsActionsEnum.GET_WRITING_ARCHIVE_DETAILS_FAILURE,
  } as const)

export const RegenerateArchiveAttempt = () =>
  ({
    type: WritingsActionsEnum.REGENERATE_ARCHIVE_ATTEMPT,
  } as const)
export const RegenerateArchiveSuccess = () =>
  ({
    type: WritingsActionsEnum.REGENERATE_ARCHIVE_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: "writing-archives.regenerate.success.title",
      bodyKey: "writing-archives.regenerate.success.message",
    },
  } as const)
export const RegenerateArchiveFailure = () =>
  ({
    type: WritingsActionsEnum.REGENERATE_ARCHIVE_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "writing-archives.regenerate.error.title",
      bodyKey: "writing-archives.regenerate.error.message",
    },
  } as const)

export const ValidateWritingAttempt = () =>
  ({
    type: WritingsActionsEnum.VALIDATE_WRITING_ATTEMPT,
  } as const)
export const ValidateWritingSuccess = () =>
  ({
    type: WritingsActionsEnum.VALIDATE_WRITING_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: "writings.validation-writing.tooltip.success.title",
      bodyKey: "writings.validation-writing.tooltip.success.message",
    },
  } as const)
export const ValidateWritingFailure = () =>
  ({
    type: WritingsActionsEnum.VALIDATE_WRITING_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "writings.validation-writing.tooltip.error.title",
      bodyKey: "writings.validation-writing.tooltip.error.message",
    },
  } as const)
export const ValidateWritingReset = () =>
  ({
    type: WritingsActionsEnum.VALIDATE_WRITING_RESET,
  } as const)

export const DeleteArchiveAttempt = () =>
  ({
    type: WritingsActionsEnum.DELETE_ARCHIVE_ATTEMPT,
  } as const)
export const DeleteArchiveSuccess = () =>
  ({
    type: WritingsActionsEnum.DELETE_ARCHIVE_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: "writings.delete.tooltip.success.title",
      bodyKey: "writings.delete.tooltip.success.body",
    },
  } as const)
export const DeleteArchiveFailure = () =>
  ({
    type: WritingsActionsEnum.DELETE_ARCHIVE_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "writings.delete.tooltip.error.title",
      bodyKey: "writings.delete.tooltip.error.body",
    },
  } as const)
export const DeleteArchiveReset = () =>
  ({
    type: WritingsActionsEnum.DELETE_ARCHIVE_RESET,
  } as const)

type WritingsActionsType = ReturnType<
  | typeof getWritingsAttemptAction
  | typeof getWritingsSuccessAction
  | typeof getWritingsFailureAction
  | typeof createWritingsAttemptAction
  | typeof createWritingsSuccessAction
  | typeof createWritingsFailureAction
  | typeof createWritingsResetAction
  | typeof downloadWritingsAttemptAction
  | typeof downloadWritingsSuccessAction
  | typeof downloadWritingsFailureAction
  | typeof setWritingsPerFiscalYearAction
  | typeof hasSeenWritings
  | typeof saveDocumentUrl
  | typeof getWritingArchiveDownloadHistoryAttempt
  | typeof getWritingArchiveDownloadHistorySuccess
  | typeof getWritingArchiveDownloadHistoryFailure
  | typeof saveWritingArchiveDownloadEventAttempt
  | typeof saveWritingArchiveDownloadEventSuccess
  | typeof saveWritingArchiveDownloadEventFailure
  | typeof getWritingArchiveDetailsAttempt
  | typeof getWritingArchiveDetailsSuccess
  | typeof getWritingArchiveDetailsFailure
  | typeof RegenerateArchiveAttempt
  | typeof RegenerateArchiveSuccess
  | typeof RegenerateArchiveFailure
  | typeof ValidateWritingAttempt
  | typeof ValidateWritingSuccess
  | typeof ValidateWritingFailure
  | typeof ValidateWritingReset
  | typeof retryApiImportForFullDocumentAttempt
  | typeof retryApiImportForFullDocumentSuccess
  | typeof retryApiImportForFullDocumentFailure
  | typeof DeleteArchiveAttempt
  | typeof DeleteArchiveSuccess
  | typeof DeleteArchiveFailure
  | typeof DeleteArchiveReset
>

export const writingsInitialState: WritingsState = {
  data: {},
  notifsPerFiscalYear: {},
  hasSeenNotifsWritings: false,
  deactivatedReasons: {
    deactivated_reasons: {},
    user_deactivated_reasons: {},
  },
  writingLoadingStatus: "idle",
  pendingFullDocumentStatus: "idle",
  downloadHistoryForCurrentArchive: [],
  userArchiveGenerator: {
    email: "",
    archive_generated_at: "",
  },
  user_writing_validator: [],
  detailsArchive: [],
  writingValidationStatus: "idle",
  deleteArchiveStatus: "idle",
}

export interface ArchiveDetail {
  batch_id: number
  full_doc_id: null | number
  writing_archive_id: null | number
  creation_date: string
  full_doc_name: string
  original_file_name: string
  email: string
}

export type ArchiveDetailWithApiStatus =
  | ArchiveDetailWithPennylaneApiStatus
  | ArchiveDetailWithACD_ApiStatus

export interface ArchiveDetailWithPennylaneApiStatus {
  batch_id: number
  full_doc_id: null | number
  writing_archive_id: null | number
  creation_date: string
  full_doc_name: string
  original_file_name: string
  email: string
  acd_import_status: null
  acd_import_error_message: null
  pennylane_import_status: ApiImportStatus
  pennylane_import_error_message: string
}

export interface ArchiveDetailWithACD_ApiStatus {
  batch_id: number
  full_doc_id: null | number
  writing_archive_id: null | number
  creation_date: string
  full_doc_name: string
  original_file_name: string
  email: string
  acd_import_status: ApiImportStatus
  acd_import_error_message: string
  pennylane_import_status: null
  pennylane_import_error_message: null
}

export const isArchiveDetailWithApiStatus = (
  legalEntityLine: ArchiveDetail | ArchiveDetailWithApiStatus
): legalEntityLine is ArchiveDetailWithApiStatus => {
  const statuses = ["in_progress", "imported", "import_error"]
  return (
    statuses.includes(
      (legalEntityLine as ArchiveDetailWithApiStatus)?.acd_import_status || ""
    ) ||
    statuses.includes(
      (legalEntityLine as ArchiveDetailWithApiStatus)
        ?.pennylane_import_status || ""
    )
  )
}
export const isArchiveDetailWithPennylaneApiStatus = (
  legalEntityLine: ArchiveDetail | ArchiveDetailWithApiStatus
): legalEntityLine is ArchiveDetailWithPennylaneApiStatus => {
  const statuses = ["in_progress", "imported", "import_error"]
  return statuses.includes(
    (legalEntityLine as ArchiveDetailWithApiStatus)?.pennylane_import_status ||
      ""
  )
}
export const isArchiveDetailWithACD_ApiStatus = (
  legalEntityLine: ArchiveDetail | ArchiveDetailWithApiStatus
): legalEntityLine is ArchiveDetailWithACD_ApiStatus => {
  const statuses = ["in_progress", "imported", "import_error"]
  return statuses.includes(
    (legalEntityLine as ArchiveDetailWithApiStatus)?.acd_import_status || ""
  )
}

export type ApiImportStatus = "in_progress" | "imported" | "import_error"

export interface Archives {
  archives_created: Array<WritingArchive>
  archives_generated: Array<WritingArchive>
  ready_for_archive: {
    count_batchs: number
    count_full_docs: number
  }
  batches_pending: number
  batches_pending_progression_status: number
  batches_in_writing: number
  writing_ids: number[]
}

export interface Writing extends Archives {
  generatedStatus: "idle" | "loading" | "success"
  pending_full_documents_with_lines: PendingFullDocumentsWithLinesGrouped
}

export interface WritingsIndex {
  [index: number]: Writing
}

export interface WritingsState {
  data: WritingsIndex
  notifsPerFiscalYear: NotifPerFiscalYear
  hasSeenNotifsWritings: boolean
  deactivatedReasons: {
    deactivated_reasons: {
      [id: string]: string
    }
    user_deactivated_reasons: DeactivatedReasonType
  }
  writingLoadingStatus: "idle" | "loading" | "success"
  pendingFullDocumentStatus: "idle" | "loading" | "success"
  downloadHistoryForCurrentArchive: Array<{
    email: string
    created_at: string
  }>
  userArchiveGenerator: {
    email: string
    archive_generated_at: string
  }
  user_writing_validator: Array<{
    email: string
    writing_validated_at: string
    count_fd: number
  }>
  detailsArchive: Array<ArchiveDetail | ArchiveDetailWithApiStatus>
  writingValidationStatus: "idle" | "loading" | "success" | "error"
  deleteArchiveStatus: "idle" | "loading" | "success" | "error"
}

export interface PendingFullDocumentsWithLinesGrouped {
  processed: { [fullDocumentId: string]: PendingFullDocumentProcessed[] }
  deactivated: { [fullDocumentId: string]: PendingFullDocumentDeactivated }
}

export function writingsReducer(
  state = writingsInitialState,
  action: WritingsActionsType | FullDocumentsActionsType
): WritingsState {
  switch (action.type) {
    case WritingsActionsEnum.GET_WRITINGS_ATTEMPT:
      return {
        ...state,
        writingLoadingStatus: "loading",
      }
    case WritingsActionsEnum.GET_WRITINGS_SUCCESS: {
      const updatedState = {
        ...state,
        writingLoadingStatus: "success",
      }
      const archiveLens = R.lensPath(["data", action.fiscalYearId])
      return R.set(
        archiveLens,
        {
          ...state.data[action.fiscalYearId],
          ...action.archives,
        },
        updatedState
      )
    }
    case WritingsActionsEnum.CREATE_WRITINGS_ATTEMPT: {
      const generatedStatusLens = R.lensPath([
        "data",
        action.fiscalYearId,
        "generatedStatus",
      ])
      return R.set(generatedStatusLens, "loading", state)
    }
    case WritingsActionsEnum.CREATE_WRITINGS_SUCCESS: {
      const generatedStatusLens = R.lensPath([
        "data",
        action.fiscalYearId,
        "generatedStatus",
      ])
      return R.set(generatedStatusLens, "success", state)
    }
    case WritingsActionsEnum.CREATE_WRITINGS_RESET: {
      const generatedStatusLens = R.lensPath([
        "data",
        action.fiscalYearId,
        "generatedStatus",
      ])
      return R.set(generatedStatusLens, "idle", state)
    }
    case WritingsActionsEnum.CREATE_WRITINGS_FAILURE: {
      const generatedStatusLens = R.lensPath([
        "data",
        action.fiscalYearId,
        "generatedStatus",
      ])
      return R.set(generatedStatusLens, "idle", state)
    }
    case WritingsActionsEnum.DOWNLOAD_WRITINGS_ATTEMPT:
      return { ...state }
    case FullDocumentsActionsEnum.GET_FULL_DOCUMENTS_OF_WRITING_SUCCESS: {
      const fullDocsLens = R.lensPath([
        "data",
        action.payload.fiscalYearId,
        "full_documents_by_archive",
        action.payload.writingArchiveId,
      ])
      return R.set(fullDocsLens, action.payload.fullDocuments, state)
    }
    case FullDocumentsActionsEnum.GET_PENDING_FULL_DOCUMENTS_SUCCESS: {
      const fullDocsLens = R.lensPath([
        "data",
        action.fiscalYearId,
        "pending_full_documents",
      ])
      return R.set(fullDocsLens, action.payload, state)
    }
    case WritingsActionsEnum.SET_WRITINGS_PER_FISCALYEAR:
      return {
        ...state,
        notifsPerFiscalYear: action.payload,
      }
    case WritingsActionsEnum.HAS_SEEN_WRITINGS:
      return {
        ...state,
        hasSeenNotifsWritings: true,
      }

    case FullDocumentsActionsEnum.GET_DEACTIVATED_REASONS_SUCCESS: {
      return {
        ...state,
        deactivatedReasons: action.payload,
      }
    }

    case WritingsActionsEnum.RETRY_API_IMPORT_FOR_FULL_DOCUMENT_SUCCESS: {
      return {
        ...state,
        detailsArchive: state.detailsArchive.map((d) => {
          if (d.full_doc_id === action.payload.fullDocumentId) {
            return action.payload.accountingSoftware === "pennylane"
              ? {
                  ...d,
                  pennylane_import_status: "in_progress",
                  pennylane_import_error_message: "",
                }
              : action.payload.accountingSoftware === "acd"
              ? {
                  ...d,
                  acd_import_status: "in_progress",
                  acd_import_error_message: "",
                }
              : { ...d }
          }
          return { ...d }
        }),
      }
    }

    case WritingsActionsEnum.SAVE_DOCUMENT_URL: {
      const deactivated = {
        ...state.data[action.fiscalYearId].pending_full_documents_with_lines
          .deactivated[action.fullDocId],
        documents_url: action.url,
      }

      const fullDocsLens = R.lensPath([
        "data",
        action.fiscalYearId,
        "pending_full_documents_with_lines",
        "deactivated",
        action.fullDocId,
      ])
      return R.set(fullDocsLens, deactivated, { ...state })
    }
    case WritingsActionsEnum.GET_WRITING_ARCHIVE_DOWNLOAD_HISTORY_SUCCESS:
      return {
        ...state,
        downloadHistoryForCurrentArchive: action.download_history,
        userArchiveGenerator: action.user_archive_generator,
        user_writing_validator: action.user_writing_validator,
      }
    case WritingsActionsEnum.GET_WRITING_ARCHIVE_DETAILS_ATTEMPT:
      return {
        ...state,
        detailsArchive: [],
      }
    case WritingsActionsEnum.GET_WRITING_ARCHIVE_DETAILS_SUCCESS:
      return {
        ...state,
        detailsArchive: action.details,
      }
    case WritingsActionsEnum.GET_WRITING_ARCHIVE_DETAILS_FAILURE:
      return {
        ...state,
        detailsArchive: [],
      }
    case WritingsActionsEnum.VALIDATE_WRITING_ATTEMPT:
      return {
        ...state,
        writingValidationStatus: "loading",
      }
    case WritingsActionsEnum.VALIDATE_WRITING_SUCCESS:
      return {
        ...state,
        writingValidationStatus: "success",
      }
    case WritingsActionsEnum.VALIDATE_WRITING_FAILURE:
      return {
        ...state,
        writingValidationStatus: "error",
      }
    case WritingsActionsEnum.VALIDATE_WRITING_RESET:
      return {
        ...state,
        writingValidationStatus: "idle",
      }
    case WritingsActionsEnum.DELETE_ARCHIVE_ATTEMPT:
      return {
        ...state,
        deleteArchiveStatus: "loading",
      }
    case WritingsActionsEnum.DELETE_ARCHIVE_SUCCESS:
      return {
        ...state,
        deleteArchiveStatus: "success",
      }
    case WritingsActionsEnum.DELETE_ARCHIVE_FAILURE:
      return {
        ...state,
        deleteArchiveStatus: "error",
      }
    case WritingsActionsEnum.DELETE_ARCHIVE_RESET:
      return {
        ...state,
        deleteArchiveStatus: "idle",
      }
    default:
      return { ...state }
  }
}

export interface ApiImportInformations {
  imported_number: number
  import_error_number: number
  import_in_progress_number: number
  total: number
}

export interface WritingArchive {
  id: number
  downloaded_at: string
  download_user: string
  api_import_status: null | "in_progress" | "imported" | "import_error"
  api_import_date: null | string
  count_batchs: number
  count_full_docs: number
  deactivated_full_documents_count: number
  processed_full_documents_count: number
  coala_payloads?: {
    imported_number: number
    import_error_number: number
    in_progress_number: number
    total: number
  }
  inqom_import_full_document_status?: {
    imported_number: number
    import_error_number: number
    import_in_progress_number: number
    total: number
  }
  pennylane_import_full_document_status?: {
    imported_number: number
    import_error_number: number
    import_in_progress_number: number
    total: number
  }
  acd_import_full_document_status?: {
    imported_number: number
    import_error_number: number
    import_in_progress_number: number
    total: number
  }
}

interface NotifPerFiscalYear {
  [fiscalYearId: number]: boolean
}

interface GetWritingArchivesPayload {
  companyId: number
  fiscalYearId: number
}

export const getAllWritingArchivesThunk =
  ({ companyId, fiscalYearId }: GetWritingArchivesPayload) =>
  (dispatch: Dispatch<WritingsActionsType>, getState: () => RootState) => {
    const fiduciaryId = getState().fiduciary.id
    const fiscalYears = getState().fiscalYears.fiscalYearsByCompanyId[companyId]
    dispatch(getWritingsAttemptAction(fiscalYearId))
    const requestURIs = fiscalYears.map(({ id: fiscalYearId }) => {
      return {
        endpoint: `/fiduciaries/${fiduciaryId}/companies/${companyId}/fiscal_years/${fiscalYearId}/writing_archives_of_fiscal_year`,
        fiscalYearId,
      }
    })

    return axios
      .all(
        requestURIs.map(({ endpoint, fiscalYearId }) =>
          axios.get<Archives>(endpoint, {
            params: { company_id: companyId, fiscal_year_id: fiscalYearId },
          })
        )
      )
      .then((responses) => {
        const notificationsPerFY = responses.reduce((acc, response) => {
          const fiscalYearOfResponse = response.config.params.fiscal_year_id
          dispatch(
            getWritingsSuccessAction(response.data, fiscalYearOfResponse)
          )
          const new_archives_generated = response.data.archives_generated.some(
            (a) => !a.downloaded_at
          )
          const notifications =
            !!new_archives_generated ||
            response.data.ready_for_archive.count_batchs > 0
          return {
            ...acc,
            [fiscalYearOfResponse]: notifications,
          }
        }, {} as NotifPerFiscalYear)
        dispatch(setWritingsPerFiscalYearAction(notificationsPerFY))
      })
      .catch((e: AxiosError) => {
        dispatch(getWritingsFailureAction(e, fiscalYearId))
      })
  }

export const getWritingArchivesThunk =
  ({ companyId, fiscalYearId }: GetWritingArchivesPayload) =>
  (dispatch: Dispatch<WritingsActionsType>, getState: () => RootState) => {
    const fiduciaryId = getState().fiduciary.id
    const requestURI = `/fiduciaries/${fiduciaryId}/companies/${companyId}/fiscal_years/${fiscalYearId}/writing_archives_of_fiscal_year`
    dispatch(getWritingsAttemptAction(fiscalYearId))
    return axios
      .get<Archives>(requestURI, {
        params: { company_id: companyId, fiscal_year_id: fiscalYearId },
      })
      .then(({ data }) => {
        dispatch(getWritingsSuccessAction(data, fiscalYearId))
      })
      .catch((e: AxiosError) => {
        dispatch(getWritingsFailureAction(e, fiscalYearId))
      })
  }

export const createWritingArchiveThunk =
  (fiscalYearId: number, selectedCompanyId: number, sendToApi = true) =>
  (dispatch: Dispatch<WritingsActionsType | RNBThunkAction>) => {
    const requestURI = `/writing_archives/create_archive`
    const params = {
      fiscal_year_id: fiscalYearId,
      send_to_api: sendToApi,
    }
    dispatch(createWritingsAttemptAction(fiscalYearId))
    return axios
      .post<{ id: number }>(requestURI, params)
      .then(() => {
        dispatch(createWritingsSuccessAction(fiscalYearId))
        dispatch(
          getAllWritingArchivesThunk({
            companyId: selectedCompanyId,
            fiscalYearId: fiscalYearId,
          })
        )
      })
      .catch((e: AxiosError) => {
        dispatch(createWritingsFailureAction(e, fiscalYearId))
      })
  }

const PrepareFileDownload = (
  fileLink: string,
  companyName: string,
  archiveId: number,
  accountSoftwareReference: string,
  accountSoftware: string
) => {
  return axios
    .get<Blob>(fileLink, { responseType: "blob", withCredentials: false })
    .then((response) => {
      const blob = new Blob([response.data])
      const downloadElement = document.createElement("a")
      const href = window.URL.createObjectURL(blob)
      downloadElement.href = href
      if (
        accountSoftwareReference &&
        (accountSoftware === "cegid" || accountSoftware === "quadratus")
      ) {
        downloadElement.download = `${accountSoftwareReference}_${archiveId}.zip`
      } else {
        downloadElement.download = `${companyName} Archive N${archiveId}.zip`
      }
      document.body.appendChild(downloadElement)
      downloadElement.click()
      document.body.removeChild(downloadElement)
      window.URL.revokeObjectURL(href)
    })
}

interface ArchiveRequestPayload {
  s3_url: string
  company_name: string
}

export const downloadWritingThunk =
  (
    archiveId: number,
    companyId: number,
    accountSoftwareReference?: string,
    accountSoftware?: string
  ) =>
  (dispatch: Dispatch<WritingsActionsType>) => {
    dispatch(downloadWritingsAttemptAction())
    const requestURI = "/writing_archives/download_url"
    const params = { id: archiveId, company_id: companyId }
    return axios
      .post<ArchiveRequestPayload>(requestURI, params, {
        withCredentials: false,
      })
      .then((response) => {
        PrepareFileDownload(
          response.data["s3_url"],
          response.data["company_name"].split("||")[0],
          archiveId,
          accountSoftwareReference || "",
          accountSoftware || ""
        )
        dispatch(downloadWritingsSuccessAction())
      })
      .catch((e: AxiosError) => {
        dispatch(downloadWritingsFailureAction(e))
      })
  }

export const createWritingArchiveDownloadEvent =
  (archiveId: number, userId: number) =>
  (dispatch: Dispatch<WritingsActionsType>) => {
    dispatch(saveWritingArchiveDownloadEventAttempt())
    const requestURI = `/writing_archives/create_writing_archive_download_event`
    const params = { archiveId: archiveId, userId: userId }

    return axios
      .post(requestURI, params, {
        withCredentials: false,
      })
      .then(() => {
        dispatch(saveWritingArchiveDownloadEventSuccess())
      })
      .catch(() => {
        dispatch(saveWritingArchiveDownloadEventFailure())
      })
  }

export const retryApiImportForFullDocumentThunk =
  ({
    fullDocumentId,
    accountingSoftware,
  }: {
    fullDocumentId: number
    accountingSoftware: "pennylane" | "acd"
  }) =>
  (dispatch: Dispatch<WritingsActionsType>) => {
    dispatch(retryApiImportForFullDocumentAttempt())
    const requestURI = `/full_documents/${fullDocumentId}/retry_api_import`

    return axios
      .post(
        requestURI,
        {
          accounting_software: accountingSoftware,
        },
        {
          withCredentials: false,
        }
      )
      .then(() => {
        dispatch(
          retryApiImportForFullDocumentSuccess({
            fullDocumentId,
            accountingSoftware,
          })
        )
      })
      .catch(() => {
        dispatch(retryApiImportForFullDocumentFailure())
      })
  }

export const getWritingArchiveDownloadHistory =
  (archiveId: number) => (dispatch: Dispatch<WritingsActionsType>) => {
    dispatch(getWritingArchiveDownloadHistoryAttempt())
    const requestURI = `/writing_archives/get_writing_archive_download_history`
    return axios
      .get<{
        download_history: Array<{
          email: string
          created_at: string
        }>
        user_archive_generator: {
          email: string
          archive_generated_at: string
        }
        user_writing_validator: Array<{
          email: string
          writing_validated_at: string
          count_fd: number
        }>
      }>(requestURI, {
        params: { archiveId: archiveId },
      })
      .then((response) => {
        dispatch(
          getWritingArchiveDownloadHistorySuccess(
            response.data.download_history,
            response.data.user_archive_generator,
            response.data.user_writing_validator
          )
        )
      })
      .catch(() => {
        dispatch(getWritingArchiveDownloadHistoryFailure())
      })
  }

export const getWritingArchiveDetailsThunk =
  (
    fiscalYearId: number,
    companyId: number,
    writing_archive_id: number | null,
    type?: "PENDING" | "WRITING_READY" | "READY_FOR_ARCHIVE"
  ) =>
  (dispatch: Dispatch<WritingsActionsType>, getState: () => RootState) => {
    dispatch(getWritingArchiveDetailsAttempt())
    const fiduciaryId = getState().fiduciary.id
    const requestURI = `/fiduciaries/${fiduciaryId}/companies/${companyId}/fiscal_years/${fiscalYearId}/writing_archive_detail_of_fiscal_year`
    return axios
      .get<(ArchiveDetail | ArchiveDetailWithApiStatus)[]>(requestURI, {
        params: {
          company_id: companyId,
          fiscal_year_id: fiscalYearId,
          writing_archive_id,
          type,
        },
      })
      .then((response) => {
        dispatch(getWritingArchiveDetailsSuccess(response.data))
      })
      .catch(() => {
        dispatch(getWritingArchiveDetailsFailure())
      })
  }

export const RegenerateArchiveThunk =
  (
    archiveId: number,
    companyId: number,
    fiscalYearId: number,
    sendToApi = true
  ) =>
  (dispatch: Dispatch<WritingsActionsType | RNBThunkAction>) => {
    dispatch(RegenerateArchiveAttempt())
    return axios
      .post(`/writing_archives/regenerate`, {
        archive_id: archiveId,
        send_to_api: sendToApi,
      })
      .then(() => {
        dispatch(RegenerateArchiveSuccess())
        dispatch(getAllWritingArchivesThunk({ companyId, fiscalYearId }))
      })
      .catch(() => {
        dispatch(RegenerateArchiveFailure())
      })
  }

export const DeleteArchiveThunk =
  (archiveId: number, companyId: number, fiscalYearId: number) =>
  (dispatch: Dispatch<WritingsActionsType | RNBThunkAction>) => {
    dispatch(DeleteArchiveAttempt())
    return axios
      .delete(`/writing_archives/delete_archive`, {
        params: {
          archive_id: archiveId,
        },
      })
      .then(() => {
        dispatch(DeleteArchiveSuccess())
        dispatch(getAllWritingArchivesThunk({ companyId, fiscalYearId }))
      })
      .catch(() => {
        dispatch(DeleteArchiveFailure())
      })
  }

export const ValidateWritingThunk =
  (companyId: number, writing_ids: number[], fiscalYearId: number) =>
  (dispatch: Dispatch<WritingsActionsType | RNBThunkAction>) => {
    dispatch(ValidateWritingAttempt())
    return axios
      .post(`/companies/validate_writing`, {
        id: companyId,
        writing_ids: writing_ids,
      })
      .then(() => {
        dispatch(ValidateWritingSuccess())
        dispatch(getAllWritingArchivesThunk({ companyId, fiscalYearId }))
      })
      .catch(() => {
        dispatch(ValidateWritingFailure())
      })
  }
