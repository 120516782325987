export enum ComptaSoftwareOptionsEnum {
  "fulll" = "Fulll",
  "loop" = "Loop",
  "cegid" = "Cegid",
  "coala" = "Coala",
  "quadratus" = "Quadratus",
  "isacompta" = "Isacompta",
  "my_unisoft" = "myUnisoft",
  "tiime" = "Tiime",
  "ciel" = "Ciel (Sage 50)",
  "sage" = "Sage 100",
  "quickbooks" = "Quickbooks",
  "ebp" = "EBP",
  "acl" = "ACL",
  "acd" = "ACD",
  "revor" = "Revor",
  "cegid_11" = "CEGID 11",
  "teogest" = "Teogest",
  "netexcom" = "Netexcom",
  "inqom" = "Inqom",
  "pennylane" = "Pennylane",
  "extrabat" = "Extrabat",
  "unknown" = "Je ne sais pas",
}

export enum DiscoveryOptionsEnum {
  "linkedin" = "Linkedin",
  "facebook" = "Facebook",
  "twitter" = "Twitter",
  "website" = "Site d'informations",
  "google" = "Recherche google",
  "word_of_mouth" = "Bouche à oreille",
  "congress" = "Congrès ou salon",
  "outbound" = "Démarchage commercial",
}

export enum LocalisationOptionsEnum {
  "metropolitan_france" = "France métropolitaine",
  "guadeloupe_martinique_reunion" = "Guadeloupe, Martinique ou Réunion",
  "other" = "Autres",
}
