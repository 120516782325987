/* eslint-disable camelcase */
import { Modal, Card, ModalComponentsProps } from "ldlj"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import styled from "styled-components/macro"
import * as Ct from "ldlj"

import { ReactComponent as CloseCrossSVG } from "../../assets/close-cross.svg"
import {
  getInputMaskAccountsThunk,
  getInputMaskMerchantThunk,
  getInputMaskWritingThunk,
  setSelectedFullDocument,
  setSelectedPage,
} from "../../store/ducks/inputMask.ducks"
import { DocumentViewer } from "../documentsViewer/DocumentViewer"
import { FullDocumentPages } from "../documentsViewer/FullDocumentPages"
import { Content } from "./Content"
import { getIdFromParams } from "../../utils/company"
import { useParams } from "react-router-dom"
import { useRNBSelector } from "../../store/rootReducer"
import { colors } from "../../styles/design.config"
import {
  GetAllMerchantsOfCompanyForCodes,
  GetCompanyMerchantCodesDataThunk,
} from "../../store/ducks/merchantCodes.ducks"

interface InputMaskModalCommonParams extends ModalComponentsProps {
  fiscalYearId: number
}

function isEDMType(
  inputMask: InputMaskModalEDMParams | InputMaskModalWritingParams
): inputMask is InputMaskModalEDMParams {
  return (inputMask as InputMaskModalEDMParams).merchantId !== undefined
}

interface InputMaskModalEDMParams extends InputMaskModalCommonParams {
  merchantId: number
  fullDocumentId: number
}

interface InputMaskModalWritingParams extends InputMaskModalCommonParams {
  onCreateWritingArchive: () => void
  merchantId: undefined
}

type InputMaskModalParams =
  | InputMaskModalEDMParams
  | InputMaskModalWritingParams

export const InputMaskModal = (props: InputMaskModalParams) => {
  const isEdm = isEDMType(props)
  const dispatch = useDispatch()
  const selectedCompanyId = getIdFromParams(useParams())("company_id") || 0

  const {
    full_documents_to_check,
    edited_full_document,
    selected_company_name,
    loading_status,
    selectedFullDocument,
    selectedPage,
    selectedTab,
  } = useRNBSelector((state) => ({
    full_documents_to_check: state.inputMask.full_documents_to_check,
    edited_full_document: state.inputMask.edited_full_document,
    selected_company_name:
      state.companies.companies[selectedCompanyId]?.name || "",
    loading_status: state.inputMask.loading_status,
    selectedFullDocument: state.inputMask.selectedFullDocument,
    selectedPage: state.inputMask.selectedPage,
    selectedTab: state.inputMask.selectedTab,
  }))

  useEffect(() => {
    if (full_documents_to_check.length > 0) {
      if (edited_full_document === null && selectedTab === "processed") {
        const fullDocumentSelectedByDefault = full_documents_to_check[0]
        if (isEdm) {
          const fullDocumentClickedBeforeModal = full_documents_to_check.find(
            (fd) => fd.fd_id === props.fullDocumentId
          )
          dispatch(
            setSelectedFullDocument(
              fullDocumentClickedBeforeModal || fullDocumentSelectedByDefault
            )
          )
        } else {
          dispatch(setSelectedFullDocument(full_documents_to_check[0]))
        }
      }
    } else {
      dispatch(setSelectedFullDocument(null))
    }
  }, [full_documents_to_check, edited_full_document])

  useEffect(() => {
    dispatch(setSelectedPage(0))
  }, [full_documents_to_check, edited_full_document?.fd_id])

  useEffect(() => {
    if (!props.isDisplayed || !props.fiscalYearId || !selectedCompanyId) {
      return
    }

    if (isEdm) {
      dispatch(
        getInputMaskMerchantThunk({
          fiscalYearId: props.fiscalYearId,
          merchantId: props.merchantId,
          companyId: selectedCompanyId,
        })
      )
    } else {
      dispatch(
        getInputMaskWritingThunk({
          fiscalYearId: props.fiscalYearId,
          companyId: selectedCompanyId,
        })
      )
    }
    dispatch(getInputMaskAccountsThunk({ companyId: selectedCompanyId }))
    dispatch(GetCompanyMerchantCodesDataThunk(selectedCompanyId))
    dispatch(GetAllMerchantsOfCompanyForCodes(selectedCompanyId))
  }, [
    selectedCompanyId,
    props.fiscalYearId,
    props.merchantId,
    props.isDisplayed,
  ])

  return (
    <Modal
      isDisplayed={props.isDisplayed}
      onClose={props.onClose}
      top={"1vh"}
      left={"1vw"}
    >
      <StyledCard width="98vw" height="98vh">
        <ModalWrapper>
          <CrossWrapper onClick={() => props.onClose()}>
            <Ct.Text
              text={selected_company_name}
              textStyle={{
                fontSize: 1.5,
                fontFamily: "Poppins",
                fontWeight: 500,
                color: "rock",
              }}
            />

            <Ct.Spacer />
            <CloseCrossSVG />
          </CrossWrapper>
          {loading_status === "loading" ? (
            <Ct.SpinningLoader spinnersize={5} />
          ) : (
            <>
              <FullDocumentPages
                documents={
                  selectedFullDocument ? selectedFullDocument?.documents : []
                }
                selectedPage={selectedPage}
                onSelect={(page) => {
                  dispatch(setSelectedPage(page))
                }}
              />
              <Invoice>
                <DocumentViewer
                  documentId={
                    selectedFullDocument
                      ? selectedFullDocument?.documents[selectedPage]?.id
                      : 0
                  }
                  rectanglesToDraw={null}
                  isHighlighted={false}
                />
              </Invoice>
              <ContentWrapper>
                <Content
                  onCreateWritingArchive={
                    isEdm ? () => {} : props.onCreateWritingArchive
                  }
                  selectedFullDocument={selectedFullDocument}
                  isWriting={!isEdm}
                />
              </ContentWrapper>
            </>
          )}
        </ModalWrapper>
      </StyledCard>
    </Modal>
  )
}

const ModalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const Invoice = styled.div`
  align-self: flex-start;
  flex: 90rem 0 1;
  max-height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`

const ContentWrapper = styled.div`
  align-self: flex-start;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 2rem;
  padding-right: 4rem;
  margin-top: 3rem;
  overflow-y: scroll;
  height: calc(100% - 3rem);

  /* FIREFOX */
  scrollbar-color: ${colors.rock} transparent;
  scrollbar-width: thin !important;
  /* CHROME */
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${colors.rock};
    border-radius: 3px;
    width: 4px;
  }
`

const CrossWrapper = styled.div`
  display: flex;
  align-items: center;
  top: 2rem;
  right: 2rem;
  position: absolute;
  cursor: pointer;
`

const StyledCard = styled(Card)`
  padding: 2rem;
  padding-right: 0;
`
