import * as Ct from "ldlj"
import styled from "styled-components/macro"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useIntl } from "react-intl"

import { ReactComponent as CloseCross } from "../../assets/close-cross.svg"
import { ReactComponent as Warning } from "../../assets/warning.svg"
import { colors } from "../../styles/design.config"

import { capitalizeFirstLetter } from "../../utils/string"
import { removeIndex } from "../../utils/array"
import {
  addPrefixToCode,
  getBackgroundColorMcCode,
  getBackgroundColorMcOtherInput,
  normalizeMerchantCode,
  parsePastedContentToMerchantCodes,
} from "../../utils/merchantCodes"

import { useRNBSelector } from "../../store/rootReducer"
import { showDefaultWarningToaster } from "../../store/notifications.config"
import {
  addMerchantCodesTooManyLines,
  CreateMultipleMerchantCodesReset,
  createMultipleMerchantCodesThunk,
  MerchantCodeToCreate,
  optionType,
} from "../../store/ducks/merchantCodes.ducks"

import { TableWrapper } from "../Commons/TableStylesForSelect"
import { Table, TableBuilder } from "../Commons/Table"
import { MultiSelect } from "../Commons/MultiSelect"
import { Switch } from "../Commons/Switch"
import { Alert } from "../Commons/Alert"
import { Input } from "../Commons/Input"
import { Text } from "../Commons/Text"

/* eslint-disable camelcase */

interface CreateMerchantCodesProps {
  onClose: () => void
  isDisplayed: boolean
  companyId: number
}

export const CreateMerchantCodesModal = ({
  isDisplayed,
  onClose,
  companyId,
}: CreateMerchantCodesProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const emptyMerchantCode: MerchantCodeToCreate = {
    code: "",
    centralize: false,
    auxiliary: false,
    merchants: [],
    merchant_ids: [],
    default_buy_code: false,
    default_sell_code: false,
  }

  const company = useRNBSelector(
    (state) => state.companies.companies[companyId]
  )
  const merchantCodes = useRNBSelector(
    (state) => state.merchantCodes.merchantCodes
  )
  const merchantsOfCompany = useRNBSelector(
    (state) => state.merchantCodes.merchantsOfCompany
  )
  const createMultipleMerchantCodesStatus = useRNBSelector(
    (state) => state.merchantCodes.createMultipleMerchantCodesStatus
  )

  const [hoveredForDeletionIndex, setHoveredForDeletionIndex] = useState(-1)
  const [merchantCodesSet, setMerchantCodesSet] = useState<Set<string>>(
    new Set()
  )
  const [merchantCodesToCreate, setMerchantCodesToCreate] = useState<
    MerchantCodeToCreate[]
  >([emptyMerchantCode])
  const [prefixes, setPrefixes] = useState<{ buy: string; sell: string }>({
    buy: "",
    sell: "",
  })
  const [buyDefaultForCompany, setBuyDefaultForCompany] = useState<string>("")
  const [sellDefaultForCompany, setSellDefaultForCompany] = useState<string>("")
  const [hasMerchantCodesAlreadyCreated, setHasMerchantCodesAlreadyCreated] =
    useState(false)
  const [hasDuplicates, setHasDuplicates] = useState(false)

  const getMultiSelectOptions = (): Ct.Option<string>[] => {
    return merchantsOfCompany.map((m) => {
      return {
        value: String(m.id),
        label: m.code
          ? capitalizeFirstLetter(m.name) + (" / code: " + m.code)
          : capitalizeFirstLetter(m.name),
      }
    })
  }

  const updateMerchantCodeAddPotentialNewLine = (
    merchantCodes: MerchantCodeToCreate[]
  ) => {
    const isLastLineEmpty =
      JSON.stringify(merchantCodes[merchantCodes.length - 1]) ===
      JSON.stringify(emptyMerchantCode)

    if (isLastLineEmpty) {
      return merchantCodes
    }
    setHoveredForDeletionIndex(-1)
    return [...merchantCodes, emptyMerchantCode]
  }

  const disableSaveButton = () => {
    const mctc = merchantCodesToCreate.slice(0, -1)
    const mctc2 = mctc.filter((mc) => mc.code === "")
    return (
      mctc.length === 0 ||
      mctc2.length > 0 ||
      hasMerchantCodesAlreadyCreated ||
      hasDuplicates
    )
  }

  const handleFormSubmit = () => {
    const mctc = merchantCodesToCreate.filter((mctc) => mctc.code !== "")
    if (companyId) dispatch(createMultipleMerchantCodesThunk(companyId, mctc))
  }

  const resetForm = () => {
    setHoveredForDeletionIndex(-1)
    setMerchantCodesToCreate([emptyMerchantCode])
    setPrefixes({ buy: "", sell: "" })
    setBuyDefaultForCompany("")
    setSellDefaultForCompany("")
    dispatch(CreateMultipleMerchantCodesReset())
  }

  const closeModal = () => {
    resetForm()
    onClose()
  }

  const globalWidthContent = "200rem"
  const rowHeightInRem = 8
  const columns: TableBuilder<MerchantCodeToCreate>[] = [
    {
      headerText: "accounting-plan.create-codes.modal.code",
      width: "20rem",
      flexGrow: "none",
      content: (row: MerchantCodeToCreate, index: number) => {
        return (
          <BorderRight>
            <Input
              label=""
              minWidth="18rem"
              maxWidth="20rem"
              height={rowHeightInRem + "rem"}
              maxHeight={"100%"}
              value={row.code}
              borderRadius={0}
              autoFocus={index === 0}
              placeholder={
                index === merchantCodesToCreate.length - 1
                  ? intl.formatMessage({
                      id: "accounting-plan.create-codes.modal.paste.format",
                    })
                  : ``
              }
              placeholderColor={colors.rock}
              backgroundColor={getBackgroundColorMcCode(
                merchantCodesToCreate,
                merchantCodesSet,
                hoveredForDeletionIndex,
                index,
                row
              )}
              borderColor={"transparent"}
              onPaste={(e) => {
                const pastedData = e.clipboardData.getData("Text")
                if (pastedData.length > 1 && pastedData.includes("\n")) {
                  e.preventDefault()
                  const pastedMerchantCodes =
                    parsePastedContentToMerchantCodes(pastedData)
                  const maximumNumberOfMerchantCodeToAdd = 1000
                  if (
                    pastedMerchantCodes.length >
                    maximumNumberOfMerchantCodeToAdd
                  ) {
                    dispatch(addMerchantCodesTooManyLines())
                  } else {
                    setMerchantCodesToCreate(
                      updateMerchantCodeAddPotentialNewLine([
                        ...merchantCodesToCreate.filter(
                          (act) => act.code !== ""
                        ),
                        ...pastedMerchantCodes,
                      ])
                    )
                  }
                }
              }}
              onChange={(e) => {
                const index = merchantCodesToCreate.indexOf(row)
                const updatedMerchantCodes = [...merchantCodesToCreate]

                updatedMerchantCodes[index] = {
                  ...row,
                  code: normalizeMerchantCode(e.target.value),
                }

                setMerchantCodesToCreate(
                  updateMerchantCodeAddPotentialNewLine(updatedMerchantCodes)
                )
              }}
            />
          </BorderRight>
        )
      },
    },
    {
      headerText: "accounting-plan.create-codes.modal.column.buy",
      width: "25rem",
      flexGrow: "none",
      content: (row: MerchantCodeToCreate, index: number) => {
        return (
          <BorderRight>
            <Input
              label=""
              minWidth="23rem"
              maxWidth="25rem"
              height={rowHeightInRem + "rem"}
              maxHeight={"100%"}
              value={row.code}
              cursor={"default"}
              prefixString={prefixes.buy}
              padding={
                prefixes.buy && prefixes.buy.length > 0
                  ? `0 1.75rem 0 ${prefixes.buy.length + 2.5}rem`
                  : "0 1.75rem"
              }
              borderRadius={0}
              placeholderColor={colors.rock}
              backgroundColor={getBackgroundColorMcOtherInput(
                merchantCodesToCreate,
                hoveredForDeletionIndex,
                index,
                row,
                colors.grey
              )}
              borderColor={"transparent"}
              readOnly={true}
            />
          </BorderRight>
        )
      },
    },
    {
      headerText: "accounting-plan.create-codes.modal.column.sell",
      width: "25rem",
      flexGrow: "none",
      content: (row: MerchantCodeToCreate, index: number) => {
        return (
          <BorderRight>
            <Input
              label=""
              minWidth="23rem"
              maxWidth="25rem"
              height={rowHeightInRem + "rem"}
              maxHeight={"100%"}
              value={row.code}
              cursor={"default"}
              prefixString={prefixes.sell}
              padding={
                prefixes.sell && prefixes.sell.length > 0
                  ? `0 1.75rem 0 ${prefixes.sell.length + 2.5}rem`
                  : "0 1.75rem"
              }
              borderRadius={0}
              placeholderColor={colors.rock}
              backgroundColor={getBackgroundColorMcOtherInput(
                merchantCodesToCreate,
                hoveredForDeletionIndex,
                index,
                row,
                colors.grey
              )}
              borderColor={"transparent"}
              readOnly={true}
            />
          </BorderRight>
        )
      },
    },
    {
      headerText: "accounting-plan.create-codes.modal.column.group",
      width: "20rem",
      flexGrow: "none",
      content: (row: MerchantCodeToCreate, index: number) => {
        return (
          <BorderRight
            backgroundColor={getBackgroundColorMcOtherInput(
              merchantCodesToCreate,
              hoveredForDeletionIndex,
              index,
              row,
              colors.white
            )}
          >
            <SwitchWrapper>
              <Ct.Switch
                value={row.centralize}
                onToggle={() => {
                  const index = merchantCodesToCreate.indexOf(row)
                  const updatedMerchantCodes = [...merchantCodesToCreate]

                  updatedMerchantCodes[index] = {
                    ...row,
                    centralize: !row.centralize,
                    merchants: !row.centralize ? row.merchants : [],
                    merchant_ids: !row.centralize ? row.merchant_ids : [],
                  }

                  setMerchantCodesToCreate(
                    updateMerchantCodeAddPotentialNewLine(updatedMerchantCodes)
                  )
                }}
              />
            </SwitchWrapper>
          </BorderRight>
        )
      },
    },
    {
      headerText: "accounting-plan.create-codes.modal.column.merchants",
      width: "80rem",
      flexGrow: "",
      content: (row: MerchantCodeToCreate, index: number) => {
        return (
          <BorderRight
            backgroundColor={getBackgroundColorMcOtherInput(
              merchantCodesToCreate,
              hoveredForDeletionIndex,
              index,
              row,
              colors.white
            )}
          >
            <MultiSelect
              intl={intl}
              field={{}}
              options={getMultiSelectOptions()}
              value={row.merchants}
              label={"merchants"}
              onChange={(newValue) => {
                const index = merchantCodesToCreate.indexOf(row)
                const updatedMerchantCodes = [...merchantCodesToCreate]

                if (row.centralize || newValue.length <= 1) {
                  const rowMerchantIds = newValue.map((e) => Number(e.value))
                  for (const i of merchantCodesToCreate.keys()) {
                    merchantCodesToCreate[i].merchants = merchantCodesToCreate[
                      i
                    ].merchants.filter(
                      (e) => !rowMerchantIds.includes(Number(e.value))
                    )
                    merchantCodesToCreate[i].merchant_ids =
                      merchantCodesToCreate[i].merchant_ids.filter(
                        (e) => !rowMerchantIds.includes(Number(e))
                      )
                  }

                  updatedMerchantCodes[index] = {
                    ...row,
                    merchants: newValue as optionType[],
                    merchant_ids: newValue.map((e) => Number(e.value)),
                  }
                } else {
                  showDefaultWarningToaster({
                    message: {
                      titleKey:
                        "accounting-plan.create-codes.modal.warning-not-centralize.title",
                      bodyKey:
                        "accounting-plan.create-codes.modal.warning-not-centralize.content",
                      bodyValues: { code: row.code },
                    },
                    type: "success",
                    withToast: true,
                  })
                }

                setMerchantCodesToCreate(
                  updateMerchantCodeAddPotentialNewLine(updatedMerchantCodes)
                )
              }}
              canToggleAllOptions={true}
              allOptionDisabled={!row.centralize}
              hasBorder={false}
              height="100%"
              maxHeight="30rem"
            />
          </BorderRight>
        )
      },
    },
    {
      headerText: "accounting-plan.create-codes.modal.column.default-buy",
      width: "20rem",
      flexGrow: "none",
      content: (row: MerchantCodeToCreate, index: number) => {
        return (
          <BorderRight
            backgroundColor={getBackgroundColorMcOtherInput(
              merchantCodesToCreate,
              hoveredForDeletionIndex,
              index,
              row,
              colors.white
            )}
          >
            <SwitchWrapper>
              <Switch
                value={row.default_buy_code}
                onToggle={() => {
                  const index = merchantCodesToCreate.indexOf(row)
                  const updatedMerchantCodes = [...merchantCodesToCreate]

                  updatedMerchantCodes[index] = {
                    ...row,
                    default_buy_code: !row.default_buy_code,
                  }

                  setMerchantCodesToCreate(
                    updateMerchantCodeAddPotentialNewLine(updatedMerchantCodes)
                  )
                }}
                disabled={buyDefaultForCompany !== "" && !row.default_buy_code}
                onToggleDisabled={() => {
                  showDefaultWarningToaster({
                    message: {
                      titleKey:
                        "accounting-plan.create-codes.modal.buy-switch-disabled.title",
                      bodyKey:
                        "accounting-plan.create-codes.modal.buy-switch-disabled.content",
                      bodyValues: { code: buyDefaultForCompany },
                    },
                    type: "success",
                    withToast: true,
                  })
                }}
              />
            </SwitchWrapper>
          </BorderRight>
        )
      },
    },
    {
      headerText: "accounting-plan.create-codes.modal.column.default-sell",
      width: "20rem",
      flexGrow: "none",
      content: (row: MerchantCodeToCreate, index: number) => {
        return (
          <BorderRight
            backgroundColor={getBackgroundColorMcOtherInput(
              merchantCodesToCreate,
              hoveredForDeletionIndex,
              index,
              row,
              colors.white
            )}
          >
            <SwitchWrapper>
              <Switch
                value={row.default_sell_code}
                onToggle={() => {
                  const index = merchantCodesToCreate.indexOf(row)
                  const updatedMerchantCodes = [...merchantCodesToCreate]

                  updatedMerchantCodes[index] = {
                    ...row,
                    default_sell_code: !row.default_sell_code,
                  }

                  setMerchantCodesToCreate(
                    updateMerchantCodeAddPotentialNewLine(updatedMerchantCodes)
                  )
                }}
                disabled={
                  sellDefaultForCompany !== "" && !row.default_sell_code
                }
                onToggleDisabled={() => {
                  showDefaultWarningToaster({
                    message: {
                      titleKey:
                        "accounting-plan.create-codes.modal.sell-switch-disabled.title",
                      bodyKey:
                        "accounting-plan.create-codes.modal.sell-switch-disabled.content",
                      bodyValues: { code: sellDefaultForCompany },
                    },
                    type: "success",
                    withToast: true,
                  })
                }}
              />
            </SwitchWrapper>
          </BorderRight>
        )
      },
    },
  ]

  useEffect(() => {
    setPrefixes(addPrefixToCode(company))
  }, [company])

  useEffect(() => {
    setBuyDefaultForCompany(
      merchantCodes.find((c) => c.default_buy_code === true)?.code ||
        merchantCodesToCreate.find((c) => c.default_buy_code === true)?.code ||
        ""
    )
    setSellDefaultForCompany(
      merchantCodes.find((c) => c.default_sell_code === true)?.code ||
        merchantCodesToCreate.find((c) => c.default_sell_code === true)?.code ||
        ""
    )
  }, [merchantCodes, merchantCodesToCreate])

  useEffect(() => {
    const mcSet = new Set(merchantCodes.map((mc) => mc.code))
    setMerchantCodesSet(mcSet)
  }, [merchantCodes])

  useEffect(() => {
    const mcCodes = merchantCodesToCreate
      .filter((mctc) => mctc.code !== "")
      .map((mctc) => mctc.code)
    setHasMerchantCodesAlreadyCreated(
      [...merchantCodesSet].some((a) => new Set(mcCodes).has(a))
    )
    setHasDuplicates(new Set(mcCodes).size !== mcCodes.length)
  }, [merchantCodesToCreate, merchantCodesSet])

  useEffect(() => {
    if (createMultipleMerchantCodesStatus === "success") {
      CreateMultipleMerchantCodesReset()
      closeModal()
    }
  }, [createMultipleMerchantCodesStatus])

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={closeModal}
      left="50%"
      right="50%"
      top="calc(50vh - 55rem)"
    >
      <StyledCard width={"220rem"}>
        <StyledHeader>
          <div />

          <StyledTitle
            text={intl.formatMessage({
              id: `accounting-plan.create-codes.modal.title`,
            })}
          />

          <ModalClose onClick={() => closeModal()}>
            <CloseCross />
          </ModalClose>
        </StyledHeader>

        <Ct.Spacer height={3} />
        <Ct.Separator size="full" />
        <Ct.Spacer height={3} />

        <Information contentWidth={globalWidthContent}>
          <StyledInfoTitle
            text={intl.formatMessage({
              id: "accounting-plan.create-codes.modal.info.title",
            })}
          />
          <Ct.Spacer height={1} />
          <Alert alertType={"bulb"}>
            <Text
              text={intl.formatMessage({
                id: "accounting-plan.create-codes.modal.info.content",
              })}
            />
          </Alert>
        </Information>

        <Ct.Spacer height={3} />

        <Content contentWidth={globalWidthContent}>
          <TableWrapper>
            <Table
              intl={intl}
              columns={columns}
              rows={merchantCodesToCreate}
              alignItems={"strech"}
              width={"100%"}
              maxHeight={"55rem"}
              padding={"0"}
              paddingRows={"0"}
              fontWeightTitle={600}
              sortableColumnsLength={0}
              customScrollBar={true}
              paddingHeader={"0 1rem"}
              healerFirstColumnLeft={false}
              deleteConfig={{
                onHover: (rowIndex: number) => {
                  setHoveredForDeletionIndex(rowIndex)
                },
                onDelete: (rowIndex: number) => {
                  setHoveredForDeletionIndex(-1)
                  if (merchantCodesToCreate.length === 1) {
                    return
                  }

                  const accountsAfterDelete = removeIndex({
                    array: merchantCodesToCreate,
                    index: rowIndex,
                  }).slice(0, -1)
                  setMerchantCodesToCreate([
                    ...accountsAfterDelete,
                    emptyMerchantCode,
                  ])
                },
              }}
            />
          </TableWrapper>
        </Content>

        {hasMerchantCodesAlreadyCreated && (
          <>
            <Ct.Spacer width={2} />
            <LegendeWrapper contentWidth={globalWidthContent}>
              <Warning />
              <Ct.Spacer width={1} />
              <Text
                text={intl.formatMessage({
                  id: `accounting-plan.create-codes.modal.input.code.error`,
                })}
                textStyle={{
                  color: "amaranth",
                }}
              />
            </LegendeWrapper>
          </>
        )}

        {hasDuplicates && (
          <>
            <Ct.Spacer width={1} />
            <LegendeWrapper contentWidth={globalWidthContent}>
              <StyledWarning color={colors.orange} />
              <Ct.Spacer width={1} />
              <Text
                text={intl.formatMessage({
                  id: `accounting-plan.create-codes.modal.input.code.duplicate.error`,
                })}
                textStyle={{
                  color: "orange",
                }}
              />
            </LegendeWrapper>
          </>
        )}

        <Ct.Spacer height={3} />

        <ButtonsPanel>
          <Ct.Button
            label={intl.formatMessage({
              id: "accounting-plan.accounts.modal.button.back",
            })}
            colorType="Tertiary"
            width={42}
            onClick={() => {
              closeModal()
            }}
            colorScheme={{
              border: "mist",
              color: "cornflower",
              background: "mist",
            }}
          />
          <Ct.Button
            label={intl.formatMessage({
              id: "accounting-plan.accounts.modal.button.save",
            })}
            colorType="Primary"
            width={42}
            onClick={() => {
              handleFormSubmit()
            }}
            loadingStatus={
              createMultipleMerchantCodesStatus === "loading"
                ? "loading"
                : "idle"
            }
            disabled={disableSaveButton()}
            colorScheme={{
              border: "mist",
              color: "white",
              background: "mist",
            }}
          />
        </ButtonsPanel>
      </StyledCard>
    </Ct.Modal>
  )
}

const StyledCard = styled((props) => <Ct.Card {...props} />)`
  padding: 3.5rem 0;
  border-radius: 2rem;
`
const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 210rem;
`
const StyledTitle = styled((props) => <Ct.Title {...props} />)`
  font-size: 24px;
`
const ModalClose = styled.div`
  cursor: pointer;
`
const Information = styled.div<{ contentWidth: string }>`
  width: ${({ contentWidth }) => (contentWidth ? contentWidth : "100%")};
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`
const LegendeWrapper = styled.div<{ contentWidth: string }>`
  width: ${({ contentWidth }) => (contentWidth ? contentWidth : "100%")};
  display: flex;
  flex-direction: row;
  align-items: center;
`
const StyledWarning = styled(Warning)<{ color: string }>`
  & path {
    fill: ${({ color }) => color};
  }
`
const StyledInfoTitle = styled((props) => <Ct.Title {...props} />)`
  text-align: left;
  font-size: 2rem;
  text-transform: uppercase;
`
const SwitchWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Content = styled.div<{ contentWidth: string }>`
  width: ${({ contentWidth }) => (contentWidth ? contentWidth : "100%")};
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`
const ButtonsPanel = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100rem;
`
const BorderRight = styled.div<{ backgroundColor?: string }>`
  border-right: 1px solid ${colors.mist};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : "#FFF"};
  transition: 0.5s background-color ease-in-out;
`
