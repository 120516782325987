import * as Ct from "ldlj"
import styled from "styled-components/macro"

import { useIntl } from "react-intl"
import { ReactNode, useState } from "react"
import { colors } from "../../styles/design.config"
import { ReactComponent as Chevron } from "../../assets/chevron-down-bigger.svg"

interface OptionButtonProps {
  labelId: string
  onClick: () => void
  prefix?: ReactNode
  disabled?: boolean
}

interface ButtonSelectProps {
  labelId: string
  onClick: () => void
  options: OptionButtonProps[]
  prefix?: ReactNode
  disabled?: boolean
  width?: string
  height?: string
  optionsItemHeight?: string
  optionsTopOffset?: string
}

export const ButtonSelect = ({
  labelId,
  onClick,
  options,
  prefix,
  disabled = false,
  width = "auto",
  height = "6rem",
  optionsItemHeight = "6rem",
  optionsTopOffset,
}: ButtonSelectProps) => {
  const intl = useIntl()

  const [optionToggle, setOptionToggle] = useState(false)

  const handleMainClick = () => {
    if (!disabled) {
      setOptionToggle(false)
      onClick()
    }
  }

  const handleOptionClick = (option: OptionButtonProps) => {
    if (!disabled) {
      setOptionToggle(false)
      option.onClick()
    }
  }

  const handleToggle = () => {
    if (!disabled) setOptionToggle(!optionToggle)
  }

  return (
    <WrapperButtonSelect width={width} height={height} disabled={disabled}>
      <WrapperMainButton onClick={handleMainClick} disabled={disabled}>
        {prefix ? (
          <>
            {prefix}
            <Ct.Spacer width={1} />
          </>
        ) : null}
        <StyledText
          disabled={disabled}
          text={intl.formatMessage({ id: labelId })}
          textStyle={{
            fontSize: 1.75,
            color: disabled ? "moon" : "white",
            fontStyle: "normal",
            textTransform: "uppercase",
            fontWeight: 500,
            textAlign: "center",
          }}
        />
      </WrapperMainButton>

      <WrapperChevron rotation={optionToggle ? 1 : 0} disabled={disabled}>
        <StyledChevronSquare
          onClick={() => handleToggle()}
          rotation={optionToggle ? 1 : 0}
        />
      </WrapperChevron>

      <WrapperOptions
        display={optionToggle}
        mainHeight={height}
        topOffset={optionsTopOffset}
      >
        {options.map((o, index) => {
          if (!o.disabled) {
            return (
              <WrapperOptionsButton
                height={optionsItemHeight}
                onClick={() => handleOptionClick(o)}
                key={"button-option-" + index}
              >
                {o.prefix ? (
                  <>
                    {o.prefix}
                    <Ct.Spacer width={1} />
                  </>
                ) : null}
                <StyledOptionText
                  text={intl.formatMessage({ id: o.labelId })}
                  textStyle={{
                    fontSize: 1.75,
                    fontStyle: "normal",
                    textTransform: "uppercase",
                    fontWeight: 500,
                    textAlign: "center",
                  }}
                  css={{ color: colors.black }}
                />
              </WrapperOptionsButton>
            )
          } else return null
        })}
      </WrapperOptions>
    </WrapperButtonSelect>
  )
}

const WrapperButtonSelect = styled.div<{
  width: string
  height: string
  disabled: boolean
}>`
  position: relative;
  display: flex;
  flex-direction: row;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;

  cursor: ${({ disabled }) => (disabled ? `not-allowed` : `pointer`)};
`

const WrapperMainButton = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  height: 100%;
  border-radius: 10px 0 0 10px;
  justify-content: center;
  align-items: center;
  background-color: ${({ disabled }) =>
    disabled ? colors.desertStorm : colors.cornflower};
`

const WrapperOptionsButton = styled.div<{ height: string }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: ${({ height }) => height};
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: ${colors.white};

  &:hover {
    background-color: ${colors.lavender};
  }
`

const StyledText = styled(Ct.Text)<{ disabled: boolean }>`
  cursor: ${({ disabled }) => (disabled ? `not-allowed` : `pointer`)};
`

const StyledOptionText = styled(Ct.Text)`
  cursor: pointer;
  margin: 0 2rem;
`

const StyledChevronSquare = styled(Chevron)<{ rotation: number }>`
  width: 3.8rem;
  transition: transform 0.3s ease;
  transform: ${({ rotation }) => `rotate(${rotation ? 180 : 0}deg)`};

  & path {
    fill: ${colors.white};
  }
`

const WrapperChevron = styled.div<{ rotation: number; disabled: boolean }>`
  display: flex;
  flex-direction: column;
  width: 6rem;
  height: 100%;
  margin-left: 1px;
  justify-content: center;
  align-items: center;
  border-radius: 0 10px 10px 0;
  background-color: ${({ disabled }) =>
    disabled ? colors.desertStorm : colors.cornflower};

  &:hover ${StyledChevronSquare} {
    transform: ${({ rotation }) =>
      `rotate(${rotation ? 180 : 0}deg) scale(1.1)`};
  }
`

const WrapperOptions = styled.div<{
  display: boolean
  mainHeight: string
  topOffset?: string
}>`
  position: absolute;
  z-index: 99999999;
  display: ${({ display }) => (display ? "flex" : "none")};
  flex-direction: column;
  right: 0;
  top: ${({ topOffset, mainHeight }) =>
    topOffset ? topOffset : `calc(${mainHeight} + 2px)`};
  width: auto;
  height: auto;
  border-radius: 10px;
  padding: 5px;
  background-color: ${colors.white};
  box-shadow: 0px 4px 14px rgba(2, 76, 248, 0.1);
`
