import { IntlShape } from "react-intl"
import { WritingLabelRule } from "../store/ducks/writingLabels.ducks"

export const formatValuesRules = (rule: WritingLabelRule, intl: IntlShape) => {
  if (!rule || !rule.values || rule.values.length === 0) return ""

  return rule.values.reduce((result, val) => {
    if (val === "free_text") {
      result += rule.free_text
    } else if (val === "space") {
      result += " "
    } else {
      result += removeSpacesFromString(
        intl.formatMessage({
          id: `office-company.writing-labels.table.values.${val}`,
        })
      )
    }
    return result
  }, "")
}

export interface FreeText {
  value: "free_text"
  label: string
}

export const ArrayDocumentType = [
  "all",
  "invoice",
  "credit_note",
  "bill_of_exchange",
] as const
export type WritingLabelDocumentType = (typeof ArrayDocumentType)[number]

export const ArrayBuyOrSell = ["all", "buy", "sell"] as const
export type WritingLabelBuyOrSell = (typeof ArrayBuyOrSell)[number]

export const ArraySeparator = ["space", "dot", "underscore", "hyphen"] as const
export type Separators = (typeof ArraySeparator)[number]

export const ArrayCropType = ["left", "middle", "right"] as const
export type CropType = (typeof ArrayCropType)[number]

export const ArraySelectableValues = [
  "amount_tax_included",
  "amount_tax_excluded",
  "currency",
  "merchant_name",
  "merchant_code",
  "date",
  "due_date",
  "periodicity",
  "document_reference",
  "automatic_reference",
  "document_reference_or_automatic_reference",
  "automatic_reference_or_document_reference",
  "original_batch_name",
  "free_text_full_doc",
] as const
export type SelectableValues = (typeof ArraySelectableValues)[number]

export type RuleValue = SelectableValues | Separators | "free_text"

export const removeSpacesFromString = (string: string) => {
  return string.replaceAll(" ", "")
}
