/* eslint-disable camelcase */
import { useEffect, VFC } from "react"
import {
  Controller,
  FieldValues,
  useForm,
  UseFormRegister,
} from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import * as yup from "yup"

import { convertEnumToArray } from "../utils/array"
import {
  ComptaSoftwareOptionsEnum,
  DiscoveryOptionsEnum,
  LocalisationOptionsEnum,
} from "../model/settings"
import * as Ct from "ldlj"
import { attemptFiduciaryRegistrationThunk } from "../store/ducks/fiduciary.ducks"
import { sizes } from "../styles/design.config"
import { PHONE_VALIDATOR } from "../utils/phoneValidator"
import { capitalizeFirstLetter } from "../utils/string"
import { Select } from "./Commons/Select"
import { useRNBSelector } from "../store/rootReducer"

interface RegistrationPanelProps {
  sirenRegistered: boolean
}

export interface FiduciaryFormData {
  fiduciaryName: string
  address: string
  city: string
  postalCode: string
  phone: string
  discovery: { value: string; label: string }
  localisation: { value: string; label: string }
  accounting_software: { value: string; label: string }
}

export const RegistrationPanel: VFC<RegistrationPanelProps> = ({
  sirenRegistered,
}: RegistrationPanelProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const fiduciary = useRNBSelector((state) => state.fiduciary)

  const schema = yup.object({
    fiduciaryName: yup.string().required(),
    address: yup.string().required(),
    city: yup.string().required(),
    postalCode: yup.string().required(),
    phone: yup.string().matches(PHONE_VALIDATOR, "invalid_phone").required(),
    discovery: yup.object({ value: yup.string().required() }),
    localisation: yup.object({ value: yup.string().required() }),
    accounting_software: yup.object({ value: yup.string().required() }),
  })

  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    setValue,
    formState: { isValid, errors },
  } = useForm<FiduciaryFormData>({
    mode: "onChange",
    resolver: yupResolver(schema, { abortEarly: false }),
    criteriaMode: "all",
    reValidateMode: "onChange",
  })

  useEffect(() => {
    if (!sirenRegistered) {
      reset({
        fiduciaryName: "",
        address: "",
        city: "",
        postalCode: "",
        phone: "",
        discovery: { value: "", label: "" },
        localisation: { value: "", label: "" },
        accounting_software: { value: "", label: "" },
      })
    }
  }, [reset, sirenRegistered])

  useEffect(() => {
    setValue("fiduciaryName", fiduciary.name)
    setValue("address", fiduciary.address)
    setValue("postalCode", fiduciary.postalCode)
    setValue("city", fiduciary.city)
  }, [fiduciary, setValue])

  const fiduciaryName = watch("fiduciaryName")
  const address = watch("address")
  const city = watch("city")
  const postalCode = watch("postalCode")
  const phone = watch("phone")
  const discovery = watch("discovery")
  const localisation = watch("localisation")
  const accounting_software = watch("accounting_software")

  const softwareOptionArray = convertEnumToArray(ComptaSoftwareOptionsEnum)
  const discoveryOptionArray = convertEnumToArray(DiscoveryOptionsEnum)
  const localisationOptionArray = convertEnumToArray(LocalisationOptionsEnum)

  const accounting_softwareOptions: Ct.OptionList<string> = Ct.createOptionList(
    softwareOptionArray
  ).map((o) => ({
    value: o.value,
    label: capitalizeFirstLetter(o.label),
  }))
  const discoveryOptions: Ct.OptionList<string> = Ct.createOptionList(
    discoveryOptionArray
  ).map((o) => ({
    value: o.value,
    label: capitalizeFirstLetter(o.label),
  }))
  const localisationOptions: Ct.OptionList<string> = Ct.createOptionList(
    localisationOptionArray
  ).map((o) => ({
    value: o.value,
    label: capitalizeFirstLetter(o.label),
  }))

  const handleFormSubmit = (fiduciaryCreationForm: FiduciaryFormData) => {
    dispatch(
      attemptFiduciaryRegistrationThunk({
        ...fiduciaryCreationForm,
      })
    )
  }

  return (
    <Ct.Card>
      <Ct.StyledForm onSubmit={handleSubmit(handleFormSubmit)}>
        <Ct.Input
          name="fiduciaryName"
          dataCy="fiduciaryName"
          register={register as unknown as UseFormRegister<FieldValues>}
          label={intl.formatMessage({ id: "fiduciary-register.name" })}
          type="text"
          value={fiduciaryName}
          disabled={!sirenRegistered}
        />
        <Ct.Spacer height={4} />
        <Ct.StyledDuoInput>
          <Ct.Input
            name="address"
            dataCy="address"
            register={register as unknown as UseFormRegister<FieldValues>}
            label={intl.formatMessage({ id: "fiduciary-register.address" })}
            type="text"
            value={address}
            disabled={!sirenRegistered}
          />
          <Ct.Spacer width={3} />
          <Ct.Input
            name="city"
            dataCy="city"
            register={register as unknown as UseFormRegister<FieldValues>}
            label={intl.formatMessage({ id: "fiduciary-register.city" })}
            type="text"
            value={city}
            disabled={!sirenRegistered}
          />
        </Ct.StyledDuoInput>
        <Ct.Spacer height={4} />
        <Ct.StyledDuoInput>
          <Ct.Input
            name="postalCode"
            register={register as unknown as UseFormRegister<FieldValues>}
            label={intl.formatMessage({
              id: "fiduciary-register.postal-code",
            })}
            type="text"
            value={postalCode}
            disabled={!sirenRegistered}
          />
          <Ct.Spacer width={3} />
          <Ct.Input
            name="phone"
            register={register as unknown as UseFormRegister<FieldValues>}
            label={intl.formatMessage({ id: "fiduciary-register.phone" })}
            type="text"
            value={phone}
            disabled={!sirenRegistered}
            showError={!isValid && errors.phone?.message === "invalid_phone"}
          />
        </Ct.StyledDuoInput>
        <Ct.Spacer height={4} />
        <Ct.StyledDuoInput>
          <Controller
            name="localisation"
            control={control}
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <Select
                intl={intl}
                field={{ onChange, onBlur, value, name, ref }}
                value={localisation}
                options={localisationOptions}
                label={"localisation"}
                domain={"fiduciary-register"}
                optionType={"localisation"}
                disabled={!sirenRegistered}
                onChangeCallback={onChange}
              />
            )}
          />
          <Ct.Spacer width={3} />
          <Controller
            name="accounting_software"
            control={control}
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <Select
                intl={intl}
                field={{ onChange, onBlur, value, name, ref }}
                value={accounting_software}
                options={accounting_softwareOptions}
                label={"accounting_software"}
                domain={"fiduciary-register"}
                optionType={"compta-software"}
                disabled={!sirenRegistered}
                onChangeCallback={onChange}
              />
            )}
          />
        </Ct.StyledDuoInput>
        <Ct.Spacer height={4} />
        <Controller
          name="discovery"
          control={control}
          render={({ field: { onChange, onBlur, value, name, ref } }) => (
            <Select
              intl={intl}
              field={{ onChange, onBlur, value, name, ref }}
              value={discovery}
              options={discoveryOptions}
              label={"discovery"}
              domain={"fiduciary-register"}
              optionType={"discovery"}
              disabled={!sirenRegistered}
              onChangeCallback={onChange}
            />
          )}
        />

        <Ct.Spacer height={4} />
        <Ct.Button
          width={sizes.button.standard}
          label={intl.formatMessage({ id: "fiduciary-register.cta" })}
          type="submit"
          disabled={!sirenRegistered || !isValid}
        />
      </Ct.StyledForm>
    </Ct.Card>
  )
}
