import axios, { AxiosError, AxiosResponse } from "axios"
import { AnyAction } from "redux"

import { Dispatch, RNBThunkAction } from "../store.config"
import {
  RedirectionDirective,
  UsersTeamResponse,
  UserTypes,
} from "../../model/users"
import {
  getAllUsersFailureAction,
  getAllUsersSuccessAction,
} from "./team.ducks"
import { attemptLoginThunk, loginSuccessAction } from "./login.ducks"
import { ConfigureAxios } from "../../axios.config"

const enum UserActionsEnum {
  GET_CURRENT_USER_ATTEMPT = "USER/getCurrentUserAttempt",
  GET_CURRENT_USER_SUCCESS = "USER/getCurrentUserSuccess",
  GET_CURRENT_USER_FAILURE = "USER/getCurrentUserFailure",
  UNINVITED = "USER/uninvited",
  INVITATION_SUCCESS = "USER/invitationSuccess",
  INVITATION_CUSTOMER_SUCCESS = "USER/invitationCustomerSuccess",
  INVITATION_COLLAB_SUCCESS = "USER/invitationCollabSuccess",
  INVITATION_EMAIL_USED = "USER/invitationEmailUsed",
  INVITATION_EMAIL_USED_AS_CAPTURE_EMAIL = "USER/invitationEmailUsedAsCaptureEmail",
  IN_ANOTHER_FIDUCIARY = "USER/invitationInAnotherFiduciary",
  INVITATION_DEFAULT_ERROR = "USER/invitationDefaultError",
  REACTIVATED_SUCCESS = "USER/invitationReactivatedSuccess",
  RETRY_ACTION = "USER/invitationRetry",
  GET_USERS_WHO_ACCESS_TO_COMPANY_ATTEMPT = "USER/getUsersWhoAccessToCompanyAttemp",
  GET_USERS_WHO_ACCESS_TO_COMPANY_SUCCESS = "USER/getUsersWhoAccessToCompanySuccess",
  GET_USERS_WHO_ACCESS_TO_COMPANY_FAILURE = "USER/getUsersWhoAccessToCompanyFailure",
  LOGIN_SSO_REDIRECTION = "USER/userLoginSSO_Redirect",
  GET_USER_LOGGED_ALL_ACCESS_ATTEMPT = "USER/getUserLoggedAllAccessAttempt",
  GET_USER_LOGGED_ALL_ACCESS_DENIED = "USER/getUserLoggedAllAccessDenied",
  GET_USER_LOGGED_ALL_ACCESS_COMPANIES_LIST = "USER/getUserLoggedAllAccessCompaniesList",
  GET_USER_LOGGED_ALL_ACCESS_SPECIFIC_COMPANY = "USER/getUserLoggedAllAccessSpecificCompany",
  GET_USER_LOGGED_ALL_ACCESS_COMPANY_CREATION = "USER/getUserLoggedAllAccessCompanyCreation",
  GET_USER_LOGGED_ALL_ACCESS_NO_FIDUCIARY_CREATED = "USER/getUserLoggedAllAccessNoFiduciaryCreated",
  GET_USER_LOGGED_ALL_ACCESS_FAILURE = "USER/getUserLoggedAllAccessFailure",
  GET_STANDARD_LEDGERS_ATTEMPT = "USER/getStandardLedgerAttempt",
  GET_STANDARD_LEDGERS_SUCCESS = "USER/getStandardLedgerSuccess",
  GET_STANDARD_LEDGERS_FAILURE = "USER/getStandardLedgerFailure",
  UPDATE_USER_ATTEMPT = "USER/updateUserAttempt",
  UPDATE_USER_FAILURE = "USER/updateUserFailure",
  UPDATE_USER_SUCCESS = "USER/updateUserSuccess",
  UPDATE_USER_RESET = "USER/updateUserReset",
  NOT_ACCEPTED_TERMS_OF_SERVICE = "USER/notAcceptedTermsOfService",
  NOT_ACCEPTED_TERMS_OF_SALES = "USER/notAcceptedTermsOfSales",
  FIRST_CONNEXION = "USER/firstConnexion",
  GET_EMAIL_FOR_CUSTOMER_SUCCESS = "USER/getEmailForCustomerSuccess",
  RESET_CURRENT_USER = "User/resetCurrentUser",
  WARN_BLOCKED_USER_ATTEMPT = "USER/warnBlockedUserAttempt",
  WARN_BLOCKED_USER_SUCCESS = "USER/warnBlockedUserSuccess",
  WARN_BLOCKED_USER_FAILURE = "USER/warnBlockedUserFailure",
  CONFIGURE_TWO_FACTOR_AUTHENTICATION_ATTEMPT = "USER/configureTwoFactorAuthenticationAttempt",
  CONFIGURE_TWO_FACTOR_AUTHENTICATION_SUCCESS = "USER/configureTwoFactorAuthenticationSuccess",
  CONFIGURE_TWO_FACTOR_AUTHENTICATION_FAILURE = "USER/configureTwoFactorAuthenticationFailure",
  CONFIGURE_TWO_FACTOR_AUTHENTICATION_RESET = "USER/configureTwoFactorAuthenticationReset",
}

export const getCurrentUserAction = () =>
  ({ type: UserActionsEnum.GET_CURRENT_USER_ATTEMPT } as const)
export const getCurrentUserSuccessAction = (payload: CurrentUserPayload) =>
  ({ type: UserActionsEnum.GET_CURRENT_USER_SUCCESS, payload } as const)
export const getCurrentUserFailureAction = (error: AxiosError) =>
  ({
    type: UserActionsEnum.GET_CURRENT_USER_FAILURE,
    error,
    withoutToast: true,
  } as const)
export const userInvitationUninvited = () =>
  ({ type: UserActionsEnum.UNINVITED } as const)
export const userInvitationSuccessAction = () =>
  ({ type: UserActionsEnum.INVITATION_SUCCESS } as const)
export const userInvitationCustomerSuccessAction = () =>
  ({ type: UserActionsEnum.INVITATION_CUSTOMER_SUCCESS } as const)
export const userInvitationCollabSuccessAction = () =>
  ({ type: UserActionsEnum.INVITATION_COLLAB_SUCCESS } as const)
export const userInvitationEmailUsedAction = (error: string) =>
  ({
    type: UserActionsEnum.INVITATION_EMAIL_USED,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `invitation.toaster.error.email-used.title`,
      bodyKey: error.includes("Cet email existe déjà en tant qu'email d'envoi")
        ? error
        : `invitation.toaster.error.email-used.body`,
    },
  } as const)
export const userInvitationEmailUsedAsCaptureEmailAction = () =>
  ({
    type: UserActionsEnum.INVITATION_EMAIL_USED_AS_CAPTURE_EMAIL,
    withToast: false,
  } as const)
export const userInvitationInAnotherFiduciaryAction = () =>
  ({
    type: UserActionsEnum.IN_ANOTHER_FIDUCIARY,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `invitation.toaster.error.in-another-fiduciary.title`,
      bodyKey: `invitation.toaster.error.in-another-fiduciary.body`,
    },
  } as const)
export const userInvitationDefaultError = () =>
  ({
    type: UserActionsEnum.INVITATION_DEFAULT_ERROR,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `invitation.toaster.error.default.title`,
      bodyKey: `invitation.toaster.error.default.body`,
    },
  } as const)
export const userInvitationReactivatedSuccessAction = () =>
  ({ type: UserActionsEnum.REACTIVATED_SUCCESS } as const)
export const userInvitationRetryAction = () =>
  ({ type: UserActionsEnum.RETRY_ACTION } as const)
export const getUsersWhoAccessToCompanyAttemptAction = () =>
  ({ type: UserActionsEnum.GET_USERS_WHO_ACCESS_TO_COMPANY_ATTEMPT } as const)
export const getUsersWhoAccessToCompanySuccessAction = (
  payload: UsersWhoAccessCompanyPayload[]
) =>
  ({
    type: UserActionsEnum.GET_USERS_WHO_ACCESS_TO_COMPANY_SUCCESS,
    payload,
  } as const)
export const getUsersWhoAccessToCompanyFailureAction = (error: AxiosError) =>
  ({
    type: UserActionsEnum.GET_USERS_WHO_ACCESS_TO_COMPANY_FAILURE,
    error,
    withoutToast: true,
  } as const)
export const getUserLoggedAllAccessAction = () =>
  ({ type: UserActionsEnum.GET_USER_LOGGED_ALL_ACCESS_ATTEMPT } as const)
export const login_SSO_redirection = (SSO_redirection_url: string) =>
  ({
    type: UserActionsEnum.LOGIN_SSO_REDIRECTION,
    payload: SSO_redirection_url,
  } as const)
export const getUserLoggedAllAccessDenied = () =>
  ({ type: UserActionsEnum.GET_USER_LOGGED_ALL_ACCESS_DENIED } as const)
export const getUserLoggedAllAccessCompaniesList = () =>
  ({ type: UserActionsEnum.GET_USER_LOGGED_ALL_ACCESS_COMPANIES_LIST } as const)
export const getUserLoggedAllAccessSpecificCompany = (
  payload: CompanyToRedirectPayload
) =>
  ({
    type: UserActionsEnum.GET_USER_LOGGED_ALL_ACCESS_SPECIFIC_COMPANY,
    payload,
  } as const)
export const getUserLoggedAllAccessCompanyCreation = () =>
  ({
    type: UserActionsEnum.GET_USER_LOGGED_ALL_ACCESS_COMPANY_CREATION,
  } as const)
export const getUserLoggedAllAccessNoFiduciaryCreated = () =>
  ({
    type: UserActionsEnum.GET_USER_LOGGED_ALL_ACCESS_NO_FIDUCIARY_CREATED,
  } as const)
export const getUserLoggedAllAccessFailureAction = () =>
  ({
    type: UserActionsEnum.GET_USER_LOGGED_ALL_ACCESS_FAILURE,
  } as const)
export const getStandardLedgerAttempt = () =>
  ({ type: UserActionsEnum.GET_STANDARD_LEDGERS_ATTEMPT } as const)
export const getStandardLedgerSuccess = (payload: StandardLedgerPayload[]) =>
  ({
    type: UserActionsEnum.GET_STANDARD_LEDGERS_SUCCESS,
    payload,
  } as const)
export const getStandardLedgerFailure = () =>
  ({ type: UserActionsEnum.GET_STANDARD_LEDGERS_FAILURE } as const)

export const updateUserAttemptAction = () =>
  ({ type: UserActionsEnum.UPDATE_USER_ATTEMPT } as const)

export const updateUserFailureAction = () =>
  ({
    type: UserActionsEnum.UPDATE_USER_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "user.incomplete-registration.error.title",
      bodyKey: "user.incomplete-registration.error.body",
    },
  } as const)

export const updateUserSuccessAction = () =>
  ({
    type: UserActionsEnum.UPDATE_USER_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: "user.incomplete-registration.success.title",
      bodyKey: "user.incomplete-registration.success.body",
    },
  } as const)

export const updateUserResetAction = () =>
  ({ type: UserActionsEnum.UPDATE_USER_RESET } as const)

export const notAcceptedTermsOfService = () =>
  ({ type: UserActionsEnum.NOT_ACCEPTED_TERMS_OF_SERVICE } as const)
export const notAcceptedTermsOfSales = () =>
  ({ type: UserActionsEnum.NOT_ACCEPTED_TERMS_OF_SALES } as const)
export const firstConnexion = () =>
  ({ type: UserActionsEnum.FIRST_CONNEXION } as const)
export const getEmailForCustomerSuccess = (email: string) =>
  ({ type: UserActionsEnum.GET_EMAIL_FOR_CUSTOMER_SUCCESS, email } as const)

export const resetCurrentUser = () =>
  ({ type: UserActionsEnum.RESET_CURRENT_USER } as const)

export const warnBlockedUserAttemptAction = () =>
  ({ type: UserActionsEnum.WARN_BLOCKED_USER_ATTEMPT } as const)
export const warnBlockedUserSuccessAction = () =>
  ({ type: UserActionsEnum.WARN_BLOCKED_USER_SUCCESS } as const)
export const warnBlockedUserFailureAction = () =>
  ({
    type: UserActionsEnum.WARN_BLOCKED_USER_FAILURE,
  } as const)

export const configureTwoFactorAuthenticationAttemptAction = (
  newOtpRequiredForLogin: boolean
) =>
  ({
    type: UserActionsEnum.CONFIGURE_TWO_FACTOR_AUTHENTICATION_ATTEMPT,
    payload: { newOtpRequiredForLogin },
  } as const)
export const configureTwoFactorAuthenticationSuccessAction = (
  newOtpRequiredForLogin: boolean
) =>
  ({
    type: UserActionsEnum.CONFIGURE_TWO_FACTOR_AUTHENTICATION_SUCCESS,
    payload: { newOtpRequiredForLogin },
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: "login.otp-configuration.toaster.success.title",
      bodyKey: newOtpRequiredForLogin
        ? "login.otp-configuration.toaster.success.body"
        : "login.otp-configuration.toaster.deactivated-success.body",
    },
  } as const)
export const configureTwoFactorAuthenticationFailureAction = (
  newOtpRequiredForLogin: boolean
) =>
  ({
    type: UserActionsEnum.CONFIGURE_TWO_FACTOR_AUTHENTICATION_FAILURE,
    payload: { newOtpRequiredForLogin },
  } as const)
export const configureTwoFactorAuthenticationResetAction = () =>
  ({
    type: UserActionsEnum.CONFIGURE_TWO_FACTOR_AUTHENTICATION_RESET,
  } as const)

type UserActionsType = ReturnType<
  | typeof getCurrentUserAction
  | typeof getCurrentUserFailureAction
  | typeof getCurrentUserSuccessAction
  | typeof userInvitationUninvited
  | typeof userInvitationSuccessAction
  | typeof userInvitationCustomerSuccessAction
  | typeof userInvitationCollabSuccessAction
  | typeof userInvitationEmailUsedAction
  | typeof userInvitationEmailUsedAsCaptureEmailAction
  | typeof userInvitationInAnotherFiduciaryAction
  | typeof userInvitationReactivatedSuccessAction
  | typeof userInvitationRetryAction
  | typeof userInvitationInAnotherFiduciaryAction
  | typeof getUsersWhoAccessToCompanySuccessAction
  | typeof getUsersWhoAccessToCompanySuccessAction
  | typeof login_SSO_redirection
  | typeof getUserLoggedAllAccessAction
  | typeof getUserLoggedAllAccessDenied
  | typeof getUserLoggedAllAccessCompaniesList
  | typeof getUserLoggedAllAccessSpecificCompany
  | typeof getUserLoggedAllAccessNoFiduciaryCreated
  | typeof getUserLoggedAllAccessCompanyCreation
  | typeof getUserLoggedAllAccessFailureAction
  | typeof getStandardLedgerAttempt
  | typeof getStandardLedgerSuccess
  | typeof getStandardLedgerFailure
  | typeof updateUserSuccessAction
  | typeof updateUserFailureAction
  | typeof updateUserAttemptAction
  | typeof updateUserResetAction
  | typeof notAcceptedTermsOfService
  | typeof notAcceptedTermsOfSales
  | typeof firstConnexion
  | typeof getEmailForCustomerSuccess
  | typeof resetCurrentUser
  | typeof warnBlockedUserAttemptAction
  | typeof warnBlockedUserFailureAction
  | typeof warnBlockedUserSuccessAction
  | typeof configureTwoFactorAuthenticationAttemptAction
  | typeof configureTwoFactorAuthenticationFailureAction
  | typeof configureTwoFactorAuthenticationSuccessAction
  | typeof configureTwoFactorAuthenticationResetAction
>

export const userInitialState: UserState = {
  status: "IDLE",
  firstName: "",
  lastName: "",
  email: "",
  id: 0,
  terms_of_service_accepted_at: null,
  terms_of_sales_accepted_at: null,
  first_connexion_at: null,
  typology: "",
  userInvitationStatus: "UNINVITED",
  editStatus: "IDLE",
  usersWhoAccessToCompany: [],
  redirection: {
    companyToRedirect: 0,
    directive: "UNSET",
    SSO_URL: "/user/sso",
  },
  updateStatus: "IDLE",
  standardLedgersStatus: "IDLE",
  standardLedgers: [],
  creatorEmail: undefined,
  created_at: "",
  otp_required_for_login: false,
  configureTwoFactorAuthenticationStatus: "IDLE",
  terms_of_sales_type: null,
}

export interface UserState {
  status: "IDLE" | "LOADING" | "SUCCESS" | "ERROR"
  updateStatus: "IDLE" | "LOADING" | "SUCCESS" | "ERROR"
  firstName: string
  lastName: string
  email: string
  id: number
  terms_of_service_accepted_at: string | null
  terms_of_sales_accepted_at: string | null
  first_connexion_at: string | null
  typology: UserTypes | ""
  userInvitationStatus:
    | "UNINVITED"
    | "SUCCESS"
    | "CUSTOMER_SUCCESS"
    | "COLLAB_SUCCESS"
    | "EMAIL_USED"
    | "IN_ANOTHER_FIDUCIARY"
    | "REACTIVATED_SUCCESS"
    | "RETRY_ACTION"
    | "EMAIL_USED_AS_CAPTURE_EMAIL"
  editStatus: "IDLE" | "LOADING" | "SUCCESS" | "ERROR"
  usersWhoAccessToCompany: UsersWhoAccessCompanyPayload[]
  redirection: {
    companyToRedirect: number
    directive: RedirectionDirective
    SSO_URL: string
  }
  standardLedgersStatus: "IDLE" | "LOADING" | "SUCCESS" | "ERROR"
  standardLedgers: StandardLedgerPayload[]
  creatorEmail: string | undefined
  created_at: string
  otp_required_for_login: boolean
  configureTwoFactorAuthenticationStatus:
    | "IDLE"
    | "LOADING"
    | "SUCCESS"
    | "ERROR"
  terms_of_sales_type: "default" | "subscription" | null
}

export function userReducer(
  state = userInitialState,
  action: UserActionsType
): UserState {
  switch (action.type) {
    case UserActionsEnum.GET_CURRENT_USER_ATTEMPT:
      return { ...state, status: "LOADING" }
    case UserActionsEnum.GET_CURRENT_USER_SUCCESS:
      return {
        ...state,
        ...action.payload,
        status: "SUCCESS",
      }
    case UserActionsEnum.GET_CURRENT_USER_FAILURE:
      return { ...state, status: "ERROR" }
    case UserActionsEnum.UNINVITED:
      return { ...state, userInvitationStatus: "UNINVITED" }
    case UserActionsEnum.INVITATION_SUCCESS:
      return { ...state, userInvitationStatus: "SUCCESS" }
    case UserActionsEnum.INVITATION_CUSTOMER_SUCCESS:
      return { ...state, userInvitationStatus: "CUSTOMER_SUCCESS" }
    case UserActionsEnum.INVITATION_COLLAB_SUCCESS:
      return { ...state, userInvitationStatus: "COLLAB_SUCCESS" }
    case UserActionsEnum.INVITATION_EMAIL_USED:
      return { ...state, userInvitationStatus: "EMAIL_USED" }
    case UserActionsEnum.INVITATION_EMAIL_USED_AS_CAPTURE_EMAIL:
      return { ...state, userInvitationStatus: "EMAIL_USED_AS_CAPTURE_EMAIL" }
    case UserActionsEnum.IN_ANOTHER_FIDUCIARY:
      return { ...state, userInvitationStatus: "IN_ANOTHER_FIDUCIARY" }
    case UserActionsEnum.REACTIVATED_SUCCESS:
      return { ...state, userInvitationStatus: "REACTIVATED_SUCCESS" }
    case UserActionsEnum.RETRY_ACTION:
      return { ...state, userInvitationStatus: "RETRY_ACTION" }
    case UserActionsEnum.GET_USERS_WHO_ACCESS_TO_COMPANY_SUCCESS:
      return { ...state, usersWhoAccessToCompany: action.payload }
    case UserActionsEnum.GET_USER_LOGGED_ALL_ACCESS_ATTEMPT:
      return {
        ...state,
        redirection: {
          companyToRedirect: 0,
          directive: "UNSET",
          SSO_URL: "/user/sso",
        },
      }
    case UserActionsEnum.LOGIN_SSO_REDIRECTION:
      return {
        ...state,
        redirection: {
          companyToRedirect: 0,
          directive: "SSO",
          SSO_URL: action.payload,
        },
      }
    case UserActionsEnum.GET_USER_LOGGED_ALL_ACCESS_DENIED:
      return {
        ...state,
        redirection: {
          companyToRedirect: 0,
          directive: "ACCESS_DENIED",
          SSO_URL: "/user/sso",
        },
      }
    case UserActionsEnum.GET_USER_LOGGED_ALL_ACCESS_COMPANIES_LIST:
      return {
        ...state,
        redirection: {
          companyToRedirect: 0,
          directive: "COMPANIES_LIST",
          SSO_URL: "/user/sso",
        },
      }
    case UserActionsEnum.GET_USER_LOGGED_ALL_ACCESS_SPECIFIC_COMPANY:
      return {
        ...state,
        redirection: {
          companyToRedirect: action.payload.companyToRedirect,
          directive: "SPECIFIC_COMPANY",
          SSO_URL: "/user/sso",
        },
      }
    case UserActionsEnum.GET_USER_LOGGED_ALL_ACCESS_COMPANY_CREATION:
      return {
        ...state,
        redirection: {
          companyToRedirect: 0,
          directive: "COMPANY_CREATION",
          SSO_URL: "/user/sso",
        },
      }
    case UserActionsEnum.GET_USER_LOGGED_ALL_ACCESS_NO_FIDUCIARY_CREATED:
      return {
        ...state,
        redirection: {
          companyToRedirect: 0,
          directive: "FIDUCIARY_NOT_CREATED",
          SSO_URL: "/user/sso",
        },
      }
    case UserActionsEnum.GET_USER_LOGGED_ALL_ACCESS_FAILURE:
      return {
        ...state,
        redirection: {
          companyToRedirect: 0,
          directive: "ERROR",
          SSO_URL: "/user/sso",
        },
      }
    case UserActionsEnum.GET_STANDARD_LEDGERS_ATTEMPT:
      return {
        ...state,
        standardLedgersStatus: "LOADING",
      }
    case UserActionsEnum.GET_STANDARD_LEDGERS_FAILURE:
      return {
        ...state,
        standardLedgersStatus: "ERROR",
      }
    case UserActionsEnum.GET_STANDARD_LEDGERS_SUCCESS:
      return {
        ...state,
        standardLedgers: action.payload,
        standardLedgersStatus: "SUCCESS",
      }
    case UserActionsEnum.UPDATE_USER_ATTEMPT:
      return { ...state, updateStatus: "LOADING" }
    case UserActionsEnum.UPDATE_USER_FAILURE:
      return { ...state, updateStatus: "ERROR" }
    case UserActionsEnum.UPDATE_USER_SUCCESS:
      return { ...state, updateStatus: "SUCCESS" }
    case UserActionsEnum.UPDATE_USER_RESET:
      return {
        ...state,
        updateStatus: "IDLE",
        redirection: {
          companyToRedirect: 0,
          directive: "UNSET",
          SSO_URL: "/user/sso",
        },
      }
    case UserActionsEnum.NOT_ACCEPTED_TERMS_OF_SERVICE:
      return {
        ...state,
        redirection: {
          companyToRedirect: 0,
          directive: "TERMS_OF_SERVICE_NOT_ACCEPTED",
          SSO_URL: "/user/sso",
        },
        status: "IDLE",
      }
    case UserActionsEnum.NOT_ACCEPTED_TERMS_OF_SALES:
      return {
        ...state,
        redirection: {
          companyToRedirect: 0,
          directive: "TERMS_OF_SALES_NOT_ACCEPTED",
          SSO_URL: "/user/sso",
        },
        status: "IDLE",
      }
    case UserActionsEnum.FIRST_CONNEXION:
      return {
        ...state,
        redirection: {
          companyToRedirect: 0,
          directive: "FIRST_CONNEXION",
          SSO_URL: "/user/sso",
        },
        status: "IDLE",
      }
    case UserActionsEnum.GET_EMAIL_FOR_CUSTOMER_SUCCESS:
      return {
        ...state,
        creatorEmail: action.email,
      }

    case UserActionsEnum.CONFIGURE_TWO_FACTOR_AUTHENTICATION_ATTEMPT:
      return {
        ...state,
        configureTwoFactorAuthenticationStatus: "LOADING",
        otp_required_for_login: action.payload.newOtpRequiredForLogin,
      }
    case UserActionsEnum.CONFIGURE_TWO_FACTOR_AUTHENTICATION_FAILURE:
      return {
        ...state,
        configureTwoFactorAuthenticationStatus: "ERROR",
        otp_required_for_login: action.payload.newOtpRequiredForLogin,
      }
    case UserActionsEnum.CONFIGURE_TWO_FACTOR_AUTHENTICATION_RESET:
      return {
        ...state,
        configureTwoFactorAuthenticationStatus: "IDLE",
      }
    case UserActionsEnum.CONFIGURE_TWO_FACTOR_AUTHENTICATION_SUCCESS:
      return {
        ...state,
        configureTwoFactorAuthenticationStatus: "SUCCESS",
        otp_required_for_login: action.payload.newOtpRequiredForLogin,
      }

    case UserActionsEnum.RESET_CURRENT_USER:
      return userInitialState
    default:
      return { ...state }
  }
}

export const getUserFromSSOThunk =
  (email: string, tid: string) =>
  (dispatch: Dispatch<AnyAction | RNBThunkAction>) => {
    dispatch(getCurrentUserAction())
    return axios
      .get(`/users/get_SSO_user`, {
        params: { email, tid },
        headers: { Authorization: String(localStorage.getItem("token")) },
      })
      .then(({ data }: AxiosResponse<unknown>) => {
        return axios.post(`/users/sign_in.json`, data)
      })
      .then((response) => {
        const token = response.headers.authorization
        localStorage.setItem("token", token)
        ConfigureAxios()
        dispatch(loginSuccessAction())
      })
      .catch((e: AxiosError) => {
        dispatch(getCurrentUserFailureAction(e))
      })
  }

export const getCurrentUserThunk =
  () => (dispatch: Dispatch<UserActionsType>) => {
    dispatch(getCurrentUserAction())

    return axios
      .get<CurrentUserResponse>(`/users/get_current_user`, {
        headers: { Authorization: String(localStorage.getItem("token")) },
      })
      .then(({ data }: AxiosResponse<CurrentUserResponse>) => {
        dispatch(
          getCurrentUserSuccessAction({
            id: data.id,
            firstName: data.first_name,
            lastName: data.last_name,
            email: data.email,
            typology: data.user_typology,
            created_at: data.created_at.slice(0, 10),
            terms_of_sales_accepted_at: data.terms_of_sales_accepted_at,
            terms_of_service_accepted_at: data.terms_of_service_accepted_at,
            terms_of_sales_type: data.terms_of_sales_type,
            first_connexion_at: data.first_connexion_at,
            otp_required_for_login: data.otp_required_for_login,
          })
        )
      })
      .catch((e: AxiosError) => {
        dispatch(getCurrentUserFailureAction({} as AxiosError))
      })
  }

interface CurrentUserPayload {
  id: number
  firstName: string
  lastName: string
  email: string
  typology: UserTypes
  created_at: string
  terms_of_service_accepted_at: string | null
  terms_of_sales_accepted_at: string | null
  terms_of_sales_type: "default" | "subscription" | null
  first_connexion_at: string | null
  otp_required_for_login: boolean
}

interface CurrentUserResponse {
  id: number
  first_name: string
  last_name: string
  email: string
  user_typology: UserTypes
  created_at: string
  terms_of_service_accepted_at: string | null
  terms_of_sales_accepted_at: string | null
  first_connexion_at: string | null
  otp_required_for_login: boolean
  terms_of_sales_type: "default" | "subscription" | null
}

interface UsersWhoAccessCompanyPayload {
  id: number
  firstName: string
  lastName: string
  email: string
}

interface UsersWhoAccessCompanyResponse {
  id: number
  first_name: string
  last_name: string
  email: string
}

interface CompanyToRedirectPayload {
  companyToRedirect: number
}
export interface ClientRegisterPayload {
  firstName: string
  lastName: string
  email: string
  userTypology: string
  fiduciaryId: number
  deleteCaptureEmail?: boolean
}

interface StandardLedgerPayload {
  id: number
  companyId: number
}

interface StandardLedgerResponse {
  id: number
  company_id: number
}

export const attemptRegisteringClientThunk =
  ({
    firstName,
    lastName,
    email,
    userTypology,
    fiduciaryId,
    deleteCaptureEmail,
  }: ClientRegisterPayload) =>
  (dispatch: Dispatch<AnyAction>) => {
    const data = {
      user: {
        first_name: firstName,
        last_name: lastName,
        email: email,
        user_typology: userTypology,
        fiduciary_id: fiduciaryId,
      },
      delete_capture_email: deleteCaptureEmail,
    }

    const params = {
      typologies: [
        "administrator",
        "manager",
        "collaborator",
        "customer_accountant",
        "customer",
      ],
    }

    axios
      .post(`/users/add_user`, data)
      .then((add_user_response) => {
        axios
          .get<UsersTeamResponse>(`/users/users_of_fiduciary`, { params })
          .then((response) => {
            dispatch(getAllUsersSuccessAction([...response.data]))
            if (add_user_response.data === "User reactivated") {
              return dispatch(userInvitationReactivatedSuccessAction())
            } else if (userTypology === "customer") {
              dispatch(userInvitationCustomerSuccessAction())
            } else if (userTypology === "customer_accountant") {
              dispatch(userInvitationCustomerSuccessAction())
            } else if (userTypology === "collaborator") {
              dispatch(userInvitationCollabSuccessAction())
            } else {
              return dispatch(userInvitationSuccessAction())
            }
          })
          .catch((e: AxiosError) => dispatch(getAllUsersFailureAction(e)))
      })
      .catch((e) => {
        const errorMessage = e.response?.data?.error || ""
        if (errorMessage === "User deactivated and in another fiduciary") {
          dispatch(userInvitationInAnotherFiduciaryAction())
        } else if (
          errorMessage.includes("email existe déjà en tant qu'email d'envoi")
        ) {
          dispatch(userInvitationEmailUsedAsCaptureEmailAction())
        } else if (errorMessage.includes("déjà")) {
          dispatch(userInvitationEmailUsedAction(errorMessage))
        } else dispatch(userInvitationDefaultError())
      })
  }

export const getUsersWhoAccessToCompanyThunk =
  (companyId: number | null) => (dispatch: Dispatch<AnyAction>) => {
    dispatch(getUsersWhoAccessToCompanyAttemptAction())
    const params = {
      company_id: companyId,
    }
    return axios
      .get<UsersWhoAccessCompanyResponse[]>(`/users/users_access_to_company`, {
        params,
      })
      .then(({ data }) => {
        const dataFormatted = data.map((user) => ({
          id: user.id,
          firstName: user.first_name,
          lastName: user.last_name,
          email: user.email,
        }))
        dispatch(getUsersWhoAccessToCompanySuccessAction(dataFormatted))
      })
      .catch((e: AxiosError) => {
        dispatch(getUsersWhoAccessToCompanyFailureAction(e))
      })
  }

interface AllUserAccessResponse {
  message: string
  company_id: number
}

export const getUserAllAccessThunk =
  () => (dispatch: Dispatch<UserActionsType>) => {
    dispatch(getUserLoggedAllAccessAction())

    return axios
      .get<AllUserAccessResponse>(`/users/all_access_user`)
      .then(({ data }) => {
        if (data.message === "need first connexion") {
          dispatch(firstConnexion())
        } else if (data.message === "terms of service not accepted") {
          dispatch(notAcceptedTermsOfService())
        } else if (data.message === "terms of sales not accepted") {
          dispatch(notAcceptedTermsOfSales())
        } else if (data.message === "access denied") {
          dispatch(getUserLoggedAllAccessDenied())
        } else if (data.message === "redirect to company list") {
          dispatch(getUserLoggedAllAccessCompaniesList())
        } else if (
          data.message === "redirect to this company" &&
          data.company_id
        ) {
          dispatch(
            getUserLoggedAllAccessSpecificCompany({
              companyToRedirect: data.company_id,
            })
          )
        } else if (data.message === "company creation") {
          dispatch(getUserLoggedAllAccessCompanyCreation())
        } else if (data.message === "no fiduciary created") {
          dispatch(getUserLoggedAllAccessNoFiduciaryCreated())
        }
      })
      .catch(() => {
        dispatch(getUserLoggedAllAccessFailureAction())
      })
  }

export const getStandardLedgersThunk =
  () => (dispatch: Dispatch<AnyAction>) => {
    dispatch(getStandardLedgerAttempt())
    return axios
      .get<StandardLedgerResponse[]>(`/users/get_standard_ledgers`)
      .then(({ data }) => {
        const formattedStandardLedgers = data.map((standardLedger) => ({
          id: standardLedger.id,
          companyId: standardLedger.company_id,
        }))
        dispatch(getStandardLedgerSuccess(formattedStandardLedgers))
      })
      .catch(() => {
        dispatch(getStandardLedgerFailure())
      })
  }

export const updateUserThunk =
  (
    firstName: string,
    lastName: string,
    userId: number,
    userEmail: string,
    acceptedSalesCondition: boolean,
    acceptedConditions: boolean,
    password?: string,
    firstConnexion?: boolean
  ) =>
  (dispatch: Dispatch<AnyAction | RNBThunkAction>) => {
    dispatch(updateUserAttemptAction())

    return axios
      .put(`users/${userId}`, {
        user: {
          first_name: firstName,
          last_name: lastName,
          password: password,
          terms_of_service_accepted: acceptedConditions,
          terms_of_sales_accepted: acceptedSalesCondition,
          first_connexion: firstConnexion,
        },
      })
      .then(() => {
        dispatch(updateUserSuccessAction())
        if (password) {
          dispatch(attemptLoginThunk(userEmail, password))
        }
      })
      .then(() => {
        dispatch(getCurrentUserThunk())
      })
      .catch(() => dispatch(updateUserFailureAction()))
  }

export const getEmailForCustomer = () => (dispatch: Dispatch<AnyAction>) => {
  return axios
    .get<{ email: string }>(`users/email_for_customer`)
    .then((response) => {
      dispatch(getEmailForCustomerSuccess(response.data.email))
    })
}

export const warnBlockedUserThunk = () => (dispatch: Dispatch<AnyAction>) => {
  dispatch(warnBlockedUserAttemptAction())

  return axios
    .post(`users/warn_blocked_user`)
    .then(() => {
      dispatch(warnBlockedUserSuccessAction())
    })
    .catch(() => {
      dispatch(warnBlockedUserFailureAction())
    })
}

export const configureTwoFactorAuthenticationThunk =
  (activateTwoFactorAuthentication: boolean, oneTimePassword: string) =>
  (dispatch: Dispatch<AnyAction>) => {
    dispatch(
      configureTwoFactorAuthenticationAttemptAction(
        activateTwoFactorAuthentication
      )
    )
    return axios
      .post(`users/configure_two_factor_authentication`, {
        activate_two_factor_authentication: activateTwoFactorAuthentication,
        one_time_password: oneTimePassword,
      })
      .then(() => {
        dispatch(
          configureTwoFactorAuthenticationSuccessAction(
            activateTwoFactorAuthentication
          )
        )
      })
      .catch(() => {
        dispatch(
          configureTwoFactorAuthenticationFailureAction(
            !activateTwoFactorAuthentication
          )
        )
      })
  }
