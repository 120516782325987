/* eslint-disable camelcase */
import styled from "styled-components/macro"
import * as Ct from "ldlj"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"

import { colors } from "../../styles/design.config"
import { useRNBSelector } from "../../store/rootReducer"
import {
  cancelReactivationAction,
  resetReview,
  setSelectedFullDocument,
  updateWritingLinesThunk,
} from "../../store/ducks/inputMask.ducks"
import { WritingLinePayload } from "../../utils/inputMask"
import { LeftArrow, RightArrow } from "../Arrows"
import { ModalDeactivate } from "./ModalDeactivate"
import { useState } from "react"
import { FullDocumentForInputMaskWithNewMerchant } from "./FullDocumentsList"
import ReactTooltip from "react-tooltip"

interface ControlBarProps {
  sortedDocuments: FullDocumentForInputMaskWithNewMerchant[]
}

export const ControlBar = ({ sortedDocuments }: ControlBarProps) => {
  const intl = useIntl()

  const [isDeactivateModalDisplayed, setIsDeactivateModalDisplayed] =
    useState(false)

  const dispatch = useDispatch()
  const {
    selectedFullDocumentIndex,
    fullDocumentHasChanged,
    writingLinesHaveChanged,
    initialFullDocument,
    editedFullDocument,
    initialWritinglines,
    editedWritingLines,
    merchantsOfCompany,
    deactivatedFullDocuments,
    blockedByAlreadyTakenMerchantCode,
    updateWritinglinesStatus,
  } = useRNBSelector((state) => {
    const fullDocumentId = state.inputMask.initial_full_document?.fd_id
    const selectedFullDocumentIndex = fullDocumentId
      ? sortedDocuments.findIndex((fd) => fd.fd_id === fullDocumentId)
      : 0

    const fullDocumentHasChanged =
      JSON.stringify(state.inputMask.edited_full_document) !==
      JSON.stringify(state.inputMask.initial_full_document)

    const writingLinesHaveChanged =
      JSON.stringify(state.inputMask.edited_writing_lines) !==
      JSON.stringify(state.inputMask.initial_writing_lines)

    return {
      selectedFullDocumentIndex,
      fullDocumentHasChanged,
      writingLinesHaveChanged,
      initialFullDocument: state.inputMask.initial_full_document,
      editedFullDocument: state.inputMask.edited_full_document,
      initialWritinglines: state.inputMask.initial_writing_lines,
      editedWritingLines: state.inputMask.edited_writing_lines,
      fullDocumentsToCheck: state.inputMask.full_documents_to_check,
      deactivatedFullDocuments: state.inputMask.deactivated_full_documents,
      merchantsOfCompany: state.merchantCodes.merchantsOfCompany,
      blockedByAlreadyTakenMerchantCode:
        state.inputMask.blockedByAlreadyTakenMerchantCode,
      updateWritinglinesStatus: state.inputMask.updateWritinglinesStatus,
    }
  })

  const isPreviousDisabled =
    selectedFullDocumentIndex === 0 ||
    fullDocumentHasChanged ||
    writingLinesHaveChanged

  const isNextDisabled =
    selectedFullDocumentIndex === sortedDocuments.length - 1 ||
    fullDocumentHasChanged ||
    writingLinesHaveChanged

  const totalTaxIncludedIsZero =
    editedWritingLines.find(
      (l) =>
        l.datum_type === "tax_included" ||
        l.datum_type === "tax_included_bill_of_exchange"
    )?.amount === "0.00"

  const allAmountsArePositive = editedWritingLines.every(
    (l) => Number(l.amount) > 0
  )

  const allAccountsSelected = editedWritingLines.every((l) => {
    if (l.datum_type === "tax_excluded_bill_of_exchange") {
      return l.merchant_code_id !== null
    }
    return l.account?.id
  })
  const allAmountsSelected = editedWritingLines.every(
    (l) => Number(l.amount) !== 0
  )

  const isCurrentDocumentBeingReactivated =
    deactivatedFullDocuments.find((d) => d.fd_id === editedFullDocument?.fd_id)
      ?.is_being_reactivated === true

  const isResetEnabled =
    updateWritinglinesStatus !== "loading" &&
    !isCurrentDocumentBeingReactivated &&
    (fullDocumentHasChanged || writingLinesHaveChanged)

  const isMissingReference =
    editedFullDocument?.buy_or_sell === "sell" &&
    !editedFullDocument.fd_document_reference

  const isSaveEnabled =
    !blockedByAlreadyTakenMerchantCode &&
    updateWritinglinesStatus !== "loading" &&
    (fullDocumentHasChanged || writingLinesHaveChanged) &&
    editedFullDocument?.merchant_code_id &&
    editedFullDocument.merchant_id &&
    !totalTaxIncludedIsZero &&
    allAccountsSelected &&
    editedFullDocument?.fd_document_date !== "" &&
    allAmountsSelected &&
    !isMissingReference &&
    allAmountsArePositive &&
    (editedFullDocument.free_text_full_doc ===
      initialFullDocument?.free_text_full_doc ||
      editedFullDocument.free_text_full_doc !== undefined)

  return (
    <>
      <ModalDeactivate
        fullDocumentId={editedFullDocument?.fd_id || 0}
        isDisplayed={isDeactivateModalDisplayed}
        onClose={() => {
          setIsDeactivateModalDisplayed(false)
        }}
      />
      <ControlBarWrapper>
        <ButtonLine>
          <LeftArrow
            onClick={() => {
              if (isPreviousDisabled) {
                return
              }
              dispatch(
                setSelectedFullDocument(
                  sortedDocuments[selectedFullDocumentIndex - 1]
                )
              )
            }}
            disabled={isPreviousDisabled}
          />
          <Ct.Spacer />
          {isCurrentDocumentBeingReactivated ? (
            <Ct.Button
              label={intl.formatMessage({
                id: "input-mask.cancel",
              })}
              onClick={() => {
                dispatch(
                  cancelReactivationAction({
                    fd_id: editedFullDocument?.fd_id || 0,
                  })
                )
                setIsDeactivateModalDisplayed(true)
              }}
              disabled={updateWritinglinesStatus === "loading"}
            />
          ) : (
            <Ct.Button
              label={intl.formatMessage({
                id: "input-mask.deactivate",
              })}
              onClick={() => {
                setIsDeactivateModalDisplayed(true)
              }}
              disabled={updateWritinglinesStatus === "loading"}
            />
          )}
          <Ct.Spacer />
          <ReactTooltip
            id="input-mask-save-tooltip"
            place="bottom"
            effect={"solid"}
          />

          <div
            data-for="input-mask-save-tooltip"
            data-tip={
              !isSaveEnabled
                ? intl.formatMessage({
                    id: "input-mask.save.tooltip",
                  })
                : ""
            }
          >
            <Ct.Button
              label={intl.formatMessage({
                id: "input-mask.save",
              })}
              disabled={!isSaveEnabled}
              onClick={() => {
                if (!initialFullDocument || !editedFullDocument) {
                  return
                }

                const changedMerchantCode = merchantsOfCompany.find(
                  (mc) => mc.id === editedFullDocument.merchant_id
                )?.code

                const changedMerchantCodeId = merchantsOfCompany.find(
                  (mc) => mc.id === editedFullDocument.merchant_id
                )?.code_id

                dispatch(
                  updateWritingLinesThunk({
                    merchantCodeId: changedMerchantCodeId || 0,
                    merchantCode: changedMerchantCode || "",
                    fullDocumentId: initialFullDocument?.fd_id || 0,
                    initialFullDocument,
                    editedFullDocument,
                    initialWritinglines,
                    editedWritingLines,
                    writingLinesHaveChanged,
                  })
                )
              }}
            />
          </div>
          <Ct.Spacer />
          <RightArrow
            onClick={() => {
              if (isNextDisabled) {
                return
              }
              dispatch(
                setSelectedFullDocument(
                  sortedDocuments[selectedFullDocumentIndex + 1]
                )
              )
            }}
            disabled={isNextDisabled}
          />
        </ButtonLine>
        <Ct.Spacer />
        {isResetEnabled && (
          <ResetLink
            onClick={() => {
              if (!initialFullDocument) return
              if (initialWritinglines.length === 0) return
              dispatch(
                resetReview({
                  initialFullDocument,
                  initialWritinglines:
                    initialWritinglines as WritingLinePayload[],
                })
              )
            }}
          >
            {intl.formatMessage({
              id: "input-mask.reset",
            })}
          </ResetLink>
        )}
        <Ct.Spacer />
      </ControlBarWrapper>
    </>
  )
}

const ControlBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ResetLink = styled.div`
  color: ${colors.cornflower};
  text-decoration: underline;
  cursor: pointer;
`

const ButtonLine = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`
