import { Account, RulesBillOfExchange } from "../store/ducks/accounts.ducks"
import { Instructions } from "../store/ducks/companySettings.ducks"
import { colors } from "../styles/design.config"

export const isValidVatAccount = (
  number: string,
  buyOrSell: "buy" | "sell"
) => {
  const startBuyVat = "4456"

  if (buyOrSell === "buy") {
    return number.startsWith(startBuyVat)
  }

  const startSellVat = "4457"
  return number.startsWith(startSellVat)
}

export const sortAccountInstructionsByDate = (
  allInstructions: Instructions[]
) => {
  return [
    ...allInstructions.sort(
      (a, b) => +new Date(b.created_at) - +new Date(a.created_at)
    ),
  ]
}

export const isValidAutoliquidatedVatAccount = (
  number: string,
  prefix: string[],
  forbid: string[]
) => {
  return (
    prefix.some((pref) => number.startsWith(pref)) &&
    !forbid.some((forbiddenPrefix) => number.startsWith(forbiddenPrefix))
  )
}

export const sortAccountsByNumber = (accounts: Account[], asc: boolean) => {
  return [
    ...accounts.sort((a, b) =>
      asc
        ? a.number?.localeCompare(b.number)
        : b.number?.localeCompare(a.number)
    ),
  ]
}

export const sortAccountsByDetails = (accounts: Account[], asc: boolean) => {
  return [
    ...accounts.sort((a, b) =>
      asc
        ? (a.details || "")?.localeCompare(b.details || "")
        : (b.details || "")?.localeCompare(a.details || "")
    ),
  ]
}

export const sortAccountsDefaultSort = (accounts: Account[]) => {
  const deactivatedAccounts = sortAccountsByNumber(
    accounts.filter((acc) => acc.deactivated_at !== null),
    true
  )
  const activatedAccounts = sortAccountsByNumber(
    accounts.filter((acc) => acc.deactivated_at === null),
    true
  )

  return [...activatedAccounts, ...deactivatedAccounts]
}

export const sortAccountsBySearch = (
  accounts: Account[],
  search: string,
  filterPrefixOnly: boolean
) => {
  return filterPrefixOnly
    ? [
        ...accounts.filter(
          (acc) =>
            acc.number.toLowerCase().slice(0, search.length) ===
            search.toLowerCase()
        ),
      ]
    : [
        ...accounts.filter(
          (acc) =>
            acc.number.toLowerCase().includes(search.toLowerCase()) ||
            acc.details?.toLowerCase().includes(search.toLowerCase())
        ),
      ]
}

export const sortRulesByPriority = (
  rules: RulesBillOfExchange[],
  asc: boolean
) => {
  return rules.sort((a, b) => {
    return asc
      ? a.priority_number - b.priority_number
      : b.priority_number - a.priority_number
  })
}

export const searchRulesBillOfExchange = (
  rules: RulesBillOfExchange[],
  search: string
) => {
  if (rules.length === 0) return []
  if (search.length === 0) return sortRulesByPriority(rules, true)

  return rules.filter((r) => {
    return (
      r.text_in_description.toLowerCase().includes(search) ||
      r.code.toLowerCase().includes(search) ||
      search.includes(r.text_in_description.toLowerCase()) ||
      search.includes(r.code.toLowerCase())
    )
  })
}

export const extendAccountNumber = (number: string, length: number) => {
  return number.padEnd(length, "0")
}

export const checkExtendableAccounts = (
  accounts: Account[],
  targetedAccountNumberLength: number
) => {
  const extendableAccounts: Account[] = []
  const inextensibleAccounts: Account[] = []
  const extendableAccountsSet: Set<string> = new Set(
    accounts
      .filter((a) => a.number.length >= targetedAccountNumberLength)
      .map((a) => a.number)
  )

  for (const account of accounts) {
    if (account.number.length < targetedAccountNumberLength) {
      const extendedNumber = extendAccountNumber(
        account.number,
        targetedAccountNumberLength
      )
      if (extendableAccountsSet.has(extendedNumber))
        inextensibleAccounts.push(account)
      else {
        extendableAccountsSet.add(extendedNumber)
        extendableAccounts.push(account)
      }
    } else inextensibleAccounts.push(account)
  }

  return { extendableAccounts, inextensibleAccounts }
}

export const reduceAccountNumber = (number: string, length: number) => {
  return number.substring(0, length)
}

export const checkReducibleAccounts = (
  accounts: Account[],
  targetedAccountNumberLength: number
) => {
  const reducibleAccounts: Account[] = []
  const unreducibleAccounts: Account[] = []
  const reducibleAccountsSet: Set<string> = new Set(
    accounts
      .filter((a) => a.number.length <= targetedAccountNumberLength)
      .map((a) => a.number)
  )

  for (const account of accounts) {
    if (account.number.length > targetedAccountNumberLength) {
      const reducedNumber = reduceAccountNumber(
        account.number,
        targetedAccountNumberLength
      )
      if (reducibleAccountsSet.has(reducedNumber))
        unreducibleAccounts.push(account)
      else {
        reducibleAccountsSet.add(reducedNumber)
        reducibleAccounts.push(account)
      }
    } else unreducibleAccounts.push(account)
  }

  return { reducibleAccounts, unreducibleAccounts }
}

export interface AccountToCreate {
  number: string
  details: string
}

export const parsePastedContentToAccounts = (
  pastedContent: string
): AccountToCreate[] => {
  const separators = ["\t", ";"]

  const pastedAccounts: AccountToCreate[] = []

  pastedContent
    .replace(/\r/g, "")
    .split("\n")
    .forEach((line) => {
      const separator =
        separators.find((s) => line.includes(s)) || separators[0]
      const values = line.split(separator)

      if (values.length >= 1 && values.length <= 2) {
        const accountNumber = values[0]
        if (accountNumber !== "") {
          pastedAccounts.push({
            number: accountNumber.trim(),
            details: values[1].trim() || "",
          })
        }
      }
    })

  return pastedAccounts
}

export const accountHasDuplicates = (
  accountsToCreate: AccountToCreate[],
  number: string
) => {
  if (number === "") return false

  let count = 0
  for (const item of accountsToCreate) {
    if (item.number === number) {
      count++
      if (count > 1) return true
    }
  }

  return false
}

export const getBackgroundColorAccountNumber = (
  accountsToCreate: AccountToCreate[],
  accountsSet: Set<string>,
  hoveredForDeletionIndex: number,
  index: number,
  number: string,
  details: string
): string => {
  if (index === hoveredForDeletionIndex) return "rgba(252, 90, 90, 0.2)"
  else if (
    accountsSet.has(number) ||
    (index !== accountsToCreate.length - 1 && number === "" && details === "")
  )
    return "rgba(252, 90, 90, 0.3)"
  else if (
    accountHasDuplicates(accountsToCreate, number) ||
    (number !== details && number === "")
  )
    return "rgba(252, 150, 90, 0.4)"
  else return colors.white
}

export const getBackgroundColorAccountDetails = (
  accountsToCreate: AccountToCreate[],
  hoveredForDeletionIndex: number,
  index: number,
  number: string,
  details: string
): string => {
  if (index === hoveredForDeletionIndex) return "rgba(252, 90, 90, 0.2)"
  else if (
    index !== accountsToCreate.length - 1 &&
    number === "" &&
    details === ""
  )
    return "rgba(252, 90, 90, 0.3)"
  else if (number !== details && details.trim() === "")
    return "rgba(252, 150, 90, 0.4)"
  else return colors.white
}
