import { Fragment, ReactNode } from "react"
import styled, { css } from "styled-components/macro"

import { colors } from "../../styles/design.config"
import ReactTooltip from "react-tooltip"
import { IntlShape } from "react-intl"
import { FontWeight } from "ldlj/dist/cjs/components/Text"
import * as Ct from "ldlj"
import { ReactComponent as ChevronUp } from "../../assets/chevron_up_2.svg"
import { ReactComponent as ChevronDown } from "../../assets/chevron_down_2.svg"
import { ReactComponent as TrashIcon } from "../../assets/TrashIcon.svg"

import { Alert } from "./Alert"

export interface TableBuilder<T> {
  headerText?: string
  width?: string
  flexGrow?: string
  headerIcon?: JSX.Element
  content: (rowData: T, index: number) => JSX.Element
  footerText?: string
  footerIcon?: JSX.Element
  selectOrInput?: "select" | "input"
  heightItem?: string
}

const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
`

export interface SortingChevronProps {
  up: boolean
  down: boolean
}

export interface TableProps<T> {
  columns: TableBuilder<T>[]
  rows: Array<T>
  rowBackgroundColors?: Array<keyof typeof colors>
  width?: string
  height?: string
  maxHeight?: string
  alignItems?: string
  padding?: string
  alertMessage?: string
  rowTooltip?: (rowData: T) => { "data-tip": string } | {}
  suffixContent?: ReactNode
  keyBuilder?: (rowData: T, index: number) => string
  intl: IntlShape
  paddingHeader?: string
  paddingBody?: string
  paddingRows?: string
  fontWeightTitle?: FontWeight | undefined
  sortableColumnsLength?: number
  sortableColumnToSkip?: number
  sortingMainFunction?: (theSetter: SortToReturn) => void
  currentSortColumn?: ChevronProps
  customScrollBar?: boolean
  overflow?: "auto" | "visible" | "hidden"
  footer?: boolean
  heightHeader?: string
  healerFirstColumnLeft?: boolean
  deleteConfig?: {
    onHover: (rowId: number) => void
    onDelete: (rowId: number) => void
    showRowDelete?: (row: T) => boolean
  }
  centerFirstItem?: boolean
}

export const Table = <T,>({
  columns,
  rows,
  rowBackgroundColors,
  width,
  height,
  maxHeight,
  alignItems,
  padding,
  alertMessage,
  rowTooltip,
  suffixContent,
  keyBuilder,
  intl,
  paddingHeader,
  paddingBody,
  paddingRows,
  fontWeightTitle,
  sortableColumnsLength,
  sortableColumnToSkip,
  sortingMainFunction,
  currentSortColumn,
  customScrollBar,
  footer,
  heightHeader,
  healerFirstColumnLeft = true,
  deleteConfig,
  centerFirstItem,
}: TableProps<T>) => {
  const bgColors = rowBackgroundColors || rows.map(() => "white")
  const widthColumnDeleteInRem = 6

  return (
    <Wrapper
      width={width}
      height={height}
      maxHeight={maxHeight}
      padding={padding}
    >
      <Header paddingHeader={paddingHeader} heightHeader={heightHeader}>
        {columns.map((column, index) => (
          <Flex1
            justifyLeft={
              centerFirstItem !== undefined
                ? false
                : index === 0 && healerFirstColumnLeft
            }
            key={column.headerText}
            flexGrow={column.flexGrow}
            width={column.width || "initial"}
          >
            {sortableColumnsLength &&
            index < sortableColumnsLength &&
            index >= (sortableColumnToSkip || 0) ? (
              <ClickableTitleSort
                tid={column.headerText || ""}
                intl={intl}
                index={index}
                sortToReturn={(column: SortToReturn) => {
                  sortingMainFunction && sortingMainFunction(column)
                }}
                currentChevron={currentSortColumn}
              />
            ) : (
              <>
                <TitleTable
                  tid={column.headerText || ""}
                  intl={intl}
                  fontWeightTitle={fontWeightTitle}
                  cursor={"default"}
                />
                {column.headerIcon}
              </>
            )}
          </Flex1>
        ))}

        {deleteConfig && (
          <Flex1
            key={"table-delete-column"}
            flexGrow={"none"}
            width={`${widthColumnDeleteInRem}rem`}
          />
        )}
      </Header>

      <ContentSection
        dataLoaded={Object.keys(rows).length > 0}
        paddingBody={paddingBody}
        customScrollBar={customScrollBar}
        footer={footer}
      >
        {rows.map((row, index) => {
          let additionalAttributes = {}
          if (rowTooltip) {
            additionalAttributes = rowTooltip(row)
            ReactTooltip.rebuild()
          }

          return (
            <Fragment
              key={keyBuilder ? keyBuilder(row, index) : `row-${index}`}
            >
              {index > 0 && <Ct.Separator size="full" color={"lavender"} />}
              <RowWithPadding
                backgroundColor={bgColors[index]}
                alignItems={alignItems}
                {...additionalAttributes}
                paddingRows={paddingRows}
              >
                {columns.map((column, colIndex) => (
                  <Flex1
                    justifyLeft={colIndex === 0}
                    key={`row-${column.headerText}-${colIndex}`}
                    flexGrow={column.flexGrow}
                    width={column.width || "initial"}
                    heightItem={column.heightItem || "initial"}
                  >
                    {column.content(row, index)}
                  </Flex1>
                ))}
                {deleteConfig && (
                  <Flex1
                    key={`row-delete-${index}`}
                    flexGrow={"none"}
                    width={`${widthColumnDeleteInRem}rem`}
                  >
                    {(deleteConfig?.showRowDelete
                      ? deleteConfig.showRowDelete(row)
                      : true) && (
                      <StyledTrashIcon
                        key={`delete-${index}`}
                        onMouseOver={() => {
                          deleteConfig.onHover(index)
                        }}
                        onMouseLeave={() => {
                          deleteConfig.onHover(-1)
                        }}
                        onClick={() => {
                          deleteConfig.onDelete(index)
                        }}
                      />
                    )}
                  </Flex1>
                )}
              </RowWithPadding>
            </Fragment>
          )
        })}

        {rows.length === 0 && alertMessage && (
          <>
            <Ct.Spacer height={8} />
            <Alert alertType="info">
              <Ct.Text
                text={intl.formatMessage({
                  id: alertMessage,
                })}
              />
            </Alert>
          </>
        )}
        {suffixContent}
      </ContentSection>

      {footer && (
        <Footer>
          {columns.map((column, index) => (
            <Flex1
              justifyLeft={centerFirstItem !== undefined ? false : index === 0}
              key={column.footerText}
              flexGrow={column.flexGrow}
              width={column.width || "initial"}
            >
              <>
                <TitleTable
                  tid={column.footerText || ""}
                  intl={intl}
                  fontWeightTitle={fontWeightTitle}
                  cursor={"default"}
                />
                {column.footerIcon}
              </>
            </Flex1>
          ))}
        </Footer>
      )}
    </Wrapper>
  )
}

interface WrapperStyle {
  width?: string
  height?: string
  padding?: string
  maxHeight?: string
}

// need 2rem padding for table shadow
export const Wrapper = styled.div<WrapperStyle>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => (width ? width : `initial`)};
  height: ${({ height }) => (height ? height : `initial`)};
  max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : `initial`)};
  overflow-y: auto;
  padding: ${({ padding }) => (padding ? padding : "0 2rem 2rem 2rem")};
`

export const TitleTable = ({
  tid,
  fontWeightTitle,
  intl,
  cursor,
  fontSize,
}: {
  tid: string
  fontWeightTitle?: FontWeight | undefined
  intl: IntlShape
  cursor?: "default" | "auto" | "pointer"
  fontSize?: number
}) => {
  return (
    <Ct.Text
      text={tid ? intl.formatMessage({ id: tid }) : ""}
      textStyle={{
        fontWeight: fontWeightTitle ? fontWeightTitle : 700,
        fontFamily: "Poppins",
        textTransform: "uppercase",
        textAlign: "center",
        cursor: cursor ? cursor : "auto",
        fontSize: fontSize ? fontSize : 1.75,
      }}
    />
  )
}

type ChevronDirections = "none" | "up" | "down"

export interface SortToReturn {
  index: number
  asc: boolean
  direction: ChevronDirections
}

export interface ChevronProps {
  direction: ChevronDirections
  index: number
}

export const HeaderClickable = ({
  headers,
  currentSortColumn,
  intl,
  index,
  sortingMainFunction,
  fontSize,
  textAlign,
}: {
  headers: { value: string; label: string }[]
  currentSortColumn?: ChevronProps
  sortingMainFunction?: (theSetter: SortToReturn) => void
  intl: IntlShape
  index: number
  fontSize: number
  textAlign?: string
}) => {
  return headers.map((head) => (
    <ClickableTitleSort
      tid={head.label || ""}
      intl={intl}
      index={index}
      sortToReturn={(column: SortToReturn) => {
        sortingMainFunction && sortingMainFunction(column)
      }}
      currentChevron={currentSortColumn}
      fontSize={fontSize}
      textAlign={textAlign}
      key={index}
    />
  ))
}

export const ClickableTitleSort = ({
  tid,
  fontWeightTitle,
  intl,
  index,
  sortToReturn,
  currentChevron,
  fontSize,
  textAlign,
}: {
  tid: string
  fontWeightTitle?: FontWeight | undefined
  intl: IntlShape
  index: number
  sortToReturn: (val: SortToReturn) => void
  currentChevron?: ChevronProps
  fontSize?: number
  textAlign?: string
}) => {
  const handleSortTitle = (index: number) => {
    let data: SortToReturn
    const newDirection: ChevronDirections =
      currentChevron && currentChevron.index === index
        ? currentChevron.direction === "none"
          ? "up"
          : currentChevron.direction === "up"
          ? "down"
          : "none"
        : "up"

    if (newDirection === "up") {
      data = {
        index,
        asc: true,
        direction: newDirection,
      }
    } else if (newDirection === "down") {
      data = {
        index: index,
        asc: false,
        direction: newDirection,
      }
    } else {
      data = {
        index,
        asc: true,
        direction: newDirection,
      }
    }

    return data
  }

  return (
    <>
      {currentChevron && (
        <>
          <ClickableTitle
            onClick={() => {
              sortToReturn(handleSortTitle(index))
            }}
            textAlign={textAlign}
          >
            <TitleTable
              tid={tid}
              intl={intl}
              fontWeightTitle={fontWeightTitle}
              cursor={"pointer"}
              fontSize={fontSize}
            />
          </ClickableTitle>
          <Ct.Spacer width={1} />
          <ChevronsColumn
            onClick={() => {
              sortToReturn(handleSortTitle(index))
            }}
          >
            <StyledChevronUp
              clicked={
                currentChevron.index === index &&
                currentChevron.direction === "up"
                  ? 1
                  : 0
              }
            />

            <StyledChevronDown
              clicked={
                currentChevron.index === index &&
                currentChevron.direction === "down"
                  ? 1
                  : 0
              }
            />
          </ChevronsColumn>
        </>
      )}
    </>
  )
}

//     padding-right: 5.5rem; because of scrollbar
//     TODO: add more padding right depending on scrollbar display
interface HeaderProps {
  paddingHeader?: string
  heightHeader?: string
}

export const Header = styled.header<HeaderProps>`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: ${({ paddingHeader }) => (paddingHeader ? paddingHeader : "0 4rem")};
  box-sizing: border-box;
  border-top-left-radius: 2.5rem;
  border-top-right-radius: 2.5rem;
  height: ${({ heightHeader }) => (heightHeader ? heightHeader : "7rem")};
  width: 100%;
  flex-shrink: 0;
  background-color: ${colors.lavender};
`
interface WithTransitionAndScrollBar {
  dataLoaded: boolean
  customScrollBar: boolean
  footer: boolean
}

interface WithFlexGrow {
  flexGrow?: string | null
  justifyLeft?: boolean
  overflow?: "auto" | "visible" | "hidden"
  width?: string
  heightItem?: string
}

export const Flex1 = styled.div<WithFlexGrow>`
  display: flex;
  flex: ${({ flexGrow }) => (flexGrow ? `0 0 ${flexGrow}` : "1 0")};
  overflow: ${({ overflow }) => (overflow !== "auto" ? overflow : "auto")};
  align-items: center;
  justify-content: ${({ justifyLeft }) =>
    justifyLeft ? `flex-start` : "center"};
  width: ${({ width }) => (width ? width : "initial")};
  height: ${({ heightItem }) => (heightItem ? heightItem : "initial")};
`
interface WithColor {
  backgroundColor: keyof typeof colors
  alignItems: string | undefined
}

const RowWithPadding = styled((props) => <Row {...props} />)<WithColor>`
  position: relative;
  padding: ${({ paddingRows }) => (paddingRows ? paddingRows : "3rem 4rem")};
  box-sizing: border-box;
  background-color: ${({ backgroundColor }) =>
    colors[backgroundColor as keyof typeof colors]};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : "normal")};
  transition: 0.3s background-color ease-in-out;
`

export const ContentSection = styled((props) => (
  <Ct.Card {...props} />
))<WithTransitionAndScrollBar>`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: ${({ paddingBody }) => (paddingBody ? paddingBody : "0")};
  border-radius: ${({ footer }) => (footer ? `0 0 0 0` : `0 0 2.5rem 2.5rem`)};
  height: 100%;
  overflow-y: auto;
  transition: 0.8s height ease-in;
  transform: translateZ(0);
  border: 1px solid ${colors.lavender};
  box-shadow: none;

  ${(props) =>
    props.customScrollBar &&
    css`
      /* FIREFOX */
      scrollbar-color: ${colors.rock} transparent;
      scrollbar-width: thin !important;

      /* CHROME */
      ::-webkit-scrollbar {
        width: 6px;
      }
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px transparent;
        border-radius: 3px;
      }
      ::-webkit-scrollbar-thumb {
        background-color: ${colors.rock};
        border-radius: 3px;
        width: 4px;
      }
    `}
`

interface ChevronsProps {
  clicked: number
}

const StyledChevronDown = styled(ChevronDown)<ChevronsProps>`
  & path {
    fill: ${({ clicked }) => (clicked ? colors.purple : "")};
  }
`
const StyledChevronUp = styled(ChevronUp)<ChevronsProps>`
  & path {
    fill: ${({ clicked }) => (clicked ? colors.purple : "")};
  }
`

const ClickableTitle = styled.div<{ textAlign?: string }>`
  user-select: none;
  text-align: ${({ textAlign }) => (textAlign ? textAlign : "initial")};
`

const ChevronsColumn = styled(Ct.Column)`
  cursor: pointer;
`

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  background-color: ${colors.lavender};
  border-radius: 0 0 2.5rem 2.5rem;
  height: 10%;
  border: 1px solid #c0ceea;
  padding-left: 4rem;
  padding-right: 4rem;
`

const StyledTrashIcon = styled(TrashIcon)`
  width: 3.5rem;
  height: 3.5rem;

  fill: ${colors.rock};
  cursor: pointer;
  transition: 0.5s fill ease -in -out;

  :hover {
    fill: ${colors.amaranth};
  }
`
