import { Indexed, indexedObjectFromArray } from "../../utils/asyncTools"
import { buyOrSell } from "../../utils/company"
import axios from "axios"
import { Dispatch, RNBThunkAction } from "../store.config"
import { selectNewAccountAction } from "./inputMask.ducks"
import { AnyAction } from "redux"
import { AccountToCreate } from "../../utils/accounts"

export interface Account {
  auxiliary_details: string | null
  auxiliary_number: string | null
  company_id: number
  construction_reverse_vat: boolean
  construction_vat: boolean
  created_at: string
  details: string
  deactivated_at: string
  eu_reverse_vat: boolean
  eu_vat: boolean
  eu_goods_reverse_vat: boolean
  eu_goods_vat: boolean
  world_reverse_vat: boolean
  world_vat: boolean
  world_goods_reverse_vat: boolean
  world_goods_vat: boolean
  id: number
  number: string
  updated_at: string
  deactivated_admin_user_id: string
  buy_third_party_account?: boolean
  sell_third_party_account?: boolean
  unit_id?: number
  unit_2_id?: number
}

const enum AccountActionsEnum {
  LOAD_PNL_ACCOUNTS_ATTEMPT = "ACCOUNTS/LoadPNLAccountsAttempt",
  LOAD_PNL_ACCOUNTS_SUCCESS = "ACCOUNTS/LoadPNLAccountsSuccess",
  LOAD_PNL_ACCOUNTS_FAILURE = "ACCOUNTS/LoadPNLAccountsFailure",

  LOAD_ACCOUNTS_ATTEMPT = "ACCOUNTS/LoadAccountsAttempt",
  LOAD_ACCOUNTS_SUCCESS = "ACCOUNTS/LoadAccountsSuccess",
  LOAD_ACCOUNTS_FAILURE = "ACCOUNTS/LoadAccountsFailure",

  CREATE_ACCOUNT_RESET_ACTION = "ACCOUNTS/CreateAccountResetAction",
  CREATE_ACCOUNT_ATTEMPT_ACTION = "ACCOUNTS/CreateAccountAttemptAction",
  CREATE_ACCOUNT_SUCCESS_ACTION = "ACCOUNTS/CreateAccountSuccessAction",
  CREATE_ACCOUNT_FAILURE_ACTION = "ACCOUNTS/CreateAccountFailureAction",

  CREATE_MULTIPLE_ACCOUNTS_ATTEMPT_ACTION = "ACCOUNTS/CreateMultipleAccountsAttemptAction",
  CREATE_MULTIPLE_ACCOUNTS_SUCCESS_ACTION = "ACCOUNTS/CreateMultipleAccountsSuccessAction",
  CREATE_MULTIPLE_ACCOUNTS_FAILURE_ACTION = "ACCOUNTS/CreateMultipleAccountsFailureAction",

  ADD_ACCOUNTS_TOO_MANY_LINES = "ACCOUNTS/addAccountsTooManyLines",

  UPDATE_ACCOUNT_RESET_ACTION = "ACCOUNTS/UpdateAccountResetAction",
  UPDATE_ACCOUNT_ATTEMPT_ACTION = "ACCOUNTS/UpdateAccountAttemptAction",
  UPDATE_ACCOUNT_SUCCESS_ACTION = "ACCOUNTS/UpdateAccountSuccessAction",
  UPDATE_ACCOUNT_FAILURE_ACTION = "ACCOUNTS/UpdateAccountFailureAction",

  GET_ACCOUNT_INSTRUCTIONS_HISTORY_ATTEMPT = "ACCOUNTS/getAccountInstructionsHistoryAttempt",
  GET_ACCOUNT_INSTRUCTIONS_HISTORY_SUCCESS = "ACCOUNTS/getAccountInstructionsHistorySuccess",
  GET_ACCOUNT_INSTRUCTIONS_HISTORY_FAILURE = "ACCOUNTS/getAccountInstructionsHistoryFailure",

  REACTIVATE_ACCOUNT_ATTEMPT = "ACCOUNTS/reactivateAccountAttempt",
  REACTIVATE_ACCOUNT_SUCCESS = "ACCOUNTS/reactivateAccountSuccess",
  REACTIVATE_ACCOUNT_FAILURE = "ACCOUNTS/reactivateAccountFailure",

  DEACTIVATE_ACCOUNT_ATTEMPT = "ACCOUNTS/deactivateAccountAttempt",
  DEACTIVATE_ACCOUNT_SUCCESS = "ACCOUNTS/deactivateAccountSuccess",
  DEACTIVATE_ACCOUNT_FAILURE = "ACCOUNTS/deactivateAccountFailure",
  DEACTIVATE_ACCOUNT_MERCHANT_CODE_DISABLED = "ACCOUNTS/deactivateAccountMerchantCodeDisabled",

  DEACTIVATE_OR_REACTIVATE_ACCOUNT_RESET = "ACCOUNTS/deactivateOrReactivateAccountReset",

  GET_BILL_OF_EXCHANGE_ACCOUNTS_ATTEMPT = "ACCOUNTS/GetBillOfExchangeAccountsAttempt",
  GET_BILL_OF_EXCHANGE_ACCOUNTS_SUCCESS = "ACCOUNTS/GetBillOfExchangeAccountsSuccess",
  GET_BILL_OF_EXCHANGE_ACCOUNTS_FAILURE = "ACCOUNTS/GetBillOfExchangeAccountsFailure",

  UPDATE_BILL_OF_EXCHANGE_ACCOUNTS_ATTEMPT = "ACCOUNTS/UpdateBillOfExchangeAccountsAttempt",
  UPDATE_BILL_OF_EXCHANGE_ACCOUNTS_SUCCESS = "ACCOUNTS/UpdateBillOfExchangeAccountsSuccess",
  UPDATE_BILL_OF_EXCHANGE_ACCOUNTS_FAILURE = "ACCOUNTS/UpdateBillOfExchangeAccountsFailure",

  ADD_REMOVE_UNIT_ATTEMPT = "ACCOUNTS/AddRemoveUnitAttempt",
  ADD_REMOVE_UNIT_SUCCESS = "ACCOUNTS/AddRemoveUnitSuccess",
  ADD_REMOVE_UNIT_FAILURE = "ACCOUNTS/AddRemoveUnitFailure",

  EXTEND_ACCOUNTS_ATTEMPT = "ACCOUNTS/ExtendAccountsAttempt",
  EXTEND_ACCOUNTS_SUCCESS = "ACCOUNTS/ExtendAccountsSuccess",
  EXTEND_ACCOUNTS_FAILURE = "ACCOUNTS/ExtendAccountsFailure",
  EXTEND_ACCOUNTS_RESET = "ACCOUNTS/ExtendAccountsReset",

  REDUCE_ACCOUNTS_ATTEMPT = "ACCOUNTS/ReduceAccountsAttempt",
  REDUCE_ACCOUNTS_SUCCESS = "ACCOUNTS/ReduceAccountsSuccess",
  REDUCE_ACCOUNTS_FAILURE = "ACCOUNTS/ReduceAccountsFailure",
  REDUCE_ACCOUNTS_RESET = "ACCOUNTS/ReduceAccountsReset",
}

export const loadPNLAccountsAttemptAction = () =>
  ({ type: AccountActionsEnum.LOAD_PNL_ACCOUNTS_ATTEMPT } as const)
export const loadPNLAccountsSuccessAction = (payload: {
  buy: Indexed<Account>
  sell: Indexed<Account>
}) => ({ type: AccountActionsEnum.LOAD_PNL_ACCOUNTS_SUCCESS, payload } as const)
export const loadPNLAccountsFailureAction = (error: Error) =>
  ({
    type: AccountActionsEnum.LOAD_PNL_ACCOUNTS_FAILURE,
    error: error,
  } as const)

export const loadAccountsAttemptAction = () =>
  ({ type: AccountActionsEnum.LOAD_ACCOUNTS_ATTEMPT } as const)
export const loadAccountsSuccessAction = (payload: Account[]) =>
  ({ type: AccountActionsEnum.LOAD_ACCOUNTS_SUCCESS, payload } as const)
export const loadAccountsFailureAction = (error: Error) =>
  ({ type: AccountActionsEnum.LOAD_ACCOUNTS_FAILURE, error: error } as const)

export const createAccountResetAction = () =>
  ({
    type: AccountActionsEnum.CREATE_ACCOUNT_RESET_ACTION,
  } as const)
export const createAccountAttemptAction = () =>
  ({
    type: AccountActionsEnum.CREATE_ACCOUNT_ATTEMPT_ACTION,
  } as const)
export const createAccountSuccessAction = ({
  newAccount,
}: {
  newAccount: Account
}) =>
  ({
    type: AccountActionsEnum.CREATE_ACCOUNT_SUCCESS_ACTION,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: "accounting-plan.accounts.modal.create.success.title",
      bodyKey: "accounting-plan.accounts.modal.create.success.body",
    },
    payload: newAccount,
  } as const)
export const createAccountFailureAction = (error: string) =>
  ({
    type: AccountActionsEnum.CREATE_ACCOUNT_FAILURE_ACTION,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "accounting-plan.accounts.modal.create.error.title",
      bodyKey: getAccountErrorId(error),
    },
  } as const)

export const createMultipleAccountsAttemptAction = () =>
  ({
    type: AccountActionsEnum.CREATE_MULTIPLE_ACCOUNTS_ATTEMPT_ACTION,
  } as const)
export const createMultipleAccountsSuccessAction = ({
  newAccounts,
}: {
  newAccounts: Account[]
}) =>
  ({
    type: AccountActionsEnum.CREATE_MULTIPLE_ACCOUNTS_SUCCESS_ACTION,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `accounting-plan.accounts.modal.multiple.create.success.title`,
      bodyKey: `accounting-plan.accounts.modal.multiple.create.success.body`,
    },
    payload: { newAccounts },
  } as const)
export const createMultipleAccountsFailureAction = (
  bodyKey: string,
  bodyValues: Record<string, string>
) =>
  ({
    type: AccountActionsEnum.CREATE_MULTIPLE_ACCOUNTS_FAILURE_ACTION,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "accounting-plan.accounts.modal.multiple.create.error.title",
      bodyKey: bodyKey,
      bodyValues,
    },
  } as const)

export const addAccountsTooManyLines = () =>
  ({
    type: AccountActionsEnum.ADD_ACCOUNTS_TOO_MANY_LINES,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey:
        "accounting-plan.accounts.modal.add-accounts.too-many-lines.title",
      bodyKey:
        "accounting-plan.accounts.modal.add-accounts.too-many-lines.body",
    },
  } as const)

export const updateAccountResetAction = () =>
  ({
    type: AccountActionsEnum.UPDATE_ACCOUNT_RESET_ACTION,
  } as const)
export const updateAccountAttemptAction = () =>
  ({
    type: AccountActionsEnum.UPDATE_ACCOUNT_ATTEMPT_ACTION,
  } as const)
export const updateAccountSuccessAction = () =>
  ({
    type: AccountActionsEnum.UPDATE_ACCOUNT_SUCCESS_ACTION,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: "accounting-plan.accounts.modal.modify.success.title",
      bodyKey: "accounting-plan.accounts.modal.modify.success.body",
    },
  } as const)
export const updateAccountFailureAction = (
  error: string,
  number: string | null,
  oldNumber?: string | null
) =>
  ({
    type: AccountActionsEnum.UPDATE_ACCOUNT_FAILURE_ACTION,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "accounting-plan.accounts.modal.modify.error.title",
      bodyKey: getAccountErrorId(error),
      bodyValues: {
        accountNumber: number,
        oldNumber: oldNumber,
      },
    },
  } as const)

export const getAccountInstructionsHistoryAttempt = () =>
  ({
    type: AccountActionsEnum.GET_ACCOUNT_INSTRUCTIONS_HISTORY_ATTEMPT,
  } as const)
export const getAccountInstructionsHistorySuccess = (
  payload: AccountInstruction[]
) =>
  ({
    type: AccountActionsEnum.GET_ACCOUNT_INSTRUCTIONS_HISTORY_SUCCESS,
    payload,
  } as const)
export const getAccountInstructionsHistoryFailure = (error: Error) =>
  ({
    type: AccountActionsEnum.GET_ACCOUNT_INSTRUCTIONS_HISTORY_FAILURE,
    error: error,
  } as const)

export const reactivateAccountAttempt = () =>
  ({ type: AccountActionsEnum.REACTIVATE_ACCOUNT_ATTEMPT } as const)
export const reactivateAccountSuccess = () =>
  ({
    type: AccountActionsEnum.REACTIVATE_ACCOUNT_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: "accounting-plan.accounts.reactivate.success.title",
      bodyKey: "accounting-plan.accounts.reactivate.success.body",
    },
  } as const)
export const reactivateAccountFailure = (error: string) =>
  ({
    type: AccountActionsEnum.REACTIVATE_ACCOUNT_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "accounting-plan.accounts.reactivate.error.title",
      bodyKey: getAccountErrorId(error),
    },
  } as const)

export const deactivateAccountAttempt = () =>
  ({ type: AccountActionsEnum.DEACTIVATE_ACCOUNT_ATTEMPT } as const)
export const deactivateAccountSuccess = () =>
  ({
    type: AccountActionsEnum.DEACTIVATE_ACCOUNT_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: "accounting-plan.accounts.deactivate.success.title",
      bodyKey: "accounting-plan.accounts.deactivate.success.body",
    },
  } as const)
export const deactivateAccountFailure = (
  error: string,
  number: string,
  merchantName: string
) =>
  ({
    type: AccountActionsEnum.DEACTIVATE_ACCOUNT_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "accounting-plan.accounts.deactivate.error.title",
      bodyKey: getAccountErrorId(error),
      bodyValues: {
        accountNumber: number,
        merchantName: merchantName,
      },
    },
  } as const)

export const deactivateOrReactivateAccountReset = () =>
  ({ type: AccountActionsEnum.DEACTIVATE_OR_REACTIVATE_ACCOUNT_RESET } as const)

export const GetBillOfExchangeAccountsAttempt = () =>
  ({ type: AccountActionsEnum.GET_BILL_OF_EXCHANGE_ACCOUNTS_ATTEMPT } as const)
export const GetBillOfExchangeAccountsSuccess = (
  accounts: Account[],
  rules: RulesBillOfExchange[],
  bill_of_exchange: AccountBillOfExchange[]
) =>
  ({
    type: AccountActionsEnum.GET_BILL_OF_EXCHANGE_ACCOUNTS_SUCCESS,
    accounts,
    rules,
    bill_of_exchange,
  } as const)
export const GetBillOfExchangeAccountsFailure = () =>
  ({ type: AccountActionsEnum.GET_BILL_OF_EXCHANGE_ACCOUNTS_FAILURE } as const)

export const UpdateBillOfExchangeAccountsAttempt = () =>
  ({
    type: AccountActionsEnum.UPDATE_BILL_OF_EXCHANGE_ACCOUNTS_ATTEMPT,
  } as const)
export const UpdateBillOfExchangeAccountsSuccess = () =>
  ({
    type: AccountActionsEnum.UPDATE_BILL_OF_EXCHANGE_ACCOUNTS_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey:
        "accounting-plan.lcr.parameter-system.accounts.tooltip.success-title",
      bodyKey:
        "accounting-plan.lcr.parameter-system.accounts.tooltip.success-message",
    },
  } as const)
export const UpdateBillOfExchangeAccountsFailure = () =>
  ({
    type: AccountActionsEnum.UPDATE_BILL_OF_EXCHANGE_ACCOUNTS_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey:
        "accounting-plan.lcr.parameter-system.accounts.tooltip.error-title",
      bodyKey:
        "accounting-plan.lcr.parameter-system.accounts.tooltip.error-message",
    },
  } as const)

export const AddRemoveUnitAttempt = () =>
  ({
    type: AccountActionsEnum.ADD_REMOVE_UNIT_ATTEMPT,
  } as const)
export const AddRemoveUnitSuccess = (addOrRemove: "add" | "remove") =>
  ({
    type: AccountActionsEnum.ADD_REMOVE_UNIT_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `accounting-plan.accounts.unit.${addOrRemove}.tooltip.success.title`,
      bodyKey: `accounting-plan.accounts.unit.${addOrRemove}.tooltip.success.message`,
    },
  } as const)
export const AddRemoveUnitFailure = (addOrRemove: "add" | "remove") =>
  ({
    type: AccountActionsEnum.ADD_REMOVE_UNIT_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `accounting-plan.accounts.unit.${addOrRemove}.tooltip.error.title`,
      bodyKey: `accounting-plan.accounts.unit.${addOrRemove}.tooltip.error.message`,
    },
  } as const)

export const extendAccountsAttempt = () =>
  ({
    type: AccountActionsEnum.EXTEND_ACCOUNTS_ATTEMPT,
  } as const)
export const extendAccountsSuccess = () =>
  ({
    type: AccountActionsEnum.EXTEND_ACCOUNTS_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `accounting-plan.accounts.extend.modal.success.title`,
      bodyKey: `accounting-plan.accounts.extend.modal.success.body`,
    },
  } as const)
export const extendAccountsFailure = (
  bodyKey: string,
  bodyValues: Record<string, string>
) =>
  ({
    type: AccountActionsEnum.EXTEND_ACCOUNTS_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `accounting-plan.accounts.extend.modal.error.title`,
      bodyKey: bodyKey,
      bodyValues: bodyValues,
    },
  } as const)
export const extendAccountsReset = () =>
  ({
    type: AccountActionsEnum.EXTEND_ACCOUNTS_RESET,
  } as const)

export const reduceAccountsAttempt = () =>
  ({
    type: AccountActionsEnum.REDUCE_ACCOUNTS_ATTEMPT,
  } as const)
export const reduceAccountsSuccess = () =>
  ({
    type: AccountActionsEnum.REDUCE_ACCOUNTS_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `accounting-plan.accounts.reduce.modal.success.title`,
      bodyKey: `accounting-plan.accounts.reduce.modal.success.body`,
    },
  } as const)
export const reduceAccountsFailure = (
  bodyKey: string,
  bodyValues: Record<string, string>
) =>
  ({
    type: AccountActionsEnum.REDUCE_ACCOUNTS_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `accounting-plan.accounts.reduce.modal.error.title`,
      bodyKey: bodyKey,
      bodyValues: bodyValues,
    },
  } as const)
export const reduceAccountsReset = () =>
  ({
    type: AccountActionsEnum.REDUCE_ACCOUNTS_RESET,
  } as const)

const getAccountErrorId = (errorMessage: string) => {
  let id = "error"

  if (errorMessage.includes("comme compte de Tiers sur votre dossier")) {
    id = "accounting-plan.accounts.deactivate.error.third-account.company"
  } else if (errorMessage.includes("il reste un PnlAccount")) {
    id = "accounting-plan.accounts.deactivate.error.pnl-account"
  } else if (errorMessage.includes("utilisé pour la TVA de votre dossier")) {
    id = "accounting-plan.accounts.deactivate.error.vat-account"
  } else if (
    errorMessage.includes("comme compte par défaut sur le fournisseur")
  ) {
    id = "accounting-plan.accounts.deactivate.error.merchant.default.buy"
  } else if (errorMessage.includes("comme compte par défaut sur le client")) {
    id = "accounting-plan.accounts.deactivate.error.merchant.default.sell"
  } else if (errorMessage.includes("comme compte de TVA sur le fournisseur")) {
    id = "accounting-plan.accounts.deactivate.error.merchant.vat.buy"
  } else if (errorMessage.includes("comme compte de TVA sur le client")) {
    id = "accounting-plan.accounts.deactivate.error.merchant.vat.sell"
  } else if (errorMessage.includes("autoliquidée déductible intraco")) {
    id = "accounting-plan.accounts.deactivate.error.company.eu-vat"
  } else if (errorMessage.includes("autoliquidée collectée intraco")) {
    id = "accounting-plan.accounts.deactivate.error.company.eu-vat-reverse"
  } else if (errorMessage.includes("autoliquidée déductible BTP")) {
    id = "accounting-plan.accounts.deactivate.error.company.construction"
  } else if (errorMessage.includes("autoliquidée collectée BTP")) {
    id =
      "accounting-plan.accounts.deactivate.error.company.construction-reverse"
  } else if (errorMessage.includes("LCR")) {
    id = "accounting-plan.accounts.deactivate.error.company.lcr"
  } else if (
    errorMessage.includes("can not change this account number to another rule")
  ) {
    id = "accounting-plan.accounts.deactivate.error.change-rule"
  } else if (errorMessage.includes("goods_transfer")) {
    id = "accounting-plan.accounts.deactivate.error.company.goods-transfer"
  } else if (
    errorMessage.includes(
      "Deactivator_user_admin and deactivator_user cant be not nil"
    )
  ) {
    id = "accounting-plan.accounts.deactivate.error.one-deactivator"
  } else if (errorMessage.includes("be unique per company")) {
    id = "accounting-plan.accounts.modal.create.error.body.unique-number"
  } else if (errorMessage.includes("Couldn't find Account with")) {
    id = "accounting-plan.accounts.error.no-account-found"
  } else if (
    errorMessage.includes("Vous devez renseigner les détails pour ce compte")
  ) {
    id = "accounting-plan.accounts.modal.create.error.body.details.empty"
  } else if (
    errorMessage.includes(
      "Vous devez renseigner un numéro d'identification pour ce compte"
    )
  ) {
    id = "accounting-plan.accounts.modal.create.error.body.number.empty"
  } else if (errorMessage.includes("is too long! Limit is")) {
    id = "accounting-plan.accounts.modal.create.error.body.number.too-long"
  } else if (errorMessage.includes("buy_deposit_account_id")) {
    id = "accounting-plan.accounts.deactivate.error.deposit_account.buy"
  } else if (errorMessage.includes("sell_deposit_account_id")) {
    id = "accounting-plan.accounts.deactivate.error.deposit_account.sell"
  }

  return id
}

type AccountsActionsType = ReturnType<
  | typeof loadPNLAccountsSuccessAction
  | typeof loadPNLAccountsFailureAction
  | typeof loadPNLAccountsAttemptAction
  | typeof loadAccountsSuccessAction
  | typeof loadAccountsFailureAction
  | typeof loadAccountsAttemptAction
  | typeof createAccountResetAction
  | typeof createAccountAttemptAction
  | typeof createAccountSuccessAction
  | typeof createAccountFailureAction
  | typeof createMultipleAccountsAttemptAction
  | typeof createMultipleAccountsSuccessAction
  | typeof createMultipleAccountsFailureAction
  | typeof updateAccountResetAction
  | typeof updateAccountAttemptAction
  | typeof updateAccountSuccessAction
  | typeof updateAccountFailureAction
  | typeof getAccountInstructionsHistoryAttempt
  | typeof getAccountInstructionsHistorySuccess
  | typeof getAccountInstructionsHistoryFailure
  | typeof reactivateAccountAttempt
  | typeof reactivateAccountSuccess
  | typeof reactivateAccountFailure
  | typeof deactivateAccountAttempt
  | typeof deactivateAccountSuccess
  | typeof deactivateAccountFailure
  | typeof deactivateOrReactivateAccountReset
  | typeof GetBillOfExchangeAccountsAttempt
  | typeof GetBillOfExchangeAccountsSuccess
  | typeof GetBillOfExchangeAccountsFailure
  | typeof UpdateBillOfExchangeAccountsAttempt
  | typeof UpdateBillOfExchangeAccountsSuccess
  | typeof UpdateBillOfExchangeAccountsFailure
  | typeof AddRemoveUnitAttempt
  | typeof AddRemoveUnitSuccess
  | typeof AddRemoveUnitFailure
  | typeof extendAccountsAttempt
  | typeof extendAccountsSuccess
  | typeof extendAccountsFailure
  | typeof extendAccountsReset
  | typeof reduceAccountsAttempt
  | typeof reduceAccountsSuccess
  | typeof reduceAccountsFailure
  | typeof reduceAccountsReset
>

export interface AccountsState {
  PNL: {
    [BOS in buyOrSell]: {
      [index: number]: Account
    }
  }
  PNLAccountsStatus: "UNSET" | "LOADING" | "SUCCESS" | "FAILURE"
  all_accounts: Account[]
  getAllAccountsStatus: "UNSET" | "LOADING" | "SUCCESS" | "FAILURE"
  extendAccountsStatus: "UNSET" | "LOADING" | "SUCCESS" | "FAILURE"
  reduceAccountsStatus: "UNSET" | "LOADING" | "SUCCESS" | "FAILURE"
  accountCreateStatus: "UNSET" | "LOADING" | "SUCCESS" | "FAILURE"
  accountUpdateStatus: "UNSET" | "LOADING" | "SUCCESS" | "FAILURE"
  accountInstructionsHistory: AccountInstruction[]
  reactivateOrDeactivateAccountStatus:
    | "UNSET"
    | "SUCCESS"
    | "LOADING"
    | "FAILURE"
  bill_of_exchange: AccountBillOfExchange[]
  accounts_for_bill_of_exchange: Account[]
  rules_bill_of_exchange: RulesBillOfExchange[]
}

export const accountsInitialState: AccountsState = {
  PNL: {
    buy: {},
    sell: {},
  },
  PNLAccountsStatus: "UNSET",
  all_accounts: [],
  getAllAccountsStatus: "UNSET",
  accountCreateStatus: "UNSET",
  accountUpdateStatus: "UNSET",
  accountInstructionsHistory: [],
  reactivateOrDeactivateAccountStatus: "UNSET",
  extendAccountsStatus: "UNSET",
  reduceAccountsStatus: "UNSET",
  bill_of_exchange: [],
  accounts_for_bill_of_exchange: [],
  rules_bill_of_exchange: [],
}

export function accountsReducer(
  state = accountsInitialState,
  action: AccountsActionsType
): AccountsState {
  switch (action.type) {
    case AccountActionsEnum.LOAD_PNL_ACCOUNTS_SUCCESS:
      return { ...state, PNL: action.payload, PNLAccountsStatus: "SUCCESS" }
    case AccountActionsEnum.LOAD_PNL_ACCOUNTS_ATTEMPT:
      return { ...state, PNLAccountsStatus: "LOADING" }
    case AccountActionsEnum.LOAD_PNL_ACCOUNTS_FAILURE:
      return { ...state, PNLAccountsStatus: "FAILURE" }
    case AccountActionsEnum.LOAD_ACCOUNTS_SUCCESS:
      return {
        ...state,
        all_accounts: action.payload,
        getAllAccountsStatus: "SUCCESS",
      }
    case AccountActionsEnum.LOAD_ACCOUNTS_ATTEMPT:
      return { ...state, getAllAccountsStatus: "LOADING" }
    case AccountActionsEnum.LOAD_ACCOUNTS_FAILURE:
      return { ...state, getAllAccountsStatus: "FAILURE" }

    case AccountActionsEnum.CREATE_ACCOUNT_RESET_ACTION:
      return { ...state, accountCreateStatus: "UNSET" }
    case AccountActionsEnum.CREATE_ACCOUNT_ATTEMPT_ACTION:
      return { ...state, accountCreateStatus: "LOADING" }
    case AccountActionsEnum.CREATE_ACCOUNT_FAILURE_ACTION:
      return { ...state, accountCreateStatus: "FAILURE" }
    case AccountActionsEnum.CREATE_ACCOUNT_SUCCESS_ACTION:
      return {
        ...state,
        all_accounts: [action.payload, ...state.all_accounts],
        accountCreateStatus: "SUCCESS",
      }

    case AccountActionsEnum.CREATE_MULTIPLE_ACCOUNTS_ATTEMPT_ACTION:
      return { ...state, accountCreateStatus: "LOADING" }
    case AccountActionsEnum.CREATE_MULTIPLE_ACCOUNTS_FAILURE_ACTION:
      return { ...state, accountCreateStatus: "FAILURE" }
    case AccountActionsEnum.CREATE_MULTIPLE_ACCOUNTS_SUCCESS_ACTION:
      return {
        ...state,
        accountCreateStatus: "SUCCESS",
        all_accounts: [...action.payload.newAccounts, ...state.all_accounts],
      }

    case AccountActionsEnum.UPDATE_ACCOUNT_RESET_ACTION:
      return { ...state, accountUpdateStatus: "UNSET" }
    case AccountActionsEnum.UPDATE_ACCOUNT_ATTEMPT_ACTION:
      return { ...state, accountUpdateStatus: "LOADING" }
    case AccountActionsEnum.UPDATE_ACCOUNT_FAILURE_ACTION:
      return { ...state, accountUpdateStatus: "FAILURE" }
    case AccountActionsEnum.UPDATE_ACCOUNT_SUCCESS_ACTION:
      return {
        ...state,
        accountUpdateStatus: "SUCCESS",
      }
    case AccountActionsEnum.REACTIVATE_ACCOUNT_ATTEMPT:
      return { ...state, reactivateOrDeactivateAccountStatus: "LOADING" }
    case AccountActionsEnum.REACTIVATE_ACCOUNT_FAILURE:
      return { ...state, reactivateOrDeactivateAccountStatus: "FAILURE" }
    case AccountActionsEnum.REACTIVATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        reactivateOrDeactivateAccountStatus: "SUCCESS",
      }
    case AccountActionsEnum.DEACTIVATE_ACCOUNT_ATTEMPT:
      return { ...state, reactivateOrDeactivateAccountStatus: "LOADING" }
    case AccountActionsEnum.DEACTIVATE_ACCOUNT_FAILURE:
      return { ...state, reactivateOrDeactivateAccountStatus: "FAILURE" }
    case AccountActionsEnum.DEACTIVATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        reactivateOrDeactivateAccountStatus: "SUCCESS",
      }
    case AccountActionsEnum.DEACTIVATE_OR_REACTIVATE_ACCOUNT_RESET:
      return { ...state, reactivateOrDeactivateAccountStatus: "UNSET" }
    case AccountActionsEnum.GET_ACCOUNT_INSTRUCTIONS_HISTORY_SUCCESS:
      return { ...state, accountInstructionsHistory: action.payload }
    case AccountActionsEnum.GET_BILL_OF_EXCHANGE_ACCOUNTS_SUCCESS:
      return {
        ...state,
        accounts_for_bill_of_exchange: action.accounts,
        rules_bill_of_exchange: action.rules,
        bill_of_exchange: action.bill_of_exchange,
      }

    case AccountActionsEnum.EXTEND_ACCOUNTS_ATTEMPT:
      return { ...state, extendAccountsStatus: "LOADING" }
    case AccountActionsEnum.EXTEND_ACCOUNTS_SUCCESS:
      return { ...state, extendAccountsStatus: "SUCCESS" }
    case AccountActionsEnum.EXTEND_ACCOUNTS_FAILURE:
      return { ...state, extendAccountsStatus: "FAILURE" }
    case AccountActionsEnum.EXTEND_ACCOUNTS_RESET:
      return { ...state, extendAccountsStatus: "UNSET" }

    case AccountActionsEnum.REDUCE_ACCOUNTS_ATTEMPT:
      return { ...state, reduceAccountsStatus: "LOADING" }
    case AccountActionsEnum.REDUCE_ACCOUNTS_SUCCESS:
      return { ...state, reduceAccountsStatus: "SUCCESS" }
    case AccountActionsEnum.REDUCE_ACCOUNTS_FAILURE:
      return { ...state, reduceAccountsStatus: "FAILURE" }
    case AccountActionsEnum.REDUCE_ACCOUNTS_RESET:
      return { ...state, reduceAccountsStatus: "UNSET" }
    default:
      return { ...state }
  }
}

export const loadPNLAccountsThunk =
  (companyId: number) => (dispatch: Dispatch<AccountsActionsType>) => {
    dispatch(loadPNLAccountsAttemptAction())
    return axios
      .get<{ buy_accounts: Array<Account>; sell_accounts: Array<Account> }>(
        `/companies/accounting_data_of_company`,
        // all accounts are always present regardless of buy/sell
        // buy/sell is only useful when loading merchants
        { params: { buy_or_sell: "buy", id: companyId } }
      )
      .then((response) => {
        return {
          buy: indexedObjectFromArray(response.data.buy_accounts),
          sell: indexedObjectFromArray(response.data.sell_accounts),
        }
      })
      .then((data) => dispatch(loadPNLAccountsSuccessAction(data)))
      .catch((error) => dispatch(loadPNLAccountsFailureAction(error)))
  }

export const getAllAccountsThunk =
  (companyId: number) => (dispatch: Dispatch<AccountsActionsType>) => {
    return axios
      .get<{ accounts: Account[] }>(`/accounts/get_all_accounts`, {
        params: { company_id: companyId },
      })
      .then((response) =>
        dispatch(loadAccountsSuccessAction(response.data.accounts))
      )
      .catch((error) => dispatch(loadAccountsFailureAction(error)))
  }

interface CreateAccountResponse {
  auto_select_buy: boolean
  auto_select_sell: boolean
  new_account: Account
}

export const createAccountThunk =
  (
    companyId: number,
    number: string,
    details: string,
    writingLineUuid?: string
  ) =>
  (dispatch: Dispatch<AnyAction>) => {
    dispatch(createAccountAttemptAction())
    return axios
      .post<CreateAccountResponse>(`/accounts`, {
        company_id: companyId,
        account_number: number,
        account_details: details,
      })
      .then(({ data: { new_account, auto_select_buy, auto_select_sell } }) => {
        dispatch(
          createAccountSuccessAction({
            newAccount: new_account,
          })
        )
        if ((auto_select_buy || auto_select_sell) && writingLineUuid) {
          dispatch(
            selectNewAccountAction({
              buyOrsell: auto_select_buy ? "buy" : "sell",
              newAccount: new_account,
              writingLineUuid,
            })
          )
        }
      })
      .catch((error) =>
        dispatch(createAccountFailureAction(error.response.data.error))
      )
  }

interface CreateAccountResponse {
  created_accounts: Account[]
}
export const createMultipleAccountThunk =
  (companyId: number, accountsToCreate: AccountToCreate[]) =>
  (dispatch: Dispatch<AnyAction>) => {
    dispatch(createMultipleAccountsAttemptAction())
    return axios
      .put<CreateAccountResponse>(`/accounts/create_all_accounts`, {
        company_id: companyId,
        accounts_to_create: accountsToCreate,
      })
      .then(({ data: { created_accounts } }) => {
        dispatch(
          createMultipleAccountsSuccessAction({
            newAccounts: created_accounts,
          })
        )
      })
      .catch((error) => {
        const errorMessage = error.response.data.error
        let bodyKey =
          "accounting-plan.accounts.modal.multiple.create.error.body"
        let bodyValues = {}
        if (errorMessage.includes("is too long! Limit is")) {
          const regex = /account (\d+) is too long! Limit is (\d+)/
          const match = errorMessage.match(regex)

          if (match) {
            bodyKey =
              "accounting-plan.accounts.modal.multiple.create.error.too-long.body"
            bodyValues = { account: match[1], limit: match[2] }
          }
        }
        dispatch(createMultipleAccountsFailureAction(bodyKey, bodyValues))
      })
  }

export const updateAccountThunk =
  (
    companyId: number,
    accountId: number,
    newNumber: string,
    oldNumber: string,
    newDetails: string,
    oldDetails: string
  ) =>
  (dispatch: Dispatch<AccountsActionsType>) => {
    dispatch(updateAccountAttemptAction())
    return axios
      .put<{ accounts: Account[] }>(`/accounts/${accountId}`, {
        company_id: companyId,
        new_account_number: newNumber,
        old_account_number: oldNumber,
        new_account_details: newDetails,
        old_account_details: oldDetails,
      })
      .then(() => dispatch(updateAccountSuccessAction()))
      .catch((error) => {
        dispatch(
          updateAccountFailureAction(
            error.response.data.error,
            newNumber,
            oldNumber
          )
        )
      })
  }

export interface AccountInstruction {
  created_at: string
  instruction_type: string
  email: string
  metadata: {
    old_account_number?: string
    old_account_details?: string
    unit_id?: number
  }
}

export const getAccountInstructionsHistory =
  (companyId: number, accountId: number) =>
  (dispatch: Dispatch<AccountsActionsType>) => {
    dispatch(getAccountInstructionsHistoryAttempt())
    return axios
      .get<AccountInstruction[]>(`/accounts/get_account_instructions_history`, {
        params: { company_id: companyId, account_id: accountId },
      })
      .then((response) =>
        dispatch(getAccountInstructionsHistorySuccess(response.data))
      )
      .catch((error) => dispatch(getAccountInstructionsHistoryFailure(error)))
  }

export const reactivateAccountThunk =
  (companyId: number, currentAccount: Account) =>
  (dispatch: Dispatch<AccountsActionsType>) => {
    dispatch(reactivateAccountAttempt())
    return axios
      .put(`/accounts/reactivate_account`, {
        company_id: companyId,
        account_id: currentAccount.id,
        old_account_number: currentAccount.number,
      })
      .then(() => dispatch(reactivateAccountSuccess()))
      .catch((error) =>
        dispatch(reactivateAccountFailure(error.response.data.error))
      )
  }

export const deactivateAccountThunk =
  (companyId: number, currentAccount: Account) =>
  (dispatch: Dispatch<AccountsActionsType>) => {
    dispatch(deactivateAccountAttempt())
    return axios
      .put<{ merchantCode: string }>(`/accounts/deactivate_account`, {
        company_id: companyId,
        account_id: currentAccount.id,
        old_account_number: currentAccount.number,
      })
      .then((response) => {
        dispatch(deactivateAccountSuccess())
      })
      .catch((error) => {
        let merchantName = ""
        if (
          error.response.data.error.includes("compte de TVA") ||
          error.response.data.error.includes("compte par défaut")
        ) {
          merchantName = error.response.data.merchantName
        }
        dispatch(
          deactivateAccountFailure(
            error.response.data.error,
            currentAccount.number,
            merchantName
          )
        )
      })
  }

export const GetBillOfExchangeAccountsThunk =
  (companyId: number) => (dispatch: Dispatch<AccountsActionsType>) => {
    dispatch(GetBillOfExchangeAccountsAttempt())
    return axios
      .get<{
        accounts: Account[]
        rules: RulesBillOfExchange[]
        bill_of_exchange: AccountBillOfExchange[]
      }>(`/accounts/get_bill_of_exchange_accounts_and_rules`, {
        params: { company_id: companyId },
      })
      .then((response) =>
        dispatch(
          GetBillOfExchangeAccountsSuccess(
            response.data.accounts,
            response.data.rules,
            response.data.bill_of_exchange
          )
        )
      )
      .catch((error) => dispatch(GetBillOfExchangeAccountsFailure()))
  }

export const UpdateBillOfExchangeAccountsThunk =
  (
    companyId: number,
    accounts: { account_id: number; month_number: number }[]
  ) =>
  (dispatch: Dispatch<AccountsActionsType | RNBThunkAction>) => {
    dispatch(UpdateBillOfExchangeAccountsAttempt())
    return axios
      .put(`/accounts/update_bill_of_exchange_accounts`, {
        company_id: companyId,
        accounts: accounts,
      })
      .then(() => {
        dispatch(UpdateBillOfExchangeAccountsSuccess())
        dispatch(GetBillOfExchangeAccountsThunk(companyId))
      })
      .catch((error) => dispatch(UpdateBillOfExchangeAccountsFailure()))
  }

export const AddRemoveUnitThunk =
  (
    companyId: number,
    accountId: number,
    unit_number: number,
    unit_id: number | null
  ) =>
  (dispatch: Dispatch<AccountsActionsType | RNBThunkAction>) => {
    dispatch(AddRemoveUnitAttempt())
    return axios
      .put(`/accounts/add_remove_units`, {
        company_id: companyId,
        account_id: accountId,
        unit_number: unit_number,
        unit_id: unit_id,
      })
      .then(() => {
        dispatch(AddRemoveUnitSuccess(unit_id ? "add" : "remove"))
        dispatch(getAllAccountsThunk(companyId))
      })
      .catch((error) =>
        dispatch(AddRemoveUnitFailure(unit_id ? "add" : "remove"))
      )
  }

export interface AccountBillOfExchange {
  id: number
  account_id: number
  month_number: number
}

export interface RulesBillOfExchange {
  id: number
  code: string
  merchant_code_id: number
  text_in_description: string
  priority_number: number
}

interface ExtendAccountsResponse {
  not_updated: string[]
}

export const extendAccountsThunk =
  (companyId: number, newLength: number) =>
  (dispatch: Dispatch<AnyAction | RNBThunkAction>) => {
    dispatch(extendAccountsAttempt())
    return axios
      .put<ExtendAccountsResponse>(`/companies/${companyId}/extend_accounts`, {
        new_length: newLength,
      })
      .then(() => {
        dispatch(extendAccountsSuccess())
        dispatch(getAllAccountsThunk(companyId))
      })
      .catch((e) => {
        const errorMsg = e.response.data.error
        let bodyKey = "accounting-plan.accounts.extend.modal.error.body"
        let bodyValues = {}
        if (errorMsg.includes("Erreur la taille maximale des comptes est de")) {
          const regex =
            /Erreur la taille maximale des comptes est de (\d+) chiffres/
          const match = errorMsg.match(regex)
          bodyKey = "accounting-plan.accounts.extend.modal.error.length.body"
          if (match) bodyValues = { maxLength: match[1] }
        }
        dispatch(extendAccountsFailure(bodyKey, bodyValues))
      })
  }

interface ReduceAccountsResponse {
  updated: string[]
  cannot_update: string[]
}
export const reduceAccountsThunk =
  (companyId: number, newLength: number) =>
  (dispatch: Dispatch<AnyAction | RNBThunkAction>) => {
    dispatch(reduceAccountsAttempt())
    return axios
      .put<ReduceAccountsResponse>(`/companies/${companyId}/reduce_accounts`, {
        new_length: newLength,
      })
      .then(() => {
        dispatch(reduceAccountsSuccess())
        dispatch(getAllAccountsThunk(companyId))
      })
      .catch((e) => {
        let bodyKey = "accounting-plan.accounts.reduce.modal.error.body"
        dispatch(reduceAccountsFailure(bodyKey, {}))
      })
  }
