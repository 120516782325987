import {
  MerchantCode,
  RenameMerchantCodeReset,
  renameMerchantCodeThunk,
} from "../../store/ducks/merchantCodes.ducks"
import * as Ct from "ldlj"
import styled from "styled-components/macro"
import { useIntl } from "react-intl"
import { useEffect, useState } from "react"
import { LockableInput } from "../Commons/LockableInput"
import { ReactComponent as CloseCross } from "../../assets/close-cross.svg"
import { useRNBSelector } from "../../store/rootReducer"
import {
  addPrefixToCode,
  normalizeMerchantCode,
} from "../../utils/merchantCodes"
import { getIdFromParams } from "../../utils/company"
import { useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import ReactTooltip from "react-tooltip"
import { useKeyDown } from "../../hooks/useKeyDown"

interface EditMerchantCodeProps extends Ct.ModalComponentsProps {
  merchantCode: MerchantCode
}
export const EditMerchantCode = ({
  isDisplayed,
  onClose,
  merchantCode,
}: EditMerchantCodeProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const [newCode, setNewCode] = useState<string>(merchantCode.code)

  const [errorCode, setErrorCode] = useState<string | null>(null)
  const [defaultSellCode, setDefaultSellCode] = useState<boolean>(false)
  const [defaultBuyCode, setDefaultBuyCode] = useState<boolean>(false)
  const [buyDefaultForCompany, setBuyDefaultForCompany] =
    useState<MerchantCode>()
  const [sellDefaultForCompany, setSellDefaultForCompany] =
    useState<MerchantCode>()
  const selectedCompanyId = getIdFromParams(useParams())("company_id") || 0
  const { merchantCodes, updateOneMerchantStatus } = useRNBSelector(
    (state) => state.merchantCodes
  )
  const company = useRNBSelector(
    (state) => state.companies.companies[selectedCompanyId]
  )
  const [prefixes, setPrefixes] = useState<{ buy: string; sell: string }>({
    buy: "",
    sell: "",
  })

  useEffect(() => {
    setBuyDefaultForCompany(
      merchantCodes.find((c) => c.default_buy_code === true)
    )
    setSellDefaultForCompany(
      merchantCodes.find((c) => c.default_sell_code === true)
    )
  }, [merchantCodes])

  useEffect(() => {
    setPrefixes(addPrefixToCode(company))
  }, [company])

  useEffect(() => {
    if (newCode !== "" && newCode !== merchantCode.code) {
      const alreadyExist = merchantCodes.find(
        (c) => c.code.toLowerCase() === newCode.toLowerCase()
      )
      if (alreadyExist) {
        setErrorCode("accounting-plan.rename-code.error-already-exist")
      } else {
        setErrorCode(null)
      }
    }
  }, [newCode])

  useEffect(() => {
    setDefaultBuyCode(merchantCode.default_buy_code)
    setDefaultSellCode(merchantCode.default_sell_code)
  }, [merchantCode])

  const close = () => {
    if (newCode.toLowerCase() !== merchantCode.code.toLowerCase()) {
      const warningMessage = intl.formatMessage(
        { id: "accounting-plan.add-merchant.exit-message-confirm" },
        { code: merchantCode.code }
      )
      if (window.confirm(warningMessage)) {
        onClose()
        dispatch(RenameMerchantCodeReset())
      }
    } else {
      onClose()
      dispatch(RenameMerchantCodeReset())
    }
  }

  const handleValidation = () => {
    dispatch(
      renameMerchantCodeThunk(
        merchantCode.id,
        newCode,
        selectedCompanyId,
        defaultBuyCode,
        defaultSellCode
      )
    )
  }

  useEffect(() => {
    if (updateOneMerchantStatus === "success") {
      onClose()
      dispatch(RenameMerchantCodeReset())
    }
  }, [updateOneMerchantStatus])

  useKeyDown(() => {
    if (isDisplayed) handleValidation()
  }, ["Enter"])

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={close}
      left="50%"
      right="50%"
      top={`calc(30vh - 20rem)`}
    >
      <StyledCard width="150rem">
        <StyledHeader>
          <div />

          <StyledTitle
            text={intl.formatMessage({
              id: `accounting-plan.rename-code.title`,
            })}
          />

          <ModalClose onClick={() => close()}>
            <CloseCross />
          </ModalClose>
        </StyledHeader>

        <Ct.Spacer height={3} />
        <Ct.Separator size="full" />
        <Ct.Spacer height={3} />

        <WrapperInputs>
          <CodeWrapper>
            <Ct.Input
              label="Code tiers"
              value={newCode}
              maxWidth={35}
              onChange={(e) => {
                setNewCode(normalizeMerchantCode(e.target.value))
              }}
              showError={!!errorCode}
            />
            {errorCode && (
              <>
                <Ct.Spacer height={2} />
                <Ct.Text
                  text={intl.formatMessage({ id: errorCode })}
                  textStyle={{
                    color: "amaranth",
                    fontSize: 2,
                  }}
                />
              </>
            )}
          </CodeWrapper>

          <Ct.Spacer width={2} />

          <LockableInput
            label={intl.formatMessage(
              {
                id: "accounting-plan.create-code.part-one.output-label",
              },
              { buyOrSell: " fournisseurs" }
            )}
            value={newCode}
            maxWidth={"35rem"}
            locked={true}
            readOnly={true}
            cursor={"default"}
            prefixString={prefixes.buy}
            padding={
              prefixes.buy && prefixes.buy.length > 0
                ? `0 1.75rem 0 ${prefixes.buy.length + 2.5}rem`
                : "0 1.75rem"
            }
          />

          <Ct.Spacer width={2} />

          <LockableInput
            label={intl.formatMessage(
              {
                id: "accounting-plan.create-code.part-one.output-label",
              },
              { buyOrSell: " clients" }
            )}
            value={newCode}
            maxWidth={"35rem"}
            locked={true}
            readOnly={true}
            cursor={"default"}
            prefixString={prefixes.sell}
            padding={
              prefixes.sell && prefixes.sell.length > 0
                ? `0 1.75rem 0 ${prefixes.sell.length + 2.5}rem`
                : "0 1.75rem"
            }
          />
        </WrapperInputs>

        <Ct.Spacer height={3} />
        <RowFlex
          data-tip={
            buyDefaultForCompany
              ? ""
              : intl.formatMessage({
                  id: "accounting-plan.create-code.uniq-code.tooltip",
                })
          }
        >
          <Ct.Checkbox
            label={""}
            isChecked={defaultBuyCode}
            name=""
            onChange={() => {
              if (
                !merchantCode.centralize ||
                (!!buyDefaultForCompany &&
                  buyDefaultForCompany.id !== merchantCode.id)
              )
                return
              setDefaultBuyCode(!defaultBuyCode)
            }}
            disabled={!merchantCode.centralize || !!buyDefaultForCompany}
          />

          <Ct.Text
            text={intl.formatMessage(
              {
                id:
                  buyDefaultForCompany === undefined
                    ? "accounting-plan.create-code.uniq-code"
                    : buyDefaultForCompany.id === merchantCode.id
                    ? "accounting-plan.create-code.uniq-code.this-code-selected"
                    : "accounting-plan.create-code.uniq-code.already-has-for-company",
              },
              { type: "tous", code: buyDefaultForCompany?.code }
            )}
            textStyle={{
              color: "navy",
              fontSize: 1.75,
            }}
          />
          <Ct.Spacer width={0.5} />
          <Ct.Text
            text={intl.formatMessage({
              id: "accounting-plan.create-code.uniq-code.buy",
            })}
            textStyle={{
              color: "orange",
              fontSize: 1.75,
              fontWeight: 700,
              textTransform: "uppercase",
            }}
          />
        </RowFlex>

        {!merchantCode.centralize && (
          <ReactTooltip
            delayShow={300}
            effect={"solid"}
            eventOff="mouseleave scroll mousewheel blur"
          />
        )}

        <Ct.Spacer height={2} />

        <RowFlex
          data-tip={
            sellDefaultForCompany
              ? ""
              : intl.formatMessage({
                  id: "accounting-plan.create-code.uniq-code.tooltip",
                })
          }
        >
          <Ct.Checkbox
            label={""}
            isChecked={defaultSellCode}
            name=""
            onChange={() => {
              if (
                !merchantCode.centralize ||
                (!!sellDefaultForCompany &&
                  sellDefaultForCompany.id !== merchantCode.id)
              )
                return
              setDefaultSellCode(!defaultSellCode)
            }}
            disabled={!merchantCode.centralize || !!sellDefaultForCompany}
          />
          <Ct.Text
            text={intl.formatMessage(
              {
                id:
                  sellDefaultForCompany === undefined
                    ? "accounting-plan.create-code.uniq-code"
                    : sellDefaultForCompany.id === merchantCode.id
                    ? "accounting-plan.create-code.uniq-code.this-code-selected"
                    : "accounting-plan.create-code.uniq-code.already-has-for-company",
              },
              { type: "toutes", code: sellDefaultForCompany?.code }
            )}
            textStyle={{
              color: "navy",
              fontSize: 1.75,
            }}
          />
          <Ct.Spacer width={0.5} />
          <Ct.Text
            text={intl.formatMessage({
              id: "accounting-plan.create-code.uniq-code.sell",
            })}
            textStyle={{
              color: "brightSun",
              fontSize: 1.75,
              fontWeight: 700,
              textTransform: "uppercase",
            }}
          />
        </RowFlex>

        <WrapperButtons>
          <Ct.Button
            label={"Annuler"}
            width={35}
            colorType={"Tertiary"}
            colorScheme={{
              background: "mist",
              color: "cornflower",
              border: "mist",
            }}
            onClick={() => {
              close()
            }}
          />

          <Ct.Button
            label={"Valider"}
            width={35}
            disabled={
              !!errorCode ||
              newCode === "" ||
              (newCode.toLowerCase() === merchantCode.code.toLowerCase() &&
                merchantCode.default_sell_code === defaultSellCode &&
                merchantCode.default_buy_code === defaultBuyCode)
            }
            onClick={() => {
              handleValidation()
            }}
          />
        </WrapperButtons>
      </StyledCard>
    </Ct.Modal>
  )
}

const StyledCard = styled((props) => <Ct.Card {...props} />)`
  padding: 3.5rem 0;
  border-radius: 2rem;
`
const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 140rem;
`
const StyledTitle = styled((props) => <Ct.Title {...props} />)`
  font-size: 24px;
`
const ModalClose = styled.div`
  cursor: pointer;
`

const WrapperInputs = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 4rem 5rem;
`

const WrapperButtons = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 80%;
  margin: 4rem 5rem;
`

const CodeWrapper = styled.div`
  width: 35rem;
`
const RowFlex = styled.div`
  display: flex;
  width: fit-content;
  width: 80%;
`
