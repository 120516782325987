import * as Ct from "ldlj"
import styled from "styled-components/macro"
import { useState } from "react"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { RegenerateArchiveThunk } from "../../store/ducks/writings.ducks"
import { useRNBSelector } from "../../store/rootReducer"
import { Switch } from "../Commons/Switch"
import { Alert } from "../Commons/Alert"
import { ReactComponent as CloseCross } from "../../assets/close-cross.svg"
import { sizes } from "../../styles/design.config"

interface RegenerateArchiveProps extends Ct.ModalComponentsProps {
  archiveId: number
  companyId: number
  fiscalYearId: number
}

export const RegenerateArchiveModal = ({
  isDisplayed,
  onClose,
  archiveId,
  companyId,
  fiscalYearId,
}: RegenerateArchiveProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const [selectedSendToApi, setSelectedSendToApi] = useState(true)

  const isConnectedToApi = useRNBSelector(
    (state) => state.companies.companies[companyId]?.is_connected_to_api
  )

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={onClose}
      left="50%"
      right="50%"
      top="calc(50vh - 50rem)"
    >
      <StyledCard width="130rem">
        <StyledHeader>
          <div />

          <StyledTitle
            text={intl.formatMessage(
              {
                id: `writing-archives.regenerate.title`,
              },
              { archiveId: archiveId }
            )}
          />

          <ModalClose onClick={() => onClose()}>
            <CloseCross />
          </ModalClose>
        </StyledHeader>

        <Ct.Spacer height={3} />
        <Ct.Separator size="full" />
        <Ct.Spacer height={3} />

        <Alert alertType="bulb">
          <TextWrapper>
            <Ct.Text
              text={intl.formatMessage(
                {
                  id: "writing-archives.regenerate.alert1",
                },
                { archiveId: archiveId }
              )}
              textStyle={{
                lineHeight: 3,
              }}
            />
            <Ct.Text
              text={intl.formatMessage(
                {
                  id: "writing-archives.regenerate.alert2",
                },
                { archiveId: archiveId }
              )}
              textStyle={{
                lineHeight: 3,
              }}
            />
            <Ct.Text
              text={intl.formatMessage(
                {
                  id: "writing-archives.regenerate.alert3",
                },
                { archiveId: archiveId }
              )}
              textStyle={{
                lineHeight: 3,
              }}
            />
            <Ct.Text
              text={intl.formatMessage(
                {
                  id: "writing-archives.regenerate.alert4",
                },
                { archiveId: archiveId }
              )}
              textStyle={{
                lineHeight: 3,
              }}
            />
            <Ct.Text
              text={intl.formatMessage({
                id: "writing-archives.regenerate.alert5",
              })}
              textStyle={{
                lineHeight: 3,
              }}
            />
            <Ct.Text
              text={intl.formatMessage({
                id: "writing-archives.regenerate.alert6",
              })}
              textStyle={{
                lineHeight: 3,
              }}
            />
          </TextWrapper>
        </Alert>

        {isConnectedToApi && (
          <>
            <Ct.Spacer height={4} />
            <Wrapper>
              <Switch
                value={selectedSendToApi}
                onToggle={() => {
                  setSelectedSendToApi(!selectedSendToApi)
                }}
                size={"normal"}
              />
              <Ct.Spacer width={2} />
              <Ct.Text
                text={intl.formatMessage({
                  id: `writings.send-to-api.${selectedSendToApi}`,
                })}
                textStyle={{
                  fontSize: 2,
                }}
              />
            </Wrapper>
          </>
        )}

        <Ct.Spacer height={4} />

        <WrapperButtons>
          <Ct.Button
            colorType={"Tertiary"}
            colorScheme={{
              background: "mist",
              color: "cornflower",
              border: "mist",
            }}
            width={sizes.button.standard}
            label={intl.formatMessage({
              id: "writing-archives.regenerate-cancel",
            })}
            onClick={onClose}
          />

          <Ct.Spacer />

          <Ct.Button
            colorType={"Primary"}
            label={intl.formatMessage({
              id: "writing-archives.regenerate",
            })}
            width={sizes.button.standard}
            onClick={() => {
              if (isConnectedToApi) {
                dispatch(
                  RegenerateArchiveThunk(
                    archiveId,
                    companyId,
                    fiscalYearId,
                    selectedSendToApi
                  )
                )
              } else {
                dispatch(
                  RegenerateArchiveThunk(archiveId, companyId, fiscalYearId)
                )
              }

              onClose()
            }}
          />
        </WrapperButtons>

        <Ct.Spacer height={4} />
      </StyledCard>
    </Ct.Modal>
  )
}

const StyledCard = styled((props) => <Ct.Card {...props} />)`
  padding: 3.5rem 0;
  border-radius: 2rem;
`
const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 120rem;
`
const StyledTitle = styled((props) => <Ct.Title {...props} />)`
  font-size: 24px;
`
const ModalClose = styled.div`
  cursor: pointer;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const WrapperButtons = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`
const Wrapper = styled.div`
  display: flex;
  width: 60%;
  align-items: center;
`
