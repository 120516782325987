import * as Ct from "ldlj"
import styled from "styled-components/macro"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { ReactComponent as CloseCross } from "../../assets/close-cross.svg"
import {
  DeleteArchiveThunk,
  DeleteArchiveReset,
} from "../../store/ducks/writings.ducks"
import { useRNBSelector } from "../../store/rootReducer"
import { useEffect } from "react"

interface DeleteArchiveProps {
  onClose: () => void
  isDisplayed: boolean
  archiveId: number | null
  companyId: number
  fiscalYearId: number
}

export const DeleteArchiveModal = ({
  isDisplayed,
  onClose,
  archiveId,
  companyId,
  fiscalYearId,
}: DeleteArchiveProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const deleteArchiveStatus = useRNBSelector(
    (state) => state.writings.deleteArchiveStatus
  )

  const handleDeleteWriting = () => {
    if (archiveId)
      dispatch(DeleteArchiveThunk(archiveId, companyId, fiscalYearId))
  }

  const closeModal = () => {
    dispatch(DeleteArchiveReset())
    onClose()
  }

  useEffect(() => {
    if (deleteArchiveStatus === "success") closeModal()
  }, [deleteArchiveStatus])

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={closeModal}
      left="50%"
      right="50%"
      top="calc(50vh - 30rem)"
    >
      <StyledCard width="110rem">
        <StyledHeader>
          <div />

          <StyledTitle
            text={intl.formatMessage(
              { id: "writings.delete.modal.title" },
              { archiveId: archiveId }
            )}
          />

          <ModalClose onClick={() => closeModal()}>
            <CloseCross />
          </ModalClose>
        </StyledHeader>

        <Ct.Spacer height={3} />
        <Ct.Separator size="full" />
        <Ct.Spacer height={3} />

        <Ct.Text
          text={intl.formatMessage({ id: "writings.delete.modal.info" })}
          textStyle={{ lineHeight: 3, textAlign: "center", fontSize: 2 }}
        />
        <Ct.Spacer height={3} />
        <Ct.Text
          text={intl.formatMessage({ id: "writings.delete.modal.question" })}
          textStyle={{ lineHeight: 3, textAlign: "center", fontSize: 2 }}
        />

        <Ct.Spacer height={4} />

        <Ct.FlexShrinkZero>
          <Ct.Button
            width={30}
            colorType={"Tertiary"}
            colorScheme={{
              background: "mist",
              color: "cornflower",
              border: "mist",
            }}
            label={intl.formatMessage({
              id: "writings.delete.modal.back",
            })}
            onClick={closeModal}
          />
          <Ct.Spacer />
          <Ct.Button
            width={30}
            label={intl.formatMessage({
              id: "writings.delete.modal.delete",
            })}
            onClick={() => handleDeleteWriting()}
            loadingStatus={deleteArchiveStatus}
          />
        </Ct.FlexShrinkZero>
      </StyledCard>
    </Ct.Modal>
  )
}

const StyledCard = styled((props) => <Ct.Card {...props} />)`
  padding: 3.5rem 0;
  border-radius: 2rem;
`
const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100rem;
`
const StyledTitle = styled((props) => <Ct.Title {...props} />)`
  font-size: 24px;
`
const ModalClose = styled.div`
  cursor: pointer;
`
