/* eslint-disable camelcase */
import { useRNBSelector } from "../../../store/rootReducer"
import { useDispatch } from "react-redux"
import React, {
  CSSProperties,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { DateTime } from "luxon"
import { getFiduciaryKPIsThunk, KPIFile } from "../../../store/ducks/kpis.ducks"
import styled, { createGlobalStyle } from "styled-components/macro"
import { FileRow } from "../../../components/FileRow"
import { useIntl } from "react-intl"
import * as Ct from "ldlj"
import { Companies } from "../../../store/ducks/companies.ducks"
import { getCurrentUserThunk } from "../../../store/ducks/user.ducks"
import { useNavigate } from "react-router-dom"
import { SortButton } from "../../../components/Commons/SortButton"
import { boxShadow, colors } from "../../../styles/design.config"
import { ReactComponent as DownloadFile } from "../../../assets/download-file.svg"
import { CSVLink } from "react-csv"
import {
  searchOnCompaniesData,
  sortKpisByAccountingType,
  sortKpisByAllFdCount,
  sortKpisByBuy,
  sortKpisByCreatedAt,
  sortKpisByDeactivated,
  sortKpisByName,
  sortKpisByPending,
  sortKpisByReference,
  sortKpisBySell,
  sortKpisBySiren,
  sortKpisByfdOnPeriod,
} from "../../../utils/fiduciary"
import { ReactComponent as Search } from "../../../assets/search.svg"
import AutoSizer from "react-virtualized-auto-sizer"
import { VariableSizeList } from "react-window"
import { TableWrapper } from "../../../components/dropDocuments/StyledDropsComponents"
import { InputDateRange } from "../../../components/Commons/InputDateRange"

const headerTableHeight = 20
const headerTableWidthOffset = 40

export const Indicators = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const intl = useIntl()

  const firstDayOfMonth = DateTime.now().set({ day: 1 }).toJSDate()
  const currentDate = DateTime.now().toJSDate()
  const [range, setRange] = useState<[Date, Date] | null>([
    firstDayOfMonth,
    currentDate,
  ])

  const userTypology = useRNBSelector((state) => state.user.typology)
  if (userTypology === "customer" || userTypology === "customer_accountant")
    navigate("/unauthorized")

  const companies: Companies = useRNBSelector(
    (state) => state.companies.companies
  )
  const fiduciary = useRNBSelector((state) => state.fiduciary)
  const files = useRNBSelector((state) => state.kpis.files)

  const [search, setSearch] = useState("")
  const [asc, setAsc] = useState(true)
  const [sortedCompanies, setSortedCompanies] = useState(
    Object.values(companies)
  )
  const [companiesToDisplay, setCompaniesToDisplay] = useState<KPIFile[]>([])
  const [CSVData, setCSVData] = useState<
    {
      name: string
      deposit_range: number
      deposit: number
      buy: number
      sell: number
      pending: number
      deactivated: number
    }[]
  >([])

  const listRef = React.createRef<VariableSizeList>()
  const rowHeights = useRef<number[]>([])

  const fiduciaryCreationDate = new Date(
    fiduciary.created_at !== ""
      ? fiduciary.created_at
      : DateTime.now().set({ day: 1 }).toJSDate()
  )

  const TableHeaders = [
    { label: "", key: "name" },
    { label: "Tous les dépôts", key: "deposit" },
    { label: "Dépôts sur la période", key: "deposit_range" },
    { label: "Achats sur la période", key: "buy" },
    { label: "Ventes sur la période", key: "sell" },
    { label: "En cours sur la période", key: "pending" },
    { label: "GED sur la période", key: "deactivated" },
  ]

  const CSVHeaders = [
    { label: "Nom", key: "name" },
    { label: "Siren", key: "siren" },
    { label: "Référence", key: "accounting_software_reference" },
    { label: "Créée par", key: "created_by" },
    { label: "Créée le", key: "created_at" },
    { label: "Type de dossier", key: "accounting_type" },
    { label: "Tous les dépôts", key: "deposit" },
    { label: "Dépôts sur la période", key: "deposit_range" },
    { label: "Achats sur la période", key: "buy" },
    { label: "Ventes sur la période", key: "sell" },
    { label: "En cours sur la période", key: "pending" },
    { label: "GED sur la période", key: "deactivated" },
  ]

  const getCSVFileName = () => {
    const fiduciaryName = fiduciary.name

    const dateStart = range ? range[0] : firstDayOfMonth
    const dateEnd = range ? range[1] : currentDate

    const firstDateDay =
      dateStart.getDate().toString().length === 1
        ? "0" + dateStart.getDate()
        : dateStart.getDate()
    const firstDateMonth =
      (dateStart.getMonth() + 1).toString().length === 1
        ? "0" + (dateStart.getMonth() + 1)
        : dateStart.getMonth() + 1
    const firstDateYear = dateStart.getFullYear()
    const secondDateDay =
      dateEnd.getDate().toString().length === 1
        ? "0" + dateEnd.getDate()
        : dateEnd.getDate()
    const secondDateMonth =
      (dateEnd.getMonth() + 1).toString().length === 1
        ? "0" + (dateEnd.getMonth() + 1)
        : dateEnd.getMonth() + 1
    const secondDateYear = dateEnd.getFullYear()
    return `indicateurs_${fiduciaryName}_${firstDateDay}-${firstDateMonth}-${firstDateYear}_${secondDateDay}-${secondDateMonth}-${secondDateYear}`
  }

  const generateForCSV = (companiesSearched: KPIFile[]) => {
    const data = companiesSearched.map((company) => {
      return {
        name: company.company_name,
        siren: company.siren,
        accounting_software_reference: company.accounting_software_reference,
        created_by: company.email,
        created_at: company.created_at,
        accounting_type:
          company.accounting_type === "cash_accounting"
            ? "Comptabilité de trésorerie"
            : "Comptabilité d'engagement",
        deposit: company.total_fd_count || 0,
        deposit_range: company.total_fd_period || 0,
        buy: company.buy || 0,
        sell: company.sell || 0,
        pending: company.pending || 0,
        deactivated: company.deactivated || 0,
      }
    })
    setCSVData(data)
  }

  type SortOptionsValues =
    | "companyName"
    | "allFdCount"
    | "fdOnPeriod"
    | "buy"
    | "sell"
    | "pending"
    | "deactivated"
    | "reference"
    | "siren"
    | "createdAt"
    | "accountingType"

  const sorter = (asc: boolean) => (option: SortOptionsValues) => {
    let companiesSorted: KPIFile[] = []
    if (option === "companyName") {
      companiesSorted = sortKpisByName(companiesToDisplay, asc)
    } else if (option === "allFdCount") {
      companiesSorted = sortKpisByAllFdCount(companiesToDisplay, asc)
    } else if (option === "fdOnPeriod") {
      companiesSorted = sortKpisByfdOnPeriod(companiesToDisplay, asc)
    } else if (option === "buy") {
      companiesSorted = sortKpisByBuy(companiesToDisplay, asc)
    } else if (option === "sell") {
      companiesSorted = sortKpisBySell(companiesToDisplay, asc)
    } else if (option === "pending") {
      companiesSorted = sortKpisByPending(companiesToDisplay, asc)
    } else if (option === "deactivated") {
      companiesSorted = sortKpisByDeactivated(companiesToDisplay, asc)
    } else if (option === "reference") {
      companiesSorted = sortKpisByReference(companiesToDisplay, asc)
    } else if (option === "siren") {
      companiesSorted = sortKpisBySiren(companiesToDisplay, asc)
    } else if (option === "createdAt") {
      companiesSorted = sortKpisByCreatedAt(companiesToDisplay, asc)
    } else if (option === "accountingType") {
      companiesSorted = sortKpisByAccountingType(companiesToDisplay, asc)
    }
    setAsc(!asc)
    setCompaniesToDisplay(companiesSorted)
    generateForCSV(companiesSorted)
  }

  const sortOptions: Ct.OptionList<SortOptionsValues> = [
    { value: "companyName", label: "Nom de société", sortType: "string" },
    { value: "allFdCount", label: "Tous les dépôts", sortType: "number" },
    {
      value: "fdOnPeriod",
      label: "Dépôts sur la période",
      sortType: "number",
    },
    { value: "buy", label: "Achats sur la période", sortType: "number" },
    { value: "sell", label: "Ventes sur la période", sortType: "number" },
    { value: "pending", label: "En Cours sur la période", sortType: "number" },
    { value: "deactivated", label: "GED sur la période", sortType: "number" },
    { value: "reference", label: "Référence", sortType: "string" },
    { value: "siren", label: "Siren", sortType: "string" },
    {
      value: "accountingType",
      label: "Type de comptabilité",
      sortType: "string",
    },
    { value: "createdAt", label: "Date de création", sortType: "string" },
  ]

  const onResize = () => {
    if (listRef.current !== null) {
      listRef.current.resetAfterIndex(0)
    }
  }

  const getRowHeight = (index: number) => {
    return rowHeights.current[index] || 150
  }

  const Row = ({
    index,
    style,
  }: {
    index: number
    style: CSSProperties | undefined
  }) => {
    const rowRef = useRef<HTMLDivElement>(null)
    const company: KPIFile = companiesToDisplay[index]

    const setRowHeight = (index: number, size: number) => {
      if (listRef.current !== null) {
        listRef.current.resetAfterIndex(0, true)
        rowHeights.current = { ...rowHeights.current, [index]: size }
      }
    }

    useEffect(() => {
      if (rowRef.current) {
        setRowHeight(index, rowRef.current.clientHeight + 20) // get height for every item
      }
    }, [rowRef])

    return (
      <div style={style}>
        <FileRow
          ref={rowRef}
          company={companies[company.id]}
          companyKPIs={company}
        />
      </div>
    )
  }

  const StyledScroolBar = createGlobalStyle`
    .styled-cross-bar {
      /* FIREFOX */
      scrollbar-color: ${colors.rock} transparent;
      scrollbar-width: thin !important;
  
      /* CHROME */
      ::-webkit-scrollbar {
        width: 6px;
      }
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px transparent;
        border-radius: 3px;
      }
      ::-webkit-scrollbar-thumb {
        background-color: ${colors.rock};
        border-radius: 3px;
        width: 4px;
      }
    }
  `

  useEffect(() => {
    setSortedCompanies(Object.values(companies))
  }, [companies])

  useEffect(() => {
    dispatch(getCurrentUserThunk())
  }, [dispatch])

  useEffect(() => {
    if (fiduciary.id === 0) {
      return
    }
    if (fiduciaryCreationDate) {
      setRange([fiduciaryCreationDate, DateTime.now().toJSDate()])
    }
  }, [fiduciary.id])

  useEffect(() => {
    if (fiduciary.id === 0) {
      return
    }
    dispatch(
      getFiduciaryKPIsThunk(fiduciary.id, {
        startDate: DateTime.fromJSDate(
          range ? range[0] : firstDayOfMonth
        ).toISODate(),
        endDate: DateTime.fromJSDate(
          range ? range[1] : currentDate
        ).toISODate(),
      })
    )
  }, [dispatch, fiduciary.id, range])

  useMemo(() => {
    if (sortedCompanies && sortedCompanies.length > 0 && files) {
      const companiesSearched = searchOnCompaniesData(
        Object.values(files),
        search
      )

      setCompaniesToDisplay(companiesSearched)
      generateForCSV(companiesSearched)
    }
  }, [
    sortedCompanies,
    files,
    range,
    JSON.stringify(Object.values(files)),
    search,
  ])

  return (
    <Wrapper>
      <StyledSection>
        <Header>
          <Ct.Input
            label="Rechercher"
            value={search}
            suffix={<Search />}
            maxWidth={30}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
            shadowed={true}
            noBorder={true}
          />
          <GrowingDiv>
            <InputDateRange
              value={range}
              onChange={(value) => {
                if (value !== null) setRange(value)
              }}
            />
          </GrowingDiv>
          <HeaderWrapperRight>
            <StyledCSVLink
              data={CSVData}
              headers={CSVHeaders}
              filename={getCSVFileName()}
            >
              <DownloadFile />
              <Ct.Spacer width={1} />
              <Ct.Text
                text={intl.formatMessage({
                  id: "fiduciary.indicators.generate-csv",
                })}
                textStyle={{
                  fontWeight: 500,
                  fontSize: 2,
                  color: "white",
                  cursor: "pointer",
                }}
              />
            </StyledCSVLink>
            <Ct.Spacer width={3} />
            <SortButton
              sortMethod={sorter(asc)}
              options={sortOptions}
              defaultOption={sortOptions[0]}
              intl={intl}
              asc={!asc}
            />
          </HeaderWrapperRight>
        </Header>

        <TableWrapper>
          <AutoSizer onResize={onResize}>
            {({ height, width }: { height: number; width: number }) => {
              return (
                <>
                  <HeaderTable totalWidth={width - headerTableWidthOffset}>
                    {TableHeaders.map((header, index) => (
                      <Head key={index}>
                        {index === 0 ? (
                          <>
                            <Ct.Spacer height={0} width={12} />
                            <Ct.Spacer height={0} width={12} />
                            <Ct.Spacer height={0} width={12} />
                          </>
                        ) : (
                          <>
                            <Ct.Spacer height={0} width={1} />
                            <Ct.Text
                              text={header.label}
                              textStyle={{
                                fontWeight: 600,
                              }}
                            />
                          </>
                        )}
                      </Head>
                    ))}
                  </HeaderTable>

                  <StyledScroolBar />
                  <VariableSizeList
                    ref={listRef}
                    height={height - headerTableHeight}
                    itemCount={companiesToDisplay.length}
                    itemSize={getRowHeight}
                    width={width}
                    className="styled-cross-bar"
                  >
                    {Row}
                  </VariableSizeList>
                </>
              )
            }}
          </AutoSizer>
        </TableWrapper>
      </StyledSection>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding-bottom: 4rem;
  display: flex;
  box-sizing: border-box;
  height: 100%;
`

const StyledSection = styled.section`
  border-radius: 0 2.5rem 2.5rem;
  background-color: ${colors.white};
  box-shadow: ${boxShadow};
  padding: 2rem 2rem 4rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem;
`

const GrowingDiv = styled.div`
  width: 300px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
const HeaderWrapperRight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledCSVLink = styled(CSVLink)`
  height: 6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 2rem;
  border-radius: 1.25rem;
  background-image: linear-gradient(125.36deg, #5982f3 5.35%, #8dcdf8 94.88%);
`

const HeaderTable = styled.div<{
  totalWidth: number
}>`
  width: ${({ totalWidth }) => `${totalWidth}px`};
  height: ${headerTableHeight}px;
  display: flex;
  padding: 2rem 0 2rem 2rem;
  margin: 0 1.5rem;
`
const Head = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
