import { Company } from "../store/ducks/companies.ducks"
import {
  MerchantCode,
  MerchantCodeToCreate,
  MerchantsOfCode,
} from "../store/ducks/merchantCodes.ducks"
import { colors } from "../styles/design.config"
import { capitalizeFirstLetter } from "./string"

export const sortMerchantCodes = (merchantCodes: MerchantCode[]) => {
  const centralized = merchantCodes.filter((c) => c.centralize === true)
  const notCentralized = merchantCodes.filter(
    (c) => c.centralize === false && c.merchants && c.merchants.length > 0
  )
  const noMerchants = merchantCodes.filter(
    (c) => c.centralize === false && c.merchants && c.merchants.length === 0
  )

  return [
    ...centralized.sort(sortByCode),
    ...notCentralized.sort(sortByCode),
    ...noMerchants.sort(sortByCode),
  ]
}

const sortByCode = (a: MerchantCode, b: MerchantCode) => {
  return a.code.localeCompare(b.code)
}

export const filterMerchantCodes =
  (search: string) => (merchantCode: MerchantCode) => {
    return search.length === 0
      ? merchantCode
      : merchantCode.code.toLowerCase().includes(search.toLowerCase()) ||
          merchantCode?.merchants?.find((m) =>
            m.merchant_name.toLowerCase().includes(search.toLowerCase())
          )
  }

export const addPrefixToCode = (company: Company) => {
  let accountingPrefix = { buy: "", sell: "" }
  if (company.accounting_software === "acd") {
    accountingPrefix.buy = "F"
    accountingPrefix.sell = "C"
  } else if (
    !company.auxiliary ||
    company.accounting_software === "isacompta"
  ) {
    accountingPrefix.buy = "401"
    accountingPrefix.sell = "411"
  }

  accountingPrefix.buy += company.buy_auxiliary_prefix
  accountingPrefix.sell += company.sell_auxiliary_prefix

  return accountingPrefix
}

export const normalizeMerchantCode = (string: string) => {
  return string
    .replace(/@/g, "A")
    .toUpperCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/ /g, "")
    .replace(/\W|/g, "")
    .replace(/[-']/g, "")
    .replace(/\s\s+/g, " ")
}

export const sortMerchantCodesByCode = (
  merchantCodes: MerchantCode[],
  asc: boolean
) => {
  return merchantCodes.sort((a, b) =>
    asc ? a.code.localeCompare(b.code) : b.code.localeCompare(a.code)
  )
}

export const sortMerchantCodesByTiers = (
  merchantCodes: MerchantCode[],
  asc: boolean
) => {
  let sortedCodes: MerchantCode[] = []

  const codesWithTiers = merchantCodes.filter(
    (code) => code.merchants.length > 0
  )

  const codesWithoutTiers = merchantCodes.filter(
    (code) => code.merchants.length === 0
  )

  const sortedCodesWithTiers = codesWithTiers.sort((a, b) =>
    sortMerchantCodeMerchants(a.merchants)
      .join(", ")
      .localeCompare(sortMerchantCodeMerchants(b.merchants).join(", "))
  )

  sortedCodes.push(...sortedCodesWithTiers)
  sortedCodes.push(...codesWithoutTiers)

  return asc ? sortedCodes : sortedCodes.reverse()
}

export const sortMerchantCodeMerchants = (merchants: MerchantsOfCode[]) => {
  const sortedMerchants = merchants.sort((a, b) =>
    a.merchant_name.localeCompare(b.merchant_name)
  )
  return sortedMerchants.map((m) => capitalizeFirstLetter(m.merchant_name))
}

/* eslint-disable camelcase */
export const parsePastedContentToMerchantCodes = (
  pastedContent: string
): MerchantCodeToCreate[] => {
  const separators = ["\t", ";"]

  const pastedMerchantCodes: MerchantCodeToCreate[] = []

  pastedContent
    .replace(/\r/g, "")
    .split("\n")
    .forEach((line) => {
      const separator =
        separators.find((s) => line.includes(s)) || separators[0]
      const values = line.split(separator)

      if (values.length >= 1 && values.length <= 2) {
        const code = values[0]
        if (code !== "") {
          pastedMerchantCodes.push({
            code: normalizeMerchantCode(code),
            centralize: false,
            auxiliary: false,
            merchants: [],
            merchant_ids: [],
            default_buy_code: false,
            default_sell_code: false,
          })
        }
      }
    })

  return pastedMerchantCodes
}

export const merchantCodeHasDuplicates = (
  merchantCodesToCreate: MerchantCodeToCreate[],
  code: string
) => {
  if (code === "") return false

  let count = 0
  for (const item of merchantCodesToCreate) {
    if (item.code === code) {
      count++
      if (count > 1) return true
    }
  }

  return false
}

export const getBackgroundColorMcCode = (
  merchantCodesToCreate: MerchantCodeToCreate[],
  merchantCodesSet: Set<string>,
  hoveredForDeletionIndex: number,
  index: number,
  mc: MerchantCodeToCreate
): string => {
  if (index === hoveredForDeletionIndex) return "rgba(252, 90, 90, 0.2)"
  else if (
    merchantCodesSet.has(mc.code) ||
    (index !== merchantCodesToCreate.length - 1 &&
      mc.code === "" &&
      !mc.centralize &&
      mc.merchant_ids.length === 0 &&
      !mc.default_buy_code &&
      !mc.default_sell_code)
  )
    return "rgba(252, 90, 90, 0.3)"
  else if (
    merchantCodeHasDuplicates(merchantCodesToCreate, mc.code) ||
    (mc.code === "" &&
      (mc.centralize ||
        mc.merchant_ids.length > 0 ||
        mc.default_buy_code ||
        mc.default_sell_code))
  )
    return "rgba(252, 150, 90, 0.4)"
  else return colors.white
}

export const getBackgroundColorMcOtherInput = (
  merchantCodesToCreate: MerchantCodeToCreate[],
  hoveredForDeletionIndex: number,
  index: number,
  mc: MerchantCodeToCreate,
  defaultColor: string
): string => {
  if (index === hoveredForDeletionIndex) return "rgba(252, 90, 90, 0.2)"
  else if (
    index !== merchantCodesToCreate.length - 1 &&
    mc.code === "" &&
    !mc.centralize &&
    mc.merchant_ids.length === 0 &&
    !mc.default_buy_code &&
    !mc.default_sell_code
  )
    return "rgba(252, 90, 90, 0.3)"
  else return defaultColor
}

export const reduceMerchantCode = (code: string, length: number) => {
  return code.substring(0, length)
}

export const checkReducibleMerchantCodes = (
  merchantCodes: MerchantCode[],
  targetedMerchantCodeLength: number
) => {
  const reducibleMerchantCodes: MerchantCode[] = []
  const unreducibleMerchantCodes: MerchantCode[] = []
  const reducibleMerchantCodesSet: Set<string> = new Set(
    merchantCodes
      .filter((a) => a.code.length <= targetedMerchantCodeLength)
      .map((a) => a.code)
  )

  for (const merchantCode of merchantCodes) {
    if (merchantCode.code.length > targetedMerchantCodeLength) {
      const reducedCode = reduceMerchantCode(
        merchantCode.code,
        targetedMerchantCodeLength
      )
      if (reducibleMerchantCodesSet.has(reducedCode))
        unreducibleMerchantCodes.push(merchantCode)
      else {
        reducibleMerchantCodesSet.add(reducedCode)
        reducibleMerchantCodes.push(merchantCode)
      }
    } else unreducibleMerchantCodes.push(merchantCode)
  }

  return { reducibleMerchantCodes, unreducibleMerchantCodes }
}

export const removePrefixMerchantCode = (code: string, prefix: string) => {
  return code.replace(new RegExp(`^${prefix}`), "")
}

export const checkRemovePrefixMerchantCodes = (
  merchantCodes: MerchantCode[],
  prefixToRemove: string
) => {
  const reducibleMerchantCodes: MerchantCode[] = []

  for (const merchantCode of merchantCodes) {
    if (
      merchantCode.code.length > prefixToRemove.length &&
      merchantCode.code.startsWith(prefixToRemove)
    ) {
      reducibleMerchantCodes.push(merchantCode)
    }
  }

  return { reducibleMerchantCodes }
}
