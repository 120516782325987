import { useIntl } from "react-intl"

import * as Ct from "ldlj"
import { useDispatch } from "react-redux"
import { Alert } from "../Commons/Alert"
import { createWritingArchiveThunk } from "../../store/ducks/writings.ducks"
import { colors, sizes } from "../../styles/design.config"
import { useEffect, useState } from "react"
import { useRNBSelector } from "../../store/rootReducer"
import { isOnboardingFinishedThunk } from "../../store/ducks/companySettings.ducks"
import ReactTooltip from "react-tooltip"
import styled from "styled-components/macro"
import { Switch } from "../Commons/Switch"
import { ReactComponent as CloseCross } from "../../assets/close-cross.svg"

interface ConfirmVerificationModalModalProps extends Ct.ModalComponentsProps {
  onVerifyWritings: () => void
  selectedFiscalYearId: number
  selectedCompanyId: number
  sendToApi: (selectedSendToApi: boolean) => void
}

export const ConfirmVerificationModalModal = ({
  isDisplayed,
  onClose,
  selectedFiscalYearId,
  selectedCompanyId,
  onVerifyWritings,
  sendToApi,
}: ConfirmVerificationModalModalProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const [selectedSendToApi, setSelectedSendToApi] = useState(true)

  const companyOnboarding = useRNBSelector(
    (state) => state.companySettings.isOnboardingFinished
  )
  const isConnectedToApi = useRNBSelector(
    (state) => state.companies.companies[selectedCompanyId]?.is_connected_to_api
  )

  useEffect(() => {
    if (selectedCompanyId)
      dispatch(isOnboardingFinishedThunk(selectedCompanyId))
  }, [dispatch, selectedCompanyId])

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={onClose}
      left="50%"
      right="50%"
      top="calc(50vh - 30rem)"
    >
      <StyledCard width="110rem">
        <StyledHeader>
          <div />

          <StyledTitle
            text={intl.formatMessage({
              id: "writings.confirm-verification.title",
            })}
          />

          <ModalClose onClick={() => onClose()}>
            <CloseCross />
          </ModalClose>
        </StyledHeader>

        <Ct.Spacer height={3} />
        <Ct.Separator size="full" />
        <Ct.Spacer height={3} />

        <Alert alertType="info">
          <Ct.Text
            text={intl.formatMessage({
              id: "writings.confirm-verification.description.1",
            })}
            textStyle={{ lineHeight: 3 }}
          />
          <Ct.Text
            text={intl.formatMessage({
              id: "writings.confirm-verification.description.2",
            })}
            textStyle={{ lineHeight: 3 }}
          />
        </Alert>
        {isConnectedToApi && (
          <>
            <Ct.Spacer height={4} />
            <Wrapper>
              <Switch
                value={selectedSendToApi}
                onToggle={() => {
                  setSelectedSendToApi(!selectedSendToApi)
                }}
                size={"normal"}
              />
              <Ct.Spacer width={2} />
              <Ct.Text
                text={intl.formatMessage({
                  id: `writings.send-to-api.${selectedSendToApi}`,
                })}
                textStyle={{
                  fontSize: 2,
                }}
              />
            </Wrapper>
          </>
        )}

        <Ct.Spacer height={4} />

        <WrapperButtons>
          <ReactTooltip
            effect={"solid"}
            backgroundColor={colors.lavender}
            textColor={colors.navy}
            borderColor={colors.desertStorm}
          />
          <Ct.Button
            width={sizes.button.standard}
            colorType={"Tertiary"}
            colorScheme={{
              background: "mist",
              color: "cornflower",
              border: "mist",
            }}
            label={intl.formatMessage({
              id: "writings.confirm-verification.button.verify",
            })}
            onClick={() => {
              onVerifyWritings()
              if (isConnectedToApi) {
                sendToApi(selectedSendToApi)
              }
              onClose()
            }}
            disabled={companyOnboarding === "unfinished"}
          />
          <Ct.Spacer height={4} />
          <Ct.Button
            width={sizes.button.standard}
            label={intl.formatMessage({
              id: "writings.confirm-verification.button.generate",
            })}
            onClick={() => {
              if (isConnectedToApi) {
                dispatch(
                  createWritingArchiveThunk(
                    selectedFiscalYearId,
                    selectedCompanyId,
                    selectedSendToApi
                  )
                )
              } else {
                dispatch(
                  createWritingArchiveThunk(
                    selectedFiscalYearId,
                    selectedCompanyId
                  )
                )
              }

              onClose()
            }}
          />
        </WrapperButtons>
      </StyledCard>
    </Ct.Modal>
  )
}

const StyledCard = styled((props) => <Ct.Card {...props} />)`
  padding: 3.5rem 0;
  border-radius: 2rem;
`
const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100rem;
`
const StyledTitle = styled((props) => <Ct.Title {...props} />)`
  font-size: 24px;
`
const ModalClose = styled.div`
  cursor: pointer;
`
const Wrapper = styled.div`
  display: flex;
  width: 60%;
  align-items: center;
`
const WrapperButtons = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`
