/* eslint-disable camelcase */
import styled from "styled-components/macro"
import * as Ct from "ldlj"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"

import { Option, Select } from "../Commons/Select"
import { useRNBSelector } from "../../store/rootReducer"
import {
  getGroupedLines,
  getLinesOptions,
  getOptionsInfo,
  LineToAdd,
} from "../../utils/inputMask"
import {
  createLineAction,
  defaultAccounts,
} from "../../store/ducks/inputMask.ducks"
import { GroupOfLines } from "./GroupOfLines"
import { getIdFromParams } from "../../utils/company"
import { useParams } from "react-router-dom"
import { colors } from "../../styles/design.config"
import { useEffect } from "react"
import { isOnboardingFinishedThunk } from "../../store/ducks/companySettings.ducks"
import { getUnitsThunk } from "../../store/ducks/invoicing.duck"

export const WritingLines = () => {
  const selectedCompanyId = getIdFromParams(useParams())("company_id") || 0
  const dispatch = useDispatch()
  const intl = useIntl()

  const companyOnboarding = useRNBSelector(
    (state) => state.companySettings.isOnboardingFinished
  )

  useEffect(() => {
    if (selectedCompanyId)
      dispatch(isOnboardingFinishedThunk(selectedCompanyId))
    dispatch(getUnitsThunk(selectedCompanyId))
  }, [dispatch, selectedCompanyId])

  const {
    buyThirdPartyAccount,
    editedWritingLines,
    buyOrSell,
    possibleAccounts,
    document_type,
  } = useRNBSelector((state) => {
    const buyThirdPartyAccountId =
      state.companies.companies[selectedCompanyId].buy_third_party_account_id
    const taxExcludedBillOfExchangeAccounts =
      state.inputMask.all_company_accounts?.buy.tax_excluded_bill_of_exchange

    return {
      buyThirdPartyAccount:
        taxExcludedBillOfExchangeAccounts?.find(
          (a) => a.id === buyThirdPartyAccountId
        ) || taxExcludedBillOfExchangeAccounts
          ? taxExcludedBillOfExchangeAccounts[0]
          : null,
      editedWritingLines: state.inputMask.edited_writing_lines,
      buyOrSell: state.inputMask.edited_full_document?.buy_or_sell,
      document_type: state.inputMask.edited_full_document?.document_type,
      possibleAccounts: state.inputMask.all_company_accounts
        ? state.inputMask.all_company_accounts[
            state.inputMask.edited_full_document?.buy_or_sell || "buy"
          ]
        : defaultAccounts,
    }
  })

  const strOptions = document_type ? getLinesOptions(document_type) : []

  const addWritingLineOptions: Option<LineToAdd>[] = strOptions.map((l) => {
    const { isOptionEnabled, tooltipKey } = getOptionsInfo({
      option: l,
      buyOrSell,
      editedWritingLines,
      possibleAccounts,
      companyOnboarding,
    })
    return {
      value: l,
      label: intl.formatMessage({ id: `input-mask.select-add.options.${l}` }),
      disabled: !isOptionEnabled || companyOnboarding === "unfinished",
      tooltipText: tooltipKey ? intl.formatMessage({ id: tooltipKey }) : "",
    }
  })

  const sortedWritingLines =
    document_type === "bill_of_exchange"
      ? editedWritingLines.sort((a, b) => {
          if (a.datum_type === "tax_included_bill_of_exchange") {
            return 1
          }
          if (b.datum_type === "tax_included_bill_of_exchange") {
            return -1
          }
          return a.writing_line_uuid.localeCompare(b.writing_line_uuid)
        })
      : editedWritingLines

  const groupedWritingLines = getGroupedLines(sortedWritingLines)

  const showAddLineHint = groupedWritingLines.length === 1

  return (
    <WritingLinesWrapper>
      {showAddLineHint && (
        <>
          <Hint>
            {intl.formatMessage({ id: "input-mask.select-add.hint" })}
          </Hint>
          <Ct.Spacer />
        </>
      )}
      <StyledTableHeader>
        <SizedContent>
          <Select
            height="5rem"
            maxwidth="20rem"
            intl={intl}
            value={null}
            defaultValue={null}
            options={addWritingLineOptions}
            onChangeCallback={(e) => {
              dispatch(
                createLineAction({
                  selectedOption: e.value as LineToAdd,
                  possibleAccounts,
                  buyThirdPartyAccount,
                })
              )
            }}
            label={"accounting_software"}
            domain={"input-mask"}
            optionType={"add-line"}
          />
        </SizedContent>
        <SizedTitle
          text={intl.formatMessage({
            id: `input-mask.table-headers.account`,
          })}
          textStyle={{
            fontWeight: 700,
            fontSize: 1.75,
            textTransform: "uppercase",
            fontFamily: "Poppins",
            textAlign: "center",
          }}
        />
        <SizedTitle
          text={intl.formatMessage({
            id: `input-mask.table-headers.debit`,
          })}
          textStyle={{
            fontWeight: 700,
            fontSize: 1.75,
            textTransform: "uppercase",
            fontFamily: "Poppins",
            textAlign: "center",
          }}
          maxWidth={"30rem"}
        />
        <SizedTitle
          text={intl.formatMessage({
            id: `input-mask.table-headers.credit`,
          })}
          textStyle={{
            fontWeight: 700,
            fontSize: 1.75,
            textTransform: "uppercase",
            fontFamily: "Poppins",
            textAlign: "center",
          }}
          maxWidth={"30rem"}
        />
      </StyledTableHeader>
      {groupedWritingLines.map((group, index) => (
        <GroupOfLines
          key={group[0]?.writing_line_uuid}
          group={group}
          isLast={index === groupedWritingLines.length - 1}
        />
      ))}
    </WritingLinesWrapper>
  )
}

const WritingLinesWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  box-shadow: 0px 4px 11px 7px #e5e5e540;
  border-radius: 1rem;
  align-self: flex-start;
`

const SizedTitle = styled(Ct.StyledTitles)<{
  maxWidth?: string
}>`
  flex: 1;
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "auto")};
`
const SizedContent = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 24rem;
`

const StyledTableHeader = styled(Ct.TableHeader)`
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
`

const Hint = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 2rem;
  width: 36rem;
  height: 3.5rem;

  background-color: ${colors.orange};
  color: ${colors.white};
  font-size: 1.75rem;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  border-radius: 3px;
  :before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;

    border-left: 0.75rem solid transparent;
    border-right: 0.75rem solid transparent;

    border-top: 0.75rem solid ${colors.orange};

    left: 1.5rem;
    top: 3.5rem;
  }
`
