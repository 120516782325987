import * as Ct from "ldlj"
import styled from "styled-components/macro"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useIntl } from "react-intl"

import { ReactComponent as CloseCross } from "../../assets/close-cross.svg"
import { ReactComponent as Cross } from "../../assets/cross.svg"
import { ReactComponent as SquareCheck } from "../../assets/square-check.svg"
import { ReactComponent as SmallArrowRight } from "../../assets/small-arrow-right.svg"
import { colors } from "../../styles/design.config"

import {
  checkReducibleAccounts,
  reduceAccountNumber,
  sortAccountsByNumber,
} from "../../utils/accounts"
import { getIdFromParams } from "../../utils/company"

import { GetDataToUpdateAccountingSoftwareThunk } from "../../store/ducks/companySettings.ducks"
import { useRNBSelector } from "../../store/rootReducer"
import {
  Account,
  reduceAccountsReset,
  getAllAccountsThunk,
  reduceAccountsThunk,
} from "../../store/ducks/accounts.ducks"

import { LockableInput } from "../Commons/LockableInput"
import { Text } from "../Commons/Text"

/* eslint-disable camelcase */

interface ReduceAccountsModalProps {
  onClose: () => void
  isDisplayed: boolean
}

export const ReduceAccountsModal = ({
  isDisplayed,
  onClose,
}: ReduceAccountsModalProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const selectedCompanyId = getIdFromParams(useParams())("company_id")
  const modalWidth = 120

  const company = useRNBSelector(
    (state) => state.companies.companies[selectedCompanyId || 0]
  )
  const accounts = useRNBSelector((state) => state.accounts.all_accounts)
  const reduceAccountsStatus = useRNBSelector(
    (state) => state.accounts.reduceAccountsStatus
  )

  const [targetedAccountNumberLength, setTargetedAccountNumberLength] =
    useState<string>("")
  const [maxAccountNumberLength, setMaxAccountNumberLength] = useState(0)
  const [validNumberLength, setValidNumberLength] = useState(false)
  const [showAccounts, setShowAccounts] = useState(false)
  const [reducibleAccounts, setReducibleAccounts] = useState<Account[]>([])
  const [unreducibleAccounts, setUnreducibleAccounts] = useState<Account[]>([])

  const handleFormSubmit = () => {
    if (showAccounts) {
      if (selectedCompanyId)
        dispatch(
          reduceAccountsThunk(
            selectedCompanyId,
            parseInt(targetedAccountNumberLength)
          )
        )
    } else {
      const processedAccounts = checkReducibleAccounts(
        accounts,
        parseInt(targetedAccountNumberLength)
      )
      setUnreducibleAccounts(processedAccounts.unreducibleAccounts)
      setReducibleAccounts(processedAccounts.reducibleAccounts)
      setShowAccounts(true)
    }
  }

  const disableSaveButton = () => {
    if (showAccounts) return reducibleAccounts.length === 0
    else return !validNumberLength
  }

  const goBackToLengthSelect = () => {
    setShowAccounts(false)
    setReducibleAccounts([])
    setUnreducibleAccounts([])
  }

  const resetForm = () => {
    dispatch(reduceAccountsReset())
    setTargetedAccountNumberLength("")
    setValidNumberLength(false)
    goBackToLengthSelect()
  }

  const closeModal = () => {
    resetForm()
    onClose()
  }

  useEffect(() => {
    if (selectedCompanyId) {
      dispatch(getAllAccountsThunk(selectedCompanyId))
    }
  }, [])

  useEffect(() => {
    if (company?.accounting_software && selectedCompanyId) {
      dispatch(
        GetDataToUpdateAccountingSoftwareThunk(
          selectedCompanyId,
          company.accounting_software,
          company.buy_auxiliary_prefix || "",
          company.sell_auxiliary_prefix || ""
        )
      )
    }
  }, [company])

  useEffect(() => {
    if (targetedAccountNumberLength !== "") {
      setValidNumberLength(
        parseInt(targetedAccountNumberLength) < maxAccountNumberLength
      )
    } else setValidNumberLength(targetedAccountNumberLength !== "")
  }, [targetedAccountNumberLength])

  useEffect(() => {
    if (reduceAccountsStatus === "SUCCESS") closeModal()
  }, [reduceAccountsStatus, maxAccountNumberLength])

  useEffect(() => {
    const maxLength = accounts.reduce((max, account) => {
      return Math.max(max, account.number.length)
    }, 0)
    setMaxAccountNumberLength(maxLength)
  }, [accounts])

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={closeModal}
      left="50%"
      right="50%"
      top={`calc(30vh - 30rem)`}
    >
      <StyledCard width={`${modalWidth}rem`}>
        <StyledHeader width={modalWidth}>
          <div />

          <StyledTitle
            text={intl.formatMessage({
              id: `accounting-plan.accounts.reduce.modal.title`,
            })}
          />

          <ModalClose onClick={() => closeModal()}>
            <CloseCross />
          </ModalClose>
        </StyledHeader>

        <Ct.Spacer height={3} />
        <Ct.Separator size="full" />
        <Ct.Spacer height={3} />

        <LengthSelectWrapper width={modalWidth}>
          <Text
            text={intl.formatMessage({
              id: "accounting-plan.accounts.reduce.modal.desc",
            })}
            textStyle={{
              textAlign: "center",
              fontSize: 2,
            }}
          />

          <Ct.Spacer height={3} />

          <InputWrapper>
            <LockableInput
              type="number"
              label={intl.formatMessage({
                id: "accounting-plan.accounts.extend.modal.input.label",
              })}
              value={targetedAccountNumberLength}
              placeholder={intl.formatMessage({
                id: "accounting-plan.accounts.extend.modal.input.label",
              })}
              maxWidth="100%"
              locked={showAccounts}
              readOnly={showAccounts}
              onChange={(e) => {
                let value = e.target.value.replaceAll(".", "")
                value =
                  value !== ""
                    ? `${parseInt(value) > 0 ? parseInt(value) : 1}`
                    : ""
                setTargetedAccountNumberLength(value)
              }}
            />
            <Ct.Spacer height={1} />

            {targetedAccountNumberLength !== "" && !validNumberLength && (
              <Text
                text={intl.formatMessage(
                  { id: "accounting-plan.accounts.reduce.modal.error.length" },
                  { maxAccountNumberLength: maxAccountNumberLength }
                )}
                textStyle={{ color: "amaranth" }}
              />
            )}
          </InputWrapper>
        </LengthSelectWrapper>

        {showAccounts && (
          <ListsAccountsWrapper width={modalWidth}>
            <Ct.Spacer height={2} />
            <Text
              text={intl.formatMessage({
                id: "accounting-plan.accounts.reduce.modal.reducible.title",
              })}
              textStyle={{
                fontSize: 2,
              }}
            />
            <Ct.Spacer height={2} />
            <GridContainer columns={3}>
              {sortAccountsByNumber(reducibleAccounts, true).map((ra) => (
                <Item key={`reducibleAccounts-${ra.id}`}>
                  <StyledSquareCheck />
                  <Ct.Spacer width={1} />
                  <StyledItemText
                    text={ra.number}
                    textStyle={{ fontWeight: 500 }}
                  />
                  <Ct.Spacer width={1} />
                  <SmallArrowRight />
                  <Ct.Spacer width={2} />
                  <StyledItemText2
                    text={reduceAccountNumber(
                      ra.number,
                      parseInt(targetedAccountNumberLength)
                    )}
                    textStyle={{ fontWeight: 500, textAlign: "center" }}
                  />
                </Item>
              ))}
            </GridContainer>

            {disableSaveButton() && (
              <Text
                text={intl.formatMessage({
                  id: "accounting-plan.accounts.reduce.modal.reducible.none",
                })}
                textStyle={{
                  fontSize: 2,
                  color: "amaranth",
                }}
              />
            )}

            <Ct.Spacer height={3} />

            {unreducibleAccounts.length > 0 && (
              <>
                <Text
                  text={intl.formatMessage({
                    id: "accounting-plan.accounts.reduce.modal.unreducible.title",
                  })}
                  textStyle={{
                    fontSize: 2,
                  }}
                />
                <Ct.Spacer height={2} />
                <GridContainer columns={5}>
                  {unreducibleAccounts.map((ua) => (
                    <Item key={`unreducibleAccounts-${ua.id}`}>
                      <StyledCross />
                      <Ct.Spacer width={1} />
                      <StyledItemText
                        text={ua.number}
                        textStyle={{ fontWeight: 500 }}
                      />
                    </Item>
                  ))}
                </GridContainer>
                <Ct.Spacer height={2} />
                <Text
                  text={intl.formatMessage({
                    id: "accounting-plan.accounts.reduce.modal.unreducible",
                  })}
                  textStyle={{
                    fontSize: 2,
                    color: "amaranth",
                  }}
                />
              </>
            )}
          </ListsAccountsWrapper>
        )}

        <Ct.Spacer height={3} />

        <ButtonsPanel>
          <Ct.Button
            label={intl.formatMessage({
              id: showAccounts
                ? "accounting-plan.accounts.extend.modal.back-to-length"
                : "accounting-plan.accounts.modal.button.back",
            })}
            colorType="Tertiary"
            width={42}
            onClick={() => {
              if (showAccounts) goBackToLengthSelect()
              else closeModal()
            }}
            colorScheme={{
              border: "mist",
              color: "cornflower",
              background: "mist",
            }}
          />
          <Ct.Button
            label={intl.formatMessage({
              id: showAccounts
                ? "accounting-plan.accounts.reduce.modal.confirm"
                : "accounting-plan.accounts.reduce.modal.continue",
            })}
            colorType="Primary"
            width={42}
            onClick={() => {
              handleFormSubmit()
            }}
            disabled={disableSaveButton()}
            colorScheme={{
              border: "mist",
              color: "white",
              background: "mist",
            }}
            loadingStatus={
              reduceAccountsStatus === "LOADING" ? "loading" : "idle"
            }
          />
        </ButtonsPanel>
      </StyledCard>
    </Ct.Modal>
  )
}

const StyledCard = styled((props) => <Ct.Card {...props} />)`
  padding: 3.5rem 0;
  border-radius: 2rem;
`
const StyledHeader = styled.div<{ width: number }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${({ width }) => `${width - 10}rem`};
`
const StyledTitle = styled((props) => <Ct.Title {...props} />)`
  font-size: 24px;
`
const ModalClose = styled.div`
  cursor: pointer;
`
const LengthSelectWrapper = styled.div<{ width: number }>`
  width: ${({ width }) => `${width - 30}rem`};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const InputWrapper = styled.div`
  width: 70%;
`

const ListsAccountsWrapper = styled.div<{ width: number }>`
  width: ${({ width }) => `${width - 20}rem`};
`

const GridContainer = styled.div<{ columns: number }>`
  width: 100%;
  max-height: 24rem;
  overflow: auto;
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
  gap: 1rem;

  /* FIREFOX */
  scrollbar-color: ${colors.rock} transparent;
  scrollbar-width: thin !important;

  /* CHROME */
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${colors.rock};
    border-radius: 3px;
    width: 4px;
  }
`
const Item = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  text-align: center;
  padding-right: 4rem;
`
const StyledSquareCheck = styled(SquareCheck)`
  height: 3rem;
`
const StyledCross = styled(Cross)`
  height: 1.5rem;
  & path {
    fill: ${colors.amaranth};
  }
`

const StyledItemText = styled(Text)`
  flex-grow: 1.2;
`
const StyledItemText2 = styled(Text)`
  flex-grow: 1;
`

const ButtonsPanel = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100rem;
`
